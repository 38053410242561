const agriculturePropertyTypes = [
    'Farm',
    'Fishfarm',
    'Forestry',
    'Horticulture/Garden Centre',
    'Greenhouse',
    'Country',
    'Equestrian',
    'Other Agriculture',
];

export default agriculturePropertyTypes;
