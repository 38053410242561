import {
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WVWTitle, WvwText } from './typography';
import { WvwButton } from './inputs';

type PropTypes = {
    cancelButtonVariant?: 'danger' | 'default' | 'link' | 'primary' | 'success';
    cancelButton?: string;
    content: string;
    continueButton?: string;
    continueButtonVariant?: 'danger' | 'default' | 'link' | 'primary' | 'success';
    isOpen: boolean;
    hideCloseButton?: boolean;
    hideContinueButton?: boolean;
    subTitle?: string;
    subTitleIcon?: ReactNode;
    subTitleTwo?: ReactNode;
    title: string;
    onCancel?: () => void;
    onContinue?: () => void;
};

const ConfirmationModal = (props: PropTypes) => {
    const {
        cancelButton,
        cancelButtonVariant,
        content,
        continueButton,
        continueButtonVariant,
        hideCloseButton,
        hideContinueButton,
        isOpen,
        subTitle,
        subTitleIcon,
        subTitleTwo,
        title,
        onCancel = () => { },
        onContinue = () => { },
    } = props;

    const { t } = useTranslation(['common']);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onCancel}
            size="lg"
        >
            <ModalOverlay />

            <ModalContent alignItems="center">
                <ModalHeader color="wvwGreen">
                    {title}
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody
                    paddingBottom="1rem"
                    textAlign="center"
                >
                    <VStack spacing="3">
                        {subTitle && (
                            <HStack>
                                {subTitleIcon}

                                <WVWTitle
                                    content={subTitle}
                                    level="3"
                                />
                            </HStack>
                        )}

                        {subTitleTwo}

                        <WvwText>
                            {content}
                        </WvwText>
                    </VStack>
                </ModalBody>

                <ModalFooter w="100%">
                    <Flex
                        gap="2"
                        justifyContent="space-between"
                        w="100%"
                    >
                        {!hideCloseButton && (
                            <WvwButton
                                block
                                content={cancelButton || t('button.close', { ns: 'common' })}
                                onClick={onCancel}
                                variant={cancelButtonVariant}
                            />
                        )}

                        {!hideContinueButton && (
                            <WvwButton
                                block
                                content={continueButton || t('button.continue', { ns: 'common' })}
                                onClick={onContinue}
                                variant={continueButtonVariant}
                            />
                        )}
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

ConfirmationModal.defaultProps = {
    cancelButton: undefined,
    cancelButtonVariant: 'danger',
    continueButtonVariant: 'success',
    continueButton: 'Continue',
    hideCloseButton: false,
    hideContinueButton: false,
    subTitle: undefined,
    subTitleIcon: undefined,
    subTitleTwo: undefined,
    onCancel: () => {},
    onContinue: () => {},
};

export default ConfirmationModal;
