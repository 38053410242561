import { Accordion } from '@chakra-ui/react';
import { DocumentRequestTemplateType } from '../../../../../types';
import TemplateListItem from './TemplateListItem';

type PropTypes = {
    caseId: number;
    templateList: DocumentRequestTemplateType[];
};

const TemplateList = (props: PropTypes) => {
    const { caseId, templateList } = props;

    return (
        <Accordion
            allowMultiple
            variant="primary"
            w="100%"
        >
            {templateList.map(template => (
                <TemplateListItem
                    caseId={caseId}
                    key={template.id}
                    template={template}
                />
            ))}
        </Accordion>
    );
};

export default TemplateList;
