/* eslint-disable no-nested-ternary */
import {
    VStack,
    Divider,
    Heading,
    Flex,
    Text,
    Spacer,
    Button,
    Box,
    HStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PublicUserSlider from '../../../../common/components/user-details/PublicUserSlider';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import delimitNumber from '../../../../common/functions/delimitNumber';
import {
    useCaseGetCase,
} from '../../../../utils/api/hooks';
import { CaseType } from '../../../../types';
import PublicUserProfilePicture from '../../../../common/components/PublicUserProfilePicture';
import Comment from '../../../../common/components/Comment';

const offerTitles = [
    'Price',
    'PI Cover',
    'Timeline',
    'Availability',
    'Properties',
];

const CaseOffer = () => {
    const { t } = useTranslation(['offer', 'common']);

    const { caseId = '' } = useParams();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const {
        data: caseData = {} as CaseType,
        isLoading: caseLoading,
    } = useCaseGetCase(caseId);

    if (caseLoading) return <LoadingSpinner />;

    const acceptedOffer = caseData?.acceptedOffer;

    return (
        <>
            <Box
                w="100%"
                bg="white"
                borderRadius="10"
            >
                <VStack
                    paddingInline="2.5rem"
                    paddingBottom="1rem"
                    align="left"
                >
                    <>
                        <Flex
                            alignItems="center"
                        >
                            <Flex
                                alignItems="center"
                            >
                                <PublicUserProfilePicture
                                    entityId={acceptedOffer?.fromEntity?.id}
                                    userId={acceptedOffer?.fromValuer.id}
                                    size="4rem"
                                />

                                {!acceptedOffer && (
                                    <Heading
                                        as="h4"
                                        size="md"
                                        color="wvwGrey"
                                        paddingBlock=".8rem"
                                    >
                                        {t('error.nameUnavailable', { ns: 'common' })}
                                    </Heading>
                                )}

                                {acceptedOffer && (
                                    <VStack
                                        spacing="0"
                                        align="left"
                                        paddingBlock=".8rem"
                                        pl="1rem"
                                    >
                                        <Heading
                                            as="h4"
                                            fontSize="1.5rem"
                                            color="wvwGreen"
                                        >
                                            {`${acceptedOffer?.fromValuer.firstName} ${acceptedOffer?.fromValuer.lastName}`}
                                        </Heading>

                                        <Heading
                                            as="h4"
                                            size="6rem"
                                            color="wvwGreen80"
                                        >
                                            {t('offerDetails.fromCompany', { company: acceptedOffer.fromEntity?.name })}
                                        </Heading>

                                        <Button
                                            variant="none"
                                            size="max-content"
                                            color="wvwYellow"
                                            alignSelf="flex-start"
                                            fontWeight="400"
                                            onClick={() => setIsDrawerOpen(true)}
                                        >
                                            {t('button.viewValuer', { ns: 'common' })}
                                        </Button>
                                    </VStack>
                                )}
                            </Flex>

                            <Spacer />

                            <Text
                                fontSize="1.1rem"
                                color="green"
                                bgColor="green10"
                                borderRadius="10"
                                paddingInline=".5rem"
                                fontWeight="bold"
                            >
                                {t('status.ACCEPTED')}
                            </Text>
                        </Flex>

                        <Divider />

                        {caseLoading && (
                            <LoadingSpinner />
                        )}

                        {!acceptedOffer && (
                            <Text>
                                {t('error.offer.loadingFailed', { ns: 'common' })}
                            </Text>
                        )}

                        {acceptedOffer && (
                            <VStack
                                align="left"
                                paddingBottom="1rem"
                            >
                                {offerTitles.map(title => (
                                    <Fragment key={title}>
                                        <Flex
                                            paddingInline="1rem"
                                        >
                                            <HStack w="17rem">
                                                <Text>
                                                    {t(`offerTitle.${title}`, { defaultValue: title })}
                                                </Text>
                                            </HStack>
                                            <Text
                                                w="20rem"
                                            >
                                                <>
                                                    {title === 'Price' && `${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(acceptedOffer.price)}`}
                                                    {title === 'PI Cover' && `${t('piCover_or_statement', {
                                                        ns: 'common',
                                                        coverValue: acceptedOffer?.piCoverValue === 0 || null ? '-' : delimitNumber(acceptedOffer?.piCoverValue?.toString()),
                                                        coverPercentage: acceptedOffer?.piCoverPercentage === 0 || null ? '-' : acceptedOffer?.piCoverPercentage,
                                                    })}`}
                                                    {title === 'Timeline' && `${acceptedOffer.timeline} ${t('offerForm.timeline.days')}`}
                                                    {title === 'Availability' && acceptedOffer.availability}
                                                    {title === 'Properties' && caseData.enquiry.properties?.map(property => (
                                                        <Text
                                                            key={property.id}
                                                        >
                                                            {`${property.street}, ${property.city}`}
                                                        </Text>
                                                    ))}
                                                </>
                                            </Text>
                                        </Flex>

                                        <Divider />
                                    </Fragment>
                                ))}

                                <VStack
                                    bg="wvwGrey05"
                                    borderRadius="10"
                                    align="left"
                                    spacing="4"
                                    padding="1rem"
                                >
                                    <Heading
                                        as="h4"
                                        size="sm"
                                    >
                                        {t('offerDetails.sectionTitle')}
                                    </Heading>

                                    <Text
                                        color={acceptedOffer.comments ? '' : 'wvwGrey40'}
                                    >
                                        {acceptedOffer.comments ? acceptedOffer.comments : t('comments.noDetails')}
                                    </Text>
                                </VStack>

                                <VStack
                                    align="left"
                                    spacing="4"
                                    paddingBlock="1rem"
                                >
                                    <Heading
                                        as="h4"
                                        size="sm"
                                    >
                                        {t('comments.sectionTitle')}
                                    </Heading>

                                    {acceptedOffer.offerComments?.map(comment => (
                                        <Comment
                                            key={comment.id}
                                            message={comment.message}
                                            readBy={comment.readBy}
                                            createdAt={comment.createdAt}
                                            id={comment.id}
                                            fromUserId={comment.fromId}
                                            notification={false}
                                        />
                                    ))}
                                </VStack>
                            </VStack>

                        )}
                    </>
                </VStack>
            </Box>

            <PublicUserSlider
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                userId={acceptedOffer?.fromValuer.id}
            />
        </>
    );
};

export default CaseOffer;
