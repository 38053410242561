import { useMutation } from 'react-query';
import { DocumentRequestCreateDocumentRequest } from '../../dto';
import { documentRequestCreateDocumentRequest } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';

type ParamsType = {
    caseId: number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestCreateDocumentRequest = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast, createSuccessToast } = useToast();

    const { refreshAll } = useRefreshAllDMSDataStores(caseId);

    const { mutate: update } = useMutation(
        (dto: DocumentRequestCreateDocumentRequest) => documentRequestCreateDocumentRequest(dto),
        {
            onSuccess: () => {
                refreshAll();

                createSuccessToast(t('toast.messages.requestCreated'));

                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestCreateDocumentRequest;
