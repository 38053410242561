import { VStack } from '@chakra-ui/react';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import ClientOffersOverview from './common/ClientOffersOverview';
import ValuerOffersOverview from './common/ValuerOffersOverview';
import { useRole } from '../../../../common/hooks';

const OffersOverview = () => {
    const { userIsClient, userIsValuer, roleLoading } = useRole();

    if (roleLoading) return <LoadingSpinner />;

    return (
        <VStack
            align="left"
            w="100%"
        >
            {userIsClient && <ClientOffersOverview />}

            {userIsValuer && <ValuerOffersOverview />}
        </VStack>
    );
};

export default OffersOverview;
