import {
    VStack,
    Button,
    Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormikForm, FormikTextarea } from '../forms';
import Comment from './Comment';

type CommentType = {
    id: number;
    createdAt: Date;
    fromUserId: number | string;
    readBy: number[];
    message: string;
};

type PropTypes = {
    title?: string;
    commentsList: CommentType[];
    onSend: (values: { comment: string }) => void;
    markAsRead?: () => void;
};

const CommentsThread = (props: PropTypes) => {
    const { t } = useTranslation('documentmanagement');

    const {
        title,
        commentsList,
        onSend,
        markAsRead,
    } = props;

    return (
        <VStack
            spacing={4}
            w="100%"
            align="flex-start"
            onMouseEnter={() => {
                if (markAsRead) {
                    markAsRead();
                }
            }}
        >
            <Heading
                as="h3"
                fontWeight="normal"
                fontSize="2xl"
                alignSelf="flex-start"
            >
                {title || t('comments', { ns: 'common' })}
            </Heading>

            <FormikForm
                initialValues={{
                    comment: '',
                }}
                onSubmit={(values, formikHelpers) => {
                    onSend(values as { comment: string });
                    formikHelpers?.resetForm();
                }}
            >
                <VStack
                    spacing={4}
                    w="100%"
                >
                    <FormikTextarea
                        name="comment"
                    />

                    <Button
                        type="submit"
                        alignSelf="flex-end"
                        variant="primaryYellow"
                    >
                        {t('button.send', { ns: 'common' })}
                    </Button>
                </VStack>
            </FormikForm>

            <VStack
                maxH="24rem"
                overflowY="scroll"
                w="100%"
                marginBottom="1rem"
            >
                {commentsList.sort((a, b) => {
                    if (a.createdAt > b.createdAt) return -1;
                    if (a.createdAt < b.createdAt) return 1;
                    return 0;
                }).map(comment => (
                    <Comment
                        key={comment.id}
                        message={comment.message}
                        readBy={comment.readBy}
                        createdAt={comment.createdAt}
                        id={comment.id}
                        fromUserId={comment.fromUserId}
                        notification
                    />
                ))}
            </VStack>
        </VStack>

    );
};

CommentsThread.defaultProps = {
    markAsRead: () => { },
    title: '',
};

export default CommentsThread;
