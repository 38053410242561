import {
    VStack,
    Flex,
    Heading,
    Spacer,
    Box,
    Text,
    Divider,
    Button,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { UpdateEntityDto } from '../../../../utils/api/dto';
import { useMyEntity } from '../../../../utils/api/hooks';
import { FormikColorPicker, FormikForm } from '../../../../common/forms';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('errors.required'),
    background: Yup.string().required('errors.required'),
    text: Yup.string().required('errors.required'),
    highlights: Yup.string().required('errors.required'),
    notifications: Yup.string().required('errors.required'),
    links: Yup.string().required('errors.required'),
    buttons: Yup.string().required('errors.required'),
    secondaryButtons: Yup.string().required('errors.required'),
    success: Yup.string().required('errors.required'),
    error: Yup.string().required('errors.required'),
});

const CompanyCustomize = () => {
    const { t } = useTranslation(['companyCustomize', 'formik']);

    const {
        data: entity,
        // isLoading,
        updateEntity,
    } = useMyEntity();

    return (
        <VStack
            w="100%"
            align="left"
            paddingInline=".5rem"
            paddingBlock="2rem"
            spacing="10"
        >
            <Flex w="100%">
                <VStack
                    align="left"
                    w="25%"
                    alignSelf="flex-start"
                    spacing="6"
                >
                    <Box>
                        <Heading
                            as="h4"
                            size="md"
                            fontWeight="light"
                        >
                            {t('makeYourOwn')}
                        </Heading>

                        <Text
                            color="wvwGrey"
                            paddingTop="1rem"
                        >
                            {t('customizeSoftware')}
                        </Text>
                    </Box>
                </VStack>

                <Spacer />

                <FormikForm
                    initialValues={{
                        name: entity?.name,
                        background: entity?.themeBackgroundColor || '#D8D8D9',
                        text: entity?.themeTextColor || '#3E3E40',
                        highlights: entity?.themeHighlightColor || '#FF9F43',
                        notifications: entity?.themeNotificationColor || '#FF9F43',
                        links: entity?.themeLinkColor || '#2D546C',
                        buttons: entity?.themeButtonColor || '#FF9F43',
                        secondaryButtons: entity?.themeSecondaryButtonColor || '#F8F8F8',
                        success: entity?.themeSuccessColor || '#2DCD7A',
                        error: entity?.themeErrorColor || '#DA5E5A',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        const dto: UpdateEntityDto = {
                            name: values.name,
                            themeBackgroundColor: values.background,
                            themeTextColor: values.text,
                            themeHighlightColor: values.highlights,
                            themeNotificationColor: values.notifications,
                            themeLinkColor: values.links,
                            themeButtonColor: values.buttons,
                            themeSecondaryButtonColor: values.secondaryButtons,
                            themeSuccessColor: values.success,
                            themeErrorColor: values.error,
                        };

                        updateEntity(dto);
                    }}
                >
                    <VStack
                        paddingTop="1rem"
                        align="start"
                        spacing="6"
                        paddingLeft="1rem"
                    >
                        <VStack w="100%" align="left" spacing="3">
                            <Heading color="wvwGreen" size="md" fontWeight="bold">
                                {t('changeColors')}
                            </Heading>

                            <FormikColorPicker
                                name="background"
                                label={t('background')}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="text"
                                label={t('text')}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="highlights"
                                label={t('highlights')}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="notifications"
                                label={t('notifications', { ns: 'companyCustomize' })}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="links"
                                label={t('links')}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="buttons"
                                label={t('buttons')}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="secondaryButtons"
                                label={t('secondaryButtons')}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="success"
                                label={t('success', { ns: 'companyCustomize' })}
                            />
                            <Divider />

                            <FormikColorPicker
                                name="error"
                                label={t('error', { ns: 'companyCustomize' })}
                            />
                        </VStack>

                        <Button
                            variant="primary"
                            alignSelf="flex-end"
                        >
                            {t('update')}
                        </Button>
                    </VStack>
                </FormikForm>
            </Flex>
        </VStack>
    );
};

export default CompanyCustomize;
