import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { DocumentRequestCreateCommentDto } from '../../dto';
import { documentRequestCreateComment } from '../../endpoints';
import useDocumentRequestGetComments from './useDocumentRequestGetComments';
import useToast from '../../../../common/hooks/useToast';

type ParamsType = {
    requestId: string | number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestCreateComment = (params: ParamsType) => {
    const {
        requestId,
        onSuccess,
        onError,
    } = params;

    const { t } = useTranslation('documentmanagement');

    const { createErrorToast } = useToast();

    const { refresh: refreshComments } = useDocumentRequestGetComments(requestId);

    const { mutate: update } = useMutation(
        (dto: DocumentRequestCreateCommentDto) => documentRequestCreateComment(dto),
        {
            onSuccess: () => {
                refreshComments();
                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestCreateComment;
