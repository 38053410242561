import {
    Box,
    Button,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ArrowRight } from 'iconsax-react';
import { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import InspectionDate from '../common/InspectionDate';
import { FormikForm } from '../../../../../common/forms';
import FormikDateSelect from '../../../../../common/forms/FormikDateSelect';
import FormikTimeSelect from '../../../../../common/forms/FormikTimeSelect';
import SplitLayout from '../../../../../common/components/SplitLayout';
import { useInspectionAddTentativeDate } from '../../../../../utils/api/hooks';
import { useToast } from '../../../../../common/hooks';

const validationSchema = Yup.object({
    date: Yup.string().required('errors.required'),
    time: Yup.string().required('errors.required'),
});

type DateType = {
    date: string;
    time: string;
};

const SelectDates = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('inspection');
    const { caseId, inspectionId } = useParams();

    const { createErrorToast } = useToast();

    const { update: submitDates } = useInspectionAddTentativeDate({
        caseId: Number(caseId),
        onSuccess: () => navigate('..', { replace: true }),
    });

    const [tentativeDates, setTentativeDates] = useState<DateType[]>([]);

    return (
        <ModalDynamic
            header="Select Dates"
            isOpen
            onClose={() => navigate('..', { replace: true })}
            variant="medium"
        >
            <SplitLayout
                dividerIcon={ArrowRight}
                titleRight={t('title.selectedDates')}
                contentLeft={(
                    <FormikForm
                        validationSchema={validationSchema}
                        initialValues={{
                            date: '',
                            time: '',
                        }}
                        onSubmit={(values, formikHelpers) => {
                            setTentativeDates([...tentativeDates,
                                { date: values.date, time: values.time },
                            ]);
                            formikHelpers?.resetForm();
                        }}
                    >
                        <VStack
                            align="start"
                            w="100%"
                        >
                            <Text>
                                {t('selectTentativeDates', { ns: 'inspection' })}
                            </Text>

                            <Box
                                bgColor="wvwGrey05"
                                borderRadius="10"
                                padding="1rem"
                                height="20rem"
                            >
                                <FormikDateSelect name="date" />
                            </Box>

                            <FormikTimeSelect name="time" />

                            <Button
                                variant="primaryYellow"
                                alignSelf="flex-end"
                                type="submit"
                            >
                                {t('button.add', { ns: 'common' })}
                            </Button>
                        </VStack>
                    </FormikForm>
                )}
                contentRight={(
                    <VStack
                        align="left"
                        w="100%"
                        pl="1rem"
                    >
                        <VStack
                            width="100%"
                            maxHeight="30rem"
                            h="100%"
                            overflowY="scroll"
                        >
                            {tentativeDates.length === 0 && (
                                <Text color="wvwGrey40">
                                    {t('noDatesAdded', { ns: 'inspection' })}
                                </Text>
                            )}
                            {tentativeDates.map(date => (
                                <InspectionDate
                                    key={date.date}
                                    dateAndTime={date}
                                    onDelete={() => {
                                        setTentativeDates(tentativeDates.filter(
                                            d => d.date !== date.date,
                                        ));
                                    }}
                                />
                            ))}
                        </VStack>

                        <Button
                            variant="primaryYellow"
                            alignSelf="flex-end"
                            onClick={() => {
                                if (tentativeDates.length === 0) {
                                    createErrorToast(t('error.noDatesSelected'));

                                    return;
                                }

                                const dates = tentativeDates.map(i => {
                                    // convert to Zulu time
                                    const date = i.date.split('/').reverse().join('-');
                                    const time = i.time.split(':');

                                    const dateString = `${date}T${time[0]}:${time[1]}:00.000Z`;

                                    return dateString;
                                });

                                submitDates({
                                    dates: dates.map(date => ({
                                        date,
                                        duration: 60,
                                    })),
                                    target: Number(inspectionId),
                                });
                            }}
                        >
                            {t('button.submit', { ns: 'common' })}
                        </Button>
                    </VStack>
                )}
            />
        </ModalDynamic>
    );
};

export default SelectDates;
