import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetUserFileList } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_USER_FILE_LIST = 'DOCUMENT_REQUEST_GET_USERS_FILE_LIST';

const useDocumentRequestGetUserFileList = (caseId: string | number) => {
    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_USER_FILE_LIST, caseId],
        () => documentRequestGetUserFileList(caseId),
        {
            onError: (err: Error) => createErrorToast(
                t(err.message, { defaultValue: err.message }),
            ),
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_USER_FILE_LIST);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetUserFileList;
