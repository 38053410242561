import { useQuery, useQueryClient } from 'react-query';
import { enquiryGetAll } from '../endpoints';

const MY_ENQUIRIES = 'MY_ENQUIRIES';

const useEnquiryGetAll = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([MY_ENQUIRIES], () => enquiryGetAll());

    const refresh = () => {
        queryClient.invalidateQueries(MY_ENQUIRIES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useEnquiryGetAll;
