function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

function rotateSize(width: number, height: number, rotation: number) {
    const rotRad = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}

const converUriToFile = (URI: string) => {
    const byteString = atob(URI.split(',')[1]);
    const mimeString = URI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], 'profile.jpg', { type: mimeString });
};

const getCroppedImage = (
    srcImage: string,
    croppedArea: { x: number; y: number; width: number; height: number; },
    returnFile?: boolean,
): File | string => {
    const image: HTMLImageElement = new Image();
    image.src = srcImage;
    const rotation = 0;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return '';
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation,
    );
    const flip = { horizontal: false, vertical: false };

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
        croppedArea.x,
        croppedArea.y,
        croppedArea.width,
        croppedArea.height,
    );

    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;

    ctx.putImageData(data, 0, 0);

    if (returnFile) {
        return converUriToFile(canvas.toDataURL('image/jpeg'));
    }

    return canvas.toDataURL('image/jpeg');
};

export default getCroppedImage;
