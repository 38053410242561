import {
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RequestCreation from './RequestCreation';
import TemplateCreation from './TemplateCreation';
import DocumentUpload from './DocumentUpload';

const ActionAreaValuer = () => {
    const { t } = useTranslation(['documentmanagement']);

    return (
        <Tabs
            paddingRight="1.5rem"
            paddingTop=".2rem"
            variant="wvwGreenUnderline"
        >
            <TabList paddingBottom=".3rem">
                <Tab paddingBlock=".25rem">
                    {t('tabs.requests')}
                </Tab>

                <Tab paddingBlock=".25rem">
                    {t('tabs.templates')}
                </Tab>

                <Tab paddingBlock=".25rem">
                    {t('documents')}
                </Tab>
            </TabList>

            <TabPanels>
                <TabPanel padding="0">
                    <RequestCreation />
                </TabPanel>

                <TabPanel padding="0">
                    <TemplateCreation />
                </TabPanel>

                <TabPanel padding="0">
                    <DocumentUpload />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default ActionAreaValuer;
