import { useMutation } from 'react-query';
import { DocumentRequestUploadDocumentsDto } from '../../dto';
import { documentRequestUploadDocuments } from '../../endpoints';
import storage from '../../../storage/storage';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

type DataType = {
    caseId: number
    documents: {
        requestId: number;
        file: File;
        submissionComment?: string;
    }[];
};

const useDocumentRequestUploadDocuments = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast, createSuccessToast } = useToast();
    const { refreshAll } = useRefreshAllDMSDataStores(Number(caseId));

    const { mutate: update } = useMutation(
        async (data: DataType) => {
            const dto: DocumentRequestUploadDocumentsDto = {
                caseId: data.caseId,
                documents: await Promise.all(data.documents.map(async document => {
                    const result = await storage.uploadDocumentRequestDocument(
                        data.caseId,
                        document.requestId,
                        document.file,
                    );

                    return {
                        requestId: document.requestId,
                        name: document.file.name,
                        url: result,
                        submissionComment: document.submissionComment,
                    };
                })),
            };

            documentRequestUploadDocuments(dto);
        },
        {
            onSuccess: () => {
                refreshAll();
                createSuccessToast(t('toast.messages.documentUploaded'));
                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestUploadDocuments;
