import {
    Flex,
    HStack,
    IconButton,
    Text,
} from '@chakra-ui/react';
import { ArrowCircleLeft, ArrowCircleRight } from 'iconsax-react';

type PropTypes = {
    onNext: () => void;
    onPrevious: () => void;
    title: string;
};

const RequestDetailsHeading = (props: PropTypes) => {
    const { onNext, onPrevious, title } = props;

    return (
        <Flex justifyContent="center">
            <HStack
                marginRight=".5rem"
                spacing="0"
            >
                <IconButton
                    aria-label="back"
                    color="wvwGreen"
                    icon={<ArrowCircleLeft />}
                    onClick={() => onPrevious()}
                    variant="none"
                />

                <Text
                    color="wvwGreen"
                    fontSize="3xl"
                    fontWeight="normal"
                >
                    {title}
                </Text>

                <IconButton
                    aria-label="next"
                    color="wvwGreen"
                    icon={<ArrowCircleRight />}
                    onClick={() => onNext()}
                    variant="none"
                />
            </HStack>
        </Flex>
    );
};

export default RequestDetailsHeading;
