import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DocumentUpload from './DocumentUpload';

const ActionAreaClient = () => {
    const { t } = useTranslation(['documentmanagement']);

    return (
        <VStack
            w="100%"
            spacing="0"
            align="left"
            paddingRight="1.5rem"
        >
            <Text
                paddingTop={1.5}
                color="wvwGreen"
                fontSize="lg"
                fontWeight="500"
                paddingRight="2rem"
            >
                {t('uploadDocument')}
            </Text>

            <DocumentUpload />
        </VStack>
    );
};

export default ActionAreaClient;
