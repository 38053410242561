import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import { ArrowCircleDown } from 'iconsax-react';
import { MouseEventHandler, ReactElement } from 'react';

type PropTypes = {
    options: {
        icon?: ReactElement;
        text: string;
        onClick: MouseEventHandler<HTMLButtonElement>;
    }[];
};

const DropdownMenu = (props: PropTypes) => {
    const { options } = props;

    return (
        <Menu placement="left-start">
            <MenuButton
                _hover={{ bgColor: 'rgba(0, 0, 0, 0.1)' }}
                as={IconButton}
                bgColor="rgba(0, 0, 0, 0)"
                borderRadius="50%"
                borderWidth="0"
                icon={<ArrowCircleDown color="white" variant="Bold" />}
                onClick={e => e.stopPropagation()}
                size="sm"
                variant="primary"
            />

            <MenuList>
                {options.map(option => (
                    <MenuItem
                        icon={(option.icon)}
                        onClick={event => {
                            event.stopPropagation();
                            option.onClick(event);
                        }}
                    >
                        {option.text}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default DropdownMenu;
