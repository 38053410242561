import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetCaseDocuments } from '../../endpoints';
import { DocumentRequestGetCaseDocumentsDto } from '../../dto';
import { DocumentRequestDocumentType } from '../../../../types';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_CASE_DOCUMENTS = 'DOCUMENT_REQUEST_GET_CASE_DOCUMENTS';

type ParamsType = DocumentRequestGetCaseDocumentsDto & {
    includeDeleted?: boolean;
};

const useDocumentRequestGetCaseDocuments = (params: ParamsType) => {
    const {
        caseId,
        requestId,
        documentStatus,
        includeDeleted,
    } = params;

    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const {
        data: caseDocumentsList,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_CASE_DOCUMENTS, caseId],
        () => {
            const dto: DocumentRequestGetCaseDocumentsDto = {
                caseId,
            };

            return documentRequestGetCaseDocuments(dto);
        },
        {
            enabled: !!caseId,
            onError: (err: Error) => {
                createErrorToast(t(err.message, { defaultValue: err.message }));
            },
        },
    );

    let data: DocumentRequestDocumentType[] = caseDocumentsList || [];

    if (requestId) {
        data = data.filter(document => document.documentRequestId === requestId);
    }

    if (documentStatus) {
        data = data.filter(document => document.status === documentStatus);
    }

    if (!includeDeleted) {
        data = data.filter(document => !document.deleted);
    }

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_CASE_DOCUMENTS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetCaseDocuments;
