import { useQuery, useQueryClient } from 'react-query';
import { publicEntity } from '../endpoints';

const PUBLIC_ENTITY = 'PUBLIC_ENTITY';

const useMyValuations = (token: string) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery(PUBLIC_ENTITY, () => publicEntity(token));

    const refresh = () => {
        queryClient.invalidateQueries(PUBLIC_ENTITY);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useMyValuations;
