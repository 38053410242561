import {
    createStandaloneToast,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Heading,
    VStack,
    Text,
    Circle,
    Flex,
    Spacer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { useNotificationGetUnread, useNotificationMarkAsRead } from '../../utils/api/hooks';

type PropType = {
    isOpen: boolean;
    onClose: () => void;
};

const NotificationPanel = (props: PropType) => {
    const {
        isOpen,
        onClose,
    } = props;

    const { t } = useTranslation(['account', 'common']);

    const navigate = useNavigate();

    const { toast } = createStandaloneToast();

    const {
        notificationUnreadList,
        error,
        isLoading,
        refresh,
    } = useNotificationGetUnread(5);

    const {
        update,
    } = useNotificationMarkAsRead({
        onSuccess: (data: any) => {
            refresh();
            navigate(data?.data?.actionLink || '/dashboard');
            onClose();
        },
        onError: err => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: err.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="sm"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader color="wvwGreen">
                    {t('notifications.panelTitle', { ns: 'common' })}
                </DrawerHeader>

                <DrawerBody>
                    <VStack
                        align="left"
                        spacing="4"
                    >
                        <>
                            {error && (
                                <VStack>
                                    <Heading size="sm">
                                        {t('error.weAreSorry')}
                                    </Heading>

                                    <Text>
                                        {t('error.notifications.loadingFailed')}
                                    </Text>
                                </VStack>
                            )}

                            {isLoading && <LoadingSpinner />}

                            {notificationUnreadList?.length === 0 && (
                                <Text>
                                    {t('notifications.noUnreads', { ns: 'common' })}
                                </Text>
                            )}

                            {notificationUnreadList?.map(notification => (
                                <VStack
                                    as="button"
                                    onClick={() => update(notification.id)}
                                    align="left"
                                    spacing="2"
                                    bgColor="wvwGrey05"
                                    padding="1rem"
                                    borderRadius="10"
                                    w="100%"
                                >
                                    <Flex w="100%">
                                        <Heading
                                            size="sm"
                                            textAlign="left"
                                            color={notification.read ? 'wvwGrey60' : 'wvwYellow'}
                                        >
                                            {notification.title}
                                        </Heading>

                                        <Spacer />

                                        {notification.read ? <div /> : <Circle bgColor="wvwYellow" size=".6rem" />}
                                    </Flex>

                                    <Text textAlign="left">
                                        {notification.message}
                                    </Text>
                                </VStack>
                            ))}
                        </>
                    </VStack>
                </DrawerBody>

                <DrawerFooter>
                    <Button
                        variant="primaryYellow"
                        onClick={() => {
                            navigate('/dashboard/notifications');
                            onClose();
                        }}
                    >
                        {t('button.seeAll', { ns: 'common' })}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default NotificationPanel;
