import {
    Box,
    Button,
    Container,
    Heading,
    Link as ChakraLink,
    Text,
    VStack,
    createStandaloneToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import { DirectEnquiryDataType } from './types';
import { usePublicCreateEnquiry, usePublicEntity } from '../../utils/api/hooks';
import CongratulationsWindow from '../../common/components/CongratulationsWindow';
import DirectEnquiryOverview from './DirectEnquiryOverview';
import DirectEnquiryRegistration from './DirectEnquiryRegistration';
import DirectPropertyDescription from './DirectPropertyDescription';
import DirectValuationType from './DirectValuationType';
import EnquiryContainer from './EnquiryContainer';
import Footer from '../../common/components/Footer';
import SiteLoading from '../../common/components/SiteLoading';
import DirectEnquiryLogo from './DirectEnquiryLogo';

const { REACT_APP_GOOGLE_RECAPTCHA_KEY = '' } = process.env;

const STEPS = [
    'VALUATION',
    'PROPERTY_DETAILS',
    'CONTACT_DETAILS',
    'OVERVIEW',
    'SUCCESS',
];

const [
    VALUATION,
    PROPERTY_DETAILS,
    CONTACT,
    OVERVIEW,
    SUCCESS,
] = STEPS;

const CreateDirectEnquiry = () => {
    const navigate = useNavigate();
    const { token = '' } = useParams();
    const { toast } = createStandaloneToast();
    const { t } = useTranslation('enquiryform');
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [step, setStep] = useState(VALUATION);
    const [enquiryData, setEnquiryData] = useState({} as DirectEnquiryDataType);

    const {
        data: targetValuer,
        isLoading,
    } = usePublicEntity(token);

    const { update } = usePublicCreateEnquiry({
        onSuccess: () => {
            const nextStep = STEPS[STEPS.indexOf(step) + 1];

            if (nextStep) {
                setStep(nextStep);
            } else {
                navigate('/dashboard');
            }
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const onBack = () => {
        const index = STEPS.indexOf(step);

        if (index > 0) {
            setStep(STEPS[index - 1]);
        }
    };

    const onContinue = (values: DirectEnquiryDataType, goToNextStep = true) => {
        const nextStep = STEPS[STEPS.indexOf(step) + 1];

        setEnquiryData({
            ...enquiryData,
            ...values,
            fromEntityId: targetValuer?.id || -1,
        });

        if (!goToNextStep) return;

        if (nextStep) {
            setStep(nextStep);
        } else {
            navigate('/dashboard');
        }
    };

    const onDone = async (data: DirectEnquiryDataType) => {
        const recaptchaToken = await recaptchaRef.current?.executeAsync();

        update({
            ...data,
            toEntityId: targetValuer?.id || -1,
            recaptchaToken: recaptchaToken || '',
        });
    };

    if (isLoading) {
        return (
            <SiteLoading
                noLogo
                text={t('loading', { ns: 'common' })}
            />
        );
    }

    const stepNames = STEPS.filter(i => i !== SUCCESS);

    if (step === SUCCESS) {
        return (
            <Box
                h="100%"
                w="100%"
                marginTop="-0.7rem"
                paddingTop="0.7rem"
                bg="white"
            >
                <CongratulationsWindow
                    logo={(
                        <DirectEnquiryLogo
                            companyId={targetValuer?.id || -1}
                            size="6rem"
                        />
                    )}
                >
                    <Heading
                        as="h4"
                        color="white"
                        maxW="30rem"
                        size="sm"
                        textAlign="center"
                    >
                        {t('directEnquirySuccessPrompt.sectionTitle')}
                    </Heading>

                    <Text padding="1rem">
                        {t('directEnquirySuccessPrompt.blurb', { valuerName: targetValuer?.name })}
                    </Text>

                    <ChakraLink
                        as={ReactLink}
                        to="/login"
                    >
                        <Button variant="primary">
                            {t('directEnquirySuccessPrompt.loginNow')}
                        </Button>
                    </ChakraLink>
                </CongratulationsWindow>
            </Box>
        );
    }

    return (
        <>
            <Container
                marginBlock="3"
                maxW="container.lg"
            >
                <VStack
                    align="left"
                    paddingBlock=".8rem"
                    w="100%"
                >
                    <EnquiryContainer
                        currentStep={step}
                        steps={stepNames}
                    >
                        {step === VALUATION && (
                            <DirectValuationType
                                enquiryData={enquiryData}
                                onContinue={onContinue}
                            />
                        )}

                        {step === PROPERTY_DETAILS && (
                            <DirectPropertyDescription
                                enquiryData={enquiryData}
                                onBack={onBack}
                                onContinue={onContinue}
                            />
                        )}

                        {step === CONTACT && (
                            <DirectEnquiryRegistration
                                enquiryData={enquiryData}
                                onBack={onBack}
                                onContinue={onContinue}
                            />
                        )}

                        {step === OVERVIEW && (
                            <DirectEnquiryOverview
                                enquiryData={enquiryData}
                                onBack={onBack}
                                onDone={onDone}
                                valuerName={targetValuer?.name as string}
                            />
                        )}
                    </EnquiryContainer>
                </VStack>
            </Container>

            <Footer />

            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
                size="invisible"
            />
        </>
    );
};

export default CreateDirectEnquiry;
