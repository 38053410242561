import {
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdminEnquiryDetails from './common/AdminEnquiryDetails';
import { useAdminEntities, useAdminGetEnquiry } from '../../../utils/api/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import AdminMatchingValuers from './common/AdminMatchingValuers';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const AdminEnquiry = () => {
    const { enquiryId = '' } = useParams<{ enquiryId: string }>();

    const { t } = useTranslation('admin');

    const {
        data: enquiry,
        isLoading,
    } = useAdminGetEnquiry(enquiryId);

    const {
        data: matchingValuers = [],
        isLoading: matchingValuersLoading,
    } = useAdminEntities({
        entityIds: enquiry?.toEntityIds || [],
    });

    if (isLoading || matchingValuersLoading || !enquiry) {
        return <LoadingSpinner />;
    }

    const {
        billingCity,
        billingCountry,
        billingStreet,
    } = enquiry;

    return (
        <PageWithTitleLayout title={`Enquiry: ${billingStreet}, ${billingCity}, ${billingCountry}`}>
            <Tabs w="100%">
                <TabList>
                    <Tab>
                        {t('tabHeading.details')}
                    </Tab>

                    <Tab>
                        {t('tabHeading.matchedValuers')}
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <AdminEnquiryDetails enquiry={enquiry} />
                    </TabPanel>

                    <TabPanel>
                        <AdminMatchingValuers
                            enquiry={enquiry}
                            matchingValuers={matchingValuers}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </PageWithTitleLayout>
    );
};

export default AdminEnquiry;
