import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { EntityType } from '../../../../../types';
import { LabelAndData } from '../../../../../common/components/display';
import delimitNumber from '../../../../../common/functions/delimitNumber';
import { WvwLink } from '../../../../../common/components/inputs';

type PropTypes = {
    entity: EntityType;
};

const AdminCompanyDetails = (props: PropTypes) => {
    const { entity } = props;

    const {
        accountType,
        city,
        companyEmail,
        companyOrIndividual,
        contactNumber,
        contactNumberDialCode,
        country,
        name,
        piCoverCurrency,
        piCoverPercentage,
        piCoverValue,
        postalCode,
        street,
        websiteAddress = '',
    } = entity;

    const { t } = useTranslation('account');

    return (
        <VStack
            align="start"
            spacing="3"
            w="100%"
        >
            <LabelAndData
                label="Entity ID"
                data={entity.id}
            />

            <LabelAndData
                label="Name"
                data={name}
            />

            <LabelAndData
                label="Entity Type"
                data={accountType}
            />

            <LabelAndData
                label="Company or Individual"
                data={companyOrIndividual}
            />

            <LabelAndData
                label={t('companyDetails.website')}
                data={(
                    <WvwLink
                        content={websiteAddress}
                        isExternal
                        to={websiteAddress}
                    />
                )}
            />

            <LabelAndData
                label={t('companyDetails.email')}
                data={companyEmail}
            />

            <LabelAndData
                label={t('companyDetails.phoneNumber')}
                data={`${contactNumberDialCode} ${contactNumber}`}
            />

            <LabelAndData
                label={t('companyDetails.location')}
                data={`${street}, ${city}, ${country}, ${postalCode}`}
            />

            <LabelAndData
                label={t('valuationCapabilities.piCover.piCover')}
                data={t('valuationCapabilities.piCover.or', {
                    coverValue: piCoverValue && Number(piCoverValue) > 0 ? (`${piCoverCurrency || '€'} ${delimitNumber(piCoverValue.toString())}`) : ('-'),
                    coverPercentage: piCoverPercentage === 0 || null ? ('-') : (`maximum ${piCoverPercentage}${t('valuationCapabilities.piCover.p_of_value')},`),
                    interpolation: { escapeValue: false },
                })}
            />
        </VStack>
    );
};

export default AdminCompanyDetails;
