import {
    Box,
    Button,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Text,
    HStack,
    IconButton,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../../common/components/Logo';
import WaterMarkBox from '../../../common/components/WaterMarkBox';
import LanguageToggleButton from '../../../common/components/LanguageToggleButton';
import Footer from '../../../common/components/Footer';
import { FlowContext } from '../../../common/components/display/ProcessFlowStateful';

type PropTypes = {
    onContinue: (values: { [index: string]: string }) => void;
};

const SelectValuerOrClient = (props: PropTypes) => {
    const { t } = useTranslation(['account', 'common']);
    const { onContinue } = props;

    const navigate = useNavigate();

    const { goNext } = useContext(FlowContext);

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.lg"
                >
                    <Box
                        bg="white"
                        borderRadius="lg"
                        p="2em"
                        w="100%"
                        marginTop="1.5rem"
                    >
                        <Flex>
                            <HStack>
                                <IconButton
                                    aria-label="Back"
                                    color="white"
                                    icon={<RiArrowGoBackFill />}
                                    size="xs"
                                    variant="primary"
                                    onClick={() => navigate('../', { replace: true })}
                                />

                                <Button
                                    color="wvwGreen"
                                    variant="link"
                                    onClick={() => navigate('../', { replace: true })}
                                >
                                    {t('button.back', { ns: 'common' })}
                                </Button>
                            </HStack>

                            <Spacer />

                            <LanguageToggleButton />
                        </Flex>

                        <VStack
                            spacing="8"
                            pb="9em"
                            pt="6em"
                            w="100%"
                        >
                            <Logo withColor />

                            <Heading
                                as="h1"
                                color="wvwGreen"
                                size="xl"
                            >
                                {t('howCanWeHelp')}
                            </Heading>

                            <Text align="center">
                                {t('WVWHasYouCovered')}
                            </Text>

                            <Grid
                                maxW="container.md"
                                gap="4em"
                                templateColumns="repeat(auto-fit, minmax(230px, 1fr))"
                                w="100%"
                                textAlign="center"
                            >
                                <GridItem p="1em">
                                    <Text
                                        paddingTop="1rem"
                                        paddingBottom="1rem"
                                        minH="7em"
                                    >
                                        {t('offeringValuations')}
                                    </Text>

                                    <Button
                                        size="lg"
                                        variant="primary"
                                        w="100%"
                                        onClick={() => {
                                            onContinue({
                                                accountType: 'VALUER',
                                                companyOrIndividual: 'COMPANY',
                                            });
                                            goNext();
                                        }}
                                    >
                                        {t('iAmAValuer')}
                                    </Button>
                                </GridItem>

                                <GridItem p="1em">
                                    <Text
                                        paddingTop="1rem"
                                        paddingBottom="1rem"
                                        minH="7em"
                                    >
                                        {t('needingValuations')}
                                    </Text>

                                    <Button
                                        size="lg"
                                        variant="primary"
                                        w="100%"
                                        onClick={() => {
                                            onContinue({ accountType: 'CLIENT' });
                                            goNext();
                                        }}
                                    >
                                        {t('lookingForValuation')}
                                    </Button>
                                </GridItem>
                            </Grid>
                        </VStack>
                    </Box>
                </Container>

                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

export default SelectValuerOrClient;
