import { useQuery, useQueryClient } from 'react-query';
import useToast from '../../../../common/hooks/useToast';
import { documentRequestGetRequests } from '../../endpoints';
import { GetRequestsDto } from '../../dto';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import { DocumentRequestType } from '../../../../types';

const DOCUMENT_REQUEST_GET_REQUESTS = 'DOCUMENT_REQUEST_GET_REQUESTS';

const useDocumentRequestGetRequests = (params: GetRequestsDto) => {
    const { caseId, folderId, status } = params;

    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');

    const { createErrorToast } = useToast();

    const {
        data: requestList,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_REQUESTS, caseId],
        () => {
            const dto = {
                caseId,
            };

            return documentRequestGetRequests(dto);
        },
        {
            enabled: !!params.caseId,
            onError: (err: Error) => {
                createErrorToast(t(err.message, { defaultValue: err.message }));
            },
        },
    );

    let data: DocumentRequestType[] = requestList || [];

    if (folderId) {
        data = data.filter(request => request.folderId === folderId);
    }

    if (status) {
        data = data.filter(request => request.status === status);
    }

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_REQUESTS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetRequests;
