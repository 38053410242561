import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    ButtonGroup,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    Add,
    Edit,
    TickCircle,
} from 'iconsax-react';
import { useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
    useDocumentRequestCreateRequestsFromTemplate,
    useDocumentRequestDeleteTemplate,
    useDocumentRequestDeleteTemplateRequest,
    useDocumentRequestUpdateTemplate,
} from '../../../../../utils/api/hooks';
import {
    DocumentRequestTemplateType,
    FolderDropTargetType,
    TemplateDragSourceType,
    dragTargetTypes,
} from '../../../../../types';
import { FormikForm, FormikInput, FormikTextarea } from '../../../../../common/forms';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import RequestListDisplay from './RequestListDisplay';
import RequestListCreation from './RequestListCreation';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    caseId: number;
    template: DocumentRequestTemplateType;
};

const TemplateListItem = (props: PropTypes) => {
    const { caseId, template } = props;

    const { t } = useTranslation('documentmanagement');

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const { update: createRequests } = useDocumentRequestCreateRequestsFromTemplate({ caseId });

    const { update: deleteTemplate } = useDocumentRequestDeleteTemplate({ caseId });

    const {
        update: updateTemplate,
    } = useDocumentRequestUpdateTemplate({ caseId });

    const { update: deleteRequest } = useDocumentRequestDeleteTemplateRequest({ caseId });

    const [editing, setEditing] = useState(false);
    const [addRequest, setAddRequest] = useState(false);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragTargetTypes.TEMPLATE_IMPORT,
        item: { templateId: template.id } as TemplateDragSourceType,
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as FolderDropTargetType;

            if (item && dropResult) {
                createRequests({
                    caseId,
                    folderId: dropResult.folderId,
                    templateId: item.templateId,
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    if (isDragging) {
        return null;
    }

    return (
        <>
            <AccordionItem
                key={template.id}
                ref={drag}
            >
                <AccordionButton
                    alignContent="center"
                    w="100%"
                >
                    <HStack
                        align="left"
                        color="wvwGreen"
                        w="100%"
                    >
                        <AccordionIcon />

                        {editing ? (
                            <FormikForm
                                initialValues={{
                                    name: template.name,
                                    description: template.description,
                                }}
                                onSubmit={values => {
                                    if (editing) {
                                        updateTemplate({
                                            templateId: template.id,
                                            name: values.name,
                                            description: values.description,
                                        });
                                    }
                                    setEditing(false);
                                }}
                            >
                                <VStack w="100%">
                                    <FormikInput
                                        name="name"
                                        placeholder={t('renameFile')}
                                    />

                                    <FormikTextarea
                                        name="description"
                                        placeholder={t('instructionNotes')}
                                    />

                                    <ButtonGroup alignSelf="flex-end">
                                        <WvwDeleteIconButton
                                            onClick={e => {
                                                setEditing(false);
                                                e.stopPropagation();
                                            }}
                                        />

                                        <IconButton
                                            aria-label="edit template"
                                            icon={<TickCircle />}
                                            type="submit"
                                            variant="none"
                                        />
                                    </ButtonGroup>
                                </VStack>
                            </FormikForm>
                        ) : (
                            <VStack align="left">
                                <Text
                                    textAlign="left"
                                    fontWeight="500"
                                >
                                    {template.name}
                                </Text>

                                <AccordionPanel
                                    marginInline="0"
                                    paddingInline="0"
                                    paddingTop="0"
                                    paddingBottom="0"
                                >
                                    <Text
                                        color="wvwGrey60"
                                        textAlign="left"
                                        maxW="12rem"
                                    >
                                        {template.description}
                                    </Text>

                                </AccordionPanel>
                            </VStack>
                        )}
                    </HStack>

                    <Spacer />

                    {!editing && (
                        <ButtonGroup>
                            <WvwDeleteIconButton
                                onClick={e => {
                                    e.stopPropagation();

                                    setDeleteConfirmOpen(true);
                                }}
                                color="black"
                            />
                            <IconButton
                                aria-label="edit template"
                                icon={<Edit />}
                                variant="none"
                                onClick={e => {
                                    setEditing(true);
                                    e.stopPropagation();
                                }}
                            />
                        </ButtonGroup>
                    )}
                </AccordionButton>

                <AccordionPanel
                    marginInline="0"
                    paddingInline="0"
                >
                    {(editing && addRequest === true) && (
                        <FormikForm
                            initialValues={{
                                requests: [],
                            }}
                            onSubmit={values => {
                                const newRequests = [
                                    ...template.requests,
                                    ...values.requests,
                                ];

                                updateTemplate({
                                    templateId: template.id,
                                    requests: newRequests,
                                });
                                setAddRequest(false);
                            }}
                        >
                            <RequestListCreation
                                name="requests"
                            />
                        </FormikForm>
                    )}

                    {(editing && addRequest === false) && (
                        <VStack
                            padding=".5rem"
                            borderRadius="10"
                            bg="wvwGrey05"
                            onClick={() => setAddRequest(true)}
                            cursor="pointer"
                        >
                            <HStack
                                spacing="0"
                                color="wvwGrey80"
                            >
                                <Add />

                                <Text>
                                    {t('addRequest')}
                                </Text>
                            </HStack>
                        </VStack>
                    )}

                    <RequestListDisplay
                        onEdit={(requestId, name, description) => {
                            updateTemplate({
                                templateId: template.id,
                                requests: template.requests.map(request => {
                                    if (request.id === requestId) {
                                        return {
                                            requestId: request.id,
                                            name,
                                            description,
                                        };
                                    }
                                    return {
                                        requestId: request.id,
                                        name: request.name,
                                        description: request.description,
                                    };
                                }),
                            });
                        }}
                        onDelete={requestId => deleteRequest(requestId)}
                        requests={template.requests}
                        requestType="TEMPLATE"
                    />
                </AccordionPanel>
            </AccordionItem>

            <ConfirmationModal
                cancelButtonVariant="primary"
                continueButtonVariant="danger"
                content={t('modal.message.deleteTemplate')}
                isOpen={deleteConfirmOpen}
                onCancel={() => setDeleteConfirmOpen(false)}
                onContinue={() => deleteTemplate(template.id)}
                title={t('modal.title.deleteTemplate')}
            />
        </>
    );
};

export default TemplateListItem;
