import { useQuery, useQueryClient } from 'react-query';
import { notificationGetUnread } from '../endpoints';

const NOTIFICATION_UNREAD_LIST = 'NOTIFICATION_UNREAD_LIST';

const useNotificationGetUnread = (limit: number) => {
    const queryClient = useQueryClient();

    const {
        data: notificationUnreadList,
        error,
        isLoading,
    } = useQuery([NOTIFICATION_UNREAD_LIST, limit], () => notificationGetUnread(limit));

    const refresh = () => {
        queryClient.invalidateQueries(NOTIFICATION_UNREAD_LIST);
    };

    return {
        notificationUnreadList,
        error,
        isLoading,
        refresh,
    };
};

export default useNotificationGetUnread;
