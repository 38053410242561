import {
    Container,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import ContactForm from './ContactForm';

const Support = () => (
    <Container paddingBlock="3rem">
        <VStack
            spacing="4"
            textAlign="center"
        >
            <Text
                color="wvwGreen"
                fontWeight="bold"
            >
                {t('contact', { ns: 'common' })}
            </Text>

            <Heading
                size="lg"
                color="wvwGreen"
            >
                {t('chatToTeamHeader', { ns: 'common' })}
                <br />
                {t('chatToTeamSubHeader', { ns: 'common' })}

            </Heading>

            <Text>
                {t('emailUsAt', { ns: 'common' })}
                <Link
                    color="wvwYellow"
                    href="mailto:platform@wer-bewertet-was.de"
                >
                    platform@wer-bewertet-was.de
                </Link>
                {t('orFillTheForm', { ns: 'common' })}
            </Text>

            <ContactForm />
        </VStack>
    </Container>
);

export default Support;
