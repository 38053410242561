import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CaseType, InspectionType } from '../../../../../types';
import InspectionFinished from './InspectionFinished';
import InspectionInspection from './InspectionInspection';
import InspectionNegotiation from './InspectionNegotiation';
import InspectionPreparation from './InspectionPreparation';

type PropTypes = {
    caseData: CaseType;
    inspection: InspectionType;
};

const InspectionDetailsItem = (props: PropTypes) => {
    const {
        caseData,
        inspection,
    } = props;

    const { phase } = inspection;

    const navigate = useNavigate();

    const goToInspectionDetails = () => {
        if (phase !== 'INSPECTION') return;

        navigate(`inspection-details/${inspection.id}`);
    };

    const cursor = phase === 'INSPECTION' ? 'pointer' : 'default';

    return (
        <Box
            _hover={{ cursor }}
            onClick={goToInspectionDetails}
            w="100%"
        >
            { phase === 'PREPARATION' && <InspectionPreparation inspection={inspection} /> }
            { phase === 'NEGOTIATION' && <InspectionNegotiation caseData={caseData} inspection={inspection} /> }
            { phase === 'INSPECTION' && <InspectionInspection inspection={inspection} /> }
            { phase === 'FINISHED' && <InspectionFinished inspection={inspection} /> }
        </Box>
    );
};

export default InspectionDetailsItem;
