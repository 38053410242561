import {
    VStack,
    Flex,
    Text,
    HStack,
    Link,
} from '@chakra-ui/react';
import { Folder } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import delimitNumber from '../functions/delimitNumber';
import GoogleMap from './GoogleMap';
import { PropertyType } from '../../types';

type PropTypes = {
    omitAccessDetails?: boolean;
    property: Omit<PropertyType, 'id' | 'enquiryId'> | PropertyType;
};

const PropertyOverview = (props: PropTypes) => {
    const { omitAccessDetails, property } = props;

    const { t } = useTranslation(['enquiryform', 'common', 'formik', 'enquiries']);

    return (
        <VStack
            align="left"
            spacing="2"
        >
            <Flex
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('propertyDetails.address', { ns: 'enquiries' })}
                </Text>

                <Text>
                    {t('addressOneLiner', {
                        street: property.street,
                        city: property.city,
                        country: property.country,
                        postalCode: property.postalCode,
                        ns: 'common',
                    })}
                </Text>
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('newEnquiry.addingProperty.propertyDescription.buildYear', { ns: 'enquiryform' })}
                </Text>

                <Text
                    color={property.buildYear ? 'wvwGrey' : 'wvwGrey40'}
                >
                    {property.buildYear ? property.buildYear : t('noneProvided', { ns: 'formik' })}
                </Text>
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('newEnquiry.addingProperty.propertyDescription.condition', { ns: 'enquiryform' })}
                </Text>

                <Text
                    color={property.propertyCondition ? 'wvwGrey' : 'wvwGrey40'}
                >
                    {t(`propertyCondition.${property.propertyCondition}` || '', { ns: 'formik', defaultValue: `${t('noneProvided')}` })}
                </Text>
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('propertyDetails.description', { ns: 'enquiries' })}
                </Text>

                <VStack
                    align="left"
                >
                    {property?.propertyTypes?.length === 0 && (
                        <Text color="wvwGrey40">
                            {t('noneProvided', { ns: 'formik' })}
                        </Text>
                    )}

                    {property?.propertyTypes?.length > 0
                    && property?.propertyTypes?.map(propertyType => (
                        <VStack
                            align="left"
                            key={propertyType}
                        >
                            <Text fontWeight="500">
                                {t(propertyType, { ns: 'formik', defaultValue: propertyType })}
                            </Text>

                            {propertyType === 'Agricultural' && (
                                property?.agricultural?.map(agricultural => (
                                    <Text
                                        key={agricultural}
                                        paddingLeft="1.5rem"
                                    >
                                        {t(agricultural, { ns: 'formik', defaultValue: agricultural })}
                                    </Text>
                                ))
                            )}

                            {propertyType === 'Land' && (
                                property?.land?.map(land => (
                                    <Text
                                        key={land}
                                        paddingLeft="1.5rem"
                                    >
                                        {t(land, { ns: 'formik', defaultValue: land })}
                                    </Text>
                                ))
                            )}

                            {propertyType === 'Commercial' && (
                                property?.commercial?.map(commercial => (
                                    <Text
                                        key={commercial}
                                        paddingLeft="1.5rem"
                                    >
                                        {t(commercial, { ns: 'formik', defaultValue: commercial })}
                                    </Text>
                                ))
                            )}

                            {propertyType === 'Development' && (
                                property?.development?.map(development => (
                                    <Text
                                        key={development}
                                        paddingLeft="1.5rem"
                                    >
                                        {t(development, { ns: 'formik', defaultValue: development })}
                                    </Text>
                                ))
                            )}

                            {propertyType === 'Residential' && (
                                property?.residential?.map(residential => (
                                    <Text
                                        key={residential}
                                        paddingLeft="1.5rem"
                                    >
                                        {t(residential, { ns: 'formik', defaultValue: residential })}
                                    </Text>
                                ))
                            )}

                            {propertyType === 'Special' && (
                                property?.specialRealEstate?.map(
                                    specialRealEstate => (
                                        <Text
                                            key={specialRealEstate}
                                            paddingLeft="1.5rem"
                                        >
                                            {t(specialRealEstate, { ns: 'formik', defaultValue: specialRealEstate })}
                                        </Text>
                                    ),
                                )
                            )}
                        </VStack>
                    ))}
                </VStack>
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('propertyDetails.features', { ns: 'enquiries' })}
                </Text>

                <VStack
                    align="left"
                >
                    {property?.propertyFeatures?.length === 0 && (
                        <Text color="wvwGrey40">
                            {t('noneProvided', { ns: 'formik' })}
                        </Text>
                    )}

                    {property?.propertyFeatures?.length > 0
                        && property?.propertyFeatures?.map(feature => (
                            <Flex key={feature}>
                                <Text>
                                    {t(feature, { ns: 'formik', defaultValue: feature })}
                                </Text>
                            </Flex>
                        ))}
                </VStack>
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('propertyDetails.otherFeatures', { ns: 'enquiries' })}
                </Text>

                <VStack
                    align="left"
                >
                    {property?.otherFeatures?.length === 0 && (
                        <Text color="wvwGrey40">
                            {t('noneProvided', { ns: 'formik' })}
                        </Text>
                    )}

                    {property?.otherFeatures?.length > 0
                        && property?.otherFeatures?.map(feature => (
                            <Flex key={feature}>
                                <Text>
                                    {t(feature, { ns: 'formik', defaultValue: feature })}
                                </Text>
                            </Flex>
                        ))}
                </VStack>
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('documents', { ns: 'enquiryform' })}
                </Text>

                <VStack
                    align="left"
                >
                    {property?.documents?.length === 0 && (
                        <Text color="wvwGrey40">
                            {t('noneProvided', { ns: 'formik' })}
                        </Text>
                    )}

                    {property?.documents?.length !== 0
                        && property?.documents?.map(document => (
                            <Link
                                href={document.url}
                                isExternal
                                key={document.id}
                                color="wvwGreen"
                            >
                                <HStack>
                                    <Folder size="1rem" />

                                    <Text>
                                        {document.name}
                                    </Text>
                                </HStack>
                            </Link>
                        ))}
                </VStack>
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('propertyDetails.grossInternalArea', { ns: 'enquiries' })}
                </Text>

                <Text>
                    {delimitNumber(property?.areaGrossInternal)}
                    {' '}
                    {t('unitSymbol.squareMeasure', { ns: 'common' })}
                </Text>
            </Flex>

            <Flex
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('propertyDetails.overallArea', { ns: 'enquiries' })}
                </Text>

                {property?.areaOverall ? (
                    <Text>
                        {delimitNumber(property?.areaOverall)}
                        {' '}
                        {t('unitSymbol.squareMeasure', { ns: 'common' })}
                    </Text>
                ) : (
                    <Text
                        color="wvwGrey40"
                    >
                        {t('noneProvided', { ns: 'formik' })}
                    </Text>
                )}
            </Flex>

            <Flex
                alignItems="flex-start"
                bg="wvwGrey10"
                borderRadius="6"
                padding=".5rem"
            >
                <Text
                    fontWeight="bold"
                    color="wvwGreen"
                    maxW="14rem"
                    w="100%"
                >
                    {t('propertyDetails.estimatedValue', { ns: 'enquiries' })}
                </Text>

                <Text>
                    {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(property?.estimateValue)}`}
                </Text>
            </Flex>

            {property?.propertyTypes?.includes('Development') && (
                <Flex
                    align="center"
                    bg="wvwGrey10"
                    borderRadius="6"
                    padding=".5rem"
                >
                    <Text
                        fontWeight="bold"
                        color="wvwGreen"
                        maxW="14rem"
                        w="100%"
                    >
                        {t('propertyDetails.estimatedGDV', { ns: 'enquiries' })}
                    </Text>

                    <Text>
                        {`${t('unitSymbol.currency', { ns: 'common' })}  ${delimitNumber(property?.estimateGVD)}`}
                    </Text>
                </Flex>
            )}

            {!omitAccessDetails && (
                <Flex
                    alignItems="flex-start"
                    bg="wvwGrey10"
                    borderRadius="6"
                    padding=".5rem"
                >
                    <Text
                        fontWeight="bold"
                        color="wvwGreen"
                        maxW="14rem"
                        w="100%"
                    >
                        {t('propertyDetails.accessDetails', { ns: 'enquiries' })}
                    </Text>

                    <VStack
                        align="left"
                    >
                        <Text>
                            {t('enquiryContact.fullName', { ns: 'enquiries' })}
                            {': '}
                            {property?.accessFName}
                            {' '}
                            {property?.accessLName}
                        </Text>
                        <Text>
                            {t('enquiryContact.email', { ns: 'enquiries' })}
                            {': '}
                            {property?.accessEmail}
                        </Text>
                        <Text>
                            {t('enquiryContact.mobileNumber', { ns: 'enquiries' })}
                            {': '}
                            {property?.accessMobileDialCode}
                            {' '}
                            {property?.accessMobile}
                        </Text>
                    </VStack>
                </Flex>
            )}

            <GoogleMap
                directionsLink
                latitude={property?.latitude}
                longitude={property?.longitude}
            />
        </VStack>
    );
};

PropertyOverview.defaultProps = {
    omitAccessDetails: false,
};

export default PropertyOverview;
