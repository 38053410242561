import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonTranslationEN from './locales/en/common.json';
import loginTranslationEN from './locales/en/login.json';
import carouselTranslationEN from './locales/en/carousel.json';
import menubarTranslationEN from './locales/en/menubar.json';
import dashboardTranslationEN from './locales/en/dashboard.json';
import profileTranslationEN from './locales/en/profile.json';
import passwordResetEN from './locales/en/passwordreset.json';
import companyCustomizeTranslationEN from './locales/en/companycustomize.json';
import offerTranslationEN from './locales/en/offer.json';
import accountTranslationEN from './locales/en/account.json';
import enquiriesTranslationEN from './locales/en/enquiries.json';
import enquiryformTranslationEN from './locales/en/enquiryform.json';
import documentManagementTranslationEN from './locales/en/documentmanagement.json';
import wizardTranslationEN from './locales/en/wizard.json';
import formikTranslationEN from './locales/en/formik.json';
import activeCasesTranslationEN from './locales/en/activecases.json';
import inspectionTranslationEN from './locales/en/inspection.json';
import adminTranslationEN from './locales/en/admin.json';
import updatesEN from './locales/en/updates.json';

import commonTranslationDE from './locales/de/common.json';
import loginTranslationDE from './locales/de/login.json';
import carouselTranslationDE from './locales/de/carousel.json';
import menubarTranslationDE from './locales/de/menubar.json';
import dashboardTranslationDE from './locales/de/dashboard.json';
import profileTranslationDE from './locales/de/profile.json';
import passwordResetDE from './locales/de/passwordreset.json';
import companyCustomizeTranslationDE from './locales/de/companycustomize.json';
import offerTranslationDE from './locales/de/offer.json';
import accountTranslationDE from './locales/de/account.json';
import enquiriesTranslationDE from './locales/de/enquiries.json';
import enquiryformTranslationDE from './locales/de/enquiryform.json';
import documentManagementTranslationDE from './locales/de/documentmanagement.json';
import wizardTranslationDE from './locales/de/wizard.json';
import formikTranslationDE from './locales/de/formik.json';
import activeCasesTranslationDE from './locales/de/activecases.json';
import inspectionTranslationDE from './locales/de/inspection.json';
import adminTranslationDE from './locales/de/admin.json';
import updatesDE from './locales/de/updates.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
    en: {
        common: commonTranslationEN,
        login: loginTranslationEN,
        carousel: carouselTranslationEN,
        menubar: menubarTranslationEN,
        dashboard: dashboardTranslationEN,
        profile: profileTranslationEN,
        passwordReset: passwordResetEN,
        companyCustomize: companyCustomizeTranslationEN,
        offer: offerTranslationEN,
        account: accountTranslationEN,
        enquiries: enquiriesTranslationEN,
        enquiryform: enquiryformTranslationEN,
        documentmanagement: documentManagementTranslationEN,
        inspection: inspectionTranslationEN,
        wizard: wizardTranslationEN,
        formik: formikTranslationEN,
        activecases: activeCasesTranslationEN,
        admin: adminTranslationEN,
        updates: updatesEN,
    },
    de: {
        common: commonTranslationDE,
        login: loginTranslationDE,
        carousel: carouselTranslationDE,
        menubar: menubarTranslationDE,
        dashboard: dashboardTranslationDE,
        profile: profileTranslationDE,
        passwordReset: passwordResetDE,
        companyCustomize: companyCustomizeTranslationDE,
        offer: offerTranslationDE,
        account: accountTranslationDE,
        enquiries: enquiriesTranslationDE,
        enquiryform: enquiryformTranslationDE,
        documentmanagement: documentManagementTranslationDE,
        inspection: inspectionTranslationDE,
        wizard: wizardTranslationDE,
        formik: formikTranslationDE,
        activecases: activeCasesTranslationDE,
        admin: adminTranslationDE,
        updates: updatesDE,
    },
};

if (localStorage.getItem('i18nextLng') === null) {
    localStorage.setItem('i18nextLng', 'de');
}

i18n
    .use(LanguageDetector) // autodetect browser default language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: 'en',
        supportedLngs: ['en', 'de'],
        nonExplicitSupportedLngs: true,
        fallbackLng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        // set this to false in order to allow for deeper nested json files - 2023-08-22 bs
        // keySeparator: false,
        appendNamespaceToMissingKey: true,
        defaultNS: 'common',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        saveMissing: true,
        /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
        missingKeyHandler: (lngs, ns, key) => {
            console.error('Missing Key', lngs, ns, key);
        },

    });

export default i18n;
