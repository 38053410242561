import { createStandaloneToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const useToast = () => {
    const { t } = useTranslation('common');
    const { toast } = createStandaloneToast();

    const createToast = (message: string, type: 'success' | 'error') => {
        const title = t(type === 'success' ? 'success.success' : 'error.error');

        toast({
            title,
            description: message,
            status: type,
            duration: 5000,
            isClosable: true,
        });
    };

    const createSuccessToast = (message: string) => createToast(message, 'success');
    const createErrorToast = (message: string) => createToast(message, 'error');

    return {
        createSuccessToast,
        createErrorToast,
    };
};

export default useToast;
