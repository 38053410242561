const commercialPropertyTypes = [
    'Office and Commercial Building',
    'Retail',
    'Industrial',
    'Car Dealer',
    'Shopping Centre',
    'Commercial Land',
    'Cold Storage',
    'Slaughterhouse',
    'Storage',
    'Other Commercial',
];

export default commercialPropertyTypes;
