import { FileUploadInput } from '../../../../../common/inputs';
import { allowedFileTypes } from '../../../../../common/vars/file-uploads/file-standards';
import { useDocumentRequestUploadDocument } from '../../../../../utils/api/hooks';

type PropTypes = {
    caseId: string;
    requestId?: string;
    submissionComment?: string;
    sortIndex?: string;
};

const UploadDocumentToActionArea = (props: PropTypes) => {
    const {
        caseId,
        requestId,
        submissionComment,
        sortIndex,
    } = props;

    const { update: upload } = useDocumentRequestUploadDocument({ caseId });

    return (
        <FileUploadInput
            fileTypes={allowedFileTypes}
            noRename
            onChange={files => files.forEach(file => (
                upload({
                    file,
                    caseId,
                    requestId,
                    submissionComment,
                    sortIndex,
                })
            ))}
        />
    );
};

UploadDocumentToActionArea.defaultProps = {
    requestId: undefined,
    submissionComment: undefined,
    sortIndex: undefined,
};

export default UploadDocumentToActionArea;
