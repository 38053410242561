import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from '../../utils/firebase';

const Logout = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate('/login');
    }, []);

    return <div>{t('loading')}</div>;
};

export default Logout;
