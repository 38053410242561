import {
    Box,
    Tabs,
    Tab,
    TabList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useRole } from '../../../common/hooks';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const Account = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { t } = useTranslation(['account', 'common']);

    const { roleLoading, userIsIndividual, userIsValuer } = useRole();

    if (roleLoading) return <LoadingSpinner />;

    const tabs = [
        {
            label: t('accountSectionLink.companyDetails'),
            url: '',
        },
        {
            label: t('accountSectionLink.team'),
            url: 'team',
        },
        {
            label: userIsValuer ? t('accountSectionLink.customers') : t('accountSectionLink.panelValuers'),
            url: 'valuer-panel',
        },
    ];

    if (userIsIndividual) {
        tabs.splice(1, 2);
    }

    if (userIsValuer) {
        tabs.push({
            label: t('accountSectionLink.sharedDocs'),
            url: 'documents',
        });
        tabs.push({
            label: t('accountSectionLink.integration'),
            url: 'integration',
        });
    }

    const index = tabs.findIndex(tab => {
        if (tab.url === '') return pathname === '/dashboard/account';

        return pathname.search(tab.url) !== -1;
    });

    return (
        <PageWithTitleLayout
            allowUnapprovedUser
            title={t('title', { ns: 'account' })}
        >
            <Box
                alignContent="center"
                bg="white"
                borderRadius="15"
                paddingBlock="1rem"
                paddingInline="1.5rem"
                w="100%"
            >
                <Tabs
                    index={index === -1 ? 0 : index}
                    padding="0"
                    variant="wvwGreen"
                    width="100%"
                >
                    <TabList>
                        {tabs.map(tab => (
                            <Tab
                                key={tab.url}
                                onClick={() => navigate(tab.url)}
                            >
                                {tab.label}
                            </Tab>
                        ))}
                    </TabList>
                </Tabs>

                <Outlet />
            </Box>
        </PageWithTitleLayout>
    );
};

export default Account;
