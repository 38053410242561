import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    children: ReactNode; // The content of the box
    color?: 'green' | 'orange' | 'white' | 'wvwGreen' | 'wvwGrey05' | 'wvwYellow10'; // The background color of the box
};

const ContentBox = (props: PropTypes) => {
    const { children, color } = props;

    return (
        <Box
            bg={color}
            borderRadius="10"
            paddingBlock="1rem"
            paddingInline="1rem"
            w="100%"
        >
            {children}
        </Box>
    );
};

ContentBox.defaultProps = {
    color: 'wvwGrey05',
};

export default ContentBox;
