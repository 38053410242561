import {
    VStack,
    Button,
    Spinner,
    Center,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
    useMe,
    useMyEntity,
    usePublicEntityProfilePicture,
    usePublicUserProfilePicture,
} from '../../utils/api/hooks';
import storage from '../../utils/storage/storage';
import readFileToDataUrl from '../functions/readFileToDataUrl';
import FileUploadInput from '../inputs/FileUploadInput';
import ImageCropModal from './ImageCropModal';
import PublicUserProfilePicture from './PublicUserProfilePicture';
import PublicCompanyProfilePicture from './PublicCompanyProfilePicture';

type PropsTypes = {
    changeButtonText?: string;
    forEntity?: boolean;
};

const ProfilePictureEditor = (props: PropsTypes) => {
    const { t } = useTranslation('common');
    const { changeButtonText, forEntity } = props;

    const [image, setImage] = useState<string | undefined>(undefined);

    const {
        data: entity,
        isLoading: entityIsLoading,
        entityProfilePictureRefresh,
    } = useMyEntity();

    const {
        data: user,
        isLoading: userIsLoading,
        profilePictureRefresh: userProfilePictureRefresh,
    } = useMe();

    const {
        refresh: publicEntityprofilePictureRefresh,
    } = usePublicEntityProfilePicture(entity.id);

    const {
        refresh: publicUserprofilePictureRefresh,
    } = usePublicUserProfilePicture(user.id);

    const isLoading = forEntity ? entityIsLoading : userIsLoading;

    if (isLoading) {
        return (
            <Center
                h="8rem"
                w="8rem"
            >
                <Spinner />
            </Center>
        );
    }

    return (
        <>
            <VStack>
                <VStack spacing={2}>
                    {forEntity && (
                        <PublicCompanyProfilePicture
                            companyId={entity?.id}
                            size="8rem"
                        />
                    )}

                    {!forEntity && (
                        <PublicUserProfilePicture
                            entityId={entity?.id}
                            userId={user?.id}
                            size="8rem"
                        />
                    )}

                    <FileUploadInput
                        onChange={async files => {
                            const imageDataUrl = await readFileToDataUrl(files[0]) as string;

                            setImage(imageDataUrl);
                        }}
                        fileTypes={['image/jpeg', 'image/png', 'image/gif', 'image/jpg']}
                        noRename
                        customUploadButton={(
                            <Button
                                pointerEvents="none"
                                variant="primaryYellow"
                                _hover={{ curser: 'pointer' }}
                            >
                                {t(changeButtonText as unknown as TemplateStringsArray)}
                            </Button>
                        )}
                    />
                </VStack>
            </VStack>

            <ImageCropModal
                image={image}
                onImageSave={async img => {
                    if (forEntity) {
                        await storage.uploadEntityProfilePicture(entity.id, img);
                    } else {
                        await storage.uploadUserProfilePicture(user.id, img);
                    }

                    setImage(undefined);

                    if (forEntity) {
                        entityProfilePictureRefresh();
                        publicEntityprofilePictureRefresh();
                    } else {
                        userProfilePictureRefresh();
                        publicUserprofilePictureRefresh();
                    }
                }}
            />
        </>
    );
};

ProfilePictureEditor.defaultProps = {
    changeButtonText: 'button.change',
    forEntity: false,
};

export default ProfilePictureEditor;
