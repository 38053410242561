import { useTranslation } from 'react-i18next';
import { useAdminEntities } from '../../../utils/api/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { EntityType } from '../../../types';
import DataTable from '../../../common/components/DataTable';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const AdminEntities = () => {
    const { t } = useTranslation('admin');

    const { data: cases, isLoading } = useAdminEntities({});

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const headers = [
        {
            title: '#',
            sorter: (a: EntityType, b: EntityType) => a.id - b.id,
            render: (i: EntityType) => i.id,
        },
        {
            title: t('tableHeading.companyName'),
            sorter: (a: EntityType, b: EntityType) => a.name.localeCompare(b.name),
            render: (i: EntityType) => i.name,
        },
        {
            title: t('tableHeading.entityType'),
            sorter: (a: EntityType, b: EntityType) => a.accountType.localeCompare(b.accountType),
            render: (i: EntityType) => i.accountType,
        },
        {
            title: t('tableHeading.createdAt'),
            sorter: (a: EntityType, b: EntityType) => a.createdAt.localeCompare(b.createdAt),
            render: (i: EntityType) => new Date(i.createdAt).toLocaleDateString(),
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Entities">
            <DataTable
                columns={headers}
                data={cases}
            />
        </PageWithTitleLayout>
    );
};

export default AdminEntities;
