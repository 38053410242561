import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetUserRequestList } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_USER_REQUEST_LIST = 'DOCUMENT_REQUEST_GET_USER_REQUEST_LIST';

const useDocumentRequestGetUserRequestList = (caseId: number | string) => {
    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_USER_REQUEST_LIST, caseId],
        () => documentRequestGetUserRequestList(caseId),
        {
            enabled: !!caseId,
            onError: (err: Error) => createErrorToast(
                t(err.message, { defaultValue: err.message }),
            ),
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_USER_REQUEST_LIST);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetUserRequestList;
