import { useMutation } from 'react-query';
import { OfferCommentsCreateDto } from '../dto';
import { offerCommentsCreate } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error?: any) => void;
};

const useOfferCommentsCreate = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: OfferCommentsCreateDto) => offerCommentsCreate(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useOfferCommentsCreate;
