import {
    Button,
    Divider,
    Heading,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
    FormikCloudSelect,
    FormikConditionalSection,
    FormikForm,
    FormikListInputCertificateUpload,
} from '../../../../common/forms';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import storage from '../../../../utils/storage/storage';
import UploadCertificate from '../../../../common/components/UploadCertificate';
import { useMe } from '../../../../utils/api/hooks';
import { standardValuerQualifications } from '../../../../common/vars/valuationsAndMembershipTypes';

type PropTypes = {
    onClose: () => void;
};

const ProfileQualificationsEdit = (props: PropTypes) => {
    const { t } = useTranslation(['profile', 'account', 'common', 'formik']);

    const { onClose } = props;

    const {
        data: user,
        isLoading: loadingUser,
        myCertificates = [],
        myCertificatesIsLoading,
        myCertificatesRefresh,
        updateMe,
    } = useMe();

    if (loadingUser || myCertificatesIsLoading) {
        return <LoadingSpinner />;
    }

    return (
        <FormikForm
            initialValues={{
                personalQualifications: user.standardQualifications
                    ?.filter(i => standardValuerQualifications.includes(i)) || [],
                otherQualifications: user.otherQualifications || [],
            }}
            onSubmit={values => {
                updateMe({
                    standardQualifications: values.personalQualifications,
                    otherQualifications: values.otherQualifications,
                });

                onClose();
            }}
        >
            <ModalHeader>
                {t('membershipsAndQualifications.edit.sectionTitle')}
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
                <VStack
                    align="left"
                    paddingBottom="2rem"
                    spacing="4"
                    w="100%"
                >
                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('membershipsAndQualifications.qualifications')}
                    </Heading>

                    <FormikCloudSelect
                        name="personalQualifications"
                        options={standardValuerQualifications.map(qualification => ({
                            label: t(qualification, { ns: 'formik', defaultValue: qualification }),
                            value: qualification,
                        }))}
                    />

                    {standardValuerQualifications.map(qualification => {
                        const files = myCertificates
                            .filter(
                                i => i.type.toLowerCase() === qualification.toLowerCase(),
                            )
                            .map(i => ({
                                name: i.label,
                                fileUrl: i.file.fullPath,
                            }));

                        return (
                            <FormikConditionalSection
                                key={qualification}
                                formDataTarget="personalQualifications"
                                condition={value => value?.includes(qualification)}
                            >
                                <VStack
                                    align="start"
                                    backgroundColor="wvwGrey05"
                                    borderRadius="8"
                                    padding="1rem"
                                    spacing="4"
                                    w="100%"
                                >
                                    <Text
                                        color="wvwGreen"
                                    >
                                        {`${t(qualification, { ns: 'formik', defaultValue: qualification })} ${t('membershipsAndQualifications.certificate', { count: 2 })}`}
                                    </Text>

                                    <UploadCertificate
                                        defaultFileName={files.length === 0 ? `${t(qualification, { ns: 'formik', defaultValue: qualification })} ${t('membershipsAndQualifications.certificate', { count: 1 })}` : undefined}
                                        files={files}
                                        onFileDelete={fileUrl => {
                                            storage.deleteUserFile(fileUrl)
                                                .then(myCertificatesRefresh);
                                        }}
                                        onRename={(fileUrl, label) => {
                                            storage.updateFileMetadata(fileUrl, label)
                                                .then(myCertificatesRefresh);
                                        }}
                                        onUpload={fileList => {
                                            fileList.forEach(file => {
                                                storage.uploadUserCertificate(
                                                    user?.id,
                                                    file,
                                                    qualification,
                                                ).then(myCertificatesRefresh);
                                            });
                                        }}
                                    />
                                </VStack>
                            </FormikConditionalSection>
                        );
                    })}

                    <Divider />

                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('membershipsAndQualifications.otherQualifications')}
                    </Heading>

                    <FormikListInputCertificateUpload
                        name="otherQualifications"
                        placeholder={t('membershipsAndQualifications.edit.addQualification')}
                        uploadedCertificates={myCertificates}
                        onUploadComplete={myCertificatesRefresh}
                    />
                </VStack>

                <Divider />
            </ModalBody>

            <ModalFooter>
                <Button
                    type="submit"
                    variant="primary"
                >
                    {t('button.save', { ns: 'common' })}
                </Button>
            </ModalFooter>
        </FormikForm>
    );
};

export default ProfileQualificationsEdit;
