import {
    VStack,
    Text,
    Link,
    Box,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useState } from 'react';
import ContactForm from '../../../common/components/ContactForm';
import ModalDynamic from '../../../common/components/ModalDynamic';

const useSupportModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const modal = (
        <ModalDynamic
            variant="small"
            isOpen={modalIsOpen}
            header={t('support', { ns: 'common' })}
            onClose={() => setModalIsOpen(false)}
        >
            <VStack>
                <VStack
                    textAlign="left"
                    paddingBlock="1rem"
                >
                    <Text
                        paddingBottom="1rem"
                    >
                        {t('emailUsAt', { ns: 'common' })}
                        <Link
                            color="wvwYellow"
                            href="mailto:platform@wer-bewertet-was.de"
                        >
                            platform@wer-bewertet-was.de
                        </Link>
                        {t('orFillTheForm', { ns: 'common' })}
                    </Text>
                    <Box
                        w="100%"
                        p="1rem"
                        bgColor="wvwGrey10"
                        borderRadius="10"
                    >
                        <ContactForm />
                    </Box>
                </VStack>
            </VStack>
        </ModalDynamic>
    );

    return {
        modal,
        openModal: () => setModalIsOpen(true),
    };
};

export default useSupportModal;
