import {
    Box,
    Button,
    Flex,
    HStack,
    IconButton,
    Input,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    ArrowRight, DocumentCloud, Edit,
} from 'iconsax-react';
import { useState } from 'react';
import { FileUploadInput } from '../../../../../common/inputs';
import {

    useDocumentRequestUploadDocuments,
} from '../../../../../utils/api/hooks';
import { FormikForm, FormikInput } from '../../../../../common/forms';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    requestId: number;
    closeOnSave?: boolean;
};

type DocumentType = {
    requestId: number;
    file: File;
    submissionComment: string;
};

const UploadDocumentRequestDocuments = (props: PropTypes) => {
    const {
        caseId = '',
    } = useParams();

    const {
        requestId,
        closeOnSave,
    } = props;

    const { t } = useTranslation(['common', 'documentmanagement']);

    const navigate = useNavigate();

    const [documents, setDocuments] = useState<DocumentType[]>([]);

    const [edit, setEdit] = useState('');

    const [editNote, setEditNote] = useState({
        doc: '',
        edit: false,
    });

    const { update: uploadDocuments } = useDocumentRequestUploadDocuments({
        caseId: Number(caseId),
    });

    return (
        <VStack
            align="left"
            spacing={4}
            w="100%"
            padding="1rem"
        >
            <FileUploadInput
                fileTypes={[
                    'application/pdf',
                    'image/gif',
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                ]}
                noRename
                onChange={files => {
                    setDocuments([
                        ...documents,
                        ...files.map(i => ({
                            requestId: Number(requestId),
                            file: i,
                            submissionComment: '',
                        })),
                    ]);
                }}
            />

            {documents.map((i, index) => (
                <VStack
                    align="left"
                    spacing={2}
                    key={i.file.name}
                >
                    {edit === i.file.name ? (
                        <FormikForm
                            initialValues={{
                                name: i.file.name,
                            }}
                            onSubmit={values => {
                                let formattedFilename = values.name.trim();
                                let uploadFile = i.file as File;

                                if (formattedFilename !== i.file.name) {
                                    const extention = i.file.name.split('.')?.pop()?.toLowerCase() || '';

                                    if (formattedFilename.split('.').pop().toLowerCase() !== extention) {
                                        formattedFilename += `.${extention}`;
                                    }

                                    uploadFile = new File(
                                        [i.file as Blob],
                                        formattedFilename as string,
                                        {
                                            type: i.file.type,
                                        },
                                    );
                                }

                                const fList = documents.map(j => {
                                    if (j.file.name === i.file.name) {
                                        return {
                                            ...j,
                                            file: uploadFile,
                                        };
                                    }

                                    return j;
                                });

                                setDocuments(fList);

                                setEdit('');
                            }}
                        >
                            <Flex>
                                <FormikInput name="name" />

                                <Spacer />

                                <IconButton
                                    aria-label="Rename File"
                                    icon={<CheckIcon />}
                                    type="submit"
                                    variant="ghost"
                                />
                            </Flex>

                        </FormikForm>
                    ) : (
                        <Flex
                            align="center"
                            key={i.file.name}
                        >
                            <Box marginInlineEnd="1rem">
                                <DocumentCloud />
                            </Box>

                            <Text variant="secondary">
                                {i.file.name}
                            </Text>

                            <Spacer />

                            <IconButton
                                aria-label="Rename File"
                                icon={<Edit />}
                                onClick={() => {
                                    setEdit(i.file.name);
                                }}
                                variant="ghost"
                            />

                            <WvwDeleteIconButton
                                onClick={() => {
                                    setDocuments(
                                        documents.filter(j => j.file.name !== i.file.name),
                                    );
                                }}
                                color="black"
                            />
                        </Flex>
                    )}

                    {(editNote.edit && i.file.name === editNote.doc) ? (
                        <VStack
                            align="left"
                            w="100%"
                        >
                            <Text>
                                {t('note', { ns: 'documentmanagement' })}
                            </Text>

                            <HStack w="100%">
                                <Input
                                    onChange={(event: { target: { value: string; }; }) => {
                                        const newDocuments = [...documents];

                                        newDocuments[index].submissionComment = event.target.value;

                                        setDocuments(newDocuments);
                                    }}
                                />

                                <IconButton
                                    aria-label="Edit Note"
                                    icon={<CheckIcon />}
                                    onClick={() => {
                                        setEditNote({
                                            doc: '',
                                            edit: false,
                                        });
                                    }}
                                    variant="ghost"
                                />
                            </HStack>
                        </VStack>
                    ) : (
                        <Flex w="100%">
                            <Text color="wvwGrey60">
                                {i.submissionComment}
                            </Text>

                            <Spacer />

                            <IconButton
                                color="wvwGrey60"
                                aria-label="Edit Note"
                                icon={<Edit />}
                                onClick={() => {
                                    setEditNote({
                                        doc: i.file.name,
                                        edit: true,
                                    });
                                }}
                                variant="ghost"
                            />

                            <IconButton
                                icon={<ArrowRight />}
                                color="wvwGrey40"
                                aria-label="Submit"
                                variant="ghost"
                            />
                        </Flex>
                    )}
                </VStack>
            ))}

            <Button
                onClick={() => {
                    if (documents.length === 0 && closeOnSave) {
                        navigate('../', { replace: true });
                    }
                    uploadDocuments({
                        caseId: Number(caseId),
                        documents,
                    });
                }}
                type="submit"
                alignSelf="flex-end"
                variant="primaryYellow"
            >

                {t('button.save', { ns: 'common' })}
            </Button>
        </VStack>
    );
};

UploadDocumentRequestDocuments.defaultProps = {
    closeOnSave: true,
};

export default UploadDocumentRequestDocuments;
