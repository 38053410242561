export const dragTargetTypes = {
    DOCUMENT_IMPORT: 'DOCUMENT_IMPORT', // Move document from the Action Area into a Document Request
    DOCUMENT_MOVE: 'DOCUMENT_MOVE', // Move document from one request to another
    REQUEST_IMPORT: 'REQUEST_IMPORT', // Move a request from the Action Area into a Document Request
    REQUEST_MOVE: 'REQUEST_MOVE', // Move a request from one folder to another
    TEMPLATE_IMPORT: 'TEMPLATE_IMPORT', // Move a template from the Action Area into a Document Request
    TEMPLATE_REQUEST_IMPORT: 'TEMPLATE_REQUEST_IMPORT', // Move a template request from the Action Area into a Document Request
};

// Source
export type DocumentDragSourceType = {
    documentId: number;
};

export type RequestDragSourceType = {
    requestId: number;
};

export type TemplateDragSourceType = {
    templateId: number;
};

// Targets
export type FolderDropTargetType = {
    folderId: number;
};

export type RequestDropTargetType = {
    folderId: number;
    requestId: number;
};
