import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';

type PropTypes = {
    content: string;
    isExternal?: boolean;
    to: string;
};

const WvwLink = (props: PropTypes) => {
    const {
        content,
        isExternal,
        to,
    } = props;

    if (isExternal) {
        return (
            <ChakraLink
                href={to}
                isExternal
                color="wvwGreen"
            >
                {content}
            </ChakraLink>
        );
    }

    return (
        <ChakraLink
            as={ReactLink}
            to={to}
            color="wvwGreen"
        >
            {content}
        </ChakraLink>
    );
};

WvwLink.defaultProps = {
    isExternal: false,
};

export default WvwLink;
