import {
    VStack,
    HStack,
    Heading,
    Flex,
    Divider,
    Box,
    Text,
    Badge,
    Radio,
    RadioGroup,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useCompanySetAccess,
    useMyEntity,
    usePublicUser,
} from '../../../utils/api/hooks';
import PublicUserProfilePicture from '../PublicUserProfilePicture';
import LoadingSpinner from '../LoadingSpinner';
import { useRole } from '../../hooks';

type PropTypes = {
    userId: number;
};

const PublicProfileDetails = (props: PropTypes) => {
    const { t } = useTranslation(['profile', 'account', 'common']);
    const { userId } = props;

    const {
        data: user,
        isLoading: userLoading,
    } = usePublicUser(userId);

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    const { userIsCompany, userIsCompanyAdmin } = useRole();

    const {
        update: updateAccess,
    } = useCompanySetAccess({});

    const inEnquiry = useLocation().pathname.includes('enquiry');

    const inCase = useLocation().pathname.includes('case');

    const inTeam = useLocation().pathname.includes('team');

    const isPanelValuer = entity?.panelValuersIds?.includes(user.entityId);

    if (userLoading || entityLoading) {
        return <LoadingSpinner />;
    }

    return (
        <VStack>
            <HStack
                w="100%"
                align="left"
                alignItems="center"
                spacing={4}
            >
                <PublicUserProfilePicture
                    entityId={user.entity?.id}
                    userId={user?.id}
                    size="8rem"
                />

                <VStack
                    align="left"
                    spacing="0"
                >
                    <Heading color="wvwGreen">
                        {userLoading ? '' : `${user?.firstName} ${user?.lastName}`}
                    </Heading>

                    {userIsCompany && (
                        <Text color="wvwGreen" fontSize="1.2rem">
                            {user.orgPosition && (
                                <>
                                    {t('position.at', {
                                        position: user.orgPosition,
                                        company: user.entity.name,
                                        interpolation: { escapeValue: false },
                                    })}
                                </>
                            )}
                        </Text>
                    )}

                    {user.entity.accountType === 'CLIENT' && (
                        <Box>
                            <Badge variant="subtle" textColor="wvwGreen" borderRadius=".3rem" paddingInline="1rem">
                                {user.entity.companyOrIndividual === 'COMPANY' ? `${t('integration.customerTypes.institution', { ns: 'account' })}` : `${t('integration.customerTypes.private', { ns: 'account' })}`}
                            </Badge>
                        </Box>
                    )}
                </VStack>
            </HStack>

            <Box
                bg="wvwGrey05"
                borderRadius="10"
                padding="1.5rem 2rem"
                w="100%"
            >
                <Heading
                    as="h4"
                    color="wvwGrey"
                    size="md"
                >
                    {t('profileDetails.sectionTitle')}
                </Heading>

                {(inEnquiry && !isPanelValuer) && (
                    <Text color="wvwGrey40">
                        {t('youCannotSeeDetails', { ns: 'common' })}
                    </Text>
                )}

                {(isPanelValuer || inCase || inTeam) && (
                    <VStack
                        paddingTop="1rem"
                    >
                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.email')}
                            </Text>

                            {user?.email ? (
                                <Text>
                                    {user?.email}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.email', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.mobileNumber')}
                            </Text>

                            {user?.mobile ? (
                                <Text>
                                    {`${user?.mobileDialCode} ${user?.mobile}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.mobileNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.telNumber')}
                            </Text>

                            {user?.tel ? (
                                <Text>
                                    {`${user?.telDialCode} ${user?.tel}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.telNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.faxNumber')}
                            </Text>

                            {user?.fax ? (
                                <Text>
                                    {`${user?.faxDialCode} ${user?.fax}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.faxNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.officeTelNumber')}
                            </Text>

                            {user?.officeTel ? (
                                <Text>
                                    {`${user?.officeTelDialCode} ${user?.officeTel}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.officeTelNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.officeFaxNumber')}
                            </Text>

                            {user?.officeFax ? (
                                <Text>
                                    {`${user?.officeFaxDialCode} ${user?.officeFax}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.officeFaxNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.location')}
                            </Text>

                            {!user?.street && (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.location', { ns: 'common' })}
                                </Text>
                            )}

                            <Text>
                                {user?.street && `${user?.street}, ${user?.postalCode}, ${user?.city}, ${user?.country}`}
                            </Text>
                        </Flex>

                        {(userIsCompanyAdmin && inTeam) && (
                            <>
                                <Divider />

                                <Flex
                                    alignItems="center"
                                    h="2rem"
                                    w="100%"
                                >
                                    <Text
                                        color="wvwGreen"
                                        maxW="17rem"
                                        w="100%"
                                    >
                                        {t('role', { ns: 'account' })}
                                    </Text>

                                    <RadioGroup
                                        defaultValue={user.role}
                                        onChange={value => updateAccess({
                                            entityId: user.entity?.id,
                                            targetUserId: user.id,
                                            accessType: value as 'ADMIN' | 'USER',
                                        })}
                                        variant="filled"
                                    >
                                        <HStack w="100%">
                                            <Radio value="USER">
                                                {t('user', { ns: 'common' })}
                                            </Radio>

                                            <Radio value="ADMIN">
                                                {t('admin', { ns: 'common' })}
                                            </Radio>
                                        </HStack>
                                    </RadioGroup>
                                </Flex>
                            </>
                        )}
                    </VStack>
                )}
            </Box>
        </VStack>
    );
};

export default PublicProfileDetails;
