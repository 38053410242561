import {
    VStack,
    HStack,
    Text,
    Flex,
    Divider,
    Box,
    Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
    FormikCheckbox,
    FormikCurrencyInput,
    FormikForm,
    FormikInput,
} from '../../../../../common/forms';
import { useMyEntity } from '../../../../../utils/api/hooks';
import parseDelimitedNumber from '../../../../../common/functions/parseDelimitedNumber';
import delimitNumber from '../../../../../common/functions/delimitNumber';
import { EntityType } from '../../../../../types';
import { WvwButton } from '../../../../../common/components/inputs';

type PropTypes = {
    entity: EntityType;
    onComplete: () => void;
};

const CompanyCapabilitiesEdit = (props: PropTypes) => {
    const { entity, onComplete } = props;

    const { t } = useTranslation(['account', 'common']);

    const validationSchema = Yup.object({
        piCoverValue: Yup.string()
            .test('is-pi-cover', 'errors.required', function (value) {
                if (value !== undefined) return true;

                // eslint-disable-next-line react/no-this-in-sfc
                return this.parent.piCoverPercentage !== undefined;
            }),
        piCoverPercentage: Yup.string()
            .test('is-pi-cover', 'errors.required', function (code) {
                if (code !== undefined) return true;

                // eslint-disable-next-line react/no-this-in-sfc
                return this.parent.piCoverValue !== undefined;
            }),
        piCoverValidityDate: Yup.string()
            .required(t('required', { ns: 'account' })),
    });

    const {
        updateEntity,
    } = useMyEntity({
        onUpdateSuccess: () => {
            onComplete();
        },
    });

    return (
        <FormikForm
            initialValues={{
                piCoverValue: entity.piCoverValue !== 0
                    ? delimitNumber(entity?.piCoverValue?.toString()) : 0,
                piCoverPercentage:
                    entity?.piCoverPercentage !== 0 && entity.piCoverPercentage !== null
                        ? entity?.piCoverPercentage : 0,
                piCoverValidityDate: entity?.piCoverValidityDate || '',
                piCoverSpecialArrangementsAvailable: entity?.piCoverSpecialArrangementsAvailable,
            }}
            onSubmit={values => updateEntity({
                piCoverValue: values.piCoverValue ? Number(parseDelimitedNumber(values.piCoverValue.toString() || '0')) : undefined,
                piCoverPercentage: Number(parseDelimitedNumber(values.piCoverPercentage.toString() || '0')),
                piCoverValidityDate: values.piCoverValidityDate,
                piCoverSpecialArrangementsAvailable: values.piCoverSpecialArrangementsAvailable,
            })}
            validationSchema={validationSchema}
        >
            <Box
                bg="wvwGrey05"
                borderRadius="10"
                marginTop="1.5rem"
                padding="1.5rem 2rem"
                w="100%"
            >
                <Heading
                    as="h4"
                    color="wvwGrey"
                    size="md"
                >
                    {t('valuationCapabilities.sectionTitle')}
                </Heading>

                <VStack
                    paddingTop="1rem"
                >

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            maxW="17rem"
                            w="100%"
                        >
                            {t('valuationCapabilities.piCover.piCover')}
                        </Text>

                        <VStack
                            spacing="2"
                            w="100%"
                        >
                            <HStack
                                alignItems="flex-start"
                                spacing="2"
                                w="100%"
                            >
                                <Box w="33%">
                                    <Text
                                        color="wvwGreen"
                                    >
                                        {t('valuationCapabilities.piCover.maxOf')}
                                    </Text>

                                    <FormikCurrencyInput
                                        name="piCoverValue"
                                        placeholder="-"
                                        prefix={t('unitSymbol.currency', { ns: 'common' })}
                                    />
                                </Box>

                                <Box w="33%">
                                    <Text
                                        color="wvwGreen"
                                    >
                                        {t('valuationCapabilities.piCover.orPercentage')}
                                    </Text>

                                    <FormikCurrencyInput
                                        name="piCoverPercentage"
                                        placeholder="-"
                                        prefix="%"
                                    />
                                </Box>

                                <Box w="34%">
                                    <Text
                                        color="wvwGreen"
                                    >
                                        {t('valuationCapabilities.piCover.ValidUntil', { ns: 'account' })}
                                    </Text>

                                    <FormikInput
                                        name="piCoverValidityDate"
                                        type="date"
                                    />
                                </Box>
                            </HStack>

                            <FormikCheckbox
                                name="piCoverSpecialArrangementsAvailable"
                                label={t('valuationCapabilities.piCover.specialArrangementsAvailable')}
                            />
                        </VStack>
                    </Flex>

                    <Divider />

                    <HStack
                        w="100%"
                        justifyContent="flex-end"
                    >
                        <WvwButton
                            content={t('button.cancel', { ns: 'common' })}
                            onClick={onComplete}
                            variant="danger"
                        />

                        <WvwButton
                            content={t('button.done', { ns: 'common' })}
                            formSubmitButton
                            onClick={() => {}}
                        />
                    </HStack>
                </VStack>
            </Box>
        </FormikForm>
    );
};

export default CompanyCapabilitiesEdit;
