import { useQuery, useQueryClient } from 'react-query';
import { getCaseInspections } from '../../endpoints';

const CASE_INSPECTIONS = 'CASE_INSPECTIONS';

const useInspectionGetCaseInspections = (target: number) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([CASE_INSPECTIONS, target], () => getCaseInspections(target));

    const refresh = () => {
        queryClient.invalidateQueries(CASE_INSPECTIONS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useInspectionGetCaseInspections;
