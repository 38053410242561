import { useMutation } from 'react-query';
import { offerWithdraw } from '../endpoints';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useOfferWithdraw = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: withdraw } = useMutation(
        (offerId: number) => offerWithdraw(offerId),
        {
            onSuccess: () => {
                onSuccess?.();
            },
            onError,
        },
    );

    return {
        withdraw,
    };
};

export default useOfferWithdraw;
