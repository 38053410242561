import {
    Flex,
    VStack,
    Heading,
    Spacer,
    Center,
    Text,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOfferGetCompanyOffers, useOfferGetMyOfferByEnquiryId } from '../../../../../utils/api/hooks';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { WvwButton } from '../../../../../common/components/inputs';

const ValuerOfferBanner = () => {
    const { t } = useTranslation(['offer', 'common']);

    const { enquiryId } = useParams();
    const navigate = useNavigate();

    const {
        data: companyOffers = [],
        isLoading: companyOffersLoading,
    } = useOfferGetCompanyOffers(Number(enquiryId));

    const {
        data: valuerOffer,
        isLoading: valuerOfferLoading,
        // error: valuerOfferError,
    } = useOfferGetMyOfferByEnquiryId(Number(enquiryId));

    if (valuerOfferLoading || companyOffersLoading) return <LoadingSpinner />;

    return (
        <>
            {!valuerOffer && (
                <Flex
                    bg="wvwYellow10"
                    borderRadius="10"
                    align="left"
                    paddingInline="2rem"
                    paddingBlock="1rem"
                >
                    <VStack
                        align="left"
                    >
                        <Heading
                            as="h4"
                            size="md"
                            color="wvwYellow"
                        >
                            {t('awaitingOffers.sectionTitle')}
                        </Heading>
                        <Text>
                            {t('awaitingOffers.submissionColleagueStats', { count: companyOffers?.filter(offer => offer?.id !== valuerOffer?.id).length })}
                            <br />
                            {t('awaitingOffers.blurb', { count: companyOffers?.filter(offer => offer?.id !== valuerOffer?.id).length })}
                        </Text>
                    </VStack>

                    <Spacer />

                    <Center>
                        <WvwButton
                            content={t('button.submitOffer', { ns: 'common' })}
                            onClick={() => navigate(`/dashboard/enquiry/${enquiryId}/offer`)}
                            variant="primary"
                        />
                    </Center>
                </Flex>
            )}

            {valuerOffer && valuerOffer.status === 'PENDING' && (
                <Flex
                    bg="wvwYellow10"
                    borderRadius="10"
                    align="left"
                    paddingInline="2rem"
                    paddingBlock="1rem"
                >
                    <VStack
                        align="left"
                    >
                        <Heading
                            as="h4"
                            size="md"
                            color="wvwYellow"
                        >
                            {t('awaitingOffers.submissionYouAndColleagueStats', { count: companyOffers.filter(offer => offer.id !== valuerOffer?.id).length })}
                        </Heading>
                        <Text>
                            {t('awaitingOffers.blurb_viewAll')}
                        </Text>
                    </VStack>

                    <Spacer />

                    <Center>
                        <WvwButton
                            content={t('button.viewOffer', { count: companyOffers.length, ns: 'offer' })}
                            onClick={() => {
                                if (companyOffers.length === 1) {
                                    navigate(`/dashboard/enquiry/${enquiryId}/offer/${valuerOffer.id}`);
                                } else {
                                    navigate(`/dashboard/enquiry/${enquiryId}/offer`);
                                }
                            }}
                            variant="primary"
                        />
                    </Center>
                </Flex>
            )}

            {valuerOffer && valuerOffer.status === 'ACCEPTED' && (
                <Flex
                    bg="green10"
                    borderRadius="10"
                    align="left"
                    paddingInline="2rem"
                    paddingBlock="1rem"
                >
                    <VStack
                        align="left"
                    >
                        <Heading
                            as="h4"
                            size="md"
                            color="green"
                        >
                            {t('reaction.congratulations')}
                        </Heading>
                        <Text>
                            {t('msg.offerAccepted')}
                        </Text>
                    </VStack>

                    <Spacer />

                    <Center>
                        <WvwButton
                            content={t('button.viewOffer', { ns: 'common' })}
                            variant="success"
                            onClick={() => navigate(`/dashboard/enquiry/${enquiryId}/offer`)}
                        />
                    </Center>
                </Flex>
            )}

            {valuerOffer && valuerOffer.status === 'REJECTED' && (
                <Flex
                    bg="red10"
                    borderRadius="10"
                    align="left"
                    paddingInline="2rem"
                    paddingBlock="1rem"
                >
                    <VStack
                        align="left"
                    >
                        <Heading
                            as="h4"
                            size="md"
                            color="red"
                        >
                            {t('reaction.weAreSorry')}
                        </Heading>
                        <Text>
                            {t('msg.offerDeclined')}
                        </Text>
                    </VStack>

                    <Spacer />

                    <Center>
                        <WvwButton
                            content={t('button.viewOffers', { ns: 'common' })}
                            onClick={() => navigate(`/dashboard/enquiry/${enquiryId}/offer`)}
                            variant="danger"
                        />
                    </Center>
                </Flex>
            )}
        </>
    );
};

export default ValuerOfferBanner;
