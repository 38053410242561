import {
    Alert,
    AlertIcon,
    Button,
    HStack,
    VStack,
    Heading,
    Text,
    Container,
    Spacer,
    Divider,
    Checkbox,
    Box,
    FormLabel,
    Flex,
    Link,
} from '@chakra-ui/react';
import {
    MultiFactorError,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useTranslation } from 'react-i18next';
import { auth } from '../../../utils/firebase';
import Logo from '../../../common/components/Logo';
import TsAndCsText from '../../../common/components/TsAndCsText';
import { FormikForm, FormikInput, FormikPassword } from '../../../common/forms';
import WaterMarkBox from '../../../common/components/WaterMarkBox';
import LanguageToggleButton from '../../../common/components/LanguageToggleButton';
import { useMe } from '../../../utils/api/hooks';
import Footer from '../../../common/components/Footer';

YupPassword(Yup);

type PropTypes = {
    onLogin: (verify?: boolean) => void;
    setAuthError: (error: MultiFactorError) => void;
};

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('errors.required'),
    password: Yup.string()
        .password()
        .required('errors.required'),
});

const Login = (props: PropTypes) => {
    const {
        onLogin,
        setAuthError,
    } = props;

    const { t } = useTranslation(['login', 'common', 'formik']);
    const navigate = useNavigate();

    const [error, setError] = useState('');

    const { refresh } = useMe();

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.lg"
                    padding="1rem"
                    backgroundColor="white"
                    borderRadius="lg"
                    marginBlock="2rem"
                >
                    <HStack
                        align="stretch"
                    >
                        <Flex
                            flexDirection="column"
                            align="left"
                            padding="1.5rem"
                            maxW="40%"
                            color="white"
                            borderRadius="lg"
                            bgGradient={[
                                'linear(to-b, #016064cc, #016064)',
                            ]}
                        >
                            <Logo />

                            <Spacer />

                            <Box
                                paddingBlock="8rem"
                            >
                                <Heading>
                                    {t('wvwWelcome')}
                                </Heading>

                                <Spacer h="1.5rem" />

                                <Text>
                                    {t('wvwBlurb')}
                                </Text>
                            </Box>

                        </Flex>

                        <VStack
                            paddingBlock="1rem"
                            paddingInline="2.5rem"
                            w="60%"
                            align="left"
                            spacing="20"
                        >
                            <HStack
                                alignSelf="flex-end"
                                spacing="0"
                            >
                                <Text>
                                    {t('noAccountQ')}

                                    <Link
                                        as={ReactLink}
                                        to="/register"
                                        color="wvwGreen"
                                        fontWeight="bold"
                                        paddingLeft=".5rem"
                                    >
                                        {t('link.register')}
                                    </Link>
                                </Text>

                                <Box
                                    pl="1rem"
                                >
                                    <LanguageToggleButton />
                                </Box>
                            </HStack>

                            <Box paddingBlock="3rem">
                                <VStack
                                    align="left"
                                    spacing="6"
                                >
                                    <Box>
                                        <Heading as="h4" size="md">
                                            {t('loginHeading')}
                                        </Heading>

                                        <Spacer h="1rem" />
                                        <Text>{t('welcomeBlurb')}</Text>
                                    </Box>

                                    {error === 'Firebase: Error (auth/user-not-found).' && (
                                        <Alert
                                            status="error"
                                        >
                                            <AlertIcon
                                                color="red"
                                            />
                                            {t('error.authError.userNotFound', { ns: 'common' })}
                                        </Alert>
                                    )}

                                    {error === 'Firebase: Error (auth/wrong-password).' && (
                                        <Alert
                                            status="error"
                                        >
                                            <AlertIcon />
                                            {t('error.authError.wrongPassword', { ns: 'common' })}
                                        </Alert>
                                    )}

                                    {error !== 'Firebase: Error (auth/user-not-found).' && error !== 'Firebase: Error (auth/wrong-password).' && error !== '' && (
                                        <Alert
                                            status="error"
                                        >
                                            <AlertIcon />
                                            {t('error.authError.tryAgain', { ns: 'common' })}
                                        </Alert>
                                    )}

                                    <FormikForm
                                        initialValues={{
                                            email: '',
                                            password: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={values => {
                                            signInWithEmailAndPassword(
                                                auth,
                                                values.email,
                                                values.password,
                                            )
                                                .then(() => {
                                                    refresh();
                                                    onLogin();
                                                })
                                                .catch((err: MultiFactorError) => {
                                                    if (err.code === 'auth/multi-factor-auth-required') {
                                                        setAuthError(err);

                                                        onLogin(true);

                                                        return;
                                                    }

                                                    setError(err.message);
                                                });
                                        }}
                                    >
                                        <FormLabel color="wvwGreen">{t('Email', { ns: 'common' })}</FormLabel>
                                        <FormikInput
                                            name="email"
                                            type="email"
                                            placeholder={t('instruction.pleaseEnter', { item: t('instruction.email') })}
                                        />

                                        <Spacer h="1rem" />

                                        <FormLabel color="wvwGreen">{t('Password', { ns: 'common' })}</FormLabel>
                                        <FormikPassword
                                            name="password"
                                            placeholder={t('instruction.pleaseEnter', { item: t('instruction.password') })}
                                        />

                                        <Spacer h="1rem" />

                                        <Flex>
                                            <Checkbox>{t('rememberMe')}</Checkbox>
                                            <Spacer />

                                            <Button
                                                color="wvwGreen"
                                                variant="none"
                                                onClick={() => { navigate('/forgot-password'); }}
                                            >
                                                {t('link.resetPassword')}
                                            </Button>
                                        </Flex>

                                        <Divider paddingBlock="1rem" />

                                        <Button
                                            variant="primary"
                                            width="8rem"
                                            marginBlock="1.5rem"
                                            type="submit"
                                        >
                                            {t('button.login', { ns: 'common' })}
                                        </Button>

                                        <HStack
                                            w="100%"
                                        >
                                            <TsAndCsText />
                                        </HStack>
                                    </FormikForm>
                                </VStack>

                            </Box>
                        </VStack>
                    </HStack>
                </Container>

                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

export default Login;
