import { useMyEntity } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { useRole } from '../../../../common/hooks';
import CompanyDetailsMain from './common/CompanyDetailsMain';
import CompanyValuationCapabilities from './common/CompanyValuationCapabilities';

const CompanyDetails = () => {
    const { userIsValuer } = useRole();

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    if (entityLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <CompanyDetailsMain entity={entity} />

            {userIsValuer && (
                <CompanyValuationCapabilities entity={entity} />
            )}
        </>
    );
};

export default CompanyDetails;
