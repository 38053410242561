import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetFolders } from '../../endpoints';
import { GetFoldersDto } from '../../dto';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_FOLDERS = 'DOCUMENT_REQUEST_GET_FOLDERS';

const useDocumentRequestGetFolders = (dto: GetFoldersDto) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation('documentmanagement');

    const { createErrorToast } = useToast();

    const {
        data,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_FOLDERS, JSON.stringify(dto)],
        () => documentRequestGetFolders(dto),
        {
            enabled: !!dto.caseId,
            onError: (err: Error) => {
                createErrorToast(t(err.message, { defaultValue: err.message }));
            },
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_FOLDERS);
    };

    return {
        data,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetFolders;
