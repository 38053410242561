import { useQuery, useQueryClient } from 'react-query';
import storage from '../../storage/storage';

const PUBLIC_USER_PROFILE_PICTURE = 'PUBLIC_USER_PROFILE_PICTURE';

const usePublicUserProfilePicture = (userId: string | number) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [PUBLIC_USER_PROFILE_PICTURE, userId],
        async () => {
            const profilePicture = await storage.getUserProfilePictureUrl(Number(userId));

            if (!profilePicture) {
                return '';
            }

            return profilePicture;
        },
        {
            enabled: !!userId,
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(PUBLIC_USER_PROFILE_PICTURE);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default usePublicUserProfilePicture;
