import {
    VStack,
    Heading,
    Circle,
    Flex,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useNotificationList } from '../../../utils/api/hooks';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const Notifications = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['account', 'common']);

    const {
        notificationList,
        error,
        isLoading,
    } = useNotificationList(1000);

    return (
        <PageWithTitleLayout
            allowUnapprovedUser
            title={t('notifications.sectionTitle', { ns: 'common' })}
        >
            <VStack
                align="left"
                spacing="4"
                w="100%"
            >
                <>
                    {error && (
                        <VStack>
                            <Heading
                                size="sm"
                            >
                                {t('error.weAreSorry')}
                            </Heading>

                            <Text>
                                {t('error.notifications.loadingFailed')}
                            </Text>
                        </VStack>
                    )}

                    {isLoading && <LoadingSpinner />}

                    {notificationList?.length === 0 && (
                        <Text>
                            {t('notifications.noUnreads', { ns: 'common' })}
                        </Text>
                    )}

                    {notificationList?.map(notification => (
                        <VStack
                            key="id"
                            as="button"
                            onClick={() => {
                                navigate(notification.actionLink);
                            }}
                            align="left"
                            spacing="2"
                            bgColor="white"
                            paddingBlock="1rem"
                            paddingInline="1.5rem"
                            borderRadius="10"
                        >
                            <Flex
                                w="100%"
                                align="left"
                            >
                                <Heading
                                    textAlign="left"
                                    size="sm"
                                    color={notification.read ? 'wvwGrey60' : 'wvwYellow'}
                                >
                                    {notification.title}
                                </Heading>

                                <Spacer />

                                {notification.read ? <div /> : <Circle bgColor="wvwYellow" size=".6rem" />}
                            </Flex>

                            <Text
                                textAlign="left"
                            >
                                {notification.message}
                            </Text>
                        </VStack>
                    ))}
                </>
            </VStack>
        </PageWithTitleLayout>
    );
};

export default Notifications;
