import {
    Button,
    Box,
    Center,
    Link as ChakraLink,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { OfferType } from '../../../../../types';
import { useOfferGetCompanyOffers, useOfferGetMyOfferByEnquiryId } from '../../../../../utils/api/hooks';
import DataTable from '../../../../../common/components/DataTable';
import delimitNumber from '../../../../../common/functions/delimitNumber';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import ValuerOfferForm from '../../offer/common/ValuerOfferForm';
import { WVWTitle } from '../../../../../common/components/typography';

const ValuerOffersOverview = () => {
    const { t } = useTranslation(['offer']);

    const { enquiryId } = useParams();

    const {
        data: valuerOffer,
        isLoading: valuerOfferLoading,
        error: valuerOfferError,
    } = useOfferGetMyOfferByEnquiryId(Number(enquiryId));

    const {
        data: companyOffers = [],
        isLoading: companyOffersLoading,
        refresh: companyOffersRefresh,
        error: companyOffersError,
    } = useOfferGetCompanyOffers(Number(enquiryId));

    if (companyOffersLoading || valuerOfferLoading) return <LoadingSpinner />;

    if (valuerOfferError || companyOffersError) return <Center>Sorry! Something went wrong</Center>;

    const headers = [
        {
            title: t('offerTable.header.valuer', { ns: 'dashboard' }),
            dataIndex: 'valuer',
            render: (i: OfferType) => `${i.fromValuer.firstName} ${i.fromValuer.lastName}`,
            sorter: (a: OfferType, b: OfferType) => {
                const aName = `${a.fromValuer.firstName} ${a.fromValuer.lastName}`;
                const bName = `${b.fromValuer.firstName} ${b.fromValuer.lastName}`;

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('offerTable.header.price', { ns: 'dashboard' }),
            dataIndex: 'price',
            sorter: (a: OfferType, b: OfferType) => a.price - b.price,
            render: (i: OfferType) => (
                <Text textAlign="left">
                    {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(i.price)}`}
                </Text>
            ),
        },
        {
            title: t('offerTable.header.availability', { ns: 'dashboard' }),
            dataIndex: 'availability',
            sorter: (a: OfferType, b: OfferType) => {
                if (b.availability.replace(/-/g, '') < a.availability.replace(/-/g, '')) {
                    return -1;
                }
                if (b.availability.replace(/-/g, '') > a.availability.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: t('offerTable.header.status', { ns: 'dashboard' }),
            dataIndex: 'status',
            render: (i: OfferType) => {
                let bgColor = 'wvwYellow10';
                let color = 'wvwYellow';

                if (i.status === 'ACCEPTED') {
                    bgColor = 'green10';
                    color = 'green';
                }

                if (i.status === 'REJECTED') {
                    bgColor = 'red10';
                    color = 'red';
                }

                if (i.status === 'WITHDRAWN') {
                    bgColor = 'purple10';
                    color = 'purple';
                }

                return (
                    <Text
                        bg={bgColor}
                        borderRadius="10"
                        color={color}
                        fontWeight="bold"
                        paddingBlock=".2rem"
                        paddingInline=".5rem"
                        fontSize=".8rem"
                        textAlign="center"
                        w="6rem"
                    >
                        {t(`status.${i.status}`, { ns: 'offer' })}
                    </Text>
                );
            },
            sorter: (a: OfferType, b: OfferType) => a.status.localeCompare(b.status),
        },
        {
            title: t('offerTable.header.action', { ns: 'dashboard' }),
            render: (i: OfferType) => (
                <ChakraLink
                    as={ReactLink}
                    to={`/dashboard/enquiry/${enquiryId}/offer/${i.id}`}
                >
                    <Button
                        borderRadius="30"
                        size="sm"
                        variant="primaryYellow"
                    >
                        {t('button.viewOffer', { ns: 'common' })}
                    </Button>
                </ChakraLink>
            ),
        },
    ];

    return (
        <VStack
            w="100%"
            spacing="0"
        >
            {!valuerOffer && <ValuerOfferForm onSuccess={() => companyOffersRefresh()} />}

            {companyOffers.length > 0 && (
                <VStack
                    w="100%"
                    borderRadius="10"
                    bgColor="white"
                >
                    <Box
                        w="100%"
                        paddingBlock="1rem"
                        paddingInline="2rem"
                    >
                        <WVWTitle content={t('offerDetails.companyOffers.sectionTitle')} level="2" />
                    </Box>

                    <Box
                        w="100%"
                        paddingInline="2rem"
                    >
                        <DataTable
                            columns={headers}
                            data={companyOffers}
                            noDataText={t('noOffers', { ns: 'enquiries' })}
                        />
                    </Box>
                </VStack>
            )}
        </VStack>
    );
};

export default ValuerOffersOverview;
