import { useQuery, useQueryClient } from 'react-query';
import { offerGetById } from '../endpoints';

const OFFERS_BY_ID = 'OFFERS_BY_ID';

const useOfferGetById = (offerId: number | string) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([OFFERS_BY_ID, offerId], () => offerGetById(offerId));

    const refresh = () => {
        queryClient.invalidateQueries(OFFERS_BY_ID);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useOfferGetById;
