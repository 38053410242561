import { Flex, Text } from '@chakra-ui/react';

export type StatusBadgeColor = 'blue10' | 'green' | 'green10' | 'orange10' | 'purple10' | 'red10' | 'white' | 'wvwGreen10' | 'wvwGrey05' | 'wvwYellow10';
export type StatusBadgeTextColor = 'blue' | 'green' | 'orange' | 'purple' | 'red' | 'wvwGreen' | 'wvwGrey60' | 'wvwYellow';

type PropTypes = {
    color: StatusBadgeColor; // The background color of the badge
    status: string; // The text of the badge
    textColor: StatusBadgeTextColor; // The text color of the badge
};

const StatusBadge = (props: PropTypes) => {
    const { color, status, textColor } = props;

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            w="15rem"
        >
            <Text
                bg={color}
                borderRadius="10"
                color={textColor}
                fontWeight="bold"
                paddingBlock="0.2rem"
                paddingInline="1rem"
                fontSize=".8rem"
                textAlign="center"
            >
                {status}
            </Text>
        </Flex>
    );
};

export default StatusBadge;
