import { useMutation } from 'react-query';
import { ValuerInvitesRespondDto } from '../dto';
import { valuerInvitesRespond } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useValuerInvitesRespond = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: ValuerInvitesRespondDto) => valuerInvitesRespond(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useValuerInvitesRespond;
