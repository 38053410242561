import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Flex,
    Box,
    Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Privacy from './Privacy';
import Terms from './Terms';

type PropTypes = {
    register?: boolean;
};

const TsAndCsText = (props: PropTypes) => {
    const { register } = props;
    const { t } = useTranslation('common');

    const [modal, setModal] = useState<'terms' | 'privacy' | undefined>();

    return (
        <Box
            width="100%"
        >
            <Flex
                w="100%"
                flexWrap="wrap"
                gap="0.25rem"
            >
                {register && (
                    <Text>
                        {t('bySigningUp')}
                    </Text>
                )}

                <Text>
                    {t('termsAndConditionsStmnt')}
                </Text>

                <Button
                    onClick={() => setModal('terms')}
                    width="auto"
                    color="black"
                    variant="link"
                    fontWeight="bold"
                >
                    {t('termsAndConditions')}
                </Button>

                <Text>
                    {t('and', { ns: 'common' })}
                </Text>

                <Button
                    onClick={() => setModal('privacy')}
                    width="auto"
                    color="black"
                    variant="link"
                    fontWeight="bold"

                >
                    {t('privacyPolicy')}
                </Button>
            </Flex>

            <Modal
                isOpen={!!modal}
                onClose={() => setModal(undefined)}
                size="xl"
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>

                        {modal === 'terms' && `${t('termsHeader', { ns: 'common' })}`}

                        {modal === 'privacy' && `${t('privacyHeader', { ns: 'common' })}`}
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody>
                        {modal === 'terms' && (
                            <Terms />
                        )}

                        {modal === 'privacy' && (
                            <Privacy />
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={() => setModal(undefined)}
                        >
                            {t('button.close', { ns: 'common' })}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

TsAndCsText.defaultProps = {
    register: false,
};

export default TsAndCsText;
