import { useQuery, useQueryClient } from 'react-query';
import { notificationGetList } from '../endpoints';

const NOTIFICATION_LIST = 'NOTIFICATION_LIST';

const useNotificationList = (limit: number) => {
    const queryClient = useQueryClient();

    const {
        data: notificationList,
        error,
        isLoading,
    } = useQuery([NOTIFICATION_LIST, limit], () => notificationGetList(limit));

    const refresh = () => {
        queryClient.invalidateQueries(NOTIFICATION_LIST);
    };

    return {
        notificationList,
        error,
        isLoading,
        refresh,
    };
};

export default useNotificationList;
