import { useTranslation } from 'react-i18next';
import { useRole } from '../../hooks';
import StatusBadge, { StatusBadgeColor, StatusBadgeTextColor } from './StatusBadge';

type PropTypes = {
    status: 'ACCEPTED' | 'CANCELLED' | 'DORMANT' | 'DRAFT' | 'PENDING' | 'REJECTED' | 'WITHDRAWN'; // The status of the parent Enquiry
};

const colors: { [index: string]: StatusBadgeColor } = {
    ACCEPTED: 'green10',
    CANCELLED: 'red10',
    DORMANT: 'wvwGrey05',
    DRAFT: 'blue10',
    PENDING: 'wvwYellow10',
    REJECTED: 'orange10',
    WITHDRAWN: 'purple10',
};

const textColors: { [index: string]: StatusBadgeTextColor } = {
    ACCEPTED: 'green',
    CANCELLED: 'red',
    DORMANT: 'wvwGrey60',
    DRAFT: 'blue',
    PENDING: 'wvwYellow',
    REJECTED: 'orange',
    WITHDRAWN: 'purple',
};

const StatusBadgeEnquiry = (props: PropTypes) => {
    const { status } = props;

    const {
        userIsClient,
    } = useRole();

    let statusKey = `${status}`;
    if (status === 'PENDING') {
        statusKey = userIsClient ? 'AWAITINGOFFERS' : 'NEW';
    }

    const { t } = useTranslation('offer');

    return (
        <StatusBadge
            color={colors[status]}
            status={t(`status.${statusKey}`, { defaultValue: status })}
            textColor={textColors[status]}
        />
    );
};

export default StatusBadgeEnquiry;
