import { useMutation } from 'react-query';
import { EmailEmailEnquiryLinkDto } from '../dto';
import { emailEmailEnquiryLink } from '../endpoints';

type ParamsType = {
    onSuccess: (data?: unknown) => void;
    onError: (error: Error) => void;
};

const useEmailEmailEnquiryLink = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: send } = useMutation(
        (dto: EmailEmailEnquiryLinkDto) => emailEmailEnquiryLink(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        send,
    };
};

export default useEmailEmailEnquiryLink;
