import { Flex, HStack, Icon } from '@chakra-ui/react';
import { Clock } from 'iconsax-react';
import { useMemo } from 'react';
import { WvwText } from '../typography';

type PropTypes = {
    alignText?: 'left' | 'right'; // The alignment of the text
    color?: 'black' | 'wvwGreen' | 'wvwGrey60' | 'white'; // The color of the icon and text
    date: Date; // The date of the event
    duration: number; // The duration of the event
};

const EventTime = (props: PropTypes) => {
    const {
        alignText,
        color,
        date,
        duration,
    } = props;

    const endDate = useMemo(() => {
        const newDate = new Date(date);

        newDate.setMinutes(newDate.getMinutes() + duration);

        return newDate;
    }, [date, duration]);

    const startDateTime = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    const endDateTime = `${endDate.getHours()}:${endDate.getMinutes().toString().padStart(2, '0')}`;

    const content = (
        <>
            <Icon
                as={Clock}
                color={color}
                boxSize="6"
            />

            <WvwText color={color}>
                {`${startDateTime} - ${endDateTime}`}
            </WvwText>
        </>
    );

    if (alignText === 'left') {
        return (
            <HStack spacing="2">
                {content}
            </HStack>
        );
    }

    return (
        <Flex
            justifyContent="space-between"
            w="8rem"
        >
            {content}
        </Flex>
    );
};

EventTime.defaultProps = {
    alignText: 'right',
    color: 'white',
};

export default EventTime;
