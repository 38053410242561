import {
    Center,
    Circle,
} from '@chakra-ui/react';
import { useEntityPublicEntityGetById, usePublicEntityProfilePicture } from '../../utils/api/hooks';
import LoadingSpinner from './LoadingSpinner';
import ProfilePicture from './display/ProfilePicture';
import ProfilePictureDefault from './display/ProfilePictureDefault';

type PropTypes = {
    companyId: number | string;
    size: string;
};

const PublicCompanyProfilePicture = (props: PropTypes) => {
    const { companyId, size } = props;

    const {
        data: entity,
        isLoading: entityIsLoading,
    } = useEntityPublicEntityGetById(companyId);

    const {
        data: profilePictureUrl,
        isLoading: profilePictureUrlIsLoading,
    } = usePublicEntityProfilePicture(companyId);

    if (profilePictureUrlIsLoading || entityIsLoading) {
        return (
            <Circle
                size={size}
                margin="0"
            >
                <Center
                    w="100%"
                    h="100%"
                >
                    <LoadingSpinner
                        noText
                        size="md"
                        thickness="3px"
                    />
                </Center>
            </Circle>
        );
    }

    if (profilePictureUrl) {
        return (
            <ProfilePicture
                url={profilePictureUrl}
                size={size}
            />
        );
    }
    return (
        <ProfilePictureDefault
            accountType={entity?.accountType === 'VALUER' ? 'VALUER' : 'CLIENT'}
            userType="ENTITY"
            size={size}
        />
    );
};

export default PublicCompanyProfilePicture;
