const parseDelimitedNumber = (stringNumber: string, locale = 'en-US'): string => {
    if (stringNumber === '') return '';

    const thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    return stringNumber
        .replace(/[^0-9]/g, '')
        .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
        .replace(new RegExp(`\\${decimalSeparator}`), '.');
};

export default parseDelimitedNumber;
