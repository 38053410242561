import { Box } from '@chakra-ui/react';
import { useRole } from '../../../../../common/hooks';
import ActionAreaClient from './ActionAreaClient';
import ActionAreaValuer from './ActionAreaValuer';

const ActionArea = () => {
    const { userIsClient, userIsValuer } = useRole();

    return (
        <Box
            h="100%"
            w="30%"
        >
            {userIsClient && (
                <ActionAreaClient />
            )}

            {userIsValuer && (
                <ActionAreaValuer />
            )}
        </Box>
    );
};

export default ActionArea;
