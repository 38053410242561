import { useQuery, useQueryClient } from 'react-query';
import { caseGetCase } from '../endpoints';

const CASE_GET_CASE = 'CASE_GET_CASE';

const useCaseGetCase = (caseId: string | number) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([CASE_GET_CASE, caseId], () => caseGetCase(caseId));

    const refresh = () => {
        queryClient.invalidateQueries(CASE_GET_CASE);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useCaseGetCase;
