import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useAdminOffers } from '../../../utils/api/hooks';
import { WvwText } from '../../../common/components/typography';
import DataTable from '../../../common/components/DataTable';
import { OfferType } from '../../../types';
import delimitNumber from '../../../common/functions/delimitNumber';
import StatusBadgeOffer from '../../../common/components/display/StatusBadgeOffer';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const AdminOffers = () => {
    const { t } = useTranslation('dashboard');

    const { data: offers, isLoading } = useAdminOffers({});

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const headers = [
        {
            title: t('offerTable.header.company', { ns: 'dashboard' }),
            render: (i: OfferType) => i.fromEntity.name,
            sorter: (a: OfferType, b: OfferType) => (
                a.fromEntity.name.localeCompare(b.fromEntity.name)
            ),
        },
        {
            title: t('offerTable.header.valuer', { ns: 'dashboard' }),
            dataIndex: 'valuer',
            render: (i: OfferType) => `${i.fromValuer.firstName} ${i.fromValuer.lastName}`,
            sorter: (a: OfferType, b: OfferType) => {
                const aName = `${a.fromValuer.firstName} ${a.fromValuer.lastName}`;
                const bName = `${b.fromValuer.firstName} ${b.fromValuer.lastName}`;

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('offerTable.header.price', { ns: 'dashboard' }),
            dataIndex: 'price',
            sorter: (a: OfferType, b: OfferType) => a.price - b.price,
            render: (i: OfferType) => (
                <WvwText>
                    {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(i.price)}`}
                </WvwText>
            ),
        },
        {
            title: t('offerTable.header.availability', { ns: 'dashboard' }),
            dataIndex: 'availability',
            sorter: (a: OfferType, b: OfferType) => {
                if (b.availability.replace(/-/g, '') < a.availability.replace(/-/g, '')) {
                    return -1;
                }
                if (b.availability.replace(/-/g, '') > a.availability.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: t('offerTable.header.status', { ns: 'dashboard' }),
            dataIndex: 'status',
            render: (i: OfferType) => <StatusBadgeOffer status={i.status} />,
            sorter: (a: OfferType, b: OfferType) => a.status.localeCompare(b.status),
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Offers">
            <DataTable
                columns={headers}
                data={offers}
            />
        </PageWithTitleLayout>
    );
};

export default AdminOffers;
