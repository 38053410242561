import {
    Box,
    Heading,
    Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CongratulationsWindow from '../../common/components/CongratulationsWindow';
import WaterMarkBox from '../../common/components/WaterMarkBox';
import Footer from '../../common/components/Footer';

type PropTypes = {
    bodyText?: string;
    buttonText?: string;
    navLink: string;
};

const SuccessScreen = (props: PropTypes) => {
    const navigate = useNavigate();

    const {
        bodyText,
        buttonText,
        navLink,
    } = props;

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <CongratulationsWindow>
                    <Heading
                        as="h4"
                        size="sm"
                        color="white"
                        maxW="18rem"
                        textAlign="center"
                    >
                        {bodyText}
                    </Heading>

                    {buttonText && (
                        <Button
                            variant="primary"
                            onClick={() => navigate(navLink)}
                        >
                            {buttonText}
                        </Button>
                    )}
                </CongratulationsWindow>
                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

SuccessScreen.defaultProps = {
    bodyText: '',
    buttonText: 'Take me there!',
};

export default SuccessScreen;
