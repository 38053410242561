import {
    Box,
    Tab,
    TabList,
    Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useRole } from '../../../common/hooks';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const Profile = () => {
    const { t } = useTranslation(['profile', 'common']);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { userIsIndividual, userIsValuer, roleLoading } = useRole();

    if (roleLoading) return <LoadingSpinner />;

    const tabs = [
        {
            label: t('profileSectionLink.profile'),
            url: '',
        },
        {
            label: t('profileSectionLink.team'),
            url: 'team',
        },
    ];

    if (userIsValuer) {
        tabs.push({
            label: t('profileSectionLink.sharedDocs'),
            url: 'documents',
        });
    }

    if (userIsIndividual) {
        tabs.splice(1, 1);
    }

    const index = tabs.findIndex(tab => pathname.split('/').pop() === tab.url);

    return (
        <PageWithTitleLayout
            allowUnapprovedUser
            title={t('profileSectionLink.profile')}
        >
            <Box
                w="100%"
                paddingInline="1.5rem"
                paddingBlock="1rem"
                bg="white"
                borderRadius="15"
            >
                <Tabs
                    index={index !== -1 ? index : 0}
                    padding="0"
                    variant="wvwGreen"
                    width="100%"
                >
                    <TabList>
                        {tabs.map(tab => (
                            <Tab
                                key={tab.url}
                                onClick={() => navigate(tab.url)}
                            >
                                {tab.label}
                            </Tab>
                        ))}
                    </TabList>

                    <Outlet />
                </Tabs>
            </Box>
        </PageWithTitleLayout>
    );
};

export default Profile;
