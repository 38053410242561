import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalCloseButton,
    VStack,
    Heading,
    Flex,
    Spacer,
    Highlight,
    SimpleGrid,
    ModalFooter,
    Button,
    Box,
    Text,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {
    FormikForm,
    FormikInput, FormikSelect,
    FormikCloudSelect,
    FormikConditionalSection,
    FormikCheckboxGroup,
    FormikListInput,
    FormikCurrencyInput,
    FormikFileUpload,
    FormikCountrySelect,
    FormikRadioGroup,
    FormikCheckbox,
    FormikAddressInputWithMap,
} from '../forms';
import delimitNumber from '../functions/delimitNumber';
import parseDelimitedNumber from '../functions/parseDelimitedNumber';
import {
    standardPropertyTypes,
    agriculturePropertyTypes,
    landPropertyTypes,
    commercialPropertyTypes,
    developmentPropertyTypes,
    residentialPropertyHouseTypes,
    residentialPropertyFlatTypes,
    residentialPropertyOtherTypes,
    specialPropertyTypes,
    parkingTypes,
} from '../vars/valuationsAndMembershipTypes';
import AccordionContainer from './AccordionContainer';
import { PropertyType } from '../../types';
import { useMe } from '../../utils/api/hooks';
import ConfirmationModal from './ConfirmationModal';

const validationSchema = Yup.object({
    street: Yup.string().required('errors.required'),
    city: Yup.string().required('errors.required'),
    country: Yup.string().required('errors.required'),
    postalCode: Yup.string().required('errors.required'),
    latitude: Yup.number().required('errors.required'),
    longitude: Yup.number().required('errors.required'),
    areaGrossInternal: Yup.string().required('errors.required'),
    estimateValue: Yup.string().required('Please enter an amount'),
    accessFName: Yup.string().required('errors.required'),
    accessLName: Yup.string().required('errors.required'),
    accessEmail: Yup.string().email('Invalid email address').required('errors.required'),
    accessMobileDialCode: Yup.string().required('errors.required').nullable(),
    accessMobile: Yup.number()
        .required('errors.required').typeError('errors.enterNumber'),
    propertyTypes: Yup.array().min(1, 'errors.required'),
});

type Property = Omit<PropertyType, 'id' | 'enquiryId'>;

type PropTypes = {
    allProperties?: Property[];
    onCancel: () => void;
    property: Property | undefined;
    saveProperty: (property: Property, updatedProp: Property | undefined) => void;
};

const PropertyForm = (props: PropTypes) => {
    const {
        allProperties = [],
        onCancel,
        property,
        saveProperty,
    } = props;

    const [confirmCancel, setConfirmCancel] = useState(false);

    const { t } = useTranslation(['enquiryform', 'common', 'enquiries']);

    const { data: user } = useMe();

    const { pathname } = useLocation();

    const [isError, setIsError] = useState(false);

    if (!property) return null;

    return (
        <Modal
            isOpen
            onClose={() => saveProperty(property, undefined)}
            size="4xl"
            closeOnOverlayClick={false}
        >
            <ModalOverlay />

            <ModalContent>
                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={{
                        street: property?.street || '',
                        city: property?.city || '',
                        country: property?.country || '',
                        postalCode: property?.postalCode || '',
                        latitude: property?.latitude,
                        longitude: property?.longitude,
                        buildYear: property?.buildYear,
                        propertyCondition: property?.propertyCondition || '',
                        propertyTypes: property?.propertyTypes || [],
                        agricultural: property?.agricultural || [],
                        land: property?.land || [],
                        commercial: property?.commercial || [],
                        development: property?.development || [],
                        residential: property?.residential || [],
                        specialRealEstate: property?.specialRealEstate || [],
                        propertyFeatures: property?.propertyFeatures || [],
                        documents: property?.documents || [],
                        areaGrossInternal: delimitNumber(property?.areaGrossInternal),
                        areaOverall: delimitNumber(property?.areaOverall),
                        estimateValue: delimitNumber(property?.estimateValue),
                        estimateGVD: delimitNumber(property?.estimateGVD),
                        otherFeatures: property?.otherFeatures || [],
                        accessFName: property?.accessFName || '',
                        accessLName: property?.accessLName || '',
                        accessEmail: property?.accessEmail || '',
                        accessMobileDialCode: property?.accessMobileDialCode || '',
                        accessMobile: property?.accessMobile || '',
                    }}
                    onSubmit={values => {
                        const hasFeatures = values.agricultural.concat(
                            values.land,
                            values.commercial,
                            values.development,
                            values.residential,
                            values.specialRealEstate,
                        );

                        if (hasFeatures == null) {
                            setIsError(true);
                            return;
                        }

                        const newProperty: Property = {
                            street: values.street,
                            city: values.city,
                            country: values.country,
                            postalCode: values.postalCode,
                            latitude: values.latitude.toString(),
                            longitude: values.longitude.toString(),
                            buildYear: values.buildYear,
                            propertyCondition: values.propertyCondition,
                            propertyTypes: values.propertyTypes,
                            agricultural: values.agricultural,
                            land: values.land,
                            commercial: values.commercial,
                            development: values.development,
                            residential: values.residential,
                            specialRealEstate: values.specialRealEstate,
                            propertyFeatures: values.propertyFeatures,
                            documents: values.documents,
                            areaGrossInternal: Number(
                                parseDelimitedNumber(values.areaGrossInternal),
                            ),
                            areaOverall: Number(
                                parseDelimitedNumber(values.areaOverall),
                            ),
                            estimateValue: Number(
                                parseDelimitedNumber(values.estimateValue),
                            ),
                            estimateGVD: Number(
                                parseDelimitedNumber(values.estimateGVD === '' ? 0 : values.estimateGVD),
                            ),
                            otherFeatures: values.otherFeatures,
                            accessFName: values.accessFName,
                            accessLName: values.accessLName,
                            accessEmail: values.accessEmail,
                            accessMobileDialCode: values.accessMobileDialCode,
                            accessMobile: values.accessMobile,
                        };

                        saveProperty(property, newProperty);
                    }}
                >
                    <ModalBody>
                        <ModalHeader color="wvwGreen">
                            {property !== undefined && Object.keys(property).length === 0 ? t('newEnquiry.addingProperty.sectionTitle.add') : t('newEnquiry.addingProperty.sectionTitle.edit')}
                        </ModalHeader>

                        <ModalCloseButton />

                        <VStack
                            align="left"
                            spacing={4}
                            w="100%"
                        >
                            <VStack
                                w="100%"
                                align="left"
                                bg="wvwGrey05"
                                borderRadius="10"
                                padding="1rem"
                            >
                                <Heading
                                    fontWeight="bold"
                                    size="md"
                                    color="wvwGreen"
                                >
                                    {t('newEnquiry.addingProperty.address.sectionTitle')}
                                </Heading>

                                <FormikAddressInputWithMap
                                    cityName="city"
                                    countryName="country"
                                    postalCodeName="postalCode"
                                    streetName="street"
                                    latName="latitude"
                                    lngName="longitude"
                                />
                            </VStack>

                            <VStack
                                w="100%"
                                align="left"
                                bg="wvwGrey05"
                                borderRadius="10"
                                padding="1rem"
                                spacing="1rem"
                            >
                                <Box>
                                    <Heading
                                        size="md"
                                        paddingBottom=".5rem"
                                        color="wvwGreen"
                                    >
                                        {t('propertyDetails.description', { ns: 'enquiries' })}
                                    </Heading>

                                    <Text>
                                        {t('newEnquiry.addingProperty.propertyDescription.typeQ')}
                                    </Text>

                                    <FormikCloudSelect
                                        name="propertyTypes"
                                        options={standardPropertyTypes.map(propertyType => ({
                                            label: t(propertyType, { ns: 'formik', defaultValue: propertyType }),
                                            value: propertyType,
                                        }))}
                                        onChange={(field, value = []) => {
                                            standardPropertyTypes.forEach(description => {
                                                if (!value.includes(description)) {
                                                    field?.onChange({
                                                        target: {
                                                            name: t(description, { ns: 'formik', defaultValue: description }),
                                                            value: [],
                                                        },
                                                    });
                                                }
                                            });
                                        }}
                                    />

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value.includes('Agricultural')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.agricultural')}
                                            </Text>
                                            <FormikCheckboxGroup
                                                name="agricultural"
                                                direction="row"
                                                options={agriculturePropertyTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value?.includes('Land')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.land')}
                                            </Text>

                                            <FormikCheckboxGroup
                                                name="land"
                                                direction="row"
                                                options={landPropertyTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value.includes('Commercial')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.commercial')}
                                            </Text>
                                            <FormikCheckboxGroup
                                                name="commercial"
                                                direction="row"
                                                options={commercialPropertyTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value.includes('Development')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.development')}
                                            </Text>
                                            <FormikCheckboxGroup
                                                name="development"
                                                direction="row"
                                                options={developmentPropertyTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value.includes('Residential')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.residentialTypes.house')}
                                            </Text>
                                            <FormikCheckboxGroup
                                                name="residential"
                                                direction="row"
                                                options={residentialPropertyHouseTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value.includes('Residential')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.residentialTypes.flat')}
                                            </Text>
                                            <FormikCheckboxGroup
                                                name="residential"
                                                direction="row"
                                                options={residentialPropertyFlatTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value.includes('Residential')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.residentialTypes.other')}
                                            </Text>
                                            <FormikCheckboxGroup
                                                name="residential"
                                                direction="row"
                                                options={residentialPropertyOtherTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>

                                    <FormikConditionalSection
                                        formDataTarget="propertyTypes"
                                        condition={value => value.includes('Special')}
                                    >
                                        <VStack
                                            align="left"
                                            backgroundColor="wvwGrey05"
                                            borderRadius="8"
                                            padding="1rem"
                                        >
                                            <Text
                                                as="b"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.type.specialRealEstate')}
                                            </Text>
                                            <FormikCheckboxGroup
                                                name="specialRealEstate"
                                                direction="row"
                                                options={specialPropertyTypes.map(
                                                    item => ({
                                                        label: t(item, { ns: 'formik', defaultValue: item }),
                                                        value: item,
                                                    }),
                                                )}
                                            />
                                        </VStack>
                                    </FormikConditionalSection>
                                </Box>
                                <Flex w="100%">
                                    <Box w="49%">
                                        <Text
                                            fontWeight="bold"
                                            color="wvwGreen"
                                        >
                                            {t('newEnquiry.addingProperty.propertyDescription.buildYear')}
                                        </Text>

                                        <FormikInput
                                            type="number"
                                            name="buildYear"
                                            placeholder={t('fieldPlaceholder.year', { ns: 'common' })}
                                        />
                                    </Box>

                                    <Spacer />

                                    <Box w="49%">
                                        <Text
                                            fontWeight="bold"
                                            color="wvwGreen"
                                        >
                                            {t('newEnquiry.addingProperty.propertyDescription.condition')}
                                        </Text>

                                        <FormikSelect
                                            name="propertyCondition"
                                            placeholder={t('fieldPlaceholder.pleaseSelect', { ns: 'common' })}
                                            options={[
                                                {
                                                    label: t('propertyCondition.NEW', { ns: 'formik' }),
                                                    value: 'NEW',
                                                },
                                                {
                                                    label: t('propertyCondition.RECENTLY_REFURBISHED', { ns: 'formik' }),
                                                    value: 'RECENTLY_REFURBISHED',
                                                },
                                                {
                                                    label: t('propertyCondition.DATED', { ns: 'formik' }),
                                                    value: 'DATED',
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Flex>

                                {isError && (
                                    <Text
                                        color="red"
                                    >
                                        {t('provideField.select.atLeastOne.propertyDescription', { ns: 'common' })}
                                    </Text>
                                )}
                                <Box>
                                    <Box paddingBottom="0.5rem">
                                        <Text
                                            fontWeight="bold"
                                            color="wvwGreen"
                                        >
                                            {t('newEnquiry.addingProperty.propertyDescription.features.sectionTitle')}
                                        </Text>
                                        <Text>
                                            {t('newEnquiry.addingProperty.propertyDescription.features.instruction')}
                                        </Text>
                                    </Box>
                                    <Box paddingBottom="0.5rem" w="100%">
                                        <FormikListInput
                                            name="otherFeatures"
                                            placeholder={t('addPropertyFeature', { ns: 'wizard' })}
                                        />
                                    </Box>

                                    <AccordionContainer
                                        title={t('newEnquiry.addingProperty.propertyDescription.parking.sectionTitle')}
                                        width="100%"
                                    >
                                        <FormikCheckboxGroup
                                            name="propertyFeatures"
                                            direction="column"
                                            options={parkingTypes.map(featureType => ({
                                                label: t(featureType, { ns: 'formik', defaultValue: featureType }),
                                                value: featureType,
                                                key: featureType,
                                            }))}
                                        />
                                    </AccordionContainer>
                                </Box>
                                <Flex
                                    w="100%"
                                >
                                    <Box
                                        w="49%"
                                    >
                                        <Text
                                            fontWeight="bold"
                                            color="wvwGreen"
                                        >
                                            {t('propertyDetails.grossInternalArea', { ns: 'enquiries' })}
                                        </Text>

                                        <FormikCurrencyInput
                                            name="areaGrossInternal"
                                            prefix={t('unitSymbol.squareMeasure', { ns: 'common' })}
                                        />
                                    </Box>

                                    <Spacer />

                                    <Box w="49%">
                                        <Text
                                            fontWeight="bold"
                                            color="wvwGreen"
                                        >
                                            <Highlight
                                                query="{t('provideField._optional', { ns: 'common' })}"
                                                styles={{ fontWeight: 'normal', color: 'wvwGrey60', fontStyle: 'italic' }}
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.features.overallArea')}
                                            </Highlight>
                                        </Text>

                                        <FormikCurrencyInput
                                            name="areaOverall"
                                            prefix={t('unitSymbol.squareMeasure', { ns: 'common' })}
                                        />
                                    </Box>
                                </Flex>

                                <FormikConditionalSection
                                    formDataTarget="propertyTypes"
                                    condition={value => !value.includes('Development')}
                                >
                                    <Flex w="100%">
                                        <Box
                                            w="100%"
                                            alignSelf="flex-end"
                                        >
                                            <Text
                                                fontWeight="bold"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.features.estimatedValue')}
                                            </Text>

                                            <FormikCurrencyInput
                                                name="estimateValue"
                                                prefix={t('unitSymbol.currency', { ns: 'common' })}
                                            />
                                        </Box>
                                    </Flex>
                                </FormikConditionalSection>

                                <FormikConditionalSection
                                    formDataTarget="propertyTypes"
                                    condition={value => value.includes('Development')}
                                >
                                    <Flex w="100%">
                                        <Box
                                            w="49%"
                                            alignSelf="flex-end"
                                        >
                                            <Text
                                                fontWeight="bold"
                                                color="wvwGreen"
                                            >
                                                {t('newEnquiry.addingProperty.propertyDescription.features.estimatedValue')}
                                            </Text>

                                            <FormikCurrencyInput
                                                name="estimateValue"
                                                prefix={t('unitSymbol.currency', { ns: 'common' })}
                                            />
                                        </Box>

                                        <Spacer />

                                        <Box w="49%">
                                            <Text
                                                fontWeight="bold"
                                                color="wvwGreen"
                                            >
                                                <Highlight
                                                    query="t('provideField._applicable', { ns: 'common' })"
                                                    styles={{ fontWeight: 'normal', color: 'wvwGrey60', fontStyle: 'italic' }}
                                                >
                                                    {t('newEnquiry.addingProperty.propertyDescription.features.estimatedGDV')}
                                                </Highlight>
                                            </Text>

                                            <FormikCurrencyInput
                                                name="estimateGVD"
                                                prefix={t('unitSymbol.currency', { ns: 'common' })}
                                            />
                                        </Box>
                                    </Flex>
                                </FormikConditionalSection>

                                <Box>
                                    <Text
                                        fontWeight="bold"
                                        color="wvwGreen"
                                    >
                                        {t('newEnquiry.addingProperty.propertyDescription.supportDocs.sectionTitle')}
                                    </Text>
                                    <Text>
                                        {t('newEnquiry.addingProperty.propertyDescription.supportDocs.instruction')}
                                    </Text>
                                </Box>

                                <FormikFileUpload name="documents" />
                            </VStack>

                            <VStack
                                w="100%"
                                align="left"
                                bg="wvwGrey05"
                                borderRadius="10"
                                padding="1rem"
                            >
                                <Box w="100%">
                                    <Heading
                                        size="md"
                                        paddingBottom=".5rem"
                                        color="wvwGreen"
                                    >
                                        {t('newEnquiry.addingProperty.propertyDescription.accessDetails.sectionTitle')}
                                    </Heading>

                                    <Flex>
                                        <Text>
                                            {t('newEnquiry.addingProperty.propertyDescription.accessDetails.instruction')}
                                        </Text>

                                        <Spacer />

                                        {user && pathname.includes('direct-enquiry-form') === false && (
                                            <Box
                                                alignSelf="flex-end"
                                            >
                                                {allProperties.length <= 1 && (
                                                    <FormikCheckbox
                                                        name="addressType"
                                                        label={t('newEnquiry.addingProperty.propertyDescription.accessDetails.myDetails')}
                                                        onChange={(event, field) => {
                                                            const newValue = !field?.value;

                                                            if (newValue) {
                                                                field?.onChange({ target: { name: 'accessFName', value: user.firstName } });
                                                                field?.onChange({ target: { name: 'accessLName', value: user.lastName } });
                                                                field?.onChange({ target: { name: 'accessEmail', value: user.email } });
                                                                field?.onChange({ target: { name: 'accessMobileDialCode', value: user.mobileDialCode } });
                                                                field?.onChange({ target: { name: 'accessMobile', value: user.mobile } });
                                                            }

                                                            if (!newValue) {
                                                                field?.onChange({ target: { name: 'accessFName', value: '' } });
                                                                field?.onChange({ target: { name: 'accessLName', value: '' } });
                                                                field?.onChange({ target: { name: 'accessEmail', value: '' } });
                                                                field?.onChange({ target: { name: 'accessMobileDialCode', value: '' } });
                                                                field?.onChange({ target: { name: 'accessMobile', value: '' } });
                                                            }
                                                        }}

                                                    />
                                                )}

                                                {allProperties.length > 1 && (
                                                    <FormikRadioGroup
                                                        name="addressType"
                                                        options={[
                                                            {
                                                                label: t('newEnquiry.addingProperty.propertyDescription.accessDetails.myDetails'),
                                                                value: 'SAME',
                                                            },
                                                            {
                                                                label: t('newEnquiry.addingProperty.propertyDescription.accessDetails.sameAsPrevious'),
                                                                value: 'ABOVE',
                                                            },
                                                        ]}
                                                        width="25rem"
                                                        onChange={(value, field) => {
                                                            if (value === 'SAME') {
                                                                field.onChange({ target: { name: 'accessFName', value: user.firstName } });
                                                                field.onChange({ target: { name: 'accessLName', value: user.lastName } });
                                                                field.onChange({ target: { name: 'accessEmail', value: user.email } });
                                                                field.onChange({ target: { name: 'accessMobileDialCode', value: user.mobileDialCode } });
                                                                field.onChange({ target: { name: 'accessMobile', value: user.mobile } });
                                                            } else if (value === 'ABOVE') {
                                                                field.onChange({ target: { name: 'accessFName', value: allProperties[0].accessFName } });
                                                                field.onChange({ target: { name: 'accessLName', value: allProperties[0].accessLName } });
                                                                field.onChange({ target: { name: 'accessEmail', value: allProperties[0].accessEmail } });
                                                                field.onChange({ target: { name: 'accessMobileDialCode', value: allProperties[0].accessMobileDialCode } });
                                                                field.onChange({ target: { name: 'accessMobile', value: allProperties[0].accessMobile } });
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Flex>
                                </Box>

                                <SimpleGrid
                                    columns={2}
                                    spacing="6"
                                >
                                    <FormikInput
                                        name="accessFName"
                                        placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                                        type="text"
                                    />

                                    <FormikInput
                                        name="accessLName"
                                        placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                                        type="text"
                                    />

                                    <FormikInput
                                        name="accessEmail"
                                        placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                                        type="email"
                                    />

                                    <SimpleGrid columns={2} spacing="6">
                                        <Box>
                                            <FormikCountrySelect
                                                name="accessMobileDialCode"
                                                selectDialCode
                                            />
                                        </Box>

                                        <Box>
                                            <FormikInput
                                                name="accessMobile"
                                                placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                            />
                                        </Box>
                                    </SimpleGrid>
                                </SimpleGrid>
                            </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Flex w="100%">
                            <Button
                                width="10rem"
                                variant="danger"
                                alignSelf="flex-end"
                                onClick={() => setConfirmCancel(true)}
                            >
                                {t('button.cancel', { ns: 'common' })}
                            </Button>

                            <Spacer />

                            <Button
                                width="10rem"
                                variant="primaryYellow"
                                type="submit"
                                alignSelf="flex-end"
                            >
                                {Object.keys(property).length === 0 ? t('button.save', { ns: 'common' }) : t('button.update', { ns: 'common' })}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </FormikForm>
            </ModalContent>

            <ConfirmationModal
                isOpen={confirmCancel}
                title={t('newEnquiry.addingProperty.cancelProperty.sectionTitle', { ns: 'enquiryform' })}
                content={t('newEnquiry.addingProperty.cancelProperty.blurb', { ns: 'enquiryform' })}
                continueButtonVariant="danger"
                continueButton={t('button.leave', { ns: 'common' })}
                cancelButtonVariant="success"
                cancelButton={t('button.stay', { ns: 'common' })}
                onContinue={() => {
                    onCancel();
                    setConfirmCancel(false);
                }}
                onCancel={() => {
                    setConfirmCancel(false);
                }}
            />

        </Modal>
    );
};

PropertyForm.defaultProps = {
    allProperties: [],
};

export default PropertyForm;
