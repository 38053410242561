import { useQuery, useQueryClient } from 'react-query';
import { userInvitesPending } from '../endpoints';

const USER_INVITES_PENDING = 'USER_INVITES_PENDING';

const useUserInvitesPending = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([USER_INVITES_PENDING], () => userInvitesPending());

    const refresh = () => {
        queryClient.invalidateQueries(USER_INVITES_PENDING);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useUserInvitesPending;
