import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import useToast from '../../../common/hooks/useToast';
import { suspendAccount } from '../endpoints';
import useUsersByEntity from './useUsersByEntity';
import useMyEntity from './useMyEntity';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useSuspendAccount = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { t } = useTranslation('common');

    const { createSuccessToast, createErrorToast } = useToast();

    const {
        data: myEntity,
    } = useMyEntity();

    const {
        refresh: refreshUsers,
    } = useUsersByEntity(myEntity.id);

    const { mutate: update } = useMutation(
        (data: { entityId: string | number, targetUserId: string | number }) => (
            suspendAccount(data.entityId, data.targetUserId)
        ),
        {
            onSuccess: (data: any) => {
                onSuccess?.(data);

                refreshUsers();

                createSuccessToast(t('success.accountSuspended', { ns: 'common' }));
            },
            onError: (error: Error) => {
                onError?.(error);

                createErrorToast(t(error.message, { defaultValue: error.message }));
            },
        },
    );

    return {
        update,
    };
};

useSuspendAccount.defaultProps = {
    onSuccess: () => {},
    onError: () => {},
};

export default useSuspendAccount;
