import {
    Box,
    Button,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string;
    options: {
        icon?: ReactNode;
        label: string;
        value: string;
    }[];
};

const FormikCloudSelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const { name, options } = props;

    const [field, meta] = useField(props);

    return (
        <Box w="100%">
            <SimpleGrid
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                minChildWidth="13rem"
                spacing="0.2rem"
            >
                {options.map(option => {
                    if (option.icon) {
                        return (
                            <Box
                                key={`${field.name}[${option.value}]`}
                                as="button"
                                maxWidth="20rem"
                                name={`${field.name}[${option.value}]`}
                                w="100%"
                            >
                                <VStack
                                    align="center"
                                    spacing="1"
                                    w="7em"
                                >
                                    {option.icon}

                                    <Text
                                        align="center"
                                    >
                                        {option.label.slice(0, 20)}
                                    </Text>
                                </VStack>
                            </Box>
                        );
                    }

                    return (
                        <Button
                            key={option.value}
                            margin="0.5rem"
                            width="12rem"
                            name={`${field.name}[${option.value}]`}
                            variant={field.value.includes(option.value) ? 'primaryYellow' : 'primary'}
                            onClick={() => {
                                if (field.value === option.value) {
                                    field.onChange({ target: { name, value: '' } });
                                } else {
                                    field.onChange({ target: { name, value: option.value } });
                                }
                            }}
                        >
                            {option.label.slice(0, 25)}
                        </Button>
                    );
                })}
            </SimpleGrid>

            {meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )}
        </Box>
    );
};

export default FormikCloudSelect;
