import {
    Button,
    Flex,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { TickCircle } from 'iconsax-react';

type PropTypes = {
    onChange: (value: string | undefined) => void;
    options: {
        label: string;
        value: string;
    }[];
    value?: string;
};

const ListSelect = (props: PropTypes) => {
    const { onChange, options, value } = props;

    return (
        <VStack
            spacing={2}
            w="100%"
        >
            {options.map(i => (
                <Button
                    justifyContent="left"
                    key={i.value}
                    fontWeight="medium"
                    h="3rem"
                    margin="0.5rem"
                    onClick={() => onChange(value !== i.value ? i.value : undefined)}
                    variant={value === i.value ? 'primaryYellow' : 'primary'}
                    w="100%"
                >
                    <Flex w="100%">
                        {i.label}

                        <Spacer />

                        {value === i.value && (
                            <TickCircle color="white" />
                        )}
                    </Flex>
                </Button>
            ))}
        </VStack>
    );
};

ListSelect.defaultProps = {
    value: undefined,
};

export default ListSelect;
