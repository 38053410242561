import { useQuery, useQueryClient } from 'react-query';
import { usersGetByEntity } from '../endpoints';

const USERS_BY_ENTITY = 'USERS_BY_ENTITY';

const useUsersByEntity = (entityId: number) => {
    const queryClient = useQueryClient();

    const {
        data: usersList,
        error,
        isLoading,
    } = useQuery(
        [USERS_BY_ENTITY, entityId],
        () => usersGetByEntity(entityId),
        {
            enabled: !!entityId,
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(USERS_BY_ENTITY);
    };

    return {
        usersList,
        error,
        isLoading,
        refresh,
    };
};

export default useUsersByEntity;
