import { Text } from '@chakra-ui/react';

type PropTypes = {
    children: React.ReactNode;
    color?: 'black' | 'wvwGreen' | 'wvwGrey60' | 'white';
    textAlign?: 'center' | 'left';
};

const WvwText = (props: PropTypes) => {
    const {
        children,
        color = 'black',
        textAlign,
    } = props;

    return (
        <Text
            textAlign={textAlign}
            color={color === 'black' ? undefined : color}
        >
            {children}
        </Text>
    );
};

WvwText.defaultProps = {
    color: 'black',
    textAlign: 'left',
};

export default WvwText;
