import { useMutation } from 'react-query';
import { documentRequestUpdateRequest } from '../../endpoints';
import { DocumentRequestUpdateRequestDto } from '../../dto';
import useToast from '../../../../common/hooks/useToast';
import useDocumentRequestGetRequest from './useDocumentRequestGetRequest';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';

type ParamsType = {
    caseId: number | string;
    requestId?: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestUpdateRequest = (params: ParamsType) => {
    const {
        caseId,
        requestId = '',
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast, createSuccessToast } = useToast();

    const { refresh: refreshRequest } = useDocumentRequestGetRequest(requestId);
    const { refreshAll } = useRefreshAllDMSDataStores(Number(caseId));

    const { mutate: update } = useMutation(
        (dto: DocumentRequestUpdateRequestDto) => documentRequestUpdateRequest(dto),
        {
            onSuccess: () => {
                refreshRequest();
                refreshAll();

                createSuccessToast(t('toast.messages.requestUpdated'));

                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));

                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestUpdateRequest;
