import {
    VStack,
    Flex,
    Text,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentText } from 'iconsax-react';
import DocumentUploadWithNote from './common/DocumentUploadWithNote';
import ModalDynamic from '../../../../../../common/components/ModalDynamic';
import { useDocumentRequestGetDocument } from '../../../../../../utils/api/hooks';

const ReplaceDocument = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { documentId, caseId } = useParams();

    const {
        data: replaceDocument,
    } = useDocumentRequestGetDocument(Number(documentId));

    return (
        <ModalDynamic
            variant="small"
            isOpen
            header={t('replaceDocument', { ns: 'documentmanagement' })}
            onClose={() => navigate('../', { replace: true })}
        >
            <VStack
                align="left"
                paddingBlock=".5rem"
                w="100%"
            >
                <Flex
                    paddingBlock=".5rem"
                    paddingInline="1rem"
                    borderRadius="10"
                    w="100%"
                    bg="wvwGrey05"
                    align="center"
                >
                    <DocumentText />

                    <Text
                        fontWeight="500"
                        pl=".5rem"
                        overflow="hidden"
                        textAlign="left"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                    >
                        {replaceDocument?.name}
                    </Text>
                </Flex>

                {replaceDocument?.responseComment && (
                    <Flex
                        paddingBlock=".5rem"
                        paddingInline="1rem"
                        borderRadius="10"
                        w="100%"
                        bg="red05"
                        align="center"
                    >
                        <VStack
                            align="left"
                            spacing="0"

                        >
                            <Text
                                fontWeight="500"
                                color="red"
                            >
                                {t('rejectionNote', { ns: 'documentmanagement', defaultValue: 'Rejection note' })}
                            </Text>

                            <Text>
                                {replaceDocument?.responseComment}
                            </Text>
                        </VStack>
                    </Flex>
                )}

                <Text
                    color="wvwGreen"
                    fontSize="1xl"
                    fontWeight="500"
                >
                    {t('replaceWith', { ns: 'documentmanagement' })}
                </Text>

                <DocumentUploadWithNote
                    caseId={Number(caseId)}
                    requestId={Number(replaceDocument?.documentRequestId)}
                    replacedDocumentId={Number(documentId)}
                    onClose={() => navigate('../', { replace: true })}
                />
            </VStack>
        </ModalDynamic>

    );
};

export default ReplaceDocument;
