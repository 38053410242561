import {
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    HStack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import delimitNumber from '../../../../../common/functions/delimitNumber';
import { EntityType } from '../../../../../types';
import { useRole } from '../../../../../common/hooks';
import CompanyCapabilitiesEdit from './CompanyCapabilitiesEdit';
import { displayDateWithCurrentLocale } from '../../../../../common/functions/displayDateInLocale';

type PropTypes = {
    entity: EntityType;
};

const CompanyValuationCapabilities = (props: PropTypes) => {
    const { entity } = props;

    const { t } = useTranslation('account');
    const { userIsCompanyAdmin: userIsAdmin } = useRole();

    const [editing, setEditng] = useState(false);

    if (editing) {
        return (
            <CompanyCapabilitiesEdit
                entity={entity}
                onComplete={() => setEditng(false)}
            />
        );
    }

    return (
        <Box
            bg="wvwGrey05"
            borderRadius="10"
            marginTop="1.5rem"
            padding="1.5rem 2rem"
            w="100%"
        >
            <Heading
                as="h4"
                color="wvwGrey"
                size="md"
            >
                {t('valuationCapabilities.sectionTitle')}
            </Heading>

            <VStack
                paddingTop="1rem"
            >
                <Flex
                    alignItems="flex-start"
                    w="100%"
                >
                    <Text
                        color="wvwGreen"
                        w="17rem"
                    >
                        {t('valuationCapabilities.piCover.piCover')}
                    </Text>

                    <HStack
                        h="2rem"
                        spacing="1"
                    >
                        <Text>
                            {t('valuationCapabilities.piCover.or', {
                                coverValue: entity?.piCoverValue && Number(entity.piCoverValue) > 0 ? (`${entity?.piCoverCurrency || t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(entity?.piCoverValue.toString())}`) : ('-'),
                                coverPercentage: entity?.piCoverPercentage === 0 || entity?.piCoverPercentage === null ? ('-') : (`maximum ${entity?.piCoverPercentage}${t('valuationCapabilities.piCover.p_of_value')},`),
                                interpolation: { escapeValue: false },
                            })}
                        </Text>

                        {entity?.piCoverValidityDate ? (
                            <Text>
                                {t('valuationCapabilities.piCover.validUntil', { date: displayDateWithCurrentLocale(entity.piCoverValidityDate) })}
                            </Text>
                        ) : (
                            <div />
                        )}
                    </HStack>
                </Flex>

                <Divider />

                <Flex
                    alignItems="flex-start"
                    w="100%"
                >
                    <Text
                        color="wvwGreen"
                        w="17rem"
                    >
                        {t('valuationCapabilities.piCover.specialArrangementsAvailable')}
                    </Text>

                    <VStack
                        align="left"
                    >
                        <Text>
                            {entity?.piCoverSpecialArrangementsAvailable ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' })}
                        </Text>
                    </VStack>
                </Flex>

                <Divider />

                {userIsAdmin && (
                    <Flex
                        justifyContent="end"
                        w="100%"
                    >
                        <Button
                            variant="primary"
                            onClick={() => setEditng(true)}
                        >
                            {t('button.edit', { ns: 'common' })}
                        </Button>
                    </Flex>
                )}
            </VStack>
        </Box>
    );
};

export default CompanyValuationCapabilities;
