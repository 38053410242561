import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { InspectionType } from '../../../../../types';
import { WvwButton } from '../../../../../common/components/inputs';
import InspectionLayout from './InspectionLayout';
import { useRole } from '../../../../../common/hooks';
import { WvwText } from '../../../../../common/components/typography';

type PropTypes = {
    inspection: InspectionType;
};

const InspectionPreparation = (props: PropTypes) => {
    const { inspection } = props;

    const { t } = useTranslation('inspection');
    const navigate = useNavigate();
    const { userIsValuer } = useRole();

    return (
        <InspectionLayout
            color="wvwYellow10"
            customHeading={(
                <>
                    <WvwText>
                        {t('inspectionDetails.addDateBlurbOne')}
                    </WvwText>

                    {userIsValuer && (
                        <WvwText>
                            {t('inspectionDetails.addDateBlurbTwo')}
                        </WvwText>
                    )}
                </>
            )}
            inspection={inspection}
            actionButton={userIsValuer && (
                <WvwButton
                    content={t('button.addDates')}
                    icon={<HiPlus />}
                    onClick={event => {
                        event.stopPropagation();
                        navigate(`select-dates/${inspection.id}`);
                    }}
                    size="sm"
                    variant="default"
                />
            )}
        />
    );
};

export default InspectionPreparation;
