import { useQuery, useQueryClient } from 'react-query';
import { AdminCasesDto } from '../../dto';
import { adminCases } from '../../endpoints';

const ADMIN_CASES = 'ADMIN_CASES';

const useAdminCases = (dto: AdminCasesDto) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([ADMIN_CASES, JSON.stringify(dto)], () => adminCases(dto));

    const refresh = () => {
        queryClient.invalidateQueries(ADMIN_CASES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useAdminCases;
