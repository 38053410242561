import { confirmPasswordReset } from 'firebase/auth';
import { useMutation } from 'react-query';
import { auth } from '../../firebase';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

type DtoType = {
    oobCode: string;
    newPassword: string;
};

const useFbConfirmPasswordReset = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        ({ oobCode, newPassword }: DtoType) => confirmPasswordReset(auth, oobCode, newPassword),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useFbConfirmPasswordReset;
