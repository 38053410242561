import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Text,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import parseDelimitedNumber from '../functions/parseDelimitedNumber';

type PropTypes = {
    name: string;
    disabled?: boolean;
    placeholder?: string;
    prefix?: string;
    maxW?: string;
    suffix?: string;
    width?: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void,
};

const FormikCurrencyInput = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        prefix,
        disabled,
        maxW,
        name,
        suffix,
        width,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <div
            style={{
                alignSelf: 'top',
                maxWidth: maxW,
                width,
            }}
        >
            <InputGroup>
                { prefix && (
                    <InputLeftAddon
                        h="3rem"
                        minW="4rem"
                    >
                        {prefix}
                    </InputLeftAddon>
                ) }

                <Input
                    borderRadius="8"
                    bg="white"
                    disabled={disabled}
                    h="3rem"
                    isInvalid={!!meta.touched && !!meta.error}
                    maxW={maxW}
                    w={width}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    onChange={e => {
                        let value = parseDelimitedNumber(e.target.value);

                        if (value !== '') {
                            value = Intl.NumberFormat('en-US').format(Number(value));
                        }

                        field.onChange({ target: { name, value } });

                        onChange?.(value, field);
                    }}
                />

                { suffix && (
                    <InputRightAddon
                        h="3rem"
                        minW="4rem"
                    >
                        {suffix}
                    </InputRightAddon>
                ) }
            </InputGroup>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </div>
    );
};

FormikCurrencyInput.defaultProps = {
    disabled: false,
    placeholder: '',
    prefix: null,
    suffix: null,
    maxW: '100%',
    width: '100%',
    onChange: () => {},
};

export default FormikCurrencyInput;
