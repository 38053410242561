import {
    ButtonGroup,
    Collapse,
    Flex,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    Edit,
    TickCircle,
    MessageText,
} from 'iconsax-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    DocumentRequestTemplateRequestType,
    DocumentRequestType,
    dragTargetTypes,
    FolderDropTargetType,
} from '../../../../../types';
import { FormikForm, FormikInput, FormikTextarea } from '../../../../../common/forms';
import { useDocumentRequestUpdateRequest } from '../../../../../utils/api/hooks';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    onDelete?: (requestId: number) => void;
    onEdit?: (requestId: number, name: string, description: string) => void;
    request: DocumentRequestType | DocumentRequestTemplateRequestType;
    requestType: 'TEMPLATE' | 'REQUEST';
};

type ViewModeProps = {
    onDelete?: (requestId: number) => void;
    onEdit?: (requestId: number, name: string, description: string) => void;
    request: DocumentRequestType | DocumentRequestTemplateRequestType;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
};

type EditModeProps = {
    onEdit?: (requestId: number, name: string, description: string) => void;
    request: DocumentRequestType | DocumentRequestTemplateRequestType;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
};

const EditMode = (props: EditModeProps) => {
    const {
        onEdit,
        request,
        setIsEditing,
    } = props;

    const { t } = useTranslation('documentmanagement');

    return (
        <FormikForm
            initialValues={{
                name: request.name,
                description: request.description,
            }}
            onSubmit={values => {
                onEdit?.(
                    request.id,
                    values.name,
                    values.description,
                );

                setIsEditing(false);
            }}
        >
            <Flex w="100%">
                <VStack w="100%">
                    <FormikInput
                        name="name"
                        placeholder={t('renameFile')}
                    />

                    <FormikTextarea
                        name="description"
                        placeholder={t('instructionNotes')}
                    />
                </VStack>

                <Spacer />

                <ButtonGroup
                    spacing="0"
                    alignSelf="flex-end"
                >
                    <WvwDeleteIconButton
                        onClick={() => setIsEditing(false)}
                        color="black"
                    />

                    <IconButton
                        aria-label="save edit"
                        icon={<TickCircle />}
                        variant="none"
                        type="submit"
                    />
                </ButtonGroup>
            </Flex>
        </FormikForm>
    );
};

const ViewMode = (props: ViewModeProps) => {
    const {
        onDelete,
        onEdit,
        request,
        setIsEditing,
    } = props;

    const [expanded, setExpanded] = useState(false);

    return (
        <VStack
            align="left"
            w="100%"
        >
            <Flex
                onClick={() => setExpanded(!!(request.description && !expanded))}
                _hover={{ cursor: 'pointer' }}
                w="100%"
            >
                <HStack spacing="1">
                    {request.description && (
                        <MessageText
                            size="1.3rem"
                            color="rgba(1, 96, 100)"
                        />
                    )}

                    <Text
                        fontWeight="500"
                        marginRight=".5rem"
                        noOfLines={1}
                        maxW="11rem"
                        _hover={{ cursor: 'auto' }}
                    >
                        {request.name}
                    </Text>
                </HStack>

                <Spacer />

                <ButtonGroup spacing="0">
                    {onDelete && (
                        <WvwDeleteIconButton
                            onClick={e => {
                                onDelete?.(request?.id);
                                e.stopPropagation();
                            }}
                            color="black"
                        />
                    )}

                    {onEdit && (
                        <IconButton
                            aria-label="edit request"
                            variant="none"
                            color="black"
                            icon={<Edit size="1.3rem" />}
                            onClick={e => {
                                setIsEditing(true);
                                e.stopPropagation();
                            }}
                        />
                    )}
                </ButtonGroup>
            </Flex>

            <Collapse in={expanded}>
                <Text color="wvwGrey60">
                    {request.description}
                </Text>
            </Collapse>
        </VStack>
    );
};

const RequestListItem = (props: PropTypes) => {
    const {
        onDelete,
        onEdit,
        request,
        requestType,
    } = props;

    const { t } = useTranslation('documentmanagement');
    const { caseId = '' } = useParams<{ caseId: string }>();

    const [isEditing, setIsEditing] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const { update: updateRequest } = useDocumentRequestUpdateRequest({ caseId });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: requestType === 'REQUEST' ? dragTargetTypes.REQUEST_IMPORT : dragTargetTypes.TEMPLATE_REQUEST_IMPORT,
        item: { requestId: request.id },
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as FolderDropTargetType;

            if (item && dropResult) {
                updateRequest({
                    requestId: request.id,
                    folderId: dropResult.folderId,
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    if (isDragging) {
        return null;
    }

    return (
        <>
            <VStack
                bg="wvwGrey05"
                borderRadius="10"
                data-testid="box"
                key={request.id}
                paddingBlock="1rem"
                paddingInline="1rem"
                ref={drag}
                w="100%"
            >
                <Flex w="100%">
                    {isEditing && (
                        <EditMode
                            onEdit={onEdit}
                            request={request}
                            setIsEditing={setIsEditing}
                        />
                    )}

                    {!isEditing && (
                        <ViewMode
                            onDelete={onDelete && (() => setDeleteConfirmOpen(true))}
                            onEdit={onEdit}
                            request={request}
                            setIsEditing={setIsEditing}
                        />
                    )}
                </Flex>
            </VStack>

            <ConfirmationModal
                content={t('modal.message.deleteRequest')}
                isOpen={deleteConfirmOpen}
                cancelButtonVariant="primary"
                continueButtonVariant="danger"
                onCancel={() => setDeleteConfirmOpen(false)}
                onContinue={() => {
                    onDelete?.(request.id);
                    setDeleteConfirmOpen(false);
                }}
                title={t('modal.title.deleteRequest')}
            />
        </>
    );
};

const defaultProps = {
    onEdit: undefined,
    onDelete: undefined,
};

EditMode.defaultProps = { ...defaultProps, onDelete: undefined };

ViewMode.defaultProps = defaultProps;
RequestListItem.defaultProps = defaultProps;

export default RequestListItem;
