const landPropertyTypes = [
    'With Planning - Commercial',
    'With Planning - Agricultural',
    'With Planning - Mixed',
    'With Planning - Residential',
    'No Planning',
    'Other Land',
];

export default landPropertyTypes;
