import {
    VStack,
    Text,
} from '@chakra-ui/react';
import LoadingSpinner from '../../../../../../../common/components/LoadingSpinner';
import { usePublicUser } from '../../../../../../../utils/api/hooks';

type PropTypes = {
    message: string;
    id: number;
    fromId: number;
};

const DocumentComment = (props: PropTypes) => {
    const {
        message,
        id,
        fromId,
    } = props;

    const {
        data: user,
        isLoading,
    } = usePublicUser(fromId);

    if (isLoading) return <LoadingSpinner />;

    return (
        <VStack
            paddingBlock=".5rem"
            paddingInline="1rem"
            bgColor="red05"
            w="100%"
            borderRadius="10"
            key={id}
            align="left"
            spacing="0"
        >
            <Text
                fontWeight="500"
                color="red"
            >
                {`${user.firstName} ${user.lastName}`}
            </Text>

            <Text
                alignSelf="flex-start"
            >
                {message}
            </Text>
        </VStack>
    );
};

export default DocumentComment;
