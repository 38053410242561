import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetTemplates } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_TEMPLATES = 'DOCUMENT_REQUEST_GET_TEMPLATES';

const useDocumentRequestGetTemplates = () => {
    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_TEMPLATES],
        () => documentRequestGetTemplates(),
        {
            onError: (err: Error) => createErrorToast(
                t(err.message, { defaultValue: err.message }),
            ),
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_TEMPLATES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetTemplates;
