import { useMutation } from 'react-query';
import { entityRemoveMeFromPanel } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

// called by the valuer to remove them from the entity's (entityId) panel
const useEntityRemoveMeFromPanel = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (entityId: number | string) => entityRemoveMeFromPanel(entityId),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useEntityRemoveMeFromPanel;
