import {
    Box,
    Button,
    Container,
    HStack,
    IconButton,
} from '@chakra-ui/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/firebase';
import LanguageToggleButton from '../../common/components/LanguageToggleButton';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import Logo from '../../common/components/Logo';
import Footer from '../../common/components/Footer';
import Support from '../../common/components/Support';
import WaterMarkBox from '../../common/components/WaterMarkBox';

const ContactUs = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [user, loading] = useAuthState(auth);

    if (loading) return <LoadingSpinner />;

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container maxW="container.lg">
                    <Box
                        bg="white"
                        borderRadius="lg"
                        borderWidth="1px"
                        marginTop="2rem"
                        marginBottom="2rem"
                        p="2em"
                        w="100%"
                    >
                        <HStack justifyContent="space-between">
                            <HStack>
                                <IconButton
                                    aria-label="Back"
                                    color="white"
                                    icon={<RiArrowGoBackFill />}
                                    size="xs"
                                    variant="primary"
                                    onClick={() => {
                                        if (user) { navigate('/dashboard'); } else {
                                            navigate('/login');
                                        }
                                    }}
                                />

                                <Button
                                    color="wvwGreen"
                                    variant="link"
                                    onClick={() => {
                                        if (user) { navigate('/dashboard'); } else {
                                            navigate('/login');
                                        }
                                    }}
                                >
                                    {t('button.back', { ns: 'common' })}
                                </Button>
                            </HStack>

                            <Logo withColor />

                            <LanguageToggleButton />
                        </HStack>

                        <Support />
                    </Box>
                </Container>

                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

export default ContactUs;
