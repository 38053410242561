import { Select, SelectProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import countryCodes from '../vars/countryCodes';

type PropTypes = {
    name: string;
    selectDialCode?: boolean;
} & SelectProps;

const CountrySelect = (props: PropTypes) => {
    const { t } = useTranslation('common');
    const { selectDialCode } = props;

    const spreadProps = { ...props };

    delete spreadProps.selectDialCode;

    return (
        <Select
            bg="white"
            h="3rem"
            borderRadius="8"
            placeholder={t('fieldPlaceholder.country', { ns: 'common' })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...spreadProps}
        >
            { countryCodes.map(i => (
                <option
                    key={`${i.name} ${i.dial_code}`}
                    disabled={i.disabled}
                    value={selectDialCode ? i.dial_code : i.name}
                >
                    {`${t(`countries.${i.name}`, { ns: 'common', defaultValue: i.name })} ${selectDialCode && i.dial_code !== '' ? `(${i.dial_code})` : ''}`}
                </option>
            )) }
        </Select>
    );
};

CountrySelect.defaultProps = {
    selectDialCode: false,
};

export default CountrySelect;
