import {
    HStack,
    IconButton,
    Text,
} from '@chakra-ui/react';
import { ArrowCircleLeft, ArrowCircleRight } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type PropTypes = {
    index: number;
    itemId: number | string;
    toggleList: any[];
    path: string;
};

const Toggle = (props: PropTypes) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        index,
        itemId,
        toggleList,
        path,
    } = props;

    return (
        <HStack
            marginRight=".5rem"
            marginTop=".5rem"
            spacing="0"
        >
            <IconButton
                variant="none"
                color="wvwGreen"
                aria-label="previous"
                icon={<ArrowCircleLeft />}
                onClick={() => {
                    if (index === 0) return;
                    navigate(`/${path}/${toggleList[index - 1].id}`, { replace: true });
                }}
            />

            <Text
                color="wvwGreen"
                fontWeight="600"
            >
                {t('thisManyOfThatMany', { thisMany: toggleList.findIndex((x: { id: number; }) => x.id === Number(itemId)) + 1, thatMany: toggleList.length, ns: 'common' })}
            </Text>

            <IconButton
                variant="none"
                color="wvwGreen"
                aria-label="next"
                icon={<ArrowCircleRight />}
                onClick={() => {
                    if (index === toggleList.length - 1) return;
                    navigate(`/${path}/${toggleList[index + 1].id}`, { replace: true });
                }}
            />
        </HStack>
    );
};

export default Toggle;
