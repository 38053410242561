/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import {
    Box,
    useRadio,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    width?: string;
    children: string | ReactNode;
    value: string;
};

const RadioCard = (props: PropTypes) => {
    const { width } = props;
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label" width="100%">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                w={width}
                margin=".5rem"
                border="2px solid #45687E"
                color="white"
                bg="wvwGreen"
                fontWeight="600"
                textAlign="center"
                borderRadius="md"
                _checked={{
                    bg: 'wvwYellow',
                    color: 'white',
                    borderColor: 'wvwYellow',
                    _hover: {
                        bg: 'wvwYellow',
                        color: 'white',
                        border: '2px solid wvwYellow',
                    },
                }}
                _hover={{
                    border: 'solid 2px #2D546C',
                    bg: '#2D546C',
                }}
                px={5}
                py={2}
            >
                {props.children}
            </Box>

        </Box>
    );
};

RadioCard.defaultProps = {
    width: '100%',
};

export default RadioCard;
