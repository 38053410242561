import { Icon, IconProps } from '@chakra-ui/icons';
import { OmitCommonProps } from '@chakra-ui/react';
import { SVGProps } from 'react';

type PropTypes = JSX.IntrinsicAttributes & OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> & IconProps & { as?: 'svg' | undefined; };

const WVWLogoWhite = (props: PropTypes) => (
    <Icon
        viewBox="0 0 214 89"
        h="auto"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <path fill="white" d="M167 0h31c13 2 20 15 14 27l-31 53c-5 9-15 11-23 7-8-5-12-15-8-24l13-22c4-9 9-17 8-27 0-5-2-9-4-14Zm-44 0h13c14 1 22 15 16 27l-31 53c-5 9-16 11-24 6-9-5-11-16-6-25l17-31c4-9 4-18 0-27-2-3 0-3 2-3h13ZM47 0h30c14 1 22 15 15 27L62 79c-6 10-16 12-25 7-8-5-11-16-5-25l14-25c6-12 8-23 1-36Z" className="cls-2" />
        <path fill="#E94A44" d="M34 17c0 4-2 7-3 10L19 48c-1 1-2 3-4 0-5-8-11-16-14-26-2-7 0-13 5-17 5-5 12-6 18-3s9 8 10 15Zm-12 0c0-3-2-5-5-5-4 0-6 2-5 6 0 3 2 5 5 5 4 0 5-2 5-6Z" />
    </Icon>
);

WVWLogoWhite.defaultProps = {
    as: 'svg',
};

export default WVWLogoWhite;
