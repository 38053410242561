import { useMutation } from 'react-query';
import { entityRemovePanelValuer } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

// called by the end client to remove the passed valuer (valuerId) from the entity's panel
const useEntityRemovePanelValuer = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (valuerId: number | string) => entityRemovePanelValuer(valuerId),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useEntityRemovePanelValuer;
