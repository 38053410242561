import {
    VStack,
    Flex,
    Text,
    Divider,
    Button,
    Heading,
    List,
    ListItem,
    Modal,
    ModalOverlay,
    ModalContent,
    Link,
    Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import toTitleCase from '../../../../common/functions/toTitleCase';
import { useMe } from '../../../../utils/api/hooks';
import ProfileQualificationsEdit from './ProfileQualificationsEdit';
import ProfileValuationsEdit from './ProfileValuationsEdit';
import { standardValuerMaxOperatingDistance } from '../../../../common/vars/valuationsAndMembershipTypes';

const ProfileCertificates = () => {
    const { t } = useTranslation(['profile', 'common', 'formik']);

    const [editMemberships, setEditMemberships] = useState(false);
    const [editValuations, setEditValuations] = useState(false);

    const {
        data: me,
        isLoading: meIsLoading,
        myCertificates = [],
        myCertificatesIsLoading,
    } = useMe();

    if (
        meIsLoading
        || myCertificatesIsLoading
    ) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Box
                bg="wvwGrey05"
                borderRadius="10"
                marginTop="1rem"
                padding="1.5rem 2rem"
                w="100%"
            >
                <Heading
                    as="h4"
                    color="wvwGrey"
                    size="md"
                >
                    {t('membershipsAndQualifications.sectionTitle')}
                </Heading>

                <VStack
                    paddingTop="1rem"
                >
                    <Flex
                        alignItems="flex-start"
                        minH="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('membershipsAndQualifications.qualifications')}
                        </Text>

                        <List spacing={2}>
                            {me?.standardQualifications?.length === 0 && (
                                <ListItem
                                    color="wvwGrey40"
                                >
                                    {t('none', { context: 'female', ns: 'common' })}
                                </ListItem>
                            )}

                            {me?.standardQualifications?.sort()?.map(i => {
                                const files = myCertificates
                                    .filter(
                                        certificate => (
                                            certificate.type.toLowerCase() === i.toLowerCase()
                                        ),
                                    );

                                return (
                                    <ListItem key={i}>
                                        <Flex w="100%">
                                            <Text w="10rem">
                                                {t(i, { ns: 'formik', defaultValue: i })}
                                            </Text>

                                            <List>
                                                {files.map(file => (
                                                    <ListItem key={file.file.fullPath}>
                                                        <Link
                                                            href={file.url}
                                                            isExternal
                                                            target="_blank"
                                                            color="wvwGreen"
                                                        >
                                                            {file.label}
                                                        </Link>
                                                    </ListItem>
                                                ))}

                                                {files.length === 0 && (
                                                    <ListItem
                                                        color="wvwGrey40"
                                                    >
                                                        {t('membershipsAndQualifications.noCertificate')}
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Flex>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Flex>

                    <Divider />

                    {me.otherQualifications && (
                        <Flex
                            alignItems="flex-start"
                            minH="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                w="17rem"
                            >
                                {t('membershipsAndQualifications.otherQualifications')}
                            </Text>

                            <List spacing={2}>
                                {me?.otherQualifications?.length === 0 && (
                                    <ListItem
                                        color="wvwGrey40"
                                    >
                                        {t('none', { context: 'female', ns: 'common' })}
                                    </ListItem>
                                )}

                                {me?.otherQualifications?.sort()?.map(i => {
                                    const files = myCertificates
                                        .filter(
                                            certificate => (
                                                certificate.type === i.toLowerCase()
                                            ),
                                        );

                                    return (
                                        <ListItem key={i}>
                                            <Flex w="100%">
                                                <Text w="10rem">
                                                    {i}
                                                </Text>

                                                <List>
                                                    {files.map(file => (
                                                        <ListItem key={file.file.fullPath}>
                                                            <Link
                                                                href={file.url}
                                                                isExternal
                                                                target="_blank"
                                                                color="wvwGreen"
                                                            >
                                                                {file.label}
                                                            </Link>
                                                        </ListItem>
                                                    ))}

                                                    {files.length === 0 && (
                                                        <ListItem
                                                            color="wvwGrey40"
                                                        >
                                                            {t('membershipsAndQualifications.noCertificate')}
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Flex>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Flex>
                    )}

                    <Flex
                        justifyContent="end"
                        w="100%"
                    >
                        <Button
                            variant="primary"
                            onClick={() => setEditMemberships(true)}
                            alignSelf="flex-end"
                        >
                            {t('button.edit', { ns: 'common' })}
                        </Button>
                    </Flex>
                </VStack>
            </Box>

            <Box
                bg="wvwGrey05"
                borderRadius="10"
                marginTop="1.5rem"
                padding="1.5rem 2rem"
                w="100%"
            >
                <Heading
                    as="h4"
                    color="wvwGrey"
                    size="md"
                >
                    {t('valuationCompetence.sectionTitle')}
                </Heading>

                <VStack
                    paddingTop="1rem"
                >
                    <Flex
                        alignItems="center"
                        h="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('profileDetails.valuerMaxOperationDistance')}
                        </Text>

                        {me.maxOperatingDistance !== null ? (
                            <Text>
                                {t(`${standardValuerMaxOperatingDistance.find(distance => distance.value === me?.maxOperatingDistance)?.label}`, { ns: 'formik', defaultValue: 'Not limit' })}
                            </Text>
                        ) : (
                            <Text
                                color="wvwGrey40"
                            >
                                {t('fieldNotProvided.valuerMaxOperationDistance', { ns: 'common' })}
                            </Text>
                        )}
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="flex-start"
                        minH="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('valuationCompetence.valuationStandards')}
                        </Text>

                        <List spacing={2}>
                            {me?.standardMemberships?.length === 0 && (
                                <ListItem
                                    color="wvwGrey40"
                                >
                                    {t('none', { context: 'female', ns: 'common' })}
                                </ListItem>
                            )}

                            {me?.standardMemberships?.sort()?.map(i => {
                                const files = myCertificates
                                    .filter(
                                        certificate => (
                                            certificate.type.toLowerCase() === i.toLowerCase()
                                        ),
                                    );

                                return (
                                    <ListItem key={i}>
                                        <Flex w="100%">
                                            <Text w="10rem">
                                                {t(i, { ns: 'formik', defaultValue: i })}
                                            </Text>

                                            <List>
                                                {files.map(file => (
                                                    <ListItem key={file.file.fullPath}>
                                                        <Link
                                                            href={file.url}
                                                            isExternal
                                                            target="_blank"
                                                            color="wvwGreen"
                                                        >
                                                            {file.label}
                                                        </Link>
                                                    </ListItem>
                                                ))}

                                                {files.length === 0 && (
                                                    <ListItem
                                                        color="wvwGrey40"
                                                    >
                                                        {t('membershipsAndQualifications.noCertificate')}
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Flex>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Flex>

                    <Divider />

                    {me.otherMemberships && (
                        <>
                            <Flex
                                alignItems="flex-start"
                                minH="2rem"
                                w="100%"
                            >
                                <Text
                                    color="wvwGreen"
                                    w="17rem"
                                >
                                    {t('valuationCompetence.otherValuationStandards')}
                                </Text>

                                <List spacing={2}>
                                    {me?.otherMemberships?.length === 0 && (
                                        <ListItem
                                            color="wvwGrey40"
                                        >
                                            {t('none', { context: 'female', ns: 'common' })}
                                        </ListItem>
                                    )}

                                    {me?.otherMemberships?.sort()?.map(i => {
                                        const files = myCertificates
                                            .filter(
                                                certificate => (
                                                    certificate.type === i.toLowerCase()
                                                ),
                                            );

                                        return (
                                            <ListItem key={i}>
                                                <Flex w="100%">
                                                    <Text w="10rem">
                                                        {i}
                                                    </Text>

                                                    <List>
                                                        {files.map(file => (
                                                            <ListItem key={file.file.fullPath}>
                                                                <Link
                                                                    href={file.url}
                                                                    isExternal
                                                                    target="_blank"
                                                                    color="wvwGreen"
                                                                >
                                                                    {file.label}
                                                                </Link>
                                                            </ListItem>
                                                        ))}

                                                        {files.length === 0 && (
                                                            <ListItem
                                                                color="wvwGrey40"
                                                            >
                                                                {t('membershipsAndQualifications.noCertificate')}
                                                            </ListItem>
                                                        )}
                                                    </List>
                                                </Flex>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Flex>

                            <Divider />
                        </>
                    )}

                    <Flex
                        alignItems="flex-start"
                        minH="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('valuationCompetence.valuationTypes')}
                        </Text>

                        <List>
                            {me?.valuationTypes?.length === 0 && (
                                <ListItem
                                    color="wvwGrey40"
                                >
                                    {t('none', { context: 'female', ns: 'common' })}
                                </ListItem>
                            )}

                            {me?.valuationTypes?.sort()?.map(valuation => (
                                <ListItem key={valuation}>
                                    {t(valuation, { ns: 'formik', defaultValue: valuation })}
                                </ListItem>
                            ))}
                        </List>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="flex-start"
                        minH="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('valuationCompetence.specialValuationTypes')}
                        </Text>

                        <List>
                            {me?.specialValuationTypes?.length === 0 && (
                                <ListItem color="wvwGrey40">
                                    {t('none', { context: 'female', ns: 'common' })}
                                </ListItem>
                            )}

                            {me?.specialValuationTypes?.sort()?.map(valuation => (
                                <ListItem key={valuation}>
                                    {t(valuation, { ns: 'formik', defaultValue: valuation })}
                                </ListItem>
                            ))}
                        </List>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="flex-start"
                        minH="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('valuationCompetence.otherValuationTypes')}
                        </Text>

                        <List>
                            {me?.otherValuationTypes?.length === 0 && (
                                <ListItem color="wvwGrey40">
                                    {t('none', { context: 'female', ns: 'common' })}
                                </ListItem>
                            )}

                            {me?.otherValuationTypes?.sort()?.map(valuation => (
                                <ListItem key={valuation}>
                                    {valuation}
                                </ListItem>
                            ))}
                        </List>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="flex-start"
                        minH="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('valuationCompetence.otherValuationOccasions')}
                        </Text>

                        <List>
                            {me?.valuationOccasions?.length === 0
                                && me?.otherValuationOccasions?.length === 0 && (
                                <ListItem color="wvwGrey40">
                                    {t('none', { context: 'female', ns: 'common' })}
                                </ListItem>
                            )}

                            {me?.valuationOccasions?.sort()?.map(valuation => (
                                <ListItem key={valuation}>
                                    {t(valuation, { ns: 'formik', defaultValue: valuation })}
                                </ListItem>
                            ))}

                            {me?.otherValuationOccasions?.sort()?.map(valuation => (
                                <ListItem key={valuation}>
                                    {valuation}
                                </ListItem>
                            ))}
                        </List>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="flex-start"
                        minH="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('valuationCompetence.propertyTypes')}
                        </Text>

                        <List>
                            {me?.valuationPropertyTypes?.length === 0 && (
                                <ListItem
                                    color="wvwGrey40"
                                >
                                    {t('none', { context: 'female', ns: 'common' })}
                                </ListItem>
                            )}

                            {me?.valuationPropertyTypes?.sort()?.map(valuation => (
                                <ListItem key={valuation}>
                                    {t(toTitleCase(valuation), { ns: 'formik', defaultValue: toTitleCase(valuation) })}
                                </ListItem>
                            ))}
                        </List>
                    </Flex>

                    <Divider />

                    <Button
                        variant="primary"
                        onClick={() => setEditValuations(true)}
                        alignSelf="flex-end"
                    >
                        {t('button.edit', { ns: 'common' })}
                    </Button>
                </VStack>
            </Box>

            <Modal
                isOpen={editMemberships || editValuations}
                onClose={() => {
                    setEditMemberships(false);
                    setEditValuations(false);
                }}
            >
                <ModalOverlay />

                <ModalContent
                    w="100%"
                    maxW="60rem"
                >
                    {editMemberships && (
                        <ProfileQualificationsEdit onClose={() => setEditMemberships(false)} />
                    )}

                    {editValuations && (
                        <ProfileValuationsEdit onClose={() => setEditValuations(false)} />
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default ProfileCertificates;
