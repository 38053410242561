import { useTranslation } from 'react-i18next';
import StatusBadge, { StatusBadgeColor, StatusBadgeTextColor } from './StatusBadge';

type PropTypes = {
    status: 'ACCEPTED' | 'PENDING' | 'REJECTED' | 'WITHDRAWN';
};

const colors: { [index: string]: StatusBadgeColor } = {
    ACCEPTED: 'green10',
    PENDING: 'wvwYellow10',
    REJECTED: 'red10',
    WITHDRAWN: 'purple10',
};

const textColors: { [index: string]: StatusBadgeTextColor } = {
    ACCEPTED: 'green',
    PENDING: 'wvwYellow',
    REJECTED: 'red',
    WITHDRAWN: 'purple',
};

const StatusBadgeOffer = (props: PropTypes) => {
    const { status } = props;

    const { t } = useTranslation('offer');

    return (
        <StatusBadge
            color={colors[status]}
            status={t(`status.${status}`, { defaultValue: status })}
            textColor={textColors[status]}
        />
    );
};

export default StatusBadgeOffer;
