const standardValuationOccasions = [
    'Acquisition/Sale',
    'Loan/Investment',
    'Divorce/Inheritance',
    'Enterprise Valuation',
    'Regulatory Reasons',
    'Annual Accounts',
    'General Information',
    'Court',
    'Residual Use For Life',
    'Auction',
    'Tax',
    'Other',
];

export default standardValuationOccasions;
