import { useMutation } from 'react-query';
import { documentRequestUpdateDocument } from '../../endpoints';
import { DocumentRequestUpdateDocumentDto } from '../../dto';
import useDocumentRequestGetDocument from './useDocumentRequestGetDocument';
import useToast from '../../../../common/hooks/useToast';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';

type ParamsType = {
    caseId: number;
    requestId?: number;
    documentId?: number;
    noSuccessToast?: boolean;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestUpdateDocument = (params: ParamsType) => {
    const {
        caseId,
        documentId = '',
        noSuccessToast = false,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast, createSuccessToast } = useToast();

    const { refresh: refreshDocument } = useDocumentRequestGetDocument(documentId);
    const { refreshAll } = useRefreshAllDMSDataStores(caseId);

    const { mutate: update } = useMutation(
        (dto: DocumentRequestUpdateDocumentDto) => documentRequestUpdateDocument(dto),
        {
            onSuccess: () => {
                refreshDocument();
                refreshAll();

                if (!noSuccessToast) {
                    createSuccessToast(t('toast.messages.documentUpdated'));
                }

                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));

                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestUpdateDocument;
