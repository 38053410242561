import { useQuery, useQueryClient } from 'react-query';
import { offerGetMyOfferByEnquiryId } from '../endpoints';

const OFFER_MY_OFFERS_BY_ENQUIRY_ID = 'OFFER_MY_OFFERS_BY_ENQUIRY_ID';

const useOfferGetMyOfferByEnquiryId = (enquiryId: number) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [OFFER_MY_OFFERS_BY_ENQUIRY_ID, enquiryId],
        () => offerGetMyOfferByEnquiryId(enquiryId),
    );

    const refresh = () => {
        queryClient.invalidateQueries(OFFER_MY_OFFERS_BY_ENQUIRY_ID);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useOfferGetMyOfferByEnquiryId;
