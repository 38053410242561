import {
    Flex,
    Box,
    Heading,
    VStack,
    Text,
    Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { useNotificationById, useNotificationMarkAsRead } from '../../utils/api/hooks';

const ProccessNotification = () => {
    const { t } = useTranslation(['account', 'common']);

    const { notificationId = '' } = useParams();

    const navigate = useNavigate();

    const [isError, setIsError] = useState(false);

    const {
        notification,
        error,
        isLoading,
    } = useNotificationById(notificationId);

    const {
        update,
    } = useNotificationMarkAsRead({
        onSuccess: () => {
            navigate(notification?.actionLink || '/dashboard');
        },
        onError: () => {
            setIsError(true);
        },
    });

    useEffect(() => {
        if (isLoading) return;

        update(notificationId);
    }, [isLoading]);

    return (
        <>
            {isLoading && (
                <Box
                    h={window.innerHeight}
                    w="100%"
                    bgGradient="linear(to-b, #016064cc, #016064)"
                >
                    <Flex alignItems="center">
                        <LoadingSpinner
                            color="wvwYellow"
                            noText
                            size="xl"
                            thickness="4px"
                        />
                    </Flex>
                </Box>
            )}

            {error && isError && (
                <VStack>
                    <Heading
                        size="sm"
                    >
                        {t('error.weAreSorry', { ns: 'common' })}
                    </Heading>

                    <Text>
                        {t('error.generic', { ns: 'common' })}
                    </Text>

                    <Button
                        variant="primaryYellow"
                        onClick={() => {
                            navigate('/dashboard');
                        }}
                    >
                        {t('button.goToDash', { ns: 'common' })}
                    </Button>
                </VStack>
            )}

        </>
    );
};

export default ProccessNotification;
