import {
    Button,
    Box,
    Circle,
    Flex,
    Heading,
    Img,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import FormikInput from '../forms/FormikInput';
import FormikForm from '../forms/FormikForm';
import FormikTextarea from '../forms/FormikTextarea';
import LoadingSpinner from './LoadingSpinner';
import { useEmailEmailPlatform, useMe } from '../../utils/api/hooks';

const { REACT_APP_GOOGLE_RECAPTCHA_KEY = '' } = process.env;

const validationSchema = Yup.object({
    firstName: Yup.string().required('errors.required'),
    lastName: Yup.string().required('errors.required'),
    email: Yup.string().required('errors.required'),
    phone: Yup.string().required('errors.required'),
    town: Yup.string().required('errors.required'),
    country: Yup.string().required('errors.required'),
    message: Yup.string().required('errors.required'),
});

const ContactForm = () => {
    const { t } = useTranslation(['common', 'formik']);

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const {
        data: user,
    } = useMe();

    const [submitting, setSubmitting] = useState(false);
    const [sent, setSent] = useState(false);

    const { send } = useEmailEmailPlatform({
        onSuccess: () => {
            setSent(true);
            setSubmitting(false);
        },
        onError: () => {
            setSubmitting(false);
        },
    });

    const inputField = (header: string, formikName: string, formikPlaceholder: string) => (
        <VStack
            w="100%"
        >
            <Text
                textAlign="left"
                w="100%"
                color="wvwGreen"
            >
                {t(`${header}`, { ns: 'profile', defaultValue: '' })}
            </Text>

            <FormikInput
                name={formikName}
                placeholder={t(`${formikPlaceholder}`, { ns: 'profile', defaultValue: '' })}
            />
        </VStack>
    );

    if (sent) {
        return (
            <VStack spacing="6" paddingTop="1rem">
                <Circle
                    size="sm"
                    alignSelf="top"
                    border="3rem solid"
                    borderColor="wvwYellow"
                    backgroundColor="wvwYellow80"
                >
                    <Circle
                        bg="white"
                        size="12rem"
                    >
                        <Img
                            src="/images/highfive.svg"
                            alt="highfive"
                            w="10rem"
                            paddingTop="3rem"
                        />
                    </Circle>
                </Circle>

                <Heading
                    as="h4"
                    size="lg"
                    color="wvwGreen"
                >
                    {t('sent!', { ns: 'common' })}
                </Heading>

                <Heading
                    as="h4"
                    size="sm"
                    color="wvwGreen"
                >
                    {t('We will get back', { ns: 'common' })}
                </Heading>
            </VStack>
        );
    }

    if (submitting) {
        return (
            <Flex alignItems="center">
                <LoadingSpinner
                    color="wvwYellow"
                    noText
                    size="xl"
                    thickness="4px"
                />
            </Flex>
        );
    }

    return (
        (!sent) && (
            <FormikForm
                initialValues={{
                    firstName: user?.firstName || '',
                    lastName: user?.lastName || '',
                    email: user?.email || '',
                    phone: user?.mobile || '',
                    town: user?.city || '',
                    country: user?.country || '',
                    message: '',
                }}
                onSubmit={async values => {
                    const recaptchaToken = await recaptchaRef.current?.executeAsync();

                    setSubmitting(true);

                    send({
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phone: values.phone,
                        town: values.town,
                        country: values.country,
                        message: values.message,
                        recaptchaToken: recaptchaToken || '',
                    });
                }}
                validationSchema={validationSchema}
            >
                <VStack
                    spacing="4"
                    w="100%"
                >
                    <Flex
                        gap="6"
                        w="100%"
                    >
                        {inputField('profileDetails.firstName', 'firstName', 'profileDetails.firstName')}
                        {inputField('profileDetails.lastName', 'lastName', 'profileDetails.lastName')}
                    </Flex>

                    {inputField('profileDetails.email', 'email', 'profileDetails.email')}
                    {inputField('profileDetails.mobileNumber', 'phone', 'profileDetails.phone')}
                    {inputField('profileDetails.town', 'town', 'profileDetails.town')}
                    {inputField('profileDetails.country', 'country', 'profileDetails.country')}

                    <VStack
                        w="100%"
                    >
                        <Text
                            textAlign="left"
                            w="100%"
                            color="wvwGreen"
                        >
                            {t('profileDetails.message', { ns: 'profile' })}
                        </Text>

                        <FormikTextarea
                            name="message"
                            placeholder={t('howCanWeHelp', { ns: 'account' })}
                        />
                    </VStack>

                    <Box
                        w="100%"
                        textAlign="right"
                    >
                        <Button
                            disabled={submitting}
                            type="submit"
                            variant="primaryYellow"
                            alignContent="right"
                        >
                            {t('button.submit', { ns: 'common' })}
                        </Button>

                        <ReCAPTCHA
                            badge="inline"
                            size="invisible"
                            ref={recaptchaRef}
                            sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
                        />
                    </Box>

                </VStack>
            </FormikForm>
        )
    );
};

export default ContactForm;
