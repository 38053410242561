import {
    IconButton,
    TableContainer,
    Table,
    Tbody,
    Td,
    Text,
    Thead,
    Th,
    Tr,
    Box,
} from '@chakra-ui/react';
import { ArrangeVertical, ArrowDown2, ArrowUp2 } from 'iconsax-react';
import {
    ReactNode,
    createContext,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

type ColumnType = {
    alignment?: string;
    title?: string;
    dataIndex?: string;
    maxWidth?: string;
    sorter?: (a: any, b: any) => any;
    render?: (data: any) => ReactNode;
};

type PropTypes = {
    columnColor?: string;
    columns: ColumnType[];
    data: unknown[];
    expandedContent?: ReactNode;
    noDataText?: string;
    tableWidth?: string;
    titleColor?: string;
};

type TableRowProps = {
    columns: ColumnType[];
    expandedContent?: ReactNode;
    item: any;
};

const cornerRadius = (index: number, length: number, expandedContent: boolean, leftOrRight: 'l' | 'r') => {
    if (index === length - 1 && leftOrRight === 'r') {
        return 10;
    }

    if (expandedContent) {
        return 0;
    }

    if (index !== 0) {
        return 0;
    }

    if (index === 0 && leftOrRight === 'r') {
        return 0;
    }

    return 10;
};

export const TableContext = createContext(undefined);

const TableRow = (props: TableRowProps) => {
    const { columns, expandedContent, item } = props;

    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <>
            <Tr bgColor="white">
                {expandedContent && (
                    <Td
                        borderBottomLeftRadius="10"
                        borderTopLeftRadius="10"
                    >
                        <IconButton
                            aria-label="Expand"
                            icon={expanded ? <ArrowUp2 size="18" /> : <ArrowDown2 size="18" />}
                            size="xs"
                            onClick={() => setExpanded(!expanded)}
                        />
                    </Td>
                )}

                {columns
                    .map((col, index) => (
                        <Td
                            borderBottomLeftRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'l')
                            }
                            borderBottomRightRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'r')
                            }
                            borderTopLeftRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'l')
                            }
                            borderTopRightRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'r')
                            }
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            maxWidth={col.maxWidth}
                            overflow="hidden"
                        >
                            <Box
                                justifyContent={col.alignment}
                                display="flex"
                            >
                                {col.render && col.render(item)}

                                {!col.render && item[col.dataIndex || '']}
                            </Box>
                        </Td>
                    ))}
            </Tr>

            {expanded && expandedContent && (
                <TableContext.Provider value={item}>
                    <Tr>
                        <Td colSpan={columns.length + 1}>
                            {expandedContent}
                        </Td>
                    </Tr>
                </TableContext.Provider>
            )}
        </>
    );
};

const DataTable = (props: PropTypes) => {
    const {
        columnColor,
        columns,
        data,
        expandedContent,
        noDataText,
        tableWidth,
        titleColor,
    } = props;

    const { t } = useTranslation(['common']);

    const [sortedData, setSortedData] = useState<any[]>(data);

    const [sort, setSort] = useState<{ column: number | undefined; direction: 'default' | 'asc' | 'desc' }>({
        column: undefined,
        direction: 'default',
    });

    useEffect(() => {
        const { column, direction } = sort;

        if (direction === 'default' || column === undefined) {
            setSortedData(data);

            return;
        }

        // sort ascending
        let newSortedData = [...data].sort((a, b) => columns[column]?.sorter?.(a, b));

        if (direction === 'desc') {
            newSortedData = newSortedData.reverse();
        }

        setSortedData([...newSortedData]);
    }, [data, sort]);

    const sortColumn = (index: number) => {
        let sortDirection = sort.direction;

        if (sort.column !== index) {
            setSort({
                column: index,
                direction: 'asc',
            });

            return;
        }

        switch (sortDirection) {
            case 'asc':
                sortDirection = 'desc';
                break;
            case 'desc':
                sortDirection = 'default';
                break;
            default:
                sortDirection = 'asc';
                break;
        }

        setSort({
            column: index,
            direction: sortDirection,
        });
    };

    return (
        <TableContainer
            maxWidth={tableWidth}
            w="100%"
            className="hide-scrollbar hide-scrollbar::-webkit-scrollbar"
        >
            <Table
                maxWidth="100%"
                size="sm"
            >
                <Thead
                    bgColor={columnColor}
                    h="4rem"
                >
                    <Tr>
                        {expandedContent && (
                            <Th
                                borderBottomLeftRadius="10"
                                borderTopLeftRadius="10"
                                w="2rem"
                            />
                        )}

                        {columns.map((col, index) => (
                            <Th
                                borderBottomLeftRadius={
                                    cornerRadius(index, columns.length, !!expandedContent, 'l')
                                }
                                borderBottomRightRadius={
                                    cornerRadius(index, columns.length, !!expandedContent, 'r')
                                }
                                borderTopLeftRadius={
                                    cornerRadius(index, columns.length, !!expandedContent, 'l')
                                }
                                borderTopRightRadius={
                                    cornerRadius(index, columns.length, !!expandedContent, 'r')
                                }
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                maxW={col.maxWidth}
                                textTransform="none"
                            >
                                <Box
                                    alignItems="center"
                                    justifyContent={col.alignment}
                                    display="flex"
                                >
                                    <Text
                                        _hover={{ cursor: 'pointer' }}
                                        as="b"
                                        color={titleColor}
                                        fontSize=".8rem"
                                        onClick={() => sortColumn(index)}
                                    >
                                        {col.title || ''}
                                    </Text>

                                    {col.sorter && (
                                        <IconButton
                                            aria-label="Sort"
                                            icon={<ArrangeVertical size="18" />}
                                            color="white"
                                            size="xs"
                                            variant="ghost"
                                            _hover={{ color: 'wvwGrey80' }}
                                            onClick={() => sortColumn(index)}
                                        />
                                    )}
                                </Box>
                            </Th>
                        ))}
                    </Tr>
                </Thead>

                <Tbody>
                    {sortedData?.map((i, index) => (
                        <TableRow
                            columns={columns}
                            expandedContent={expandedContent}
                            item={i}
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                        />
                    ))}
                </Tbody>
            </Table>

            {
                !sortedData.length && (
                    <Text
                        color="wvwGrey60"
                        paddingBlock=".6rem"
                        textAlign="center"
                        w="100%"
                    >
                        {noDataText || t('noData')}
                    </Text>
                )
            }
        </TableContainer>
    );
};

DataTable.defaultProps = {
    columnColor: 'wvwGreen',
    expandedContent: undefined,
    noDataText: undefined,
    tableWidth: '100%',
    titleColor: 'white',
};

TableRow.defaultProps = {
    expandedContent: undefined,
};

export default DataTable;
