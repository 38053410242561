import {
    RadioGroup,
    Spacer,
    Text,
    Stack,
    StackDirection,
    Box,
    Radio,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    direction?: StackDirection | undefined;
    maxWidth?: string;
    name: string,
    width?: string,
    align?: string,
    fontWeight?: string,
    onChange?: (value: string, field: FieldInputProps<any>) => void,
    options: {
        label: string;
        description?: string;
        value: string;
    }[];
};

const FormikRadioGroup = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        options,
        maxWidth,
        width,
        align,
        fontWeight,
        direction,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <div>
            <RadioGroup
                variant="filled"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onChange={value => {
                    field.onChange({ target: { name, value } });

                    onChange?.(value, field);
                }}
                value={field.value}
            >
                <Stack
                    maxWidth={maxWidth}
                    w={width}
                    direction={direction}
                    fontWeight={fontWeight}
                >
                    { options.map(({ label, description, value }) => (
                        <Radio
                            key={value}
                            value={value}
                            fontWeight={value}
                        >
                            <Box
                                alignSelf={align}
                                padding=".3rem"
                            >
                                <Text
                                    fontWeight={value}
                                >
                                    {label}
                                </Text>

                                { description && (
                                    <Text
                                        width="14rem"
                                        fontWeight="normal"
                                    >
                                        {description}
                                    </Text>
                                )}
                            </Box>

                            <Spacer />
                        </Radio>
                    ))}
                </Stack>
            </RadioGroup>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </div>
    );
};

FormikRadioGroup.defaultProps = {
    align: '',
    direction: '',
    width: '100%',
    fontWeight: 'bold',
    maxWidth: undefined,
    onChange: () => {},
};

export default FormikRadioGroup;
