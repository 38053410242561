import { useQuery } from 'react-query';
import { notificationGetById } from '../endpoints';

const NOTIFICATION_LIST = 'NOTIFICATION_LIST';

const useNotificationList = (notificationId: string) => {
    const {
        data: notification,
        error,
        isLoading,
    } = useQuery([NOTIFICATION_LIST, notificationId], () => notificationGetById(notificationId));

    return {
        notification,
        error,
        isLoading,
    };
};

export default useNotificationList;
