import { Outlet } from 'react-router-dom';
import MainLayout from '../../common/components/display/MainLayout';

const Admin = () => (
    <MainLayout>
        <Outlet />
    </MainLayout>
);

export default Admin;
