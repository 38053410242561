import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string;
    min?: number;
    max?: number;
    w?: string;
    maxW?: string;
};

const FormikNumberInput = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        min,
        max,
        maxW,
        w,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <div
            style={{
                alignSelf: 'top',
                maxWidth: maxW,
                width: w,
            }}
        >
            <NumberInput
                borderRadius="8"
                bg="white"
                isInvalid={!!meta.touched && !!meta.error}
                min={min}
                max={max}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onChange={(_sValue, value) => {
                    field.onChange({ target: { name, value } });
                }}
            >
                <NumberInputField h="3rem" />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </div>
    );
};

FormikNumberInput.defaultProps = {
    min: undefined,
    max: undefined,
    w: '100%',
    maxW: '100%',
};

export default FormikNumberInput;
