/* eslint-disable react/jsx-props-no-spreading */
import {
    SimpleGrid,
    Text,
    useRadioGroup,
    Box,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import RadioCard from '../components/RadioCard';

type PropTypes = {
    name: string,
    width?: string,
    options: {
        label: string,
        value: string,
    }[],
};

const FormikRadioCard = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        options,
        width,
    } = props;

    const [field, meta] = useField(props);

    const { getRootProps, getRadioProps } = useRadioGroup({
        name,
    });

    const group = getRootProps();

    return (
        <Box w="100%">
            <SimpleGrid
                {...group}
                {...field}
                {...props}
                onChange={() => {}}
                minChildWidth="13rem"
                w="100%"
            >
                { options.map(({ value, label }) => {
                    const radio = getRadioProps({ value });
                    return (
                        <RadioCard
                            value={value}
                            key={value}
                            {...radio}
                            width={width}
                        >
                            {label}
                        </RadioCard>
                    );
                })}
            </SimpleGrid>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </Box>
    );
};

FormikRadioCard.defaultProps = {
    width: '',
};

export default FormikRadioCard;
