import { HStack, Icon } from '@chakra-ui/react';
import { Calendar } from 'iconsax-react';
import { WvwText } from '../typography';
import { displayDateWithCurrentLocale } from '../../functions/displayDateInLocale';

type PropTypes = {
    color?: 'black' | 'wvwGreen' | 'wvwGrey60' | 'white'; // The color of the icon and text
    date: Date; // The date of the event
};

const EventDate = (props: PropTypes) => {
    const { color, date } = props;

    return (
        <HStack spacing="2">
            <Icon
                as={Calendar}
                color={color}
                boxSize="6"
            />

            <WvwText color={color}>
                {displayDateWithCurrentLocale(date)}
            </WvwText>
        </HStack>
    );
};

EventDate.defaultProps = {
    color: 'white',
};

export default EventDate;
