import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { AdminEntitiesDto } from '../../dto';
import { adminEntities } from '../../endpoints';
import useToast from '../../../../common/hooks/useToast';

const ADMIN_ENTITIES = 'ADMIN_ENTITIES';

const useAdminEntities = (dto: AdminEntitiesDto) => {
    const queryClient = useQueryClient();

    const { t } = useTranslation('common');

    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [ADMIN_ENTITIES, JSON.stringify(dto)],
        () => adminEntities(dto),
        {
            onError: (err: Error) => {
                createErrorToast(t(err.message, { defaultValue: err.message }));
            },
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(ADMIN_ENTITIES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useAdminEntities;
