import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Heading,
    Spacer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DocumentRequestType } from '../../../../../../../types';
import { useRole } from '../../../../../../../common/hooks';
import DownloadAllFiles from './DownloadAllFiles';
import UploadDocumentRequestDocuments from '../../UploadDocumentRequestDocuments';
import { useDocumentRequestGetCaseDocuments, useDocumentRequestUpdateDocumentsStatus } from '../../../../../../../utils/api/hooks';
import RequestDocumentsList from './RequestDocumentsList';
import LoadingSpinner from '../../../../../../../common/components/LoadingSpinner';

type PropTypes = {
    request: DocumentRequestType
};

const RequestDocuments = (props: PropTypes) => {
    const { request } = props;

    const {
        caseId,
        id: requestId,
    } = request;

    const { t } = useTranslation('common');
    const { userIsClient, userIsValuer } = useRole();

    const {
        data: documents,
        isLoading,
    } = useDocumentRequestGetCaseDocuments({
        caseId,
        requestId,
        includeDeleted: true,
    });

    const { update: updateDocStatuses } = useDocumentRequestUpdateDocumentsStatus({
        caseId,
        requestId,
    });

    if (isLoading) return <LoadingSpinner />;

    return (
        <>
            <Flex align="center">
                <Heading
                    as="h3"
                    fontWeight="normal"
                    size="md"
                >
                    {t('documents')}
                </Heading>

                <Spacer />

                <DownloadAllFiles
                    downloadUrls={documents?.map(doc => doc.url!)}
                />
            </Flex>

            {userIsClient && request.status === 'PENDING' && (
                <UploadDocumentRequestDocuments
                    requestId={requestId}
                />
            )}

            <Box
                justifySelf="center"
                maxH="21rem"
                overflowY="scroll"
            >
                <RequestDocumentsList
                    documentList={documents}
                    request={request}
                />
            </Box>

            { userIsValuer && (
                <ButtonGroup
                    alignSelf="flex-end"
                    pt=".5rem"
                >
                    <Button
                        variant="danger"
                        w="fit-content"
                        onClick={() => updateDocStatuses({
                            requestId,
                            documents:
                                documents.map(document => ({
                                    id: document.id,
                                    status: 'REJECTED',
                                })),
                        })}
                    >
                        {t('button.rejectAll')}
                    </Button>

                    <Button
                        variant="primaryGreen"
                        w="fit-content"
                        onClick={() => updateDocStatuses({
                            requestId,
                            documents:
                                documents.map(document => ({
                                    id: document.id,
                                    status: 'ACCEPTED',
                                })),
                        })}
                    >
                        {t('button.approveAll')}
                    </Button>
                </ButtonGroup>
            )}
        </>
    );
};

export default RequestDocuments;
