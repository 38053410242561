import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Avatar,
    AvatarBadge,
    Box,
    Link as ChakraLink,
    Flex,
    HStack,
    Spacer,
    useDisclosure,
} from '@chakra-ui/react';
import {
    ArchiveBox,
    ClipboardText,
    Headphone,
    Home2,
    HomeTrendUp,
    Logout,
    Moon,
    Notification,
    Profile,
    ProfileAdd,
    SaveAdd,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { useMe, useNotificationGetUnread } from '../../utils/api/hooks';
import ProfilePictureCompany from './display/ProfilePictureCompany';
import DropdownMenu from './DropdownMenu';
import LanguageToggleButton from './LanguageToggleButton';
import Logo from './Logo';
import NotificationPanel from './NotificationPanel';
import { useRole } from '../hooks';
import useSupportModal from '../../utils/api/hooks/useSupportModal';

const MenuBar = () => {
    const { onOpen, isOpen, onClose } = useDisclosure();

    const { userIsApproved, userIsSystemAdmin } = useRole();

    const [notifications, setNotifications] = useState(false);

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const {
        notificationUnreadList,
    } = useNotificationGetUnread(5);

    const { t } = useTranslation('menubar');

    const path = useLocation().pathname;

    const [active, setActive] = useState('Enquiries');

    const { modal: supportModal, openModal } = useSupportModal();

    const userAccessDeterminedMenuItemSettings = {
        isDisabled: !userIsApproved,
        tooltip: userIsApproved ? '' : t('platformAccessRequired', { ns: 'common' }),
    };

    const userMenu = [
        {
            menuTitle: userLoading ? '' : `${user?.firstName} ${user?.lastName}`,
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t('Profile'),
                    key: 'profile',
                    nav: '/dashboard/profile',
                    icon: <Profile />,
                },
                {
                    name: t('Account'),
                    key: 'account',
                    nav: '/dashboard/account',
                    icon: <ProfileAdd />,
                },
                {
                    name: t('Logout'),
                    key: 'logout',
                    nav: '/logout',
                    icon: <Logout />,
                },
            ],
        },
    ];

    const systemMenu = [
        {
            menuTitle: t('section.enquiries', { ns: 'dashboard' }),
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t('section.enquiries', { ns: 'dashboard' }),
                    key: 'enquiries',
                    nav: '/dashboard/enquiries',
                    icon: <SaveAdd />,
                    ...userAccessDeterminedMenuItemSettings,
                },
                {
                    name: t('section.dormantEnquiries', { ns: 'dashboard' }),
                    key: 'dormant-enquiries',
                    nav: '/dashboard/dormant-enquiries',
                    icon: <Moon />,
                    ...userAccessDeterminedMenuItemSettings,
                },
                {
                    name: t('section.draftEnquiries', { ns: 'dashboard' }),
                    key: 'drafts',
                    nav: '/dashboard/drafts',
                    icon: <ArchiveBox />,
                    ...userAccessDeterminedMenuItemSettings,
                },
            ],
        },
        {
            menuTitle: t('cases', { ns: 'dashboard' }),
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t('Active Cases', { ns: 'activecases' }),
                    key: 'active-cases',
                    nav: '/dashboard/active-cases',
                    icon: <HomeTrendUp />,
                    ...userAccessDeterminedMenuItemSettings,
                },
                {
                    name: t('section.dormantCases', { ns: 'dashboard' }),
                    key: 'dormant-cases',
                    nav: '/dashboard/dormant-cases',
                    icon: <Home2 />,
                    ...userAccessDeterminedMenuItemSettings,
                },
            ],
        },
        {
            menuTitle: t('section.system', { ns: 'dashboard' }),
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t('section.messages', { ns: 'dashboard' }),
                    key: 'notifications',
                    nav: '/dashboard/notifications',
                    icon: <ClipboardText />,
                },
                {
                    name: t('section.support', { ns: 'dashboard' }),
                    key: 'support',
                    onClick: () => openModal(),
                    icon: <Headphone />,
                },
            ],
        },
    ];

    const adminMenu = {
        menuTitle: t('admin', { ns: 'common' }),
        rightIcon: <ChevronDownIcon />,
        menuList: [
            {
                name: t('cases', { ns: 'dashboard' }),
                key: 'cases',
                nav: '/admin/cases',
                icon: <Profile />,
            },
            {
                name: t('enquiries', { ns: 'enquiries' }),
                key: 'enquiries',
                nav: '/admin/enquiries',
                icon: <Profile />,
            },
            {
                name: t('entities', { ns: 'menubar' }),
                key: 'entities',
                nav: '/admin/entities',
                icon: <Profile />,
            },
            {
                name: t('offers', { ns: 'offer' }),
                key: 'offers',
                nav: '/admin/offers',
                icon: <Profile />,
            },
            {
                name: t('users', { ns: 'menubar' }),
                key: 'users',
                nav: '/admin/users',
                icon: <Profile />,
            },
        ],
    };

    useEffect(() => {
        if (userLoading) return;

        switch (path) {
            case '/dashboard':
            case '/dashboard/dormant-enquiries':
            case '/dashboard/drafts':
            case '/dashboard/enquiries':
                setActive(t('Enquiries', { ns: 'menubar' }));
                break;
            case '/dashboard/dormant-cases':
            case '/dashboard/active-cases':
                setActive(t('Cases', { ns: 'menubar' }));
                break;
            case '/dashboard/notifications':
            case '/contact-us':
                setActive(t('System', { ns: 'menubar' }));
                break;
            case '/dashboard/profile':
            case '/dashboard/account':
                setActive(`${user.firstName} ${user.lastName}`);
                break;
            case '/admin':
            case '/admin/cases':
            case '/admin/enquiries':
            case '/admin/entities':
            case '/admin/offers':
            case '/admin/users':
                setActive(t('admin', { ns: 'common' }));
                break;
            default:
                break;
        }
    }, [path, userLoading]);

    return (
        <>
            <Flex
                bg="white"
                w="100%"
                boxShadow="0 0 8px lightwvwGrey"
                align="center"
            >
                <ChakraLink
                    paddingBlock=".8rem"
                    paddingInline="3.5rem"
                    as={ReactLink}
                    to="/dashboard"
                >
                    <Logo withColor />
                </ChakraLink>

                <HStack>
                    {systemMenu.map(item => (
                        <DropdownMenu
                            key={item.menuTitle}
                            menu={item}
                            active={active}
                        />
                    ))}

                    {userIsSystemAdmin && (
                        <DropdownMenu
                            menu={adminMenu}
                            active={active}
                        />
                    )}
                </HStack>

                <Spacer />

                <HStack
                    spacing="0"
                    paddingInline="2rem"
                >
                    <Box paddingInline=".8rem">
                        <LanguageToggleButton />
                    </Box>

                    <Box
                        paddingRight="1rem"
                    >
                        <Box
                            _hover={{
                                bg: 'wvwGrey10',
                            }}
                            borderRadius="10"
                            paddingInline=".3rem"
                            paddingBlock=".3rem"
                        >
                            <Avatar
                                color="wvwGrey80"
                                bg="none"
                                icon={<Notification />}
                                aria-label="notification"
                                as="button"
                                size="xs"
                                onClick={() => {
                                    setNotifications(true);
                                    onOpen();
                                }}
                            >
                                {Number(notificationUnreadList?.length) > 0 && (
                                    <AvatarBadge
                                        boxSize=".65rem"
                                        marginBottom=".9rem"
                                        marginRight=".15rem"
                                        bg="wvwYellow"
                                        color="wvwYellow"
                                        borderColor="none"
                                    />
                                )}
                            </Avatar>
                        </Box>
                    </Box>

                    {userMenu.map(item => (
                        <Box
                            pr="1rem"
                            key={item.menuTitle}
                        >
                            <DropdownMenu
                                menu={item}
                                active={active}
                            />
                        </Box>
                    ))}

                    <ChakraLink
                        as={ReactLink}
                        to="/dashboard/account"
                    >
                        <ProfilePictureCompany size="2.5rem" />
                    </ChakraLink>
                </HStack>
            </Flex>

            {notifications && (
                <NotificationPanel
                    isOpen={isOpen}
                    onClose={onClose}
                />
            )}

            {supportModal}
        </>
    );
};

export default MenuBar;
