import {
    Container,
    Flex,
    Spacer,
    Button,
    Heading,
    VStack,
    HStack,
    Divider,
    SimpleGrid,
    createStandaloneToast,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HiOutlineUserCircle } from 'react-icons/hi';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    FormikCheckbox,
    FormikCountrySelect,
    FormikForm,
    FormikInput,
    FormikPassword,
} from '../../../common/forms';
import { useAuthSignupInvited, useFbCreateUserWithEmailAndPassword, useSignup } from '../../../utils/api/hooks';
import { RegisterDataType } from '../../../types';
import { FlowContext } from '../../../common/components/display/ProcessFlowStateful';
import RegisterFormsContainer from '../common/RegisterFormsContainer';
import TsAndCsText from '../../../common/components/TsAndCsText';
import { AuthSignupInvitedDto, SignupDto } from '../../../utils/api/dto';
import SiteLoading from '../../../common/components/SiteLoading';

type PropTypes = {
    onContinue: (values: { [index: string]: string }) => void;
    registerData: RegisterDataType;
};

type FormDataType = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    dialCode: string;
    mobile: string;
    termsAndConditions: boolean;
};

const RegisterPersonalForm = (props: PropTypes) => {
    const {
        onContinue,
        registerData,
    } = props;

    const { token = '', inviteType = '' } = useParams();

    const { goNext } = useContext(FlowContext);

    const { t } = useTranslation(['profile', 'common', 'account']);

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .required(t('required', { ns: 'account' })),
        lastName: Yup.string()
            .required(t('required', { ns: 'account' })),
        email: Yup.string()
            .email(t('invalidEmail', { ns: 'account' }))
            .required(t('required', { ns: 'account' })),
        password: Yup.string()
            .min(8, t('tooShort', { ns: 'account' }))
            .minLowercase(1, t('minLowercase', { ns: 'account' }))
            .minUppercase(1, t('minUppercase', { ns: 'account' }))
            .minNumbers(1, t('minNumbers', { ns: 'account' }))
            .minSymbols(1, t('minSymbols', { ns: 'account' }))
            .required(t('required', { ns: 'account' })),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], t('passwordMustMatch', { ns: 'account' }))
            .required(t('required', { ns: 'account' })),
        dialCode: Yup.string()
            .required(t('required', { ns: 'account' })),
        mobile: Yup.number()
            .required('errors.required').typeError('errors.enterNumber'),
        termsAndConditions: Yup.boolean().required(t('required', { ns: 'account' })).oneOf([true], t('required', { ns: 'account' })),
    });

    const { toast } = createStandaloneToast();

    const [formData, setFormData] = useState<FormDataType>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        dialCode: '',
        mobile: '',
        termsAndConditions: false,
    });

    const [loading, setLoading] = useState(false);

    const {
        update: signup,
    } = useSignup({
        onSuccess: () => {
            onContinue({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                mobileDialCode: formData.dialCode,
                mobile: formData.mobile,
            });
            goNext();
        },
        onError: error => {
            setLoading(false);

            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const { update: signUpInvited } = useAuthSignupInvited({
        onSuccess: () => goNext(),
        onError: error => {
            setLoading(false);

            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const {
        update: createUserWithEmailAndPassword,
    } = useFbCreateUserWithEmailAndPassword({
        onSuccess: () => {
            let dto = {} as SignupDto | AuthSignupInvitedDto;

            if (inviteType === 'invitedToTeam' && token) {
                dto = {
                    email: formData.email,
                    token,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    mobileDialCode: formData.dialCode,
                    mobile: formData.mobile,
                    orgPosition: '',
                };

                signUpInvited(dto);
            } else {
                if ((inviteType === 'invitedToClientPanel' || 'invitedToValuerPanel') && token) {
                    dto = {
                        accountType: registerData.accountType === 'CLIENT' ? 'CLIENT' : 'VALUER',
                        companyOrIndividual: registerData.companyOrIndividual === 'COMPANY' ? 'COMPANY' : 'INDIVIDUAL',
                        billingCity: '',
                        billingCountry: '',
                        billingPostalCode: '',
                        billingStreet: '',
                        billingTier: 'FREE',
                        city: '',
                        country: '',
                        email: formData.email,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        mobileDialCode: formData.dialCode,
                        mobile: formData.mobile,
                        mollieCustomerId: '',
                        orgName: '',
                        orgPosition: '',
                        postalCode: '',
                        street: '',
                        token,
                        invitationType: inviteType === 'invitedToValuerPanel' ? 'INVITEDTOVALUERPANEL' : 'INVITEDTOCLIENTPANEL',
                    };
                } else {
                    dto = {
                        accountType: registerData.accountType === 'CLIENT' ? 'CLIENT' : 'VALUER',
                        companyOrIndividual: registerData.companyOrIndividual === 'COMPANY' ? 'COMPANY' : 'INDIVIDUAL',
                        billingCity: '',
                        billingCountry: '',
                        billingPostalCode: '',
                        billingStreet: '',
                        billingTier: 'FREE',
                        city: '',
                        country: '',
                        email: formData.email,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        mobileDialCode: formData.dialCode,
                        mobile: formData.mobile,
                        mollieCustomerId: '',
                        orgName: '',
                        orgPosition: '',
                        postalCode: '',
                        street: '',
                    };
                }

                console.log(dto);
                signup(dto);
            }
        },
        onError: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        if (!formData.firstName) return;

        createUserWithEmailAndPassword({
            email: formData.email,
            password: formData.password,
        });
    }, [formData, inviteType, token]);

    if (loading) {
        return <SiteLoading />;
    }

    return (
        <RegisterFormsContainer>
            <Container
                minW="container.md"
            >
                <VStack
                    spacing="4"
                    w="100%"
                    align="left"
                >
                    <HStack>
                        <HiOutlineUserCircle size="2em" color="#2e536c" />

                        <Heading
                            color="darkWvwGreen"
                            as="h4"
                            size="md"
                            width="100%"
                        >
                            {t('profileDetails.wizardTitle')}
                        </Heading>
                    </HStack>

                    <FormikForm
                        initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={values => {
                            setLoading(true);

                            setFormData({
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email,
                                password: values.password,
                                confirmPassword: values.confirmPassword,
                                dialCode: values.dialCode,
                                mobile: values.mobile,
                                termsAndConditions: values.termsAndConditions,
                            });
                        }}
                    >
                        <VStack
                            align="left"
                            spacing="6"
                        >
                            <FormikInput
                                name="firstName"
                                placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                                type="text"
                            />

                            <FormikInput
                                name="lastName"
                                placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                                type="text"
                            />

                            <FormikInput
                                name="email"
                                placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                                type="email"
                            />

                            <FormikPassword
                                name="password"
                                placeholder={t('fieldPlaceholder.password', { ns: 'common' })}
                            />

                            <FormikPassword
                                name="confirmPassword"
                                placeholder={t('fieldPlaceholder.confirmPassword', { ns: 'common' })}
                            />

                            <SimpleGrid
                                columns={2}
                                spacing={2}
                            >
                                <FormikCountrySelect
                                    name="dialCode"
                                    selectDialCode
                                />

                                <FormikInput
                                    name="mobile"
                                    placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                />
                            </SimpleGrid>

                            <FormikCheckbox
                                name="termsAndConditions"
                                label={<TsAndCsText register />}
                            />

                            <Divider />

                            <Flex
                                minWidth="max-content"
                                alignItems="left"
                                gap="2"
                            >
                                <Spacer />

                                <Button
                                    type="submit"
                                    variant="primary"
                                >
                                    {t('button.next', { ns: 'common' })}
                                </Button>
                            </Flex>
                        </VStack>
                    </FormikForm>
                </VStack>
            </Container>
        </RegisterFormsContainer>
    );
};

export default RegisterPersonalForm;
