import {
    Circle,
    Image,
    Spinner,
} from '@chakra-ui/react';
import { useMyEntity } from '../../../utils/api/hooks';
import { useRole } from '../../hooks';
import ProfilePictureDefault from './ProfilePictureDefault';

type PropTypes = {
    size: string; // The size of the profile picture
};

const ProfilePictureCompany = (props: PropTypes) => {
    const { size } = props;

    const { userIsValuer } = useRole();

    const {
        entityProfilePictureUrl,
        entityProfilePictureUrlIsLoading,
        entityProfilePictureUrlError,
    } = useMyEntity();

    if (entityProfilePictureUrlIsLoading) {
        return (
            <Circle size={size}>
                <Spinner />
            </Circle>
        );
    }

    return (
        <Circle size={size}>
            <>
                {(entityProfilePictureUrlError || !entityProfilePictureUrl) && (
                    <ProfilePictureDefault
                        accountType={userIsValuer ? 'VALUER' : 'CLIENT'}
                        userType="ENTITY"
                        size={size}
                    />
                )}

                {!entityProfilePictureUrlIsLoading && entityProfilePictureUrl && (
                    <Image
                        src={entityProfilePictureUrl}
                        alt="Profile Picture"
                        borderRadius="50%"
                        objectFit="cover"
                        w="100%"
                        h="100%"
                    />
                )}
            </>
        </Circle>
    );
};

export default ProfilePictureCompany;
