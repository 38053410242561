import {
    Box,
    Checkbox,
    CheckboxGroup,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string,
    onChange?: (value: string[], field?: FieldInputProps<any>) => void,
    options: {
        label: ReactNode | string;
        value: string,
    }[],
};

const FormikCheckboxList = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        options,
        name,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <Box w="100%">
            <CheckboxGroup
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onChange={(value: string[]) => {
                    field.onChange({ target: { name, value } });

                    onChange?.(value, field);
                }}
            >
                <VStack
                    alignItems="flex-start"
                    spacing="0"
                >
                    {options.map(({ value, label }) => (
                        <Checkbox
                            key={value}
                            value={value}
                            paddingBlock=".3rem"
                        >
                            {label}
                        </Checkbox>
                    ))}
                </VStack>
            </CheckboxGroup>

            {meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )}
        </Box>
    );
};

FormikCheckboxList.defaultProps = {
    onChange: () => { },
};

export default FormikCheckboxList;
