import { useMutation } from 'react-query';
import { DocumentRequestCreateTemplateDto } from '../../dto';
import { documentRequestCreateTemplate } from '../../endpoints';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

type ParamsType = {
    caseId: string | number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestCreateTemplate = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast, createSuccessToast } = useToast();
    const { refreshActionArea } = useRefreshAllDMSDataStores(Number(caseId));

    const { mutate: update } = useMutation(
        (dto: DocumentRequestCreateTemplateDto) => documentRequestCreateTemplate(dto),
        {
            onSuccess: () => {
                refreshActionArea();
                onSuccess?.();
                createSuccessToast(t('toast.messages.templateCreated'));
            },
            onError: (error: Error) => {
                onError?.(error);
                createErrorToast(t(error.message, { defaultValue: error.message }));
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestCreateTemplate;
