import useDocumentRequestGetCaseDocuments from './useDocumentRequestGetCaseDocuments';
import useDocumentRequestGetFolders from './useDocumentRequestGetFolders';
import useDocumentRequestGetRequests from './useDocumentRequestGetRequests';
import useDocumentRequestGetTemplates from './useDocumentRequestGetTemplates';
import useDocumentRequestGetUserFileList from './useDocumentRequestGetUserFileList';
import useDocumentRequestGetUserRequestList from './useDocumentRequestGetUserRequestList';

const useRefreshAllDMSDataStores = (caseId: number) => {
    // All the users action area data stores
    const { refresh: refreshUserRequestList } = useDocumentRequestGetUserRequestList(caseId);
    const { refresh: refreshUserDocumentsList } = useDocumentRequestGetUserFileList(caseId);
    const { refresh: refreshTemplateList } = useDocumentRequestGetTemplates();

    // The document management data stores
    const { refresh: refreshFolders } = useDocumentRequestGetFolders({ caseId: Number(caseId) });
    const { refresh: refreshRequests } = useDocumentRequestGetRequests({ caseId });
    const { refresh: refreshDocuments } = useDocumentRequestGetCaseDocuments({ caseId });

    const refreshActionArea = () => {
        // refresh the user's action area request list
        refreshUserRequestList();
        // refresh the user's action area document list
        refreshUserDocumentsList();
        // refresh the user's action area template list
        refreshTemplateList();
    };

    const refreshDocumentManagementArea = () => {
        // refresh the document management area folders
        refreshFolders();
        // refresh the document management area requests
        refreshRequests();
        // refresh the document management area documents
        refreshDocuments();
    };

    const refreshAll = () => {
        refreshActionArea();

        refreshDocumentManagementArea();
    };

    return {
        refreshAll,
        refreshActionArea,
        refreshDocumentManagementArea,
    };
};

export default useRefreshAllDMSDataStores;
