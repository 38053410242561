import {
    Box,
    Container,
    VStack,
    HStack,
    Heading,
    Circle,
    Img,
} from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';

type PropTypes = {
    children: ReactNode | ReactNode[];
    logo?: ReactElement;
};

const CongratulationsWindow = (props: PropTypes) => {
    const { children, logo } = props;

    const { t } = useTranslation(['account', 'common']);

    return (
        <>
            <Container
                marginBlock="3"
                maxWidth="50rem"
                w="100%"
            >
                <VStack
                    spacing="2"
                    w="100%"
                    align="left"
                >
                    {logo}

                    {!logo && (
                        <HStack spacing="2">
                            <Logo />

                            <Heading
                                as="h4"
                                size="md"
                                textAlign="left"
                                color="white"
                            >
                                {t('whoValuesWhat', { ns: 'common' })}
                            </Heading>
                        </HStack>
                    )}

                    <Box
                        alignSelf="center"
                        background="wvwYellow"
                        borderColor="wvwYellow"
                        w="100%"
                        borderWidth="1px"
                        borderRadius="lg"
                        p="2em"
                    >
                        <VStack spacing="6" paddingTop="1rem">
                            <Circle
                                size="sm"
                                alignSelf="top"
                                border="3rem solid #FFDA48"
                                backgroundColor="#FFE478"
                            >
                                <Circle
                                    bg="white"
                                    size="12rem"
                                >
                                    <Img
                                        src="/images/highfive.svg"
                                        alt="highfive"
                                        w="10rem"
                                        paddingTop="3rem"
                                    />
                                </Circle>
                            </Circle>

                            <Heading
                                as="h4"
                                size="xl"
                                color="white"
                            >
                                {t('congratulations', { ns: 'common' })}
                            </Heading>

                            {children}
                        </VStack>
                    </Box>
                </VStack>
            </Container>
            {/* <Footer /> */}
        </>
    );
};

CongratulationsWindow.defaultProps = {
    logo: undefined,
};

export default CongratulationsWindow;
