import {
    Box,
    Container,
    Flex,
    Heading,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';
import WaterMarkBox from './WaterMarkBox';
import LoadingSpinner from './LoadingSpinner';

type PropTypes = {
    noLogo?: boolean;
    text?: string;
};

const SiteLoading = (props: PropTypes) => {
    const { noLogo = false, text } = props;

    const { t } = useTranslation();

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container maxW="container.lg">
                    <Box
                        bg="white"
                        borderRadius="lg"
                        borderWidth="1px"
                        marginTop="2rem"
                        p="2em"
                        w="100%"
                    >
                        <VStack
                            pb="9em"
                            pt="6em"
                            spacing="8"
                            w="100%"
                        >
                            { !noLogo && (
                                <Logo withColor />
                            ) }

                            <Heading
                                as="h1"
                                color="wvwGreen"
                                size="xl"
                            >
                                {text || t('Welcome to WVW')}
                            </Heading>

                            <Flex alignItems="center">
                                <LoadingSpinner
                                    color="wvwYellow"
                                    noText
                                    size="xl"
                                    thickness="4px"
                                />
                            </Flex>
                        </VStack>
                    </Box>
                </Container>
            </WaterMarkBox>
        </Box>
    );
};

SiteLoading.defaultProps = {
    noLogo: false,
    text: undefined,
};

export default SiteLoading;
