import { useMutation } from 'react-query';
import { documentRequestUpdateTemplate } from '../../endpoints';
import { DocumentRequestUpdateTemplateDto } from '../../dto';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestUpdateTemplate = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast, createSuccessToast } = useToast();
    const { refreshActionArea } = useRefreshAllDMSDataStores(Number(caseId));

    const { mutate: update } = useMutation(
        (dto: DocumentRequestUpdateTemplateDto) => documentRequestUpdateTemplate(dto),
        {
            onSuccess: () => {
                refreshActionArea();
                createSuccessToast(t('toast.messages.templateUpdated'));
                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestUpdateTemplate;
