import { useTranslation } from 'react-i18next';
import { StatusBadge } from '../../../../../common/components/display';
import { StatusBadgeColor, StatusBadgeTextColor } from '../../../../../common/components/display/StatusBadge';

type PropTypes = {
    status: 'accepted' | 'awaitingConfirmation' | 'awaitingDates' | 'completed' | 'scheduled' | 'rescheduled';
};

const colors: { [index: string]: StatusBadgeColor } = {
    accepted: 'green10',
    awaitingConfirmation: 'purple10',
    awaitingDates: 'wvwYellow10',
    completed: 'white',
    scheduled: 'orange10',
    rescheduled: 'white',
};

const textColors: { [index: string]: StatusBadgeTextColor } = {
    accepted: 'green',
    awaitingConfirmation: 'purple',
    awaitingDates: 'wvwYellow',
    completed: 'green',
    scheduled: 'orange',
    rescheduled: 'wvwGrey60',
};

const InspectionStatusBadge = (props: PropTypes) => {
    const { status } = props;

    const { t } = useTranslation('inspection');

    return (
        <StatusBadge
            color={colors[status]}
            status={t(`status.${status}`)}
            textColor={textColors[status]}
        />
    );
};

export default InspectionStatusBadge;
