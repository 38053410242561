import {
    Center,
    VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import DocumentListItem from './DocumentListItem';
import { DocumentRequestDocumentType } from '../../../../../types';

type PropTypes = {
    documents: DocumentRequestDocumentType[],
    isDeleteable?: boolean;
    isEditable?: boolean;
};

const DocumentListDisplay = (props: PropTypes) => {
    const {
        documents = [],
        isDeleteable,
        isEditable,
    } = props;

    return (
        <VStack
            bg="wvwGrey05"
            borderRadius="10"
            paddingInline=".5rem"
            alignSelf="center"
            w="100%"
            paddingTop=".5rem"
            maxH={window.innerHeight - 280}
            overflow="scroll"
        >
            {documents.length === 0 && (
                <Center
                    pb="1rem"
                    color="wvwGrey40"
                >
                    {t('toast.errors.noDocuments', { ns: 'documentmanagement' })}
                </Center>
            )}

            {documents.length > 0 && documents.map(document => (
                <DocumentListItem
                    key={document.id}
                    document={document}
                    isDeleteable={isDeleteable}
                    isEditable={isEditable}
                />
            ))}
        </VStack>
    );
};

DocumentListDisplay.defaultProps = {
    isDeleteable: false,
    isEditable: false,
};

export default DocumentListDisplay;
