import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MainLayout from '../../common/components/display/MainLayout';
import { useRole } from '../../common/hooks';

const Dashboard = () => {
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const { userIsApproved, roleLoading } = useRole();

    useEffect(() => {
        if (roleLoading) return;
        if (userIsApproved) return;

        if (pathname === '/dashboard') navigate('/dashboard/profile');
    }, [roleLoading, userIsApproved]);

    return (
        <MainLayout>
            <Outlet />
        </MainLayout>
    );
};

export default Dashboard;
