import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { enquiryReactivate } from '../endpoints';
import useToast from '../../../common/hooks/useToast';
import useEnquiryGetAll from './useEnquiryGetAll';

type ParamsType = {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
};

const useEnquiryReactivate = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { t } = useTranslation('common');
    const { createErrorToast } = useToast();

    const { refresh: refreshEnquiryList } = useEnquiryGetAll();

    const { mutate: update } = useMutation(
        (enquiryId: number | string) => enquiryReactivate(enquiryId),
        {
            onSuccess: (data: any) => {
                refreshEnquiryList();

                onSuccess?.(data);
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));

                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useEnquiryReactivate;
