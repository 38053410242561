export const displayDateInLocale = (date: Date | string, locale: string) => {
    if (locale === '') throw new Error('No locale provided');

    return new Date(date).toLocaleDateString(locale);
};

export const displayDateWithCurrentLocale = (date: Date | string) => {
    const locale = localStorage.getItem('i18nextLng') || '';

    return displayDateInLocale(date, locale);
};
