import { useQuery, useQueryClient } from 'react-query';
import { valuerInvitesSent } from '../endpoints';

const VALUER_INVITES_SENT = 'VALUER_INVITES_SENT';

const useValuerInvitesSent = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([VALUER_INVITES_SENT], () => valuerInvitesSent());

    const refresh = () => {
        queryClient.invalidateQueries(VALUER_INVITES_SENT);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useValuerInvitesSent;
