import { useMutation } from 'react-query';
import { publicCreateEnquiry } from '../endpoints';
import { PublicCreateEnquiryDto } from '../dto';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const usePublicCreateEnquiry = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: PublicCreateEnquiryDto) => publicCreateEnquiry(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default usePublicCreateEnquiry;
