const toUrl = (url: string) => {
    if (url === null) return '';

    const splitUrl = url.split('://');

    if (splitUrl.length === 1) {
        return `https://${url}`;
    }

    return url;
};

export default toUrl;
