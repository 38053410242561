/* eslint-disable react/no-this-in-sfc */
import {
    Box,
    Button,
    Divider,
    Flex,
    GridItem,
    Heading,
    HStack,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ProfilePictureEditor from '../../../../common/components/ProfilePictureEditor';
import { useMe, useUpdateEmail } from '../../../../utils/api/hooks';
import {
    FormikAddressInput,
    FormikCountrySelect,
    FormikForm,
    FormikInput,
} from '../../../../common/forms';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { useRole } from '../../../../common/hooks';
import PasswordConfirmModal from '../../../../common/components/PasswordConfirmModal';

const ProfileDetailsEdit = (props: { openEditor: () => void }) => {
    const { openEditor } = props;

    const { t } = useTranslation(['profile', 'account', 'common', 'formik']);
    const {
        userIsClient,
        userIsCompany,
        userIsValuer,
        roleLoading,
    } = useRole();

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .required('errors.required'),
        lastName: Yup.string()
            .required('errors.required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('errors.required'),
        mobileDialCode: Yup.string()
            .required('errors.required'),
        mobile: Yup.number()
            .required('errors.required').typeError('errors.enterNumber'),
        telDialCode: Yup.string().test('tel', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.tel === undefined) return true;

                return false;
            }

            return true;
        }),
        tel: Yup.number().typeError('errors.enterNumber').test('tel', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.telDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
        faxDialCode: Yup.string().test('fax', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.fax === undefined) return true;

                return false;
            }

            return true;
        }),
        fax: Yup.number().typeError('errors.enterNumber').test('fax', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.faxDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
        officeTelDialCode: Yup.string().test('officeTel', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.officeTel === undefined) return true;

                return false;
            }

            return true;
        }),
        officeTel: Yup.number().typeError('errors.enterNumber').test('officeTel', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.officeTelDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
        officeFaxDialCode: Yup.string().test('officeFax', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.officeFax === undefined) return true;

                return false;
            }

            return true;
        }),
        officeFax: Yup.number().typeError('errors.enterNumber').test('officeFax', 'errors.required', function (value) {
            if (value === undefined) {
                if (this.parent.officeFaxDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
        street: Yup.string().required('errors.required'),
        city: Yup.string().required('errors.required'),
        country: Yup.string().required('errors.required'),
        postalCode: Yup.string().required('errors.required'),
    });

    const [formDataForEmailUpdate, setFormDataForEmailUpdate] = useState<any>(undefined);

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            openEditor();
        },
    });

    const updateUser = (values: any) => {
        updateMe({
            firstName: values.firstName,
            lastName: values.lastName,
            orgPosition: values.orgPosition,
            email: values.email,
            mobileDialCode: values.mobileDialCode,
            mobile: values.mobile,
            tel: values.tel,
            telDialCode: values.telDialCode,
            officeTelDialCode: values.officeTelDialCode,
            officeTel: values.officeTel,
            officeFax: values.officeFax,
            officeFaxDialCode: values.officeFaxDialCode,
            faxDialCode: values.faxDialCode,
            fax: values.fax,
            useOrgAddress: values.useOrgAddress,
            street: values.street,
            city: values.city,
            country: values.country,
            postalCode: values.postalCode,
        });
    };

    const { update: updateEmail } = useUpdateEmail({
        onSuccess: () => {
            updateUser(formDataForEmailUpdate);
            setFormDataForEmailUpdate(undefined);
        },
    });

    if (isLoading || roleLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    firstName: user?.firstName || '',
                    lastName: user?.lastName || '',
                    orgPosition: user?.orgPosition || '',
                    email: user?.email || '',
                    mobileDialCode: user?.mobileDialCode || '',
                    mobile: user?.mobile || '',
                    telDialCode: user?.telDialCode || '',
                    tel: user?.tel || '',
                    officeTelDialCode: user?.officeTelDialCode || '',
                    officeTel: user?.officeTel || '',
                    faxDialCode: user?.faxDialCode || '',
                    fax: user?.fax || '',
                    officeFax: user?.officeFax || '',
                    officeFaxDialCode: user?.officeFaxDialCode || '',
                    useOrgAddress: user?.useOrgAddress || false,
                    street: user?.street || '',
                    city: user?.city || '',
                    country: user?.country || '',
                    postalCode: user?.postalCode || '',
                    latitude: user?.latitude,
                    longitude: user?.longitude,
                }}
                onSubmit={values => {
                    if (values.email !== user?.email) {
                        setFormDataForEmailUpdate(values);
                    } else {
                        updateUser(values);
                    }
                }}
            >
                <Box
                    bg="wvwGrey05"
                    borderRadius="10"
                    marginTop="1.5rem"
                    padding="1.5rem 2rem"
                    w="100%"
                >
                    <Heading
                        as="h4"
                        color="wvwGrey"
                        size="md"
                    >
                        {t('profileDetails.sectionTitle')}
                    </Heading>

                    <VStack
                        paddingTop="1rem"
                    >
                        <Flex
                            alignItems="center"
                            w="100%"
                        >
                            <Box
                                w="100%"
                                maxW="17rem"
                            >
                                <ProfilePictureEditor />
                            </Box>

                            <SimpleGrid
                                columns={2}
                                spacing={2}
                                w="100%"
                            >
                                <Box
                                    alignSelf="flex-end"
                                >
                                    <Text color="wvwGreen">
                                        {t('profileDetails.firstName')}
                                    </Text>

                                    <FormikInput
                                        name="firstName"
                                        placeholder={t('profileDetails.firstName')}
                                        type="text"
                                    />
                                </Box>

                                <Box
                                    alignSelf="flex-end"
                                >
                                    <Text color="wvwGreen">
                                        {t('profileDetails.lastName')}
                                    </Text>

                                    <FormikInput
                                        name="lastName"
                                        placeholder={t('profileDetails.lastName')}
                                        type="text"
                                    />
                                </Box>

                                {userIsCompany && (
                                    <GridItem colSpan={2}>
                                        <Text color="wvwGreen">
                                            {t('profileDetails.roleInCompany')}
                                        </Text>

                                        <FormikInput
                                            name="orgPosition"
                                            placeholder={t('profileDetails.roleInCompanyPlaceholder')}
                                            type="text"
                                        />
                                    </GridItem>
                                )}
                            </SimpleGrid>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.email')}
                            </Text>

                            <FormikInput
                                name="email"
                                placeholder={t('profileDetails.email')}
                                type="email"
                            />
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.mobileNumber')}
                            </Text>

                            <HStack
                                alignItems="flex-start"
                                spacing="2"
                                w="100%"
                            >
                                <FormikCountrySelect
                                    name="mobileDialCode"
                                    selectDialCode
                                />
                                <FormikInput
                                    name="mobile"
                                    placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                />
                            </HStack>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.telNumber')}
                            </Text>

                            <HStack
                                alignItems="flex-start"
                                spacing="2"
                                w="100%"
                            >
                                <FormikCountrySelect
                                    name="telDialCode"
                                    selectDialCode
                                />
                                <FormikInput
                                    name="tel"
                                    placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                />
                            </HStack>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.faxNumber')}
                            </Text>

                            <HStack
                                alignItems="flex-start"
                                spacing="2"
                                w="100%"
                            >
                                <FormikCountrySelect
                                    name="faxDialCode"
                                    selectDialCode
                                />

                                <FormikInput
                                    name="fax"
                                    placeholder={t('fieldPlaceholder.faxNumber', { ns: 'common' })}
                                />
                            </HStack>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.officeTelNumber')}
                            </Text>

                            <HStack
                                alignItems="flex-start"
                                spacing="2"
                                w="100%"
                            >
                                <FormikCountrySelect
                                    name="officeTelDialCode"
                                    selectDialCode
                                />

                                <FormikInput
                                    name="officeTel"
                                    placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                />
                            </HStack>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.officeFaxNumber')}
                            </Text>

                            <HStack
                                alignItems="flex-start"
                                spacing="2"
                                w="100%"
                            >
                                <FormikCountrySelect
                                    name="officeFaxDialCode"
                                    selectDialCode
                                />

                                <FormikInput
                                    name="officeFax"
                                    placeholder={t('fieldPlaceholder.faxNumber', { ns: 'common' })}
                                />
                            </HStack>
                        </Flex>

                        <Divider />

                        <Flex w="100%">
                            <Text
                                color="wvwGreen"
                                mt="0.75rem"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('companyDetails.location', { ns: 'account' })}
                            </Text>

                            {userIsValuer && (
                                <FormikAddressInput
                                    cityName="city"
                                    countryName="country"
                                    postalCodeName="postalCode"
                                    streetName="street"
                                />
                            )}

                            {userIsClient && (
                                <VStack w="100%">
                                    <Flex
                                        alignItems="center"
                                        w="100%"
                                    >
                                        <FormikInput
                                            name="street"
                                            placeholder={t('fieldPlaceholder.street', { ns: 'common' })}
                                            type="text"
                                        />
                                    </Flex>

                                    <Flex
                                        alignItems="center"
                                        w="100%"
                                    >
                                        <HStack
                                            alignItems="baseline"
                                            spacing="2"
                                            w="100%"
                                        >
                                            <FormikInput
                                                name="postalCode"
                                                placeholder={t('fieldPlaceholder.postalCode', { ns: 'common' })}
                                                type="text"
                                            />

                                            <FormikInput
                                                name="city"
                                                placeholder={t('fieldPlaceholder.city', { ns: 'common' })}
                                                type="text"
                                            />
                                        </HStack>
                                    </Flex>

                                    <Flex
                                        alignItems="center"
                                        w="100%"
                                    >
                                        <FormikCountrySelect
                                            name="country"
                                            w="100%"
                                        />
                                    </Flex>
                                </VStack>
                            )}
                        </Flex>

                        <Divider />

                        <Button
                            alignSelf="end"
                            variant="primary"
                            type="submit"
                        >
                            {t('button.done', { ns: 'common' })}
                        </Button>
                    </VStack>
                </Box>
            </FormikForm>

            <PasswordConfirmModal
                isOpen={!!formDataForEmailUpdate}
                onCancel={() => setFormDataForEmailUpdate(undefined)}
                onContinue={() => updateEmail(formDataForEmailUpdate.email)}
            />
        </>
    );
};

export default ProfileDetailsEdit;
