import { useMutation } from 'react-query';
import { DocumentRequestCreateDocumentCommentDto } from '../../dto';
import { documentRequestCreateDocumentComment } from '../../endpoints';

type ParamsType = {
    onSuccess: () => void;
    onError: (error: Error) => void;
};

const useDocumentRequestCreateDocumentComment = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: DocumentRequestCreateDocumentCommentDto) => documentRequestCreateDocumentComment(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestCreateDocumentComment;
