import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegisterPersonalForm from './account-details/RegisterPersonalForm';
import SelectOrganisationOrIndividual from './select-company-or-individual/SelectOrganisationOrIndividual';
import { RegisterDataType } from '../../types';
import ProcessFlowStateful from '../../common/components/display/ProcessFlowStateful';
import SuccessScreen from '../wizard-success/SuccessScreen';
import SelectValuerOrClient from './select-valuer-or-client/SelectValuerOrClient';
import InviteLandingPage from '../invited-register/common/InviteLandingPage';

type ParamsType = {
    inviteType?: 'invitedToClientPanel' | 'invitedToValuerPanel' | 'invitedToTeam';
    token?: string;
};

const initialRegisterData: RegisterDataType = {
    accountType: '',
    companyOrIndividual: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobile: '',
    orgName: '',
    orgPosition: '',
    street: '',
    city: '',
    country: '',
    postalCode: '',
    billingTier: 'FREE',
    billingCity: '',
    billingCountry: '',
    billingPostalCode: '',
    billingStreet: '',
};

const Register = () => {
    const { inviteType } = useParams<ParamsType>();

    const { t } = useTranslation();

    const inviteClientType = inviteType === 'invitedToClientPanel' ? 'VALUER' : 'CLIENT';

    const [registerData, setRegisterData] = useState<RegisterDataType>({
        ...initialRegisterData,
        accountType: inviteType ? inviteClientType : '',
    });

    const onContinue = (values?: { [index: string]: string }) => {
        setRegisterData({
            ...registerData,
            ...values,
        });
    };

    const inviteLanding = <InviteLandingPage />;
    const selectValuerOrClient = <SelectValuerOrClient onContinue={onContinue} />;
    const selectOrganisationOrIndividual = (
        <SelectOrganisationOrIndividual
            onContinue={onContinue}
        />
    );
    const registerPersonalForm = (
        <RegisterPersonalForm
            onContinue={onContinue}
            registerData={registerData}
        />
    );
    const successScreen = (
        <SuccessScreen
            bodyText={t('registerSuccess')}
            buttonText={t('button.goToProfile')}
            navLink="../dashboard/profile"
        />
    );

    const steps = () => {
        let registrationFlow = [];

        switch (inviteType) {
            case 'invitedToClientPanel':
                registrationFlow = [
                    inviteLanding,
                    registerPersonalForm,
                    successScreen,
                ];
                break;
            case 'invitedToValuerPanel':
                registrationFlow = [
                    inviteLanding,
                    selectOrganisationOrIndividual,
                    registerPersonalForm,
                    successScreen,
                ];
                break;
            case 'invitedToTeam':
                registrationFlow = [
                    inviteLanding,
                    registerPersonalForm,
                    successScreen,
                ];
                break;
            default:
                if (registerData.accountType === 'CLIENT') {
                    registrationFlow = [
                        selectValuerOrClient,
                        selectOrganisationOrIndividual,
                        registerPersonalForm,
                        successScreen,
                    ];
                } else {
                    registrationFlow = [
                        selectValuerOrClient,
                        registerPersonalForm,
                        successScreen,
                    ];
                }
        }
        return registrationFlow;
    };

    return (
        <ProcessFlowStateful
            steps={steps()}
        />
    );
};

export default Register;
