import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

const WaterMarkBox = ({ children } : { children: ReactNode }) => (
    <Box
        bgGradient="linear(to-b, #016064cc, #016064)"
        h="100%"
        w="100%"
    >
        <Box
            h="100%"
            w="100%"
            backgroundImage="url('/images/WVWPatternWithColor.svg')"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="cover"
            overflow="scroll"
            overflowX="hidden"
            className="hide-scrollbar"
        >
            {children}
        </Box>
    </Box>
);

export default WaterMarkBox;
