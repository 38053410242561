import {
    Input,
    InputProps,
    Text,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import 'react-calendar/dist/Calendar.css';

type PropTypes = InputProps & {
    name: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void,
};

const FormikTimeSelect = (props: PropTypes) => {
    const { name, onChange } = props;

    const { t } = useTranslation('formik');

    const [field, meta] = useField({
        name,
        onChange: undefined,
    });

    return (
        <div style={{ width: '100%' }}>
            <Input
                borderRadius="8"
                bg="white"
                h="3rem"
                isInvalid={!!meta.touched && !!meta.error}
                type="time"
                w="100%"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onChange={e => {
                    field.onChange(e);

                    onChange?.(e.target.value, field);
                }}
            />

            {meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )}
        </div>
    );
};

FormikTimeSelect.defaultProps = {
    onChange: undefined,
};

export default FormikTimeSelect;
