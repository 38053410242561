import { CheckIcon } from '@chakra-ui/icons';
import {
    Grid,
    GridItem,
    HStack,
    IconButton,
    Button,
    Text,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import {
    useNavigate,
    useParams,
    Link as ReactLink,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentText } from 'iconsax-react';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import { FormikForm, FormikInput } from '../../../../../../common/forms';
import { DocumentRequestDocumentType, RequestDropTargetType, dragTargetTypes } from '../../../../../../types';
import { useRole } from '../../../../../../common/hooks';
import { useDocumentRequestRemoveDocumentFromRequest, useDocumentRequestUpdateDocument } from '../../../../../../utils/api/hooks';
import { displayDateWithCurrentLocale } from '../../../../../../common/functions/displayDateInLocale';
import ConfirmationModal from '../../../../../../common/components/ConfirmationModal';
import WvwDeleteIconButton from '../../../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    document: DocumentRequestDocumentType;
    inEditMode: boolean | string;
};

const DocumentOverviewItem = (props: PropTypes) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { caseId = '' } = useParams();

    const {
        document,
        inEditMode,
    } = props;

    const { userIsClient } = useRole();

    const [selectedDocument, setSelectedDocument] = useState(undefined as unknown as number);
    const [deleteFile, setDeleteFile] = useState('');

    const { update: updateDocument } = useDocumentRequestUpdateDocument({
        caseId: Number(caseId),
        onSuccess: () => setSelectedDocument(undefined as unknown as number),
    });

    const {
        update: removeDocumentFromRequest,
    } = useDocumentRequestRemoveDocumentFromRequest({ caseId });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragTargetTypes.DOCUMENT_MOVE,
        item: { documentId: document.id },
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as RequestDropTargetType;

            if (item && dropResult) {
                updateDocument({
                    documentId: item.documentId,
                    documentRequestId: Number(dropResult.requestId),
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    if (isDragging) {
        return null;
    }

    return (
        <Grid
            alignItems="center"
            key={document.id}
            maxW="100%"
            paddingBlock=".5rem"
            ref={drag}
            templateColumns="repeat(4, 1fr)"
            w="100%"
            _hover={{ cursor: 'pointer' }}
        >
            <GridItem
                alignSelf="left"
                w="18rem"
            >
                {(selectedDocument === document.id) && (inEditMode) ? (
                    <FormikForm
                        initialValues={{
                            name: document.name,
                        }}
                        onSubmit={values => updateDocument({
                            documentId: document.id,
                            documentRequestId:
                                Number(document.documentRequestId),
                            name: values.name,
                        })}
                    >
                        <HStack
                            width="100%"
                        >
                            <FormikInput
                                name="name"
                                width="25rem"
                            />

                            <IconButton
                                aria-label="Save"
                                icon={<CheckIcon />}
                                size="xs"
                                borderRadius="30"
                                variant="primary"
                                type="submit"
                            />

                            <WvwDeleteIconButton
                                onClick={() => setSelectedDocument(
                                    undefined as unknown as number,
                                )}
                            />
                        </HStack>
                    </FormikForm>
                ) : (
                    <Button
                        w="13rem"
                        alignContent="left"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        ml="2.4rem"
                        variant="link"
                        color="wvwGreen"
                        leftIcon={<DocumentText />}
                        isDisabled={document.deleted || document.status === 'UNAVAILABLE'}
                        fontWeight="normal"
                        onClick={() => {
                            if (inEditMode
                                && document.id !== selectedDocument
                            ) {
                                setSelectedDocument(document.id);
                            } else if (inEditMode
                                && document.id === selectedDocument) {
                                setSelectedDocument(
                                    undefined as unknown as number,
                                );
                            }
                        }}
                    >
                        <ChakraLink
                            as={ReactLink}
                            textAlign="left"
                            to={!inEditMode ? `document-preview/${document.id}` : undefined}
                            w="100%"
                        >
                            {document.name}
                        </ChakraLink>
                    </Button>
                )}
            </GridItem>

            {
                !inEditMode && (
                    <>
                        <GridItem
                            justifySelf="center"
                            w="10rem"
                        >
                            <StatusBadge status={document.deleted ? 'DELETED' : document.status!} />
                        </GridItem>

                        <HStack
                            align="left"
                            pl="1rem"
                        >
                            <Text
                                noOfLines={1}
                                textAlign="left"
                            >
                                {document.updatedBy ? `${document.updatedBy.firstName} ${document.updatedBy.lastName}` : '-'}
                            </Text>

                            <Text>
                                {displayDateWithCurrentLocale(document.updatedAt)}
                            </Text>
                        </HStack>

                        <GridItem justifySelf="flex-end">
                            {(userIsClient && document.status === 'REJECTED') && (
                                <Button
                                    size="sm"
                                    marginRight=".5rem"
                                    variant="primaryYellow"
                                    onClick={e => {
                                        e.stopPropagation();
                                        navigate(`replace-document/${document.id}`);
                                    }}
                                >
                                    {t('replace', { ns: 'documentmanagement' })}
                                </Button>
                            )}

                            {(userIsClient && document.status === 'PENDING') && (
                                <WvwDeleteIconButton
                                    onClick={e => {
                                        e.stopPropagation();
                                        setDeleteFile(document.name);
                                    }}
                                    color="black"
                                />
                            )}
                        </GridItem>

                        <ConfirmationModal
                            isOpen={deleteFile !== ''}
                            onCancel={() => setDeleteFile('')}
                            onContinue={async () => {
                                removeDocumentFromRequest(document.id);

                                setDeleteFile('');
                            }}
                            title={t('warning.deleteDocument.title', { ns: 'common' })}
                            content={t('warning.deleteDocument.prompt', { ns: 'common' })}
                        />
                    </>
                )
            }
        </Grid>
    );
};

export default DocumentOverviewItem;
