import {
    Box,
    Button,
    Flex,
    HStack,
    Icon,
} from '@chakra-ui/react';
import {
    TickCircle,
    Folder2,
} from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';
import { FormikForm, FormikInput } from '../../../../../common/forms';
import { useDocumentRequestCreateFolder } from '../../../../../utils/api/hooks';

type PropTypes = {
    closeEditor?: () => void;
};

const validationSchema = Yup.object({
    folder: Yup.string().required('errors.required'),
});

const NewFolderBox = (props: PropTypes) => {
    const {
        closeEditor,
    } = props;

    const { t } = useTranslation(['documentmanagement', 'common']);
    const { caseId = '' } = useParams();

    const { update } = useDocumentRequestCreateFolder({
        caseId,
        onSuccess: () => closeEditor?.(),
    });

    return (
        <Box
            bg="wvwGrey05"
            paddingBlock=".8rem"
            borderRadius="10"
            fontWeight="500"
            pl="2.75rem"
            w="100%"
        >
            <HStack>
                <Icon
                    as={Folder2}
                    color="wvwGreen"
                    boxSize={6}
                />

                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={{
                        folder: '',
                    }}
                    onSubmit={values => {
                        update({
                            caseId: Number(caseId),
                            name: values.folder,
                        });
                    }}
                >
                    <Flex
                        align="left"
                        w="100%"
                        paddingRight="1.6rem"
                        justifyContent="space-between"
                    >
                        <FormikInput
                            name="folder"
                            placeholder={t('nameFolder', { ns: 'documentmanagement' })}
                            width="75%"
                        />

                        <HStack
                            spacing=".2rem"
                            justifyContent="flex-end"
                        >
                            <WvwDeleteIconButton
                                onClick={closeEditor}
                                color="black"
                            />

                            <Button
                                variant="none"
                                type="submit"
                            >
                                <TickCircle />
                            </Button>
                        </HStack>
                    </Flex>
                </FormikForm>
            </HStack>
        </Box>
    );
};

NewFolderBox.defaultProps = {
    closeEditor: undefined,
};

export default NewFolderBox;
