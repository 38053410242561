import { CheckIcon } from '@chakra-ui/icons';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Grid,
    GridItem,
    HStack,
    IconButton,
    Link as ChakraLink,
    Text,
    VStack,
    Button,
    Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { Link as ReactLink } from 'react-router-dom';
import {
    DocumentRequestType,
    FolderDropTargetType,
    RequestDropTargetType,
    dragTargetTypes,
} from '../../../../../../types';
import {
    FormikForm,
    FormikInput,

} from '../../../../../../common/forms';
import Documents from '../../documents/Documents';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import { useDocumentRequestUpdateRequest } from '../../../../../../utils/api/hooks';
import { useRole } from '../../../../../../common/hooks';
import { displayDateWithCurrentLocale } from '../../../../../../common/functions/displayDateInLocale';
import { DocumentFiltersType } from '../../common/documentManagementAreaTypes';
import WvwCancelIconButton from '../../../../../../common/components/WvwCancelIconButton';

type PropTypes = {
    documentFilter: DocumentFiltersType;
    inEditMode: boolean;
    request: DocumentRequestType;
};

const RequestOverviewItem = (props: PropTypes) => {
    const {
        documentFilter,
        inEditMode,
        request,
    } = props;

    const {
        caseId,
        folderId,
        name,
        updatedAt,
        updatedBy,
        status,
    } = request;

    const [editing, setEditing] = useState(false);

    const { t } = useTranslation('documentmanagement');

    const { userIsClient } = useRole();

    const {
        update: updateRequest,
    } = useDocumentRequestUpdateRequest({ caseId });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragTargetTypes.REQUEST_MOVE,
        item: { requestId: request.id },
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as FolderDropTargetType;

            if (item && dropResult) {
                updateRequest({
                    requestId: item.requestId,
                    folderId: dropResult.folderId,
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [{ acceptedObjectIsBeingDragged, acceptedObjectIsOver }, drop] = useDrop(() => ({
        accept: [dragTargetTypes.DOCUMENT_IMPORT, dragTargetTypes.DOCUMENT_MOVE],
        drop: () => (
            { requestId: request.id, folderId } as RequestDropTargetType
        ),
        collect: monitor => ({
            acceptedObjectIsBeingDragged: monitor.canDrop(),
            acceptedObjectIsOver: monitor.canDrop() && monitor.isOver(),
        }),
    }));

    let bgColor = 'wvwGrey05';

    if (acceptedObjectIsOver) {
        bgColor = 'wvwGreen80';
    } else if (acceptedObjectIsBeingDragged) {
        bgColor = 'wvwGreen20';
    }

    if (isDragging) {
        return null;
    }

    return (
        <AccordionItem
            isFocusable={false}
            ref={drag}
            onKeyUp={e => e.preventDefault()}
        >
            <AccordionButton
                bgColor={bgColor}
                paddingBlock="0"
                ref={drop}
            >
                <Grid
                    alignItems="center"
                    paddingBlock=".5rem"
                    templateColumns="repeat(4, 1fr)"
                    w="100%"
                >
                    <HStack
                        alignSelf="left"
                        w="18rem"
                    >
                        <AccordionIcon />

                        {(inEditMode && editing) ? (
                            <FormikForm
                                initialValues={{ requestName: name }}
                                onSubmit={values => {
                                    updateRequest({
                                        requestId: request.id,
                                        name: values.requestName,
                                    });

                                    setEditing(false);
                                }}
                            >
                                <HStack
                                    paddingLeft="1.1rem"
                                    width="100%"
                                >
                                    <Box
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <FormikInput
                                            name="requestName"
                                            width="25rem"
                                        />
                                    </Box>

                                    <IconButton
                                        aria-label="Save"
                                        borderRadius="30"
                                        icon={<CheckIcon />}
                                        onClick={e => e.stopPropagation()}
                                        size="xs"
                                        type="submit"
                                        variant="primary"
                                    />

                                    <WvwCancelIconButton
                                        onClick={e => {
                                            setEditing(false);
                                            e.stopPropagation();
                                        }}
                                    />
                                </HStack>
                            </FormikForm>
                        ) : (
                            <ChakraLink
                                as={ReactLink}
                                justifySelf="flex-end"
                                color="wvwGreen"
                                fontWeight="500"
                                to={inEditMode ? undefined : `request/${request.id}`}
                                onClick={() => {
                                    if (inEditMode) {
                                        setEditing(!editing);
                                    }
                                }}
                            >
                                <Text
                                    maxW="17rem"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                >
                                    {name}
                                </Text>
                            </ChakraLink>
                        )}
                    </HStack>

                    {!inEditMode && (
                        <>
                            <GridItem
                                justifySelf="center"
                                w="10rem"
                            >
                                <StatusBadge status={status} />
                            </GridItem>

                            <HStack
                                align="left"
                                pl="1rem"
                            >
                                <Text
                                    noOfLines={1}
                                    textAlign="left"
                                >
                                    {updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : '-'}
                                </Text>

                                <Text>
                                    {displayDateWithCurrentLocale(updatedAt)}
                                </Text>
                            </HStack>

                            <GridItem
                                justifySelf="flex-end"
                                w="10rem"
                                onClick={e => e.stopPropagation()}
                                onKeyDown={e => e.stopPropagation()}
                            >
                                {(userIsClient && request.status === 'PENDING') && (
                                    <ChakraLink
                                        as={ReactLink}
                                        to={`request-unavailable-or-incomplete/${request.id}`}
                                    >
                                        <Button
                                            justifySelf="right"
                                            variant="danger"
                                        >
                                            {t('button.unavailable')}
                                        </Button>
                                    </ChakraLink>
                                )}
                            </GridItem>
                        </>
                    )}
                </Grid>
            </AccordionButton>

            <AccordionPanel paddingTop="0">
                <VStack
                    align="left"
                    w="100%"
                >
                    <Text
                        color="wvwGrey60"
                        paddingLeft="1.7rem"
                    >
                        {request.description}
                    </Text>

                    {request.unavailableReason && (
                        <Text
                            color="orange"
                            paddingLeft="1.7rem"
                        >
                            {request.unavailableReason}
                        </Text>
                    )}

                    <Documents
                        inEditMode={inEditMode}
                        requestId={request.id}
                        documentFilter={documentFilter}
                    />
                </VStack>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default RequestOverviewItem;
