import { useQuery, useQueryClient } from 'react-query';
import { offerGetAllMyOffers } from '../endpoints';

const MY_OFFERS = 'MY_OFFERS';

const useOfferGetAllMyOffers = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([MY_OFFERS], () => offerGetAllMyOffers());

    const refresh = () => {
        queryClient.invalidateQueries(MY_OFFERS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useOfferGetAllMyOffers;
