import {
    Flex,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InspectionAccessDetails from './common/InspectionAccessDetails';
import { InspectionType, PropertyType } from '../../../../types';
import { CalendarEntryType } from '../../../../types/calendar/CalendarEntryType';
import { useInspectionReportOutcome, useInspectionReportProblem } from '../../../../utils/api/hooks';
import { useRole } from '../../../../common/hooks';
import { WvwButton } from '../../../../common/components/inputs';
import { ContentBox, EventDateTime } from '../../../../common/components/display';

type PropTypes = {
    calendarEntry: CalendarEntryType;
    inspection: InspectionType;
    property: PropertyType;
};

const InspectionDetailsActionArea = (props: PropTypes) => {
    const { calendarEntry, inspection, property } = props;
    const { start, duration } = calendarEntry;
    const {
        accessFName,
        accessLName,
        accessEmail,
        accessMobileDialCode,
        accessMobile,
    } = property;

    const { t } = useTranslation(['inspection']);
    const navigate = useNavigate();
    const { userIsValuer } = useRole();

    const { update: reportOutcome } = useInspectionReportOutcome({
        caseId: inspection.caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    const { update: reportProblem } = useInspectionReportProblem({
        caseId: inspection.caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    const startDate = new Date(start);

    return (
        <VStack spacing={4}>
            <Flex
                gap="2"
                justifyContent="space-between"
                w="100%"
            >
                { userIsValuer && (
                    <WvwButton
                        block
                        content={t('button.markComplete')}
                        onClick={() => reportOutcome({
                            outcome: {
                                success: true,
                                comment: 'The inspection was successful',
                            },
                            target: inspection.id,
                        })}
                        size="sm"
                    />
                )}

                <WvwButton
                    block
                    content={t('button.reportProblem')}
                    onClick={() => reportProblem({
                        comment: 'The inspection had a problem',
                        target: inspection.id,
                    })}
                    size="sm"
                    variant="danger"
                />

                <WvwButton
                    block
                    content={t('button.reschedule')}
                    onClick={() => reportOutcome({
                        outcome: {
                            success: false,
                            comment: 'The inspection needs to be rescheduled',
                        },
                        target: inspection.id,
                    })}
                    size="sm"
                    variant="primary"
                />
            </Flex>

            <ContentBox color="orange">
                <EventDateTime
                    date={startDate}
                    duration={duration}
                    orientation="vertical"
                />
            </ContentBox>

            <InspectionAccessDetails fullName={`${accessFName} ${accessLName}`} email={accessEmail} mobile={`${accessMobileDialCode} ${accessMobile}`} />
        </VStack>
    );
};

export default InspectionDetailsActionArea;
