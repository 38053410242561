import { useQuery, useQueryClient } from 'react-query';
import { AdminEnquiriesDto } from '../../dto';
import { adminEnquiries } from '../../endpoints';

const ADMIN_ENQUIRES = 'ADMIN_ENQUIRES';

const useAdminEnquiries = (dto: AdminEnquiriesDto) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([ADMIN_ENQUIRES, JSON.stringify(dto)], () => adminEnquiries(dto));

    const refresh = () => {
        queryClient.invalidateQueries(ADMIN_ENQUIRES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useAdminEnquiries;
