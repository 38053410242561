import { useQuery, useQueryClient } from 'react-query';
import { caseDormantCases } from '../endpoints';

const CASE_DORMANT_CASES = 'CASE_DORMANT_CASES';

const useCaseDormantCases = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([CASE_DORMANT_CASES], () => caseDormantCases());

    const refresh = () => {
        queryClient.invalidateQueries(CASE_DORMANT_CASES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useCaseDormantCases;
