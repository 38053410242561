import {
    Button, Divider, Input, Textarea, VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import RequestListDisplay from './RequestListDisplay';
import { DocumentRequestType } from '../../../../../types';

type RequestType = {
    name: string;
    description: string;
};

type PropTypes = {
    name: string;
};

const RequestListCreation = (props: PropTypes) => {
    const { name } = props;

    const { t } = useTranslation(['documentmanagement']);

    const [field] = useField(props);

    const [request, setRequest] = useState({} as RequestType);

    const submitValues = () => {
        const inputList = [...field.value, request];

        field.onChange({
            target: {
                name,
                value: inputList,
            },
        });

        setRequest({
            name: '',
            description: '',
        } as RequestType);
    };

    return (
        <VStack w="100%">
            <VStack w="100%">
                <Input
                    name="name"
                    value={request.name}
                    placeholder={t('requestTitle', { ns: 'documentmanagement' })}
                    onChange={
                        e => {
                            setRequest({
                                name: e.target.value as string,
                                description: request.description,
                            });
                        }
                    }
                />

                <Textarea
                    name="description"
                    value={request.description}
                    placeholder={t('instructionNotes', { ns: 'documentmanagement' })}
                    onChange={
                        e => {
                            setRequest({
                                name: request.name,
                                description: e.target.value as string,
                            });
                        }
                    }
                />

                <Button
                    variant="primary"
                    marginLeft=".5rem"
                    alignSelf="flex-end"
                    onClick={submitValues}
                >
                    {t('button.add', { ns: 'common' })}
                </Button>
            </VStack>

            <Divider />

            <RequestListDisplay
                onDelete={() => {
                    const newRequestList = field.value.filter((i: RequestType) => i !== request);

                    field.onChange({
                        target: {
                            name,
                            value: newRequestList,
                        },
                    });
                }}
                requests={field.value as DocumentRequestType[]}
                requestType="REQUEST"
            />
        </VStack>
    );
};

export default RequestListCreation;
