import {
    VStack,
    HStack,
    Text,
    Flex,
    Divider,
    Box,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    FormikAddressInput,
    FormikCountrySelect,
    FormikForm,
    FormikInput,
} from '../../../../../common/forms';
import ProfilePictureEditor from '../../../../../common/components/ProfilePictureEditor';
import { useMyEntity } from '../../../../../utils/api/hooks';
import { EntityType } from '../../../../../types';
import { WvwButton } from '../../../../../common/components/inputs';

type PropTypes = {
    entity: EntityType;
    onComplete: () => void;
};

const validationSchema = Yup.object({
    name: Yup.string().required('errors.required'),
    contactNumberDialCode: Yup.string().test('contactNumber', 'errors.required', function (value) {
        if (value === undefined) {
            if (this.parent.contactNumber === undefined) return true;

            return false;
        }

        return true;
    }),
    contactNumber: Yup.number().typeError('errors.enterNumber').test('contactNumber', 'errors.required', function (value) {
        if (value === undefined) {
            if (this.parent.contactNumberDialCode === undefined) return true;

            return false;
        }

        return true;
    }),
    street: Yup.string().required('errors.required'),
    city: Yup.string().required('errors.required'),
    country: Yup.string().required('errors.required'),
    postalCode: Yup.string().required('errors.required'),
});

const CompanyDetailsEditClient = (props: PropTypes) => {
    const { entity, onComplete } = props;

    const { t } = useTranslation(['account', 'common', 'formik']);

    const {
        updateEntity,
    } = useMyEntity({
        onUpdateSuccess: () => {
            onComplete();
        },
    });

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                name: entity?.name || '',
                websiteAddress: entity?.websiteAddress || '',
                companyEmail: entity?.companyEmail || '',
                contactNumberDialCode: entity?.contactNumberDialCode || '',
                contactNumber: entity?.contactNumber || '',
                street: entity?.street || '',
                city: entity?.city || '',
                country: entity?.country || '',
                postalCode: entity?.postalCode || '',
            }}
            onSubmit={values => {
                updateEntity({
                    name: values.name,
                    websiteAddress: values.websiteAddress,
                    companyEmail: values.companyEmail,
                    contactNumberDialCode: values.contactNumberDialCode,
                    contactNumber: values.contactNumber,
                    street: values.street,
                    city: values.city,
                    country: values.country,
                    postalCode: values.postalCode,
                });
            }}
        >
            <Box
                bg="wvwGrey05"
                borderRadius="10"
                marginTop="1.5rem"
                padding="1.5rem 2rem"
                w="100%"
            >
                <VStack
                    paddingTop="1rem"
                >
                    <Flex
                        w="100%"
                        alignItems="center"
                    >
                        <Box
                            w="100%"
                            maxW="17rem"
                        >
                            <ProfilePictureEditor forEntity />
                        </Box>

                        <Box w="100%">
                            <Text color="wvwGreen" paddingBottom=".5rem">
                                {t('companyDetails.name')}
                            </Text>

                            <FormikInput
                                name="name"
                                type="text"
                                placeholder={t('fieldPlaceholder.companyName', { ns: 'common' })}
                            />
                        </Box>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.website')}
                        </Text>

                        <FormikInput
                            name="websiteAddress"
                            placeholder={t('fieldPlaceholder.url', { ns: 'common' })}
                            type="text"
                        />
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.email')}
                        </Text>

                        <FormikInput
                            name="companyEmail"
                            placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                            type="email"
                        />
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.phoneNumber')}
                        </Text>

                        <HStack
                            alignItems="baseline"
                            spacing="2"
                            w="100%"
                        >
                            <FormikCountrySelect
                                name="contactNumberDialCode"
                                selectDialCode
                            />

                            <FormikInput
                                name="contactNumber"
                                placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                            />
                        </HStack>
                    </Flex>

                    <Divider />

                    <Flex w="100%">
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            mt="0.7rem"
                            w="100%"
                        >
                            {t('companyDetails.location')}
                        </Text>

                        <FormikAddressInput
                            streetName="street"
                            cityName="city"
                            countryName="country"
                            postalCodeName="postalCode"
                        />
                    </Flex>

                    <Divider />

                    <HStack
                        w="100%"
                        justifyContent="flex-end"
                    >
                        <WvwButton
                            content={t('button.cancel', { ns: 'common' })}
                            onClick={onComplete}
                            variant="danger"
                        />

                        <WvwButton
                            content={t('button.done', { ns: 'common' })}
                            formSubmitButton
                            onClick={() => {}}
                        />
                    </HStack>

                </VStack>
            </Box>
        </FormikForm>
    );
};

export default CompanyDetailsEditClient;
