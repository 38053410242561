import { useTranslation } from 'react-i18next';
import { useMyEntity } from '../../../utils/api/hooks';
import WvwAlertBanner from './WvwAlertBanner';
import { calculateDaysFromToday } from '../../functions/calculateDaysDifference';

const PiCoverExpiryAlert = () => {
    const { t } = useTranslation('updates');

    const {
        data: entity,
    } = useMyEntity();

    const validityDate = entity?.piCoverValidityDate || '';

    const daysDifference = calculateDaysFromToday(validityDate);

    let BannerProps = {
        isMandatory: false,
        messageText: '',
    };

    switch (true) {
        case daysDifference < 0:
            BannerProps = { isMandatory: true, messageText: t('piCoverAlert.hasExpired') };
            break;
        case daysDifference === 0:
            BannerProps = { isMandatory: true, messageText: t('piCoverAlert.expiresToday') };
            break;
        case daysDifference <= 14 && daysDifference > 0:
            BannerProps = { isMandatory: true, messageText: t('piCoverAlert.expiresIn', { days: daysDifference }) };
            break;
        case daysDifference <= 30 && daysDifference > 14:
            BannerProps = { isMandatory: false, messageText: t('piCoverAlert.expiresIn', { days: daysDifference }) };
            break;
        default:
            BannerProps = { isMandatory: false, messageText: '' };
    }

    if (daysDifference < 30) {
        return (
            <WvwAlertBanner
                message={BannerProps.messageText}
                isMandatory={BannerProps.isMandatory}
            />
        );
    }

    return null;
};

export default PiCoverExpiryAlert;
