import {
    Box,
    Container,
    Flex,
    Heading,
    VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Logo from './common/components/Logo';
import { auth } from './utils/firebase';
import WaterMarkBox from './common/components/WaterMarkBox';
import LoadingSpinner from './common/components/LoadingSpinner';

const Home = () => {
    const [user, loading] = useAuthState(auth);

    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;

        if (user) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    }, [user, loading]);

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.lg"
                >
                    <Box
                        bg="white"
                        borderWidth="1px"
                        borderRadius="lg"
                        p="2em"
                        w="100%"
                        marginTop="2rem"
                    >
                        <VStack
                            spacing="8"
                            pb="9em"
                            pt="6em"
                            w="100%"
                        >
                            <Logo withColor />

                            <Heading
                                as="h1"
                                color="wvwGreen"
                                size="xl"
                            >
                                {t('Welcome to WVW')}
                            </Heading>

                            <Flex alignItems="center">
                                <LoadingSpinner
                                    color="wvwYellow"
                                    noText
                                    size="xl"
                                    thickness="4px"
                                />
                            </Flex>
                        </VStack>
                    </Box>
                </Container>
            </WaterMarkBox>
        </Box>
    );
};

export default Home;
