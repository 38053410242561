import { useMutation } from 'react-query';
import { EnquiryCreateDto } from '../dto';
import { enquiryCreate } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useEnquiryCreate = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        async (dto: EnquiryCreateDto) => enquiryCreate(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useEnquiryCreate;
