import {
    Box,
    VStack,
    Text,
    SimpleGrid,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
    useDisclosure,
    createStandaloneToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    FormikCheckbox,
    FormikCountrySelect,
    FormikForm,
    FormikInput,
} from '../../../common/forms';
import { useEnquiry, useMe } from '../../../utils/api/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import useEnquirySteps from '../common/hooks/useEnquirySteps';
import CancelEnquiryModal from '../common/CancelEnquiryModal';
import { useRole } from '../../../common/hooks';

const validationSchema = Yup.object({
    invoiceFName: Yup.string()
        .required('errors.required'),
    invoiceLName: Yup.string()
        .required('errors.required'),
    invoiceEmail: Yup.string()
        .email('Invalid email address')
        .required('errors.required'),
    invoiceEmailCc: Yup.string()
        .email('Invalid email address'),
    invoiceMobileDialCode: Yup.string()
        .required('errors.required').nullable(),
    invoiceMobile: Yup.number()
        .required('errors.required').typeError('errors.enterNumber'),
});

const EnquiryInvoiceInfo = () => {
    const { t } = useTranslation(['enquiryform', 'common', 'formik']);
    const { toast } = createStandaloneToast();
    const { onContinue, onBack } = useEnquirySteps();
    const { enquiryId = '' } = useParams();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { userIsValuer } = useRole();

    const { enquiry, isLoading: isEnquiryLoading, update: updateEnquiry } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => onContinue(),
        onUpdateError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        }),
    });

    const {
        data: user,
        isLoading: isUserLoading,
    } = useMe();

    if (isEnquiryLoading || isUserLoading) {
        <LoadingSpinner
            color="wvwYellow"
            noText
            size="xl"
            thickness="4px"
        />;
    }

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                invoiceFName: enquiry?.invoiceFName || '',
                invoiceLName: enquiry?.invoiceLName || '',
                invoiceEmail: enquiry?.invoiceEmail || '',
                invoiceEmailCc: enquiry?.invoiceEmailCc || '',
                invoiceMobileDialCode: enquiry?.invoiceMobileDialCode || '',
                invoiceMobile: enquiry?.invoiceMobile || '',
            }}
            onSubmit={values => {
                const data = {
                    ...values,
                    id: Number(enquiryId),
                    createdOnBehalfOf: userIsValuer ? values.invoiceEmail : undefined,
                };

                updateEnquiry(data);
            }}
        >
            <VStack
                align="left"
                spacing={4}
                w="100%"
                paddingBlock="1.5rem"
                paddingInline="1rem"
            >
                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    padding="1rem"
                    borderRadius="10"
                >
                    <Flex>
                        <Box>
                            <Text
                                fontWeight="bold"
                                color="wvwGreen"
                            >
                                {t('newEnquiry.invoiceSection.contactDetails.sectionTitle')}
                            </Text>
                            <Text>
                                {t('newEnquiry.invoiceSection.contactDetails.instruction')}
                            </Text>
                        </Box>

                        <Spacer />

                        <Box alignSelf="flex-end">
                            <FormikCheckbox
                                name="invoiceDetails"
                                label={t('newEnquiry.invoiceSection.contactDetails.myDetails')}
                                onChange={(event, field) => {
                                    const newValue = !field?.value;

                                    if (newValue) {
                                        field?.onChange({ target: { name: 'invoiceFName', value: user?.firstName } });
                                        field?.onChange({ target: { name: 'invoiceLName', value: user?.lastName } });
                                        field?.onChange({ target: { name: 'invoiceEmail', value: user?.email } });
                                        field?.onChange({ target: { name: 'invoiceMobileDialCode', value: user?.mobileDialCode } });
                                        field?.onChange({ target: { name: 'invoiceMobile', value: user?.mobile } });
                                    }

                                    if (!newValue) {
                                        field.onChange({ target: { name: 'invoiceFName', value: '' } });
                                        field.onChange({ target: { name: 'invoiceLName', value: '' } });
                                        field.onChange({ target: { name: 'invoiceEmail', value: '' } });
                                        field.onChange({ target: { name: 'invoiceMobileDialCode', value: '' } });
                                        field.onChange({ target: { name: 'invoiceMobile', value: '' } });
                                    }
                                }}
                            />
                        </Box>

                    </Flex>

                    <SimpleGrid columns={2} spacing="6">
                        <FormikInput
                            name="invoiceFName"
                            placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                            type="text"
                        />

                        <FormikInput
                            name="invoiceLName"
                            placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                            type="text"
                        />

                        <FormikInput
                            name="invoiceEmail"
                            placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                            type="email"
                        />

                        <SimpleGrid columns={2} spacing="6">
                            <Box>
                                <FormikCountrySelect
                                    name="invoiceMobileDialCode"
                                    selectDialCode
                                />
                            </Box>

                            <Box>
                                <FormikInput
                                    name="invoiceMobile"
                                    placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                />
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>
                </VStack>

            </VStack>

            <Flex
                minWidth="max-content"
                alignItems="left"
                gap="2"
                w="100%"
                paddingBlock="1rem"
                paddingInline="2rem"
            >
                <ButtonGroup gap="2">
                    <Button
                        variant="outline"
                        color="wvwGreen"
                        onClick={() => onBack()}
                    >
                        {t('button.back', { ns: 'common' })}
                    </Button>
                </ButtonGroup>

                <Spacer />

                <ButtonGroup>
                    <Button
                        onClick={onOpen}
                        variant="danger"
                    >
                        {t('button.cancel', { ns: 'common' })}
                    </Button>

                    <Button
                        type="submit"
                        variant="primary"
                    >
                        {t('button.next', { ns: 'common' })}
                    </Button>
                </ButtonGroup>
            </Flex>

            <CancelEnquiryModal
                isOpen={isOpen}
                onCancel={onClose}
            />
        </FormikForm>
    );
};

export default EnquiryInvoiceInfo;
