/* eslint-disable react/jsx-props-no-spreading */
import {
    CheckboxGroup,
    Text,
    Checkbox,
    // Stack,
    // Flex,
    SimpleGrid,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string,
    direction?: 'row' | 'column',
    onChange?: (value: string[], field?: FieldInputProps<any>) => void,
    options: {
        label: ReactNode | string;
        value: string,
    }[],
};

const FormikCheckboxGroup = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        options,
        // direction,
        name,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <CheckboxGroup
            {...field}
            {...props}
            onChange={(value: string[]) => {
                field.onChange({ target: { name, value } });

                onChange?.(value, field);
            }}
        >
            <SimpleGrid
                minChildWidth="12rem"
                spacing={2}
            >
                {options.map(({ value, label }) => (
                    <Checkbox
                        key={value}
                        value={value}
                        paddingBlock=".3rem"
                        alignItems="top"
                    >
                        {label}
                    </Checkbox>
                ))}
            </SimpleGrid>

            {
                meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )
            }
        </CheckboxGroup>

    );
};

FormikCheckboxGroup.defaultProps = {
    direction: '',
    onChange: () => { },
};

export default FormikCheckboxGroup;
