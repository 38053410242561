const specialPropertyTypes = [
    'Fishing Lake',
    'Pharmacy',
    'Doctorial',
    'Mining and Mineral',
    'Datacentre',
    'Electric Plant',
    'Leisure Facilities/ Caravan Park/ Cabin',
    'Bed and breakfast',
    'Health and Fitness',
    'Golf',
    'Trade Retail/ Post Office/ Hot Food Shop',
    'Hotel',
    'Yacht Harbour',
    'Kindergarten',
    'Cinema',
    'Other Special Real Estate',
];

export default specialPropertyTypes;
