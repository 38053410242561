import {
    Box,
    Container,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { upperCase } from 'lodash';
import { Buildings, Profile } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { EnquiryStepsContextType } from './common/types';
import { useMe, useMyEntity } from '../../utils/api/hooks';
import Footer from '../../common/components/Footer';
import Logo from '../../common/components/Logo';
import LanguageToggleButton from '../../common/components/LanguageToggleButton';
import ProgressTabs from '../../common/components/ProgressTabs';
import SiteLoading from '../../common/components/SiteLoading';
import WaterMarkBox from '../../common/components/WaterMarkBox';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { useRole } from '../../common/hooks';
import AwaitingAccessPage from '../../common/components/AwaitingAccessPage';

const STEPS = [
    'VALUATION',
    'VALUER',
    'PROPERTY_DETAILS',
    'INVOICE_DETAILS',
    'OVERVIEW',
    'SUCCESS',
];

const ClientEnquiryBox = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation(['enquiryform', 'common']);
    let firstStep = pathname.includes('create-client-enquiry/') ? upperCase(pathname.split('-')[3]) : STEPS[0];
    const navigate = useNavigate();
    const { enquiryId = '' } = useParams();

    const {
        userIsApproved,
        userIsClient,
        userIsIndividual,
    } = useRole();

    if (firstStep === 'PROPERTY') {
        firstStep = 'PROPERTY_DETAILS';
    } else if (firstStep === 'INVOICE') {
        firstStep = 'INVOICE_DETAILS';
    }

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const [step, setStep] = useState(firstStep);

    const onContinue = (eId?: number) => {
        const subPath = pathname.split('/')[2] || 'enquiry-valuation-info';

        switch (subPath) {
            case 'enquiry-valuation-info':
                if (userIsIndividual && userIsClient) {
                    navigate(`/create-client-enquiry/enquiry-property-info/${eId}`);
                    setStep(STEPS[2]);
                } else {
                    navigate(`/create-client-enquiry/enquiry-valuer-info/${eId}`);
                    setStep(STEPS[1]);
                }
                break;
            case 'enquiry-valuer-info':
                navigate(`enquiry-property-info/${enquiryId}`);
                setStep(STEPS[2]);
                break;
            case 'enquiry-property-info':
                navigate(`enquiry-invoice-info/${enquiryId}`);
                setStep(STEPS[3]);
                break;
            case 'enquiry-invoice-info':
                navigate(`enquiry-overview-info/${enquiryId}`);
                setStep(STEPS[4]);
                break;
            default:
                break;
        }
    };

    if (entityLoading || userLoading) return <LoadingSpinner />;

    const stepNames = userIsIndividual && userIsClient
        ? STEPS.filter(i => i !== STEPS[1] && i !== STEPS[5]) : STEPS.filter(i => i !== STEPS[5]);

    const onBack = () => {
        const subPath = pathname.split('/')[2] || 'enquiry-valuation-info';
        const index = STEPS.indexOf(step);
        if (index > 0) {
            setStep(STEPS[index - 1]);
        }
        switch (subPath) {
            case 'enquiry-overview-info':
                navigate(`enquiry-invoice-info/${enquiryId}`);
                setStep(STEPS[3]);
                break;
            case 'enquiry-invoice-info':
                navigate(`enquiry-property-info/${enquiryId}`);
                setStep(STEPS[2]);
                break;
            case 'enquiry-property-info':
                if (userIsIndividual && userIsClient) {
                    navigate(`enquiry-valuation-info/${enquiryId}`);
                    setStep(STEPS[0]);
                } else {
                    navigate(`enquiry-valuer-info/${enquiryId}`);
                    setStep(STEPS[1]);
                }
                break;
            case 'enquiry-valuer-info':
                navigate(`enquiry-valuation-info/${enquiryId}`);
                setStep(STEPS[0]);
                break;
            default:
                break;
        }
    };

    if (entityLoading || userLoading) {
        return <SiteLoading />;
    }

    if (!userIsApproved) {
        return (
            <AwaitingAccessPage />
        );
    }

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    marginBlock="3"
                    maxW="container.lg"
                >
                    <VStack
                        align="left"
                        paddingBlock=".8rem"
                        w="100%"
                    >
                        <HStack spacing="2">
                            <Logo />

                            <Heading
                                as="h4"
                                color="white"
                                size="md"
                                textAlign="left"
                            >
                                {t('whoValuesWhat', { ns: 'common' })}
                            </Heading>
                        </HStack>
                    </VStack>

                    <VStack
                        align="left"
                        bg="white"
                        borderRadius="10px"
                        paddingBottom=".5rem"
                        w="100%"
                    >
                        <Flex
                            paddingBottom=".5rem"
                            paddingInline="2.5rem"
                            paddingTop="2rem"
                        >
                            <Box>
                                <Heading>
                                    {t('newEnquiry.sectionTitle')}
                                </Heading>

                                <HStack
                                    paddingTop=".5rem"
                                    spacing="4"
                                >
                                    <HStack>
                                        <Profile color="rgba(250, 202, 22)" />

                                        {user && (
                                            <Text>
                                                {userLoading ? '' : `${user?.firstName} ${user?.lastName}`}
                                            </Text>
                                        )}
                                    </HStack>

                                    <HStack>
                                        <Buildings color="rgba(250, 202, 22)" />

                                        {user && (
                                            <Text>
                                                {entityLoading ? '' : entity?.name}
                                            </Text>
                                        )}
                                    </HStack>
                                </HStack>
                            </Box>

                            <Spacer />

                            <LanguageToggleButton />
                        </Flex>

                        <Box
                            alignSelf="center"
                            w="95%"
                        >
                            <ProgressTabs
                                currentStep={step}
                                steps={stepNames}
                            />
                        </Box>

                        <Box
                            paddingBottom=".5rem"
                            paddingInline="1.5rem"
                        >
                            <Outlet
                                context={{ onBack, onContinue } satisfies EnquiryStepsContextType}
                            />
                        </Box>
                    </VStack>
                </Container>

                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

export default ClientEnquiryBox;
