import {
    Button,
    Divider,
    Heading,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { useMe } from '../../../../utils/api/hooks';
import {
    standardPropertyTypes,
    standardSpecialValuationTypes,
    standardValuationOccasions,
    standardValuationTypes,
    standardValuerMaxOperatingDistance,
    standardValuerMemberships,
} from '../../../../common/vars/valuationsAndMembershipTypes';
import {
    FormikCloudSelect,
    FormikConditionalSection,
    FormikForm,
    FormikListInput,
    FormikListInputCertificateUpload,
    FormikSelect,
} from '../../../../common/forms';
import UploadCertificate from '../../../../common/components/UploadCertificate';
import storage from '../../../../utils/storage/storage';

type PropTypes = {
    onClose: () => void;
};

const validationSchema = Yup.object({
    valuationTypes: Yup.array()
        .min(1, 'Please select at least one'),
});

const ProfileValuationsEdit = (props: PropTypes) => {
    const { t } = useTranslation(['profile', 'common']);

    const { onClose } = props;

    const initialLoading: { [index: string]: boolean } = [] as any;

    standardValuerMemberships.forEach(membership => {
        initialLoading[membership] = false;
    });

    const [loadingUploads, setLoadingUploads] = useState(initialLoading);

    const {
        data: user,
        isLoading: loadingUser,
        myCertificates = [],
        myCertificatesIsLoading,
        myCertificatesRefresh,
        updateMe,
    } = useMe();

    if (loadingUser || myCertificatesIsLoading) {
        return <LoadingSpinner />;
    }

    return (
        <FormikForm
            initialValues={{
                maxOperatingDistance: user?.maxOperatingDistance || 0,
                personalMemberships: user.standardMemberships
                    ?.filter(i => standardValuerMemberships.includes(i)) || [],
                otherMemberships: user.otherMemberships || [],
                valuationTypes: user?.valuationTypes
                    ?.filter(i => standardValuationTypes.includes(i)) || [],
                specialValuationTypes: user?.specialValuationTypes || [],
                otherValuationTypes: user?.otherValuationTypes || [],
                valuationOccasions: user?.valuationOccasions || [],
                otherValuationOccasions: user?.otherValuationOccasions || [],
                valuationPropertyTypes: user?.valuationPropertyTypes || [],
            }}
            onSubmit={values => {
                updateMe({
                    maxOperatingDistance: Number(values.maxOperatingDistance),
                    standardMemberships: values.personalMemberships,
                    otherMemberships: values.otherMemberships,
                    valuationTypes: values.valuationTypes,
                    valuationPropertyTypes: values.valuationPropertyTypes,
                    specialValuationTypes: values.specialValuationTypes,
                    otherValuationTypes: values.otherValuationTypes,
                    valuationOccasions: values.valuationOccasions,
                    otherValuationOccasions: values.otherValuationOccasions,
                });

                onClose();
            }}
            validationSchema={validationSchema}
        >
            <ModalHeader>
                {t('valuationCompetence.edit.sectionTitle')}
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
                <VStack
                    align="left"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('profileDetails.valuerMaxOperationDistance')}
                    </Heading>

                    <FormikSelect
                        width="100%"
                        name="maxOperatingDistance"
                        options={standardValuerMaxOperatingDistance.map(
                            distance => ({
                                label: t(distance.label, { ns: 'formik', defaultValue: distance.label }),
                                value: distance.value,
                            }),
                        )}
                    />
                </VStack>

                <Divider />

                <VStack
                    align="left"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('valuationCompetence.valuationStandards')}
                    </Heading>

                    <FormikCloudSelect
                        name="personalMemberships"
                        options={standardValuerMemberships.map(membership => ({
                            label: t(membership, { ns: 'formik', defaultValue: membership }),
                            value: membership,
                        }))}
                    />

                    {standardValuerMemberships.map(membership => {
                        const files = myCertificates
                            .filter(
                                i => i.type.toLowerCase() === membership.toLowerCase(),
                            )
                            .map(i => ({
                                name: i.label,
                                fileUrl: i.file.fullPath,
                            }));

                        return (
                            <FormikConditionalSection
                                key={membership}
                                formDataTarget="personalMemberships"
                                condition={value => value?.includes(membership)}
                            >
                                <VStack
                                    align="start"
                                    backgroundColor="wvwGrey05"
                                    borderRadius="8"
                                    padding="1rem"
                                    spacing="4"
                                    w="100%"
                                >
                                    <Text
                                        color="wvwGreen"
                                    >
                                        {`${t(membership, { ns: 'formik', defaultValue: membership })} ${t('membershipsAndQualifications.certificate', { count: 2 })}`}
                                    </Text>

                                    <UploadCertificate
                                        defaultFileName={files.length === 0 ? `${t(membership, { ns: 'formik', defaultValue: membership })} ${t('membershipsAndQualifications.certificate', { count: files.length })}` : undefined}
                                        files={files}
                                        loading={loadingUploads[membership]}
                                        onFileDelete={fileUrl => {
                                            storage.deleteUserFile(fileUrl)
                                                .then(myCertificatesRefresh);
                                        }}
                                        onRename={(fileUrl, label) => {
                                            storage.updateFileMetadata(fileUrl, label)
                                                .then(myCertificatesRefresh);
                                        }}
                                        onUpload={fileList => {
                                            setLoadingUploads({
                                                ...loadingUploads,
                                                [membership]: true,
                                            });

                                            fileList.forEach(file => {
                                                storage.uploadUserCertificate(
                                                    user?.id,
                                                    file,
                                                    membership,
                                                )
                                                    .then(myCertificatesRefresh)
                                                    .finally(() => {
                                                        setLoadingUploads({
                                                            ...loadingUploads,
                                                            [membership]: false,
                                                        });
                                                    });
                                            });
                                        }}
                                    />
                                </VStack>
                            </FormikConditionalSection>
                        );
                    })}

                    <Text color="wvwGreen">
                        {t('valuationCompetence.otherValuationStandards')}
                    </Text>

                    <FormikListInputCertificateUpload
                        name="otherMemberships"
                        placeholder={t('membershipsAndQualifications.edit.addValuationStandard')}
                        uploadedCertificates={myCertificates}
                        onUploadComplete={myCertificatesRefresh}
                    />

                    <Divider />

                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('valuationCompetence.valuationTypes')}
                    </Heading>

                    <FormikCloudSelect
                        name="valuationTypes"
                        options={standardValuationTypes.map(valuationType => ({
                            label: t(valuationType, { ns: 'formik', defaultValue: valuationType }),
                            value: valuationType,
                        }))}
                    />

                    <Text color="wvwGreen">
                        {t('valuationCompetence.specialValuationTypes')}
                    </Text>

                    <FormikCloudSelect
                        name="specialValuationTypes"
                        options={standardSpecialValuationTypes.map(specialValuationType => ({
                            label: t(specialValuationType, { ns: 'formik', defaultValue: specialValuationType }),
                            value: specialValuationType,
                        }))}
                    />

                    <Text color="wvwGreen">
                        {t('valuationCompetence.otherValuationTypes')}
                    </Text>

                    <FormikListInput
                        name="otherValuationTypes"
                        placeholder={t('addValuationType', { ns: 'wizard' })}
                    />

                    <Divider />

                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('valuationCompetence.valuationOccasions')}
                    </Heading>

                    <FormikCloudSelect
                        name="valuationOccasions"
                        options={standardValuationOccasions.map(valuationOccasion => ({
                            label: t(valuationOccasion, { ns: 'formik', defaultValue: valuationOccasion }),
                            value: valuationOccasion,
                        }))}
                    />

                    <Text color="wvwGreen">
                        {t('valuationCompetence.otherValuationOccasions')}
                    </Text>

                    <FormikListInput
                        name="otherValuationOccasions"
                        placeholder={t('addValuationOccasion', { ns: 'wizard' })}
                    />

                    <Divider />

                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('valuationCapabilities.propertyTypes', { ns: 'account' })}
                    </Heading>

                    <FormikCloudSelect
                        name="valuationPropertyTypes"
                        options={standardPropertyTypes.map(propertyType => ({
                            label: t(propertyType, { ns: 'formik', defaultValue: propertyType }),
                            value: propertyType.toUpperCase(),
                        }))}
                    />
                </VStack>
            </ModalBody>

            <ModalFooter>
                <Button
                    type="submit"
                    variant="primary"
                >
                    {t('button.save', { ns: 'common' })}
                </Button>
            </ModalFooter>
        </FormikForm>
    );
};

export default ProfileValuationsEdit;
