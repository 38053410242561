import {
    Heading,
    VStack,
    HStack,
    Text,
    Flex,
    Divider,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Link,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useEntityPublicEntityGetById } from '../../../utils/api/hooks';
import delimitNumber from '../../functions/delimitNumber';
import { displayDateWithCurrentLocale } from '../../functions/displayDateInLocale';
import toTitleCase from '../../functions/toTitleCase';
import toUrl from '../../functions/toUrl';
import LoadingSpinner from '../LoadingSpinner';
import PublicCompanyProfilePicture from '../PublicCompanyProfilePicture';

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
    entityId: number;
};

const PublicEntitySlider = (props: PropTypes) => {
    const { t } = useTranslation(['account', 'common', 'profile']);
    const { isOpen, onClose, entityId } = props;

    const {
        data: entity,
        isLoading: entityLoading,
    } = useEntityPublicEntityGetById(entityId);

    if (entityLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    color="wvwGreen"
                >
                    {t('details', { ns: 'common' })}
                </DrawerHeader>

                <DrawerBody>
                    <VStack
                        w="100%"
                        maxW="container.xl"
                        align="left"
                        spacing="5"
                    >
                        <HStack
                            align="left"
                            spacing="5"
                            alignItems="center"
                        >
                            <>
                                <PublicCompanyProfilePicture
                                    size="8rem"
                                    companyId={entity.id}
                                />

                                <VStack
                                    align="left"
                                    spacing="0"
                                >
                                    <Heading color="wvwGreen">
                                        {entityLoading ? '' : `${entity.name}`}
                                    </Heading>
                                </VStack>
                            </>
                        </HStack>

                        <Box
                            bg="wvwGrey05"
                            borderRadius="10"
                            marginTop="1.5rem"
                            padding="1.5rem 2rem"
                            w="100%"
                        >
                            <Heading
                                as="h4"
                                color="wvwGrey"
                                size="md"
                            >
                                {t('companyDetails.sectionTitle', { ns: 'account' })}
                            </Heading>

                            <VStack
                                paddingTop="1rem"
                            >
                                <Flex
                                    alignItems="center"
                                    h="2rem"
                                    w="100%"
                                >
                                    <Text
                                        color="wvwGreen"
                                        w="100%"
                                        maxW="12rem"
                                    >
                                        {t('companyDetails.website')}
                                    </Text>

                                    {entity?.websiteAddress ? (
                                        <Link
                                            color="wvwGreen"
                                            href={toUrl(entity?.websiteAddress)}
                                            isExternal
                                            textOverflow="elipsis"
                                            noOfLines={1}
                                        >
                                            {entity?.websiteAddress}
                                        </Link>
                                    ) : (
                                        <Text
                                            color="wvwGrey40"
                                        >
                                            {t('fieldNotProvided.website', { ns: 'common' })}
                                        </Text>
                                    )}
                                </Flex>

                                <Divider />

                                <Flex
                                    alignItems="center"
                                    h="2rem"
                                    w="100%"
                                >
                                    <Text
                                        color="wvwGreen"
                                        w="12rem"
                                    >
                                        {t('companyDetails.email')}
                                    </Text>

                                    {entity?.companyEmail ? (
                                        <Text>
                                            {entity?.companyEmail}
                                        </Text>
                                    ) : (

                                        <Text
                                            color="wvwGrey40"
                                        >
                                            {t('fieldNotProvided.email', { ns: 'common' })}
                                        </Text>
                                    )}
                                </Flex>

                                <Divider />

                                <Flex
                                    alignItems="center"
                                    h="2rem"
                                    w="100%"
                                >
                                    <Text
                                        color="wvwGreen"
                                        w="12rem"
                                    >
                                        {t('companyDetails.phoneNumber')}
                                    </Text>

                                    {entity?.contactNumber ? (
                                        <Text>
                                            {`${entity?.contactNumberDialCode} ${entity?.contactNumber}`}
                                        </Text>
                                    ) : (
                                        <Text
                                            color="wvwGrey40"
                                        >
                                            {t('fieldNotProvided.phoneNumber', { ns: 'common' })}
                                        </Text>
                                    )}
                                </Flex>

                                <Divider />

                                <Flex
                                    w="100%"
                                >
                                    <Text
                                        color="wvwGreen"
                                        w="12rem"
                                        alignItems="flex-start"
                                    >
                                        {t('companyDetails.location')}
                                    </Text>

                                    {entity?.street ? (
                                        <VStack
                                            align="left"
                                        >
                                            <Text>
                                                {`${entity?.street}, ${entity?.postalCode}`}
                                            </Text>

                                            <Text>
                                                {`${entity?.city}, ${entity?.country}`}
                                            </Text>
                                        </VStack>
                                    ) : (
                                        <Text
                                            color="wvwGrey40"
                                        >
                                            {t('fieldNotProvided.location', { ns: 'common' })}
                                        </Text>
                                    )}
                                </Flex>
                            </VStack>
                        </Box>

                        {entity?.accountType !== 'CLIENT' && (
                            <Box
                                bg="wvwGrey05"
                                borderRadius="10"
                                marginTop="1.5rem"
                                padding="1.5rem 2rem"
                                w="100%"
                            >
                                <Heading
                                    as="h4"
                                    color="wvwGrey"
                                    size="md"
                                >
                                    {t('valuationCapabilities.sectionTitle')}
                                </Heading>

                                <VStack
                                    paddingTop="1rem"
                                >

                                    <HStack
                                        alignItems="flex-start"
                                        spacing="1"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="12rem"
                                        >
                                            {t('valuationCapabilities.piCover.piCover')}
                                        </Text>

                                        <VStack
                                            align="left"
                                        >
                                            {t('valuationCapabilities.piCover.or', {
                                                coverValue: entity?.piCoverValue && Number(entity.piCoverValue) > 0 ? (`${entity?.piCoverCurrency || '€'} ${delimitNumber(entity?.piCoverValue.toString())}`) : ('-'),
                                                coverPercentage: entity?.piCoverPercentage === 0 || null ? ('-') : (`maximum ${entity?.piCoverPercentage}${t('valuationCapabilities.piCover.p_of_value')},`),
                                                interpolation: { escapeValue: false },
                                            })}

                                            {entity?.piCoverValidityDate ? (
                                                <Text>
                                                    {t('valuationCapabilities.piCover.validUntil', { date: displayDateWithCurrentLocale(entity?.piCoverValidityDate) })}
                                                </Text>
                                            ) : (
                                                <div />
                                            )}
                                        </VStack>

                                    </HStack>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="12rem"
                                        >
                                            {t('valuationCompetence.propertyTypes', { ns: 'profile' })}
                                        </Text>

                                        {entity?.valuationTypes?.length === 0 && (
                                            <Text
                                                color="wvwGrey40"
                                            >
                                                {t('fieldNotProvided.propertyTypes', { ns: 'common' })}
                                            </Text>
                                        )}

                                        <VStack
                                            align="left"
                                        >
                                            {entity?.valuationTypes?.map(propertyType => (
                                                <Text>
                                                    {t(propertyType, { ns: 'formik', defaultValue: toTitleCase(propertyType) })}
                                                </Text>
                                            ))}
                                        </VStack>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="12rem"
                                        >
                                            {t('valuationCapabilities.specialReports')}
                                        </Text>

                                        {entity?.specialValuationTypes?.length === 0 && (
                                            <Text
                                                color="wvwGrey40"
                                            >
                                                {t('fieldNotProvided.specialReports', { ns: 'common' })}
                                            </Text>
                                        )}

                                        <VStack
                                            align="left"
                                        >
                                            {entity?.specialValuationTypes?.map(
                                                specialValuation => (
                                                    <Text>
                                                        {t(specialValuation, { ns: 'formik', defaultValue: toTitleCase(specialValuation) })}
                                                    </Text>
                                                ),
                                            )}
                                        </VStack>
                                    </Flex>
                                </VStack>
                            </Box>
                        )}
                    </VStack>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant="primary" mr={3} onClick={onClose}>
                        {t('button.close', { ns: 'common' })}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default PublicEntitySlider;
