import {
    Box,
    VStack,
    Text,
    SimpleGrid,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    FormikCountrySelect,
    FormikForm,
    FormikInput,
} from '../../common/forms';
import { DirectEnquiryDataType } from './types';

const validationSchema = Yup.object({
    invoiceFName: Yup.string()
        .required('errors.required'),
    invoiceLName: Yup.string()
        .required('errors.required'),
    invoiceEmail: Yup.string()
        .email('Invalid email address')
        .required('errors.required'),
    invoiceEmailCc: Yup.string()
        .email('Invalid email address'),
    invoiceMobileDialCode: Yup.string()
        .required('errors.required'),
    invoiceMobile: Yup.number()
        .required('errors.required').typeError('errors.enterNumber'),
    billingStreet: Yup.string()
        .required('errors.required'),
    billingCity: Yup.string()
        .required('errors.required'),
    billingCountry: Yup.string()
        .required('errors.required'),
    billingPostalCode: Yup.string()
        .required('errors.required'),
});

type PropTypes = {
    onBack: () => void;
    onContinue: (values: any) => void;
    enquiryData: DirectEnquiryDataType;
};

const DirectEnquiryRegistration = (props: PropTypes) => {
    const { t } = useTranslation(['enquiryform', 'common', 'formik']);

    const {
        onBack,
        onContinue,
        enquiryData,
    } = props;

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                invoiceFName: enquiryData.invoiceFName || '',
                invoiceLName: enquiryData.invoiceLName || '',
                invoiceEmail: enquiryData.invoiceEmail || '',
                invoiceEmailCc: enquiryData.invoiceEmailCc || '',
                invoiceMobileDialCode: enquiryData?.invoiceMobileDialCode || '',
                invoiceMobile: enquiryData.invoiceMobileDialCode || '',
                billingStreet: enquiryData.billingStreet || '',
                billingCity: enquiryData.billingCity || '',
                billingCountry: enquiryData.billingCountry || '',
                billingPostalCode: enquiryData.billingPostalCode || '',
            }}
            onSubmit={values => {
                onContinue({
                    invoiceFName: values.invoiceFName,
                    invoiceLName: values.invoiceLName,
                    invoiceEmail: values.invoiceEmail,
                    invoiceEmailCc: values.invoiceEmailCc,
                    invoiceMobileDialCode: values.invoiceMobileDialCode,
                    invoiceMobile: values.invoiceMobile,
                    billingStreet: values.billingStreet,
                    billingCity: values.billingCity,
                    billingCountry: values.billingCountry,
                    billingPostalCode: values.billingPostalCode,
                });
            }}
        >
            <VStack
                align="left"
                spacing={4}
                w="100%"
                paddingBlock="1.5rem"
                paddingInline="1rem"
            >
                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    padding="1rem"
                    borderRadius="10"
                >
                    <Flex
                        w="100%"
                        alignItems="flex-end"
                    >
                        <Box>
                            <Text
                                fontWeight="bold"
                                color="wvwGreen"
                            >
                                {t('newEnquiry.invoiceSection.contactDetails.sectionTitle')}
                            </Text>
                            <Text>
                                {t('newEnquiry.invoiceSection.contactDetails.instruction')}
                            </Text>
                        </Box>

                    </Flex>

                    <SimpleGrid columns={2} spacing="6">
                        <FormikInput
                            name="invoiceFName"
                            placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                            type="text"
                        />

                        <FormikInput
                            name="invoiceLName"
                            placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                            type="text"
                        />
                    </SimpleGrid>

                    <Box>
                        <Text>
                            {t('newEnquiry.invoiceSection.contactDetails.emailClarifier1')}
                        </Text>

                        <Text>
                            {t('newEnquiry.invoiceSection.contactDetails.emailClarifier2')}
                        </Text>
                    </Box>

                    <SimpleGrid columns={2} spacing="6">
                        <FormikInput
                            name="invoiceEmail"
                            placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                            type="email"
                        />

                        <SimpleGrid columns={2} spacing="6">
                            <Box>
                                <FormikCountrySelect
                                    name="invoiceMobileDialCode"
                                    selectDialCode
                                />
                            </Box>

                            <Box>
                                <FormikInput
                                    name="invoiceMobile"
                                    placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                />
                            </Box>
                        </SimpleGrid>
                    </SimpleGrid>

                </VStack>

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    padding="1rem"
                    borderRadius="10"
                >
                    <Box>
                        <Text
                            fontWeight="bold"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.invoiceSection.billingAddress.sectionTitle')}
                        </Text>

                        <Text>
                            {t('newEnquiry.invoiceSection.billingAddress.blurb')}
                        </Text>
                    </Box>

                    <Grid
                        templateRows="repeat(2, 1fr)"
                        templateColumns="repeat(4, 1fr)"
                        gap="6"
                    >
                        <GridItem colSpan={4}>
                            <FormikInput
                                name="billingStreet"
                                placeholder={t('fieldPlaceholder.street', { ns: 'common' })}
                                type="text"
                            />
                        </GridItem>

                        <GridItem colSpan={2}>
                            <Flex w="26.3rem">
                                <Box w="10rem">
                                    <FormikInput
                                        name="billingPostalCode"
                                        placeholder={t('fieldPlaceholder.postalCode', { ns: 'common' })}
                                        type="text"
                                    />
                                </Box>

                                <Spacer />

                                <Box w="15rem">
                                    <FormikInput
                                        name="billingCity"
                                        placeholder={t('fieldPlaceholder.city', { ns: 'common' })}
                                        type="text"
                                    />
                                </Box>
                            </Flex>
                        </GridItem>

                        <GridItem colSpan={2}>
                            <FormikCountrySelect name="billingCountry" />
                        </GridItem>

                    </Grid>
                </VStack>

            </VStack>

            <Flex
                minWidth="max-content"
                alignItems="right"
                gap="2"
                w="100%"
                paddingBlock="1rem"
                paddingInline="2rem"
            >
                <ButtonGroup gap="2">
                    <Button
                        variant="outline"
                        color="wvwGreen"
                        onClick={() => onBack()}
                    >
                        {t('button.back', { ns: 'common' })}
                    </Button>
                </ButtonGroup>

                <Spacer />

                <Button
                    type="submit"
                    variant="primary"
                >
                    {t('button.next', { ns: 'common' })}
                </Button>
            </Flex>
        </FormikForm>
    );
};

export default DirectEnquiryRegistration;
