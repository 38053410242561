const standardValuerMaxOperatingDistance = [
    { label: 'standardValuerMaxOperatingDistance.noLimit', value: 0 },
    { label: 'standardValuerMaxOperatingDistance.to5', value: 5 },
    { label: 'standardValuerMaxOperatingDistance.to10', value: 10 },
    { label: 'standardValuerMaxOperatingDistance.to20', value: 20 },
    { label: 'standardValuerMaxOperatingDistance.to50', value: 50 },
    { label: 'standardValuerMaxOperatingDistance.to100', value: 100 },
];

export default standardValuerMaxOperatingDistance;
