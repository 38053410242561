import { useMutation } from 'react-query';
import { valuerInvitesDelete } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useValuerInvitesDelete = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (inviteId: number | string) => valuerInvitesDelete(inviteId),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useValuerInvitesDelete;
