import { Icon } from '@chakra-ui/react';
import { Location } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import { PropertyType } from '../../../../../types';
import { EventDateTime } from '../../../../../common/components/display';

type PropTypes = {
    title: string;
    eventDate?: Date;
    eventDuration?: number;
    content: string;
    isOpen: boolean;
    property: PropertyType;
    onClose: () => void;
    onConfirm: () => void;
};

const InspectionConfirmationModal = (props: PropTypes) => {
    const {
        title,
        eventDate,
        eventDuration,
        content,
        isOpen,
        property,
        onClose,
        onConfirm,
    } = props;

    const { t } = useTranslation('inspection');

    return (
        <ConfirmationModal
            isOpen={isOpen}
            title={title}
            subTitleIcon={(
                <Icon
                    as={Location}
                    color="wvwGreen"
                    boxSize="5"
                    variant="Bold"
                />
            )}
            subTitle={t('addressOneLiner', {
                street: property?.street,
                city: property?.city,
                country: property?.country,
                postalCode: property?.postalCode,
                ns: 'common',
            })}
            subTitleTwo={(eventDate && eventDuration) && (
                <EventDateTime
                    color="wvwGreen"
                    date={eventDate}
                    duration={eventDuration}
                />
            )}
            content={content}
            onContinue={onConfirm}
            onCancel={onClose}
        />
    );
};

InspectionConfirmationModal.defaultProps = {
    eventDate: undefined,
    eventDuration: undefined,
};

export default InspectionConfirmationModal;
