import {
    VStack,
    HStack,
    Text,
    Flex,
    Divider,
    Box,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    FormikAddressInput,
    FormikCountrySelect,
    FormikForm,
    FormikInput,
    FormikSelect,
} from '../../../../../common/forms';
import ProfilePictureEditor from '../../../../../common/components/ProfilePictureEditor';
import { useMyEntity } from '../../../../../utils/api/hooks';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { useStandards } from '../../../../../common/hooks';
import { WvwButton } from '../../../../../common/components/inputs';

type PropTypes = {
    onComplete: () => void;
};

const CompanyDetailsEdit = (props: PropTypes) => {
    const { onComplete } = props;

    const { t } = useTranslation(['account', 'common', 'formik']);

    const { yearsOfExperience } = useStandards();

    const {
        data: entity,
        isLoading: entityLoading,
        updateEntity,
    } = useMyEntity({
        onUpdateSuccess: () => {
            onComplete();
        },
    });

    const validationSchema = Yup.object({
        name: Yup.string().required('errors.required'),
        contactNumberDialCode: Yup.string().required('errors.required'),
        contactNumber: Yup.number()
            .required('errors.required')
            .typeError('errors.enterNumber'),
        companyEmail: Yup.string()
            .email(t('invalidEmail', { ns: 'account' }))
            .required(t('required', { ns: 'account' })),
        street: Yup.string().required('errors.required'),
        city: Yup.string().required('errors.required'),
        country: Yup.string().required('errors.required'),
        postalCode: Yup.string().required('errors.required'),
        valuerYearsOfExperience: Yup.string().required('errors.required'),
        valuerNumberOfPartners: Yup.number().required('errors.required'),
    });

    if (entityLoading) {
        return <LoadingSpinner />;
    }

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                name: entity?.name || '',
                websiteAddress: entity?.websiteAddress || '',
                companyEmail: entity?.companyEmail || '',
                contactNumberDialCode: entity?.contactNumberDialCode || '',
                contactNumber: entity?.contactNumber || '',
                street: entity?.street || '',
                city: entity?.city || '',
                country: entity?.country || '',
                postalCode: entity?.postalCode || '',
                valuerYearsOfExperience: entity?.valuerYearsOfExperience || '',
                valuerNumberOfPartners: entity?.valuerNumberOfPartners || '',
            }}
            onSubmit={values => {
                updateEntity({
                    name: values.name,
                    websiteAddress: values.websiteAddress,
                    companyEmail: values.companyEmail,
                    contactNumberDialCode: values.contactNumberDialCode,
                    contactNumber: values.contactNumber,
                    street: values.street,
                    city: values.city,
                    country: values.country,
                    postalCode: values.postalCode,
                    valuerYearsOfExperience: values.valuerYearsOfExperience,
                    valuerNumberOfPartners: values.valuerNumberOfPartners.toString(),
                });
            }}
        >
            <Box
                bg="wvwGrey05"
                borderRadius="10"
                marginTop="1.5rem"
                padding="1.5rem 2rem"
                w="100%"
            >
                <VStack
                    paddingTop="1rem"
                >
                    <Flex
                        w="100%"
                        alignItems="center"
                    >
                        <Box
                            w="100%"
                            maxW="17rem"
                        >
                            <ProfilePictureEditor forEntity />
                        </Box>

                        <Box w="100%">
                            <Text color="wvwGreen" paddingBottom=".5rem">
                                {t('companyDetails.name')}
                            </Text>

                            <FormikInput
                                name="name"
                                placeholder={t('fieldPlaceholder.companyName', { ns: 'common' })}
                                type="text"
                            />
                        </Box>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.website')}
                        </Text>

                        <FormikInput
                            name="websiteAddress"
                            placeholder={t('fieldPlaceholder.url', { ns: 'common' })}
                            type="text"
                        />
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.yearsOfExperience')}
                        </Text>

                        <Box
                            w="100%"
                        >
                            <FormikSelect
                                name="valuerYearsOfExperience"
                                placeholder={t('fieldPlaceholder.numberOfYears', { ns: 'common' })}
                                options={yearsOfExperience}
                            />
                        </Box>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.numberOfPartners')}
                        </Text>

                        <FormikInput
                            name="valuerNumberOfPartners"
                            type="number"
                        />
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.email')}
                        </Text>

                        <FormikInput
                            name="companyEmail"
                            placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                            type="email"
                        />
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            w="100%"
                        >
                            {t('companyDetails.phoneNumber')}
                        </Text>

                        <HStack
                            alignItems="baseline"
                            spacing="2"
                            w="100%"
                        >
                            <FormikCountrySelect
                                name="contactNumberDialCode"
                                selectDialCode
                            />

                            <FormikInput
                                name="contactNumber"
                                placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                            />
                        </HStack>
                    </Flex>

                    <Divider />

                    <Flex w="100%">
                        <Text
                            color="wvwGreen"
                            maxW="17rem"
                            mt="0.7rem"
                            w="100%"
                        >
                            {t('companyDetails.location')}
                        </Text>

                        <FormikAddressInput
                            streetName="street"
                            cityName="city"
                            countryName="country"
                            postalCodeName="postalCode"
                        />
                    </Flex>

                    <Divider />

                    <HStack
                        w="100%"
                        justifyContent="flex-end"
                    >
                        <WvwButton
                            content={t('button.cancel', { ns: 'common' })}
                            onClick={onComplete}
                            variant="danger"
                        />

                        <WvwButton
                            content={t('button.done', { ns: 'common' })}
                            formSubmitButton
                            onClick={() => {}}
                        />
                    </HStack>
                </VStack>
            </Box>
        </FormikForm>
    );
};

export default CompanyDetailsEdit;
