import {
    Box,
    Container,
    Heading,
    VStack,
    HStack,
    Flex,
    Spacer,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../common/components/Footer';
import LanguageToggleButton from '../../../common/components/LanguageToggleButton';
import Logo from '../../../common/components/Logo';
import ProgressTabs from '../../../common/components/ProgressTabs';
import WaterMarkBox from '../../../common/components/WaterMarkBox';

type PropTypes = {
    children: ReactNode | ReactNode[];
};

const steps = [
    'account-details',
];

const RegisterFormsContainer = (props: PropTypes) => {
    const { t } = useTranslation(['account', 'common']);

    const {
        children,
    } = props;

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    marginBlock="3"
                    minW="max"
                >
                    <VStack
                        align="left"
                        spacing="4"
                        w="100%"
                    >
                        <HStack spacing="2">
                            <Logo />

                            <Heading
                                as="h4"
                                color="white"
                                size="md"
                                textAlign="left"
                            >
                                {t('whoValuesWhat', { ns: 'common' })}
                            </Heading>
                        </HStack>

                        <Box
                            bg="white"
                            borderRadius="lg"
                            borderWidth="1px"
                            p="2em"
                            w="100%"
                        >
                            <VStack
                                align="start"
                                spacing="7"
                            >
                                <Flex w="100%">
                                    <Heading
                                        as="h4"
                                        size="md"
                                        textAlign="left"
                                    >
                                        {t('setupAccount')}
                                    </Heading>

                                    <Spacer />

                                    <LanguageToggleButton />
                                </Flex>

                                <ProgressTabs
                                    currentStep={steps[0]}
                                    steps={steps}
                                />

                                {children}
                            </VStack>
                        </Box>
                    </VStack>
                </Container>

                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

export default RegisterFormsContainer;
