import { useTranslation } from 'react-i18next';
import { resources } from '../../i18n';

type NamespaceTypes = keyof typeof resources.en;

const useSmartTranslation = (defaultNamespace?: NamespaceTypes) => {
    const { t: translate } = useTranslation(defaultNamespace);

    return translate;
};

export default useSmartTranslation;
