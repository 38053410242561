import { useQuery, useQueryClient } from 'react-query';
import { entityMyValuerPanels } from '../endpoints';

const ENTITY_MY_VALUER_PANELS = 'ENTITY_MY_VALUER_PANELS';

const useMyValuerPanels = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([ENTITY_MY_VALUER_PANELS], () => entityMyValuerPanels());

    const refresh = () => {
        queryClient.invalidateQueries(ENTITY_MY_VALUER_PANELS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useMyValuerPanels;
