import { VStack } from '@chakra-ui/react';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import PersonalProfileDetails from './PersonalProfileDetails';
import ProfileCertificates from './ProfileCertificates';
import { useRole } from '../../../../common/hooks';

const ProfileDetails = () => {
    const { userIsValuer, roleLoading } = useRole();

    if (roleLoading) {
        return <LoadingSpinner />;
    }

    return (
        <VStack
            w="100%"
            spacing="1.5rem"
            paddingBottom="2rem"
        >
            <PersonalProfileDetails />

            {userIsValuer && (
                <ProfileCertificates />
            )}
        </VStack>
    );
};

export default ProfileDetails;
