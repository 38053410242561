import { Button, HStack, Text } from '@chakra-ui/react';
import { DocumentDownload } from 'iconsax-react';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    downloadUrls: string[];
};

const DownloadAllFiles = (props: PropTypes) => {
    const { downloadUrls } = props;

    const { t } = useTranslation(['common']);

    const downloadZip = (blob: Blob) => {
        // Create download link for zip file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'download.zip';

        link.style.display = 'none';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(link.href);
    };

    const handleDownloadAll = async () => {
        // Fetch download urls from firebase and create array of promises
        const promises = downloadUrls.map(async url => {
            const result = await fetch(url, { method: 'GET', mode: 'no-cors' });
            const blob = await result.blob();
            return blob;
        });

        const result = await Promise.all(promises);

        // Create zip file and add files to it
        const zip = new JSZip();

        result.forEach((blob, index) => {
            const fileType = blob.type.split('/')[1];
            zip.file(`file${index}.${fileType}`, blob);
        });

        const readme = zip.folder('readme');
        readme?.file('readme.txt', 'Created with JSZip');

        const zipFile = await zip.generateAsync({ type: 'blob' });

        // Download zip file
        downloadZip(zipFile);
    };

    return (
        <Button
            onClick={handleDownloadAll}
            variant="none"
            color="wvwGreen"
        >
            <HStack>
                <DocumentDownload />

                <Text fontWeight="400">
                    {t('button.downloadAll', { ns: 'common' })}
                </Text>
            </HStack>
        </Button>
    );
};

export default DownloadAllFiles;
