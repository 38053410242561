import { useMutation } from 'react-query';
import { documentRequestMarkDocumentCommentAsRead } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';
import useDocumentRequestGetDocument from './useDocumentRequestGetDocument';

type ParamsType = {
    documentId: string | number;
    onSuccess?: () => void;
    onError?: (error?: Error) => void;
};

const useDocumentRequestMarkDocumentCommentsAsRead = (params: ParamsType) => {
    const {
        documentId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();
    const { refresh: refreshComments } = useDocumentRequestGetDocument(documentId);

    const { mutate: update } = useMutation(
        () => documentRequestMarkDocumentCommentAsRead(documentId),
        {
            onSuccess: () => {
                refreshComments();
                onSuccess?.();
            },
            onError: (err: Error) => {
                createErrorToast(t(err.message, { defaultValue: err.message }));
                onError?.(err);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestMarkDocumentCommentsAsRead;
