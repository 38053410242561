import {
    VStack,
    Flex,
    Select,
    Grid,
    ButtonGroup,
    GridItem,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
    CloseCircle,
    Edit,
    Filter,
    FolderAdd,
} from 'iconsax-react';
import NewFolderBox from './NewFolderBox';
import { useRole, useSmartTranslation, useToast } from '../../../../../common/hooks';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import { useDocumentRequestGetFolders, useDocumentRequestSubmitAllRequests } from '../../../../../utils/api/hooks';
import { DocumentRequestType } from '../../../../../types';
import Requests from '../requests/Requests';
import Documents from '../documents/Documents';
import Folders from '../folders/Folders';
import {
    VIEW_FILTER,
    ViewFiltersType,
    RequestFiltersType,
    DocumentFiltersType,
    REQUEST_FILTER,
    DOCUMENT_FILTER,
} from './documentManagementAreaTypes';
import { WvwButton } from '../../../../../common/components/inputs';
import { WvwText } from '../../../../../common/components/typography';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';

const FOLDER_LEVEL_LIST = [
    {
        labelTranslationKey: 'folders',
        level: VIEW_FILTER.FOLDERS,
    },
    {
        labelTranslationKey: 'requests',
        level: VIEW_FILTER.REQUESTS,
    },
    {
        labelTranslationKey: 'documents',
        level: VIEW_FILTER.DOCUMENTS,
    },
];

const REQUEST_FILTER_OPTIONS = [
    {
        labelTranslationKey: 'allRequests',
        value: REQUEST_FILTER.ALL,
    },
    {
        labelTranslationKey: 'statuses.approved',
        value: REQUEST_FILTER.APPROVED,
    },
    {
        labelTranslationKey: 'statuses.rejected',
        value: REQUEST_FILTER.REJECTED,
    },
    {
        labelTranslationKey: 'statuses.submitted',
        value: REQUEST_FILTER.SUBMITTED,
    },
    {
        labelTranslationKey: 'statuses.pending',
        value: REQUEST_FILTER.PENDING,
    },
];

const DOCUMENT_FILTER_OPTIONS = [
    {
        labelTranslationKey: 'allDocuments',
        value: DOCUMENT_FILTER.ALL,
    },
    {
        labelTranslationKey: 'statuses.accepted',
        value: DOCUMENT_FILTER.ACCEPTED,
    },
    {
        labelTranslationKey: 'statuses.rejected',
        value: DOCUMENT_FILTER.REJECTED,
    },
    {
        labelTranslationKey: 'statuses.submitted',
        value: DOCUMENT_FILTER.SUBMITTED,
    },
    {
        labelTranslationKey: 'statuses.pending',
        value: DOCUMENT_FILTER.PENDING,
    },
];

const DocumentManagementArea = () => {
    const { caseId } = useParams<{ caseId: string }>();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();
    const { userIsClient, userIsValuer } = useRole();

    const [viewLevel, setViewLevel] = useState<ViewFiltersType>(VIEW_FILTER.FOLDERS);
    const [requestFilter, setRequestFilter] = useState<RequestFiltersType>(REQUEST_FILTER.ALL);
    const [documentFilter, setDocumentFilter] = useState<DocumentFiltersType>(DOCUMENT_FILTER.ALL);

    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [openFolderCreate, setOpenFolderCreate] = useState<boolean>(false);
    const [submitWarning, setSubmitWarning] = useState(false);

    const {
        data: folders = [],
        isLoading: foldersLoading,
    } = useDocumentRequestGetFolders({
        caseId: Number(caseId),
        requestStatus: requestFilter === 'ALL' ? undefined : requestFilter,
        documentStatus: documentFilter === 'ALL' ? undefined : documentFilter,
    });

    const {
        update: submitAllRequests,
    } = useDocumentRequestSubmitAllRequests({
        caseId: Number(caseId),
        onSuccess: () => {
            setSubmitWarning(false);
        },
        onError: error => createErrorToast(error.message),
    });

    const renderView = () => {
        switch (viewLevel) {
            case VIEW_FILTER.FOLDERS:
                return (
                    <Folders
                        inEditMode={inEditMode}
                        documentFilter={documentFilter}
                        requestFilter={requestFilter}
                    />
                );
            case VIEW_FILTER.REQUESTS:
                return (
                    <Requests
                        inEditMode={inEditMode}
                        documentFilter={documentFilter}
                        requestFilter={requestFilter}
                    />
                );
            case VIEW_FILTER.DOCUMENTS:
                return (
                    <Documents
                        inEditMode={inEditMode}
                        documentFilter={documentFilter}
                    />
                );
            default:
                return null;
        }
    };

    if (foldersLoading) return <LoadingSpinner />;

    const allRequests = folders.reduce(
        (acc, folder) => acc.concat(folder.requests),
        [] as DocumentRequestType[],
    );

    const isClientAndHasPending = userIsClient && allRequests.filter(r => r.status === 'PENDING').length > 0;

    return (
        <>
            <VStack
                align="stretch"
                w="70%"
                spacing="0"
                paddingLeft="1.5rem"
            >
                <Flex
                    w="100%"
                    align="center"
                    mb=".5rem"
                >
                    <Filter
                        color="#016064"
                        size="1.3rem"
                    />

                    <Select
                        marginLeft=".5rem"
                        maxW="fit-content"
                        defaultValue={viewLevel}
                        onChange={e => setViewLevel(VIEW_FILTER[e.target.value])}
                    >
                        {FOLDER_LEVEL_LIST.map(i => (
                            <option
                                key={i.level}
                                value={i.level}
                            >
                                {t(i.labelTranslationKey, { ns: 'documentmanagement', defaultValue: i.labelTranslationKey })}
                            </option>
                        ))}
                    </Select>

                    <Select
                        maxW="fit-content"
                        pl="1rem"
                        value={requestFilter}
                        onChange={e => setRequestFilter(REQUEST_FILTER[e.target.value])}
                    >
                        {REQUEST_FILTER_OPTIONS.map(i => (
                            <option
                                key={i.value}
                                value={i.value}
                            >
                                {t(i.labelTranslationKey, { ns: 'documentmanagement', defaultValue: i.labelTranslationKey })}
                            </option>
                        ))}
                    </Select>

                    <Select
                        maxW="fit-content"
                        pl="1rem"
                        value={documentFilter}
                        onChange={e => setDocumentFilter(DOCUMENT_FILTER[e.target.value])}
                    >
                        {DOCUMENT_FILTER_OPTIONS.map(i => (
                            <option
                                key={i.value}
                                value={i.value}
                            >
                                {t(i.labelTranslationKey, { ns: 'documentmanagement', defaultValue: i.labelTranslationKey })}
                            </option>
                        ))}
                    </Select>

                    {userIsValuer && (
                        <ButtonGroup
                            spacing="1rem"
                            ml="auto"
                        >
                            <WvwButton
                                content={inEditMode ? t('endEditMode') : t('button.edit', { ns: 'common' })}
                                icon={inEditMode ? <CloseCircle /> : <Edit />}
                                onClick={() => setInEditMode(!inEditMode)}
                                variant="link"
                            />

                            <WvwButton
                                content={t('newFolder')}
                                icon={<FolderAdd />}
                                onClick={() => setOpenFolderCreate(true)}
                                variant="link"
                            />
                        </ButtonGroup>
                    )}
                </Flex>

                <Grid
                    bg="wvwGreen"
                    color="white"
                    borderRadius="10"
                    fontWeight="500"
                    pl="2.7rem"
                    alignItems="center"
                    maxW="100%"
                    paddingBlock=".5rem"
                    templateColumns="repeat(4, 1fr)"
                    w="100%"
                >
                    <GridItem minW="18rem">
                        <WvwText color="white">
                            {t('title', { ns: 'documentmanagement' })}
                        </WvwText>
                    </GridItem>

                    <GridItem
                        justifySelf="center"
                        w="9.5rem"
                    >
                        <WvwText color="white">
                            {t('status', { ns: 'documentmanagement' })}
                        </WvwText>
                    </GridItem>

                    <GridItem
                        w="11rem"
                    >
                        <WvwText color="white">
                            {t('lastUpdated')}
                        </WvwText>
                    </GridItem>
                </Grid>

                {openFolderCreate && (
                    <NewFolderBox closeEditor={() => setOpenFolderCreate(false)} />
                )}

                {renderView()}

                {isClientAndHasPending && (
                    <Flex justifyContent="flex-end">
                        <WvwButton
                            content={t('button.submit', { ns: 'common' })}
                            onClick={() => setSubmitWarning(true)}
                        />
                    </Flex>
                )}

                <ConfirmationModal
                    isOpen={submitWarning}
                    onCancel={() => setSubmitWarning(false)}
                    title={t('modal.title.submitDocuments')}
                    content={t('modal.message.submitDocuments')}
                    onContinue={() => submitAllRequests()}
                />
            </VStack>

            <Outlet />
        </>
    );
};

export default DocumentManagementArea;
