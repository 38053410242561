import { Flex } from '@chakra-ui/react';
import { ArrowRight } from 'iconsax-react';
import ActionArea from './common/ActionAreaContainer';
import VerticalDividerWithIcon from '../../../../common/components/VerticalDivider';
import DocumentManagementArea from './common/DocumentManagementArea';

const CaseDocuments = () => (
    <Flex
        w="100%"
        h="100%"
        bg="white"
        align="stretch"
        borderRadius="10"
        paddingBlock="1rem"
        paddingInline="1.5rem"
    >
        <ActionArea />

        <VerticalDividerWithIcon
            icon={ArrowRight}
        />

        <DocumentManagementArea />
    </Flex>
);

export default CaseDocuments;
