import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import toTitleCase from '../functions/toTitleCase';

type PropTypes = {
    currentStep: string;
    steps: string[];
};

const ProgressTabs = (props: PropTypes) => {
    const { currentStep, steps } = props;

    const currentIndex = steps.findIndex(i => i === currentStep) || 0;

    const { t } = useTranslation('common');

    return (
        <Tabs
            w="100%"
            index={currentIndex}
            variant="unstyled"
            isFitted
        >
            <TabList>
                { steps.map((step, index) => (
                    <Tab
                        key={step}
                        cursor="default"
                        color={index < currentIndex ? 'wvwGreen' : 'wvwGrey60'}
                        borderBottom={index < currentIndex ? '2px solid #45687e' : '2px solid #D8D8D9'}
                        _selected={
                            {
                                color: 'green', borderBottom: '2px solid #2dcd7a', fontWeight: 'bold',
                            }
                        }
                    >
                        {t(`processStep.${step}`, { defaultValue: toTitleCase(step) })}
                    </Tab>
                )) }
            </TabList>
        </Tabs>
    );
};

export default ProgressTabs;
