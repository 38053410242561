import {
    VStack,
    Input,
    Grid,
    GridItem,
    Box,
    Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { CountrySelect } from '../inputs';

type PropTypes = {
    cityName: string;
    countryName: string;
    streetName: string;
    postalCodeName: string;
};

const FormikAddressInput = (props: PropTypes) => {
    const {
        cityName,
        countryName,
        streetName,
        postalCodeName,
    } = props;

    const { t } = useTranslation();

    const [streetField, streetMeta] = useField(streetName);
    const [postalCodeField, postalCodeMeta] = useField(postalCodeName);
    const [cityField, cityMeta] = useField(cityName);
    const [countryField, countryMeta] = useField(countryName);

    return (
        <VStack
            alignItems="stretch"
            spacing="3"
            w="100%"
        >
            <Box>
                <Input
                    bg="white"
                    h="3rem"
                    onChange={e => {
                        streetField.onChange({
                            target: {
                                name: streetName,
                                value: e.target.value,
                            },
                        });
                    }}
                    placeholder={t('fieldPlaceholder.street', { ns: 'common' })}
                    value={streetField.value}
                    w="100%"
                />

                {streetMeta.error && streetMeta.touched && (
                    <Text color="red">
                        {t(
                            streetMeta.error,
                            {
                                defaultValue: streetMeta.error,
                                ns: 'formik',
                            },
                        )}
                    </Text>
                )}
            </Box>

            <Grid
                gap="3"
                templateColumns="repeat(4, 1fr)"
                w="100%"
            >
                <GridItem colSpan={1}>
                    <Box>
                        <Input
                            bg="white"
                            h="3rem"
                            onChange={e => {
                                postalCodeField.onChange({
                                    target: {
                                        name: postalCodeName,
                                        value: e.target.value,
                                    },
                                });
                            }}
                            placeholder={t('fieldPlaceholder.postalCode', { ns: 'common' })}
                            value={postalCodeField.value}
                            w="100%"
                        />

                        {postalCodeMeta.error && postalCodeMeta.touched && (
                            <Text color="red">
                                {t(
                                    postalCodeMeta.error,
                                    {
                                        defaultValue: postalCodeMeta.error,
                                        ns: 'formik',
                                    },
                                )}
                            </Text>
                        )}
                    </Box>
                </GridItem>

                <GridItem colSpan={1}>
                    <Box>
                        <Input
                            bg="white"
                            h="3rem"
                            onChange={e => {
                                cityField.onChange({
                                    target: {
                                        name: cityName,
                                        value: e.target.value,
                                    },
                                });
                            }}
                            placeholder={t('fieldPlaceholder.city', { ns: 'common' })}
                            value={cityField.value}
                            w="100%"
                        />

                        {cityMeta.error && cityMeta.touched && (
                            <Text color="red">
                                {t(
                                    cityMeta.error,
                                    {
                                        defaultValue: cityMeta.error,
                                        ns: 'formik',
                                    },
                                )}
                            </Text>
                        )}
                    </Box>
                </GridItem>

                <GridItem colSpan={2}>
                    <Box>
                        <CountrySelect
                            name="country"
                            onChange={e => {
                                countryField.onChange({
                                    target: {
                                        name: countryName,
                                        value: e.target.value,
                                    },
                                });
                            }}
                            placeholder={t('fieldPlaceholder.country', { ns: 'common' })}
                            value={countryField.value}
                        />

                        {countryMeta.error && countryMeta.touched && (
                            <Text color="red">
                                {t(
                                    countryMeta.error,
                                    {
                                        defaultValue: countryMeta.error,
                                        ns: 'formik',
                                    },
                                )}
                            </Text>
                        )}
                    </Box>
                </GridItem>
            </Grid>
        </VStack>
    );
};

export default FormikAddressInput;
