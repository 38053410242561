const standardPropertyTypes = [
    'Residential',
    'Commercial',
    'Land',
    'Agricultural',
    'Development',
    'Special',
];

export default standardPropertyTypes;
