import {
    VStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { DocumentRequestDocumentType } from '../../../../../types';
import DocumentOverviewItem from './common/DocumentOverviewItem';
import { useDocumentRequestGetCaseDocuments } from '../../../../../utils/api/hooks';
import { DocumentFiltersType } from '../common/documentManagementAreaTypes';

type PropTypes = {
    inEditMode: boolean;
    requestId?: number | string;
    documentFilter: DocumentFiltersType;
};

const Documents = (props: PropTypes) => {
    const { inEditMode, documentFilter, requestId } = props;

    const { caseId = '' } = useParams<{ caseId: string }>();

    const {
        data: documents,
    } = useDocumentRequestGetCaseDocuments({
        caseId,
        requestId,
        documentStatus: documentFilter === 'ALL' ? undefined : documentFilter,
    });

    return (
        <VStack w="100%">
            {documents?.map((document: DocumentRequestDocumentType) => (
                <DocumentOverviewItem
                    key={document.id}
                    document={document}
                    inEditMode={inEditMode}
                />
            ))}
        </VStack>
    );
};

Documents.defaultProps = {
    requestId: undefined,
};

export default Documents;
