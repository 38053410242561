import { Heading } from '@chakra-ui/react';

type PropTypes = {
    black?: boolean;
    content: string;
    level: '1' | '2' | '3';
    normalFontWeight?: boolean;
};

const WVWTitle = (props: PropTypes) => {
    const {
        black,
        content,
        level,
        normalFontWeight,
    } = props;

    let size = 'xl';

    if (level === '2') {
        size = 'md';
    } else if (level === '3') {
        size = 'sm';
    }

    return (
        <Heading
            color={black ? undefined : 'wvwGreen'}
            fontWeight={normalFontWeight ? 'normal' : 'bold'}
            overflow="hidden"
            size={size}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
        >
            {content}
        </Heading>
    );
};

WVWTitle.defaultProps = {
    black: false,
    normalFontWeight: false,
};

export default WVWTitle;
