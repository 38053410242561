import { useTranslation } from 'react-i18next';
import WvwAlertBanner from './WvwAlertBanner';

const AccountAccessDeniedAlert = () => {
    const { t } = useTranslation('common');

    return (
        <WvwAlertBanner
            message={t('accountDoesNotHaveAccessToFeature')}
            isMandatory
        />
    );
};

export default AccountAccessDeniedAlert;
