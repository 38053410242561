import { logout } from '../../firebase';

const goToLogin = () => {
    const { pathname } = window.location;

    if (pathname.includes('direct-enquiry-form')) return;
    if (pathname.includes('login')) return;
    if (pathname.includes('language-redirect')) return;
    if (pathname.includes('register')) return;
    if (pathname.includes('reset-password')) return;
    if (pathname.includes('forgot-password')) return;
    if (pathname.includes('report-issue')) return;
    if (pathname.includes('contact-us')) return;

    logout();

    const pName = encodeURIComponent(pathname);

    if (pName !== '%2F') {
        window.location.href = `/login/${pName}`;
        return;
    }

    window.location.href = '/login';
};

export default goToLogin;
