import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { documentRequestMarkDocumentRequestCommentAsRead } from '../../endpoints';
import useToast from '../../../../common/hooks/useToast';
import useDocumentRequestGetComments from './useDocumentRequestGetComments';

type ParamsType = {
    requestId: number | string;
    onSuccess?: () => void;
    onError?: (error?: Error) => void;
};

const useDocumentRequestMarkDocumentRequestCommentAsRead = (params: ParamsType) => {
    const {
        requestId,
        onSuccess,
        onError,
    } = params;

    const { t } = useTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const { refresh } = useDocumentRequestGetComments(requestId);

    const { mutate: update } = useMutation(
        () => documentRequestMarkDocumentRequestCommentAsRead(requestId),
        {
            onSuccess: () => {
                refresh();
                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));

                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestMarkDocumentRequestCommentAsRead;
