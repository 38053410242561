import {
    VStack,
    Heading,
    Text,
    Box,
    Button,
    ButtonGroup,
    useDisclosure,
    createStandaloneToast,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    standardSpecialValuationTypes,
    standardValuationOccasions,
    standardValuationTypes,
    enquiryCommonValuerMemberships,
    enquiryOtherValuerMemberships,
} from '../../../common/vars/valuationsAndMembershipTypes';
import {
    FormikCheckboxGroup,
    FormikCloudSelect,
    FormikCloudSelectExpandable,
    FormikConditionalSection,
    FormikFileUpload,
    FormikForm,
    FormikInput,
    FormikRadioCloud,
    FormikTextarea,
} from '../../../common/forms';
import { useEnquiry, useEnquiryCreate, useMyEntity } from '../../../utils/api/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import useEnquirySteps from '../common/hooks/useEnquirySteps';
import CancelEnquiryModal from '../common/CancelEnquiryModal';
import { useRole } from '../../../common/hooks';

const baseValiadtionSchema = {
    valuationType: Yup.string().required('errors.selectValuationType'),
    valuationPurposes: Yup.array()
        .min(1, 'errors.selectValuationPurpose')
        .required('errors.selectValuationPurpose'),
    completionDate: Yup.string().required('errors.selectCompletionDate'),
};

const individualValidationSchema = Yup.object(baseValiadtionSchema);

const entityValidationSchema = Yup.object({
    ...baseValiadtionSchema,
    valuationStandards: Yup.string().required('errors.selectValuationStandard'),
});

const EnquiryValuationInfo = () => {
    const { t } = useTranslation(['enquiryform', 'formik', 'common']);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { onContinue } = useEnquirySteps();
    const { toast } = createStandaloneToast();
    const { data: entity, isLoading } = useMyEntity();
    const { enquiryId = '' } = useParams();

    const { userIsCompany, userIsIndividual, userIsValuer } = useRole();

    const standardReducedPurposes = standardValuationOccasions.slice(0, 3);
    const standardExpandedPurposes = standardValuationOccasions.slice(
        3,
        standardValuationOccasions.length,
    );

    const { enquiry, isLoading: isEnquiryLoading, update: updateEnquiry } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => onContinue(Number(enquiryId)),
        onUpdateError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
        }),
    });

    const { update: createEnquiry } = useEnquiryCreate({
        onSuccess: data => onContinue(data.data.id),
        onError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        }),
    });

    if (isLoading || isEnquiryLoading) {
        return (
            <LoadingSpinner
                color="wvwYellow"
                noText
                size="xl"
                thickness="4px"
            />
        );
    }

    return (
        <FormikForm
            validationSchema={userIsCompany ? entityValidationSchema : individualValidationSchema}
            initialValues={{
                valuationType: enquiry?.valuationType || '',
                valuationStandards: enquiry?.valuationStandards || '',
                retypeDocuments: enquiry?.retypeDocuments || [],
                valuationPurposes: enquiry?.valuationPurposes || [],
                valuationSpecial: enquiry?.valuationSpecial || [],
                valuationOther: enquiry?.valuationOther || '',
                valuationStandardsOther: enquiry?.valuationStandardsOther || '',
                valuationStandardsExtra: enquiry?.valuationStandardsExtra || '',
                valuationPurposesOther: enquiry?.valuationPurposesOther || '',
                completionDate: enquiry?.completionDate || '',
            }}
            onSubmit={(values: any) => {
                if (enquiryId === '') {
                    const data = {
                        ...values,
                        status: 'DRAFT',
                        properties: [],
                        fromEntityId: entity.id,
                        valuerExperience: '0',
                        valuerMinimumPartners: '0',
                        valuerMaxOfficeDistance: 'none',
                        allowFullMatchmaking: true,
                        valuationStandards: `${values.valuationStandards === 'Other' ? values.valuationStandardsExtra : values.valuationStandards}`,
                        valuationStandardsExtra: `${values.valuationStandards !== 'Other' ? '' : values.valuationStandardsExtra}`,
                    };

                    createEnquiry(data);
                } else {
                    const data = {
                        ...values,
                        id: enquiry?.id,
                        valuationStandards: `${values.valuationStandards === 'Other' ? values.valuationStandardsExtra : values.valuationStandards}`,
                        valuationStandardsExtra: `${values.valuationStandards !== 'Other' ? '' : values.valuationStandardsExtra}`,
                    };

                    updateEnquiry(data);
                }
            }}
        >
            <VStack
                align="left"
                spacing={4}
                w="100%"
                paddingBlock="1.5rem"
                paddingInline="1rem"
            >
                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <VStack
                        w="100%"
                        align="left"
                    >
                        <Heading
                            size="md"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.valuationSection.valuationType.sectionTitle')}
                        </Heading>

                        <Text>
                            {t('newEnquiry.valuationSection.valuationType.leadQuestion')}
                        </Text>

                        <FormikRadioCloud
                            name="valuationType"
                            options={standardValuationTypes.map(type => ({
                                label: t(type, { ns: 'formik', defaultValue: type }),
                                value: type,
                            }))}
                        />
                    </VStack>

                    <FormikConditionalSection
                        formDataTarget="valuationType"
                        condition={x => x.includes('Retype Valuation')}
                    >
                        <VStack
                            align="left"
                            backgroundColor="wvwGrey05"
                            borderRadius="8"
                            padding="1rem"
                        >
                            <Text
                                as="b"
                                color="wvwGreen"
                            >
                                {t('newEnquiry.valuationSection.valuationType.retype.label')}
                            </Text>

                            <Text paddingBottom="1rem">
                                {t('newEnquiry.valuationSection.valuationType.retype.instruction')}
                            </Text>

                            <FormikFileUpload name="retypeDocuments" />
                        </VStack>
                    </FormikConditionalSection>

                    <FormikConditionalSection
                        formDataTarget="valuationType"
                        condition={x => x.includes('Other Valuation')}
                    >
                        <>
                            <VStack
                                align="left"
                                backgroundColor="wvwGrey05"
                                borderRadius="8"
                                padding="1rem"
                            >
                                {/*
                                <Text
                                    as="b"
                                    color="wvwGreen"
                                >
                                    {t('newEnquiry.valuationSection.valuationType.special')}
                                </Text>
                                */}

                                <FormikCheckboxGroup
                                    name="valuationSpecial"
                                    options={standardSpecialValuationTypes.map(type => ({
                                        label: t(type, { ns: 'formik', defaultValue: type }),
                                        value: type,
                                    }))}
                                />
                            </VStack>

                            <VStack
                                align="left"
                                backgroundColor="wvwGrey05"
                                borderRadius="8"
                                padding="1rem"
                            >
                                <Text
                                    as="b"
                                    color="wvwGreen"
                                >
                                    {t('Other Valuation', { ns: 'formik' })}
                                </Text>

                                <Text paddingBottom="1rem">
                                    {t('newEnquiry.valuationSection.valuationType.other.instruction')}
                                </Text>

                                <FormikTextarea
                                    name="valuationOther"
                                />
                            </VStack>
                        </>
                    </FormikConditionalSection>
                </VStack>

                {(userIsCompany || userIsValuer) && (
                    <VStack
                        w="100%"
                        align="left"
                        bg="wvwGrey05"
                        borderRadius="10"
                        padding="1rem"
                    >
                        <Box>
                            <Heading
                                size="md"
                                paddingBottom=".5rem"
                                color="wvwGreen"
                            >
                                {t('newEnquiry.valuationSection.valuationStandards.sectionTitle')}
                            </Heading>

                            <Text>
                                {t('newEnquiry.valuationSection.valuationStandards.leadQuestion')}
                            </Text>
                        </Box>

                        <FormikRadioCloud
                            name="valuationStandards"
                            options={enquiryCommonValuerMemberships.map(membership => ({
                                label: t(membership, { ns: 'formik', defaultValue: membership }),
                                value: membership,
                            }))}
                        />

                        <FormikConditionalSection
                            formDataTarget="valuationStandards"
                            condition={x => x.includes('Other')}
                        >
                            <VStack
                                align="start"
                                backgroundColor="wvwGrey05"
                                borderRadius="8"
                                padding="1rem"
                                spacing="4"
                                w="100%"
                            >
                                <FormikRadioCloud
                                    name="valuationStandardsExtra"
                                    options={enquiryOtherValuerMemberships.map(membership => ({
                                        label: t(membership, { ns: 'formik', defaultValue: membership }),
                                        value: membership,
                                    }))}
                                />
                                <Text
                                    color="wvwGreen"
                                >
                                    {t('newEnquiry.valuationSection.valuationStandards.other.instruction')}
                                </Text>

                                <FormikTextarea
                                    name="valuationStandardsOther"
                                />
                            </VStack>
                        </FormikConditionalSection>
                    </VStack>
                )}

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Box>
                        <Heading
                            size="md"
                            paddingBottom=".5rem"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.valuationSection.valuationPurpose.sectionTitle')}
                        </Heading>

                        <Text>
                            {t('newEnquiry.valuationSection.valuationPurpose.leadQuestion')}
                        </Text>
                    </Box>

                    {userIsIndividual ? (
                        <FormikCloudSelectExpandable
                            name="valuationPurposes"
                            options={standardReducedPurposes.map(purpose => ({
                                label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                                value: purpose,
                            }))}
                            optionsExpanded={standardExpandedPurposes.map(purpose => ({
                                label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                                value: purpose,
                            }))}
                        />
                    ) : (
                        <FormikCloudSelect
                            name="valuationPurposes"
                            options={standardValuationOccasions.map(purpose => ({
                                label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                                value: purpose,
                            }))}
                        />
                    )}

                    <FormikConditionalSection
                        formDataTarget="valuationPurposes"
                        condition={x => x.includes('Other')}
                    >
                        <VStack
                            align="left"
                            backgroundColor="wvwGrey05"
                            borderRadius="8"
                            padding="1rem"
                        >
                            <Text
                                color="wvwGreen"
                            >
                                {t('newEnquiry.valuationSection.valuationPurpose.other.instruction')}
                            </Text>

                            <FormikTextarea
                                name="valuationPurposesOther"
                            />
                        </VStack>
                    </FormikConditionalSection>
                </VStack>

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Box>
                        <Heading
                            size="md"
                            paddingBottom=".5rem"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.valuationSection.completionDate.sectionTitle')}
                        </Heading>

                        <Text>
                            {t('newEnquiry.valuationSection.completionDate.leadQuestion')}
                        </Text>
                    </Box>

                    <FormikInput
                        width="53%"
                        name="completionDate"
                        type="date"
                        placeholder={t('fieldPlaceholder.date', { ns: 'common' })}
                    />
                </VStack>

                <ButtonGroup alignSelf="flex-end">
                    <Button
                        onClick={onOpen}
                        variant="danger"
                    >
                        {t('button.cancel', { ns: 'common' })}
                    </Button>

                    <Button
                        type="submit"
                        variant="primary"
                    >
                        {t('button.next', { ns: 'common' })}
                    </Button>
                </ButtonGroup>
            </VStack>

            <CancelEnquiryModal
                content={enquiryId === '' ? t('newEnquiry.exitModalText_WithoutSavingDraft', { ns: 'enquiryform', defaultValue: 'Are you sure you want to exit? All your current changes will be lost.' }) : undefined}
                isOpen={isOpen}
                onCancel={onClose}
                onContinue={() => {
                    if (enquiryId === '') {
                        navigate('../', { replace: true });
                    } else {
                        navigate('/dashboard');
                    }
                }}
            />
        </FormikForm>
    );
};

export default EnquiryValuationInfo;
