import { useTranslation } from 'react-i18next';
import { VStack, Text, Box } from '@chakra-ui/react';
import { PropertyType } from '../../../../../types';
import GoogleMap from '../../../../../common/components/GoogleMap';
import { WVWLabel } from '../../../../../common/components/typography';
import InspectionAccessDetails from './InspectionAccessDetails';

type PropTypes = {
    property: PropertyType;
};

const PropertyDetailsCondensedOverview = (props: PropTypes) => {
    const { t } = useTranslation(['inspection']);
    const { property } = props;

    return (
        <VStack width="100%" height="90%">
            <InspectionAccessDetails
                fullName={`${property.accessFName} ${property.accessLName}`}
                email={property.accessEmail}
                mobile={`${property.accessMobileDialCode} ${property?.accessMobile}`}
            />

            <Box
                bg="wvwGrey05"
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="1rem"
                width="100%"
            >
                <WVWLabel content={t('label.address')} />

                {property?.street && (
                    <Text maxW="20rem">
                        {t('addressOneLiner', {
                            street: property?.street,
                            city: property?.city,
                            country: property?.country,
                            postalCode: property?.postalCode,
                            ns: 'common',
                        })}
                    </Text>
                )}
            </Box>

            <GoogleMap
                directionsLink
                latitude={property?.latitude}
                longitude={property?.longitude}
            />
        </VStack>
    );
};

export default PropertyDetailsCondensedOverview;
