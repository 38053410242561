import { useQuery, useQueryClient } from 'react-query';
import { caseActiveCases } from '../endpoints';

const CASE_ACTIVE_CASES = 'CASE_ACTIVE_CASES';

const useCaseActiveCases = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([CASE_ACTIVE_CASES], () => caseActiveCases());

    const refresh = () => {
        queryClient.invalidateQueries(CASE_ACTIVE_CASES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useCaseActiveCases;
