import {
    Heading,
    VStack,
    HStack,
    Text,
    Button,
    Flex,
    Divider,
    Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { useMe, useMyEntity } from '../../../../utils/api/hooks';
import ProfileDetailsEdit from './ProfileDetailsEdit';
import { useRole } from '../../../../common/hooks';
import PublicUserProfilePicture from '../../../../common/components/PublicUserProfilePicture';

const PersonalProfileDetails = () => {
    const { t } = useTranslation(['profile', 'account', 'common']);

    const { userIsCompany } = useRole();

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    const [edit, setEdit] = useState(false);

    const openEditor = () => {
        setEdit(!edit);
    };

    if (userLoading || entityLoading) {
        return <LoadingSpinner />;
    }

    return (
        edit ? (
            <ProfileDetailsEdit openEditor={openEditor} />
        ) : (
            <>
                <HStack
                    w="100%"
                    align="left"
                    paddingTop="1rem"
                    alignItems="center"
                    spacing={4}
                >
                    <PublicUserProfilePicture
                        entityId={entity?.id}
                        userId={user?.id}
                        size="8rem"
                    />

                    <VStack
                        align="left"
                        spacing="0"
                    >
                        <Heading color="wvwGreen">
                            {userLoading ? '' : `${user?.firstName} ${user?.lastName}`}
                        </Heading>

                        {userIsCompany && (
                            <Text color="wvwGreen" fontSize="1.2rem">
                                {user.orgPosition && (
                                    <>
                                        {t('position.at', {
                                            position: user.orgPosition,
                                            company: entity.name,
                                            interpolation: { escapeValue: false },
                                        })}
                                    </>
                                )}
                            </Text>
                        )}
                    </VStack>
                </HStack>

                <Box
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1.5rem 2rem"
                    w="100%"
                >
                    <VStack
                        paddingTop="1rem"
                    >
                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.email')}
                            </Text>

                            {user?.email ? (
                                <Text>
                                    {user?.email}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.email', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.mobileNumber')}
                            </Text>

                            {user?.mobile ? (
                                <Text>
                                    {`${user?.mobileDialCode} ${user?.mobile}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.mobileNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.telNumber')}
                            </Text>

                            {user?.tel ? (
                                <Text>
                                    {`${user?.telDialCode} ${user?.tel}`}
                                </Text>
                            ) : (
                                <Text color="wvwGrey40">
                                    {t('fieldNotProvided.telNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.faxNumber')}
                            </Text>

                            {user?.fax ? (
                                <Text>
                                    {`${user?.faxDialCode} ${user?.fax}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.faxNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.officeTelNumber')}
                            </Text>

                            {user?.officeTel ? (
                                <Text>
                                    {`${user?.officeTelDialCode} ${user?.officeTel}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.officeTelNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.officeFaxNumber')}
                            </Text>

                            {user?.officeFax ? (
                                <Text>
                                    {`${user?.officeFaxDialCode} ${user?.officeFax}`}
                                </Text>
                            ) : (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.officeFaxNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                                maxW="17rem"
                                w="100%"
                            >
                                {t('profileDetails.location')}
                            </Text>

                            {!user?.street && (
                                <Text
                                    color="wvwGrey40"
                                >
                                    {t('fieldNotProvided.location', { ns: 'common' })}
                                </Text>
                            )}

                            <Text>
                                {user?.street && `${user?.street}, ${user?.postalCode}, ${user?.city}, ${user?.country}`}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            justifyContent="end"
                            w="100%"
                        >
                            <Button
                                variant="primary"
                                onClick={() => openEditor()}
                            >
                                {t('button.edit', { ns: 'common' })}
                            </Button>
                        </Flex>
                    </VStack>
                </Box>
            </>
        )
    );
};

export default PersonalProfileDetails;
