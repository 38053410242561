import { useQuery, useQueryClient } from 'react-query';
import { enquiryGetDormant } from '../endpoints';

const DORMANT_EQUIRIES = 'DORMANT_EQUIRIES';

const useEnquiryGetDormant = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([DORMANT_EQUIRIES], () => enquiryGetDormant());

    const refresh = () => {
        queryClient.invalidateQueries(DORMANT_EQUIRIES);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useEnquiryGetDormant;
