import {
    VStack,
} from '@chakra-ui/react';
import { DocumentRequestTemplateRequestType, DocumentRequestType } from '../../../../../types';
import RequestListItem from './RequestListItem';

type PropTypes = {
    onDelete?: (requestId: number) => void;
    onEdit?: (requestId: number, name: string, description: string) => void;
    requests: DocumentRequestType[] | DocumentRequestTemplateRequestType[];
    requestType: 'TEMPLATE' | 'REQUEST';
};

const RequestListDisplay = (props: PropTypes) => {
    const {
        onDelete,
        onEdit,
        requests = [],
        requestType,
    } = props;

    return (
        <VStack
            alignSelf="center"
            maxH={window.innerHeight - 380}
            overflow="scroll"
            paddingTop=".5rem"
            w="100%"
        >
            {requests.length > 0 && requests.map(request => (
                <RequestListItem
                    key={request.id}
                    onDelete={onDelete && (requestId => onDelete?.(requestId))}
                    onEdit={onEdit && ((...requestParams) => onEdit?.(...requestParams))}
                    request={request}
                    requestType={requestType}
                />
            ))}
        </VStack>
    );
};

RequestListDisplay.defaultProps = {
    onDelete: undefined,
    onEdit: undefined,
};

export default RequestListDisplay;
