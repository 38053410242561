import { CheckIcon, NotAllowedIcon } from '@chakra-ui/icons';
import {
    Box,
    Flex,
    Spacer,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentBox, EventDateTime, ListItemLayout } from '../../../../../common/components/display';
import { CalendarEntryType } from '../../../../../types/calendar/CalendarEntryType';
import { useInspectionAcceptTentativeDate, useInspectionRemoveTentativeDate } from '../../../../../utils/api/hooks';
import { DropdownMenu, WvwButton } from '../../../../../common/components/inputs';
import { useRole, useToast } from '../../../../../common/hooks';
import { CaseType, InspectionType } from '../../../../../types';
import InspectionConfirmationModal from './InspectionConfirmationModal';

type PropTypes = {
    caseData: CaseType;
    calendarEntry: CalendarEntryType;
    inspection: InspectionType;
};

const TenatativeDateItem = (props: PropTypes) => {
    const { caseData, calendarEntry, inspection } = props;

    const { property } = inspection;

    const { t } = useTranslation('inspection');
    const { userIsClient, userIsValuer } = useRole();
    const { createErrorToast } = useToast();

    const [confirmModalState, setConfirmModalState] = useState<'CLOSED' | 'ACCEPT' | 'CANCEL'>('CLOSED');

    const { update: acceptDate } = useInspectionAcceptTentativeDate({ caseId: caseData.id });
    const { update: removeDate } = useInspectionRemoveTentativeDate({ caseId: caseData.id });

    const accept = () => {
        const participation = calendarEntry.eventParticipations
            .find(p => p.userId === caseData.clientUserId);

        if (!participation) {
            createErrorToast(t('error.participationNotFound'));
            return;
        }

        acceptDate({
            participation: participation.id,
            target: inspection.id,
        });
    };

    const remove = () => removeDate({
        date: calendarEntry.id,
        target: inspection.id,
    });

    return (
        <>
            <ContentBox color="wvwGreen">
                <Box ml="2rem">
                    <ListItemLayout
                        heading={(
                            <EventDateTime
                                date={new Date(calendarEntry.start)}
                                duration={calendarEntry.duration}
                            />
                        )}
                        extra={(
                            <Flex
                                ml="3rem"
                                w="100%"
                            >
                                <Spacer />

                                {userIsValuer && (
                                    <DropdownMenu
                                        options={[
                                            {
                                                icon: <CheckIcon boxSize="1.2rem" />,
                                                text: t('button.confirmAppointment'),
                                                onClick: () => setConfirmModalState('ACCEPT'),
                                            },
                                            {
                                                icon: <NotAllowedIcon boxSize="1.2rem" />,
                                                text: t('button.cancelAppointment'),
                                                onClick: () => setConfirmModalState('CANCEL'),
                                            },
                                        ]}
                                    />
                                )}

                                {userIsClient && (
                                    <WvwButton
                                        content={t('button.accept', { ns: 'common' })}
                                        onClick={() => setConfirmModalState('ACCEPT')}
                                        size="sm"
                                        variant="primary"
                                    />
                                )}
                            </Flex>
                        )}
                    />
                </Box>
            </ContentBox>

            <InspectionConfirmationModal
                content={confirmModalState === 'ACCEPT' ? t('modal.confirmAppointment.content') : t('modal.removeAppointment.content')}
                eventDate={new Date(calendarEntry.start)}
                eventDuration={calendarEntry.duration}
                isOpen={confirmModalState !== 'CLOSED'}
                onClose={() => setConfirmModalState('CLOSED')}
                onConfirm={confirmModalState === 'ACCEPT' ? accept : remove}
                property={property}
                title={confirmModalState === 'ACCEPT' ? t('modal.confirmAppointment.title') : t('modal.removeAppointment.title')}
            />
        </>
    );
};

export default TenatativeDateItem;
