import CompanyValuers from './common/CompanyValuers';
import ValuerPanelPermissions from './common/ValuerPanelPermissions';
import { useRole } from '../../../../common/hooks';
import AccountAccessDeniedAlert from '../../../../common/components/display/AccountAccessDeniedAlert';

const ValuerPanel = () => {
    const { userIsIndividual, userIsValuer } = useRole();

    if (userIsValuer) return <ValuerPanelPermissions />;

    if (userIsIndividual) return <AccountAccessDeniedAlert />;

    return <CompanyValuers />;
};

export default ValuerPanel;
