import { VStack } from '@chakra-ui/react';
import ClientOffer from './common/ClientOffer';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import ValuerOffer from './common/ValuerOffer';
import { useRole } from '../../../../common/hooks';

const Offer = () => {
    const { userIsClient, userIsValuer, roleLoading } = useRole();

    if (roleLoading) return <LoadingSpinner />;

    return (
        <VStack
            align="left"
            w="100%"
        >
            {userIsClient && <ClientOffer />}

            {userIsValuer && <ValuerOffer />}
        </VStack>
    );
};

export default Offer;
