import {
    Button,
    Box,
    Icon,
    IconButton,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { DE, GB } from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const LanguageToggleButton = () => {
    const { t } = useTranslation('common');

    const [languageOpen, setLanguageOpen] = useState(false);

    return (
        <>
            <Box>
                <IconButton
                    aria-label="Language"
                    bg="white"
                    color="white"
                    icon={i18n.language === 'de' ? <Icon as={DE} fontSize="1.5rem" bg="transparent" /> : <Icon as={GB} fontSize="1.5rem" />}
                    size="sm"
                    onClick={() => setLanguageOpen(true)}
                />
            </Box>

            <Modal
                isOpen={languageOpen}
                onClose={() => setLanguageOpen(false)}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        {t('selectLanguage')}
                    </ModalHeader>

                    <ModalBody>
                        <VStack spacing="4">
                            <Button
                                onClick={() => {
                                    i18n.changeLanguage('en');
                                    setLanguageOpen(false);
                                }}
                                w="100%"
                                leftIcon={<Icon as={GB} fontSize="2rem" />}
                            >
                                English
                            </Button>

                            <Button
                                onClick={() => {
                                    i18n.changeLanguage('de');
                                    setLanguageOpen(false);
                                }}
                                w="100%"
                                leftIcon={<Icon as={DE} fontSize="2rem" />}
                            >
                                Deutsch
                            </Button>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={() => setLanguageOpen(false)}
                        >
                            {t('button.close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

LanguageToggleButton.defaultProps = {
    noLangSwitch: false,
};

export default LanguageToggleButton;
