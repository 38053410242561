import { VStack, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { WVWLabel, WvwText } from '../../../../../common/components/typography';
import { ContentBox } from '../../../../../common/components/display';

type PropTypes = {
    fullName: string;
    email: string;
    mobile: string;
};

const InspectionAccessDetails = (props: PropTypes) => {
    const { fullName, email, mobile } = props;
    const { t } = useTranslation(['inspection']);

    return (
        <ContentBox>
            <VStack
                align="left"
                spacing="4"
                width="100%"
            >
                <Box>
                    <WVWLabel content={t('label.fullName')} />

                    <WvwText>
                        {fullName}
                    </WvwText>
                </Box>

                <Box>
                    <WVWLabel content={t('label.email')} />

                    <WvwText>
                        {email}
                    </WvwText>
                </Box>

                <Box>
                    <WVWLabel content={t('label.mobile')} />

                    <WvwText>
                        {mobile}
                    </WvwText>
                </Box>
            </VStack>
        </ContentBox>
    );
};

export default InspectionAccessDetails;
