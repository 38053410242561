import {
    Heading,
    HStack,
    VStack,
    Button,
    Badge,
    Box,
    Spacer,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useEnquiry,
    useEntityPublicEntityGetById,
    usePublicUser,
} from '../../../../../utils/api/hooks';
import { EnquiryType } from '../../../../../types';

import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import PublicUserProfilePicture from '../../../../../common/components/PublicUserProfilePicture';
import PublicUserSlider from '../../../../../common/components/user-details/PublicUserSlider';
import ValuerOfferBanner from './ValuerOfferBanner';
import GeneralEnquiryOverview from '../../../../../common/components/display/enquiry-overview/GeneralEnquiryOverview';
import CancelledEnquiryBanner from './CancelledEnquiryBanner';
import DraftEnquiryBanner from './DraftEnquiryBanner';

const ValuerEnquiryOverview = () => {
    const { enquiryId = '' } = useParams();
    const { t } = useTranslation(['enquiryform', 'enquiries', 'common', 'account']);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const {
        enquiry: enquiryData = {} as unknown as EnquiryType,
    } = useEnquiry({ enquiryId });

    const {
        data: clientCompany,
        isLoading: clientCompanyLoading,
    } = useEntityPublicEntityGetById(enquiryData.fromEntityId);

    const {
        profilePictureUrlIsLoading,
        data: client,
        isLoading: clientLoading,
    } = usePublicUser(enquiryData.fromUserId);

    if (clientLoading || clientCompanyLoading
        || profilePictureUrlIsLoading) return <LoadingSpinner />;

    return (
        <VStack
            w="100%"
            align="left"
            paddingBottom="4rem"
        >
            {enquiryData?.status === 'CANCELLED' && (
                <CancelledEnquiryBanner />
            )}

            {enquiryData?.status === 'DRAFT' && (
                <DraftEnquiryBanner />
            )}

            {enquiryData?.status !== 'CANCELLED' && enquiryData?.status !== 'DRAFT' && (
                <ValuerOfferBanner />
            )}

            <VStack
                w="100%"
                bg="white"
                align="left"
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="2.5rem"
                margin="0"
            >

                <HStack
                    spacing="4"
                    alignItems="center"
                >
                    <PublicUserProfilePicture
                        entityId={enquiryData.fromEntityId}
                        userId={enquiryData.fromUserId}
                        size="4rem"
                    />

                    <VStack
                        spacing="0"
                        align="left"
                        paddingBlock=".8rem"
                        w="100%"
                    >
                        {client && (
                            <>
                                <HStack>
                                    <Heading
                                        as="h4"
                                        fontSize="1.5rem"
                                        color="wvwGreen"
                                    >
                                        {`${client.firstName} ${client.lastName}`}
                                    </Heading>

                                </HStack>

                                <Heading
                                    as="h4"
                                    size="6rem"
                                    color="wvwGreen80"
                                >
                                    {clientCompany?.name}
                                </Heading>

                                <Button
                                    variant="none"
                                    size="max-content"
                                    color="wvwYellow"
                                    alignSelf="flex-start"
                                    fontWeight="400"
                                    onClick={() => setIsDrawerOpen(true)}
                                >
                                    {t('button.viewClient', { ns: 'common' })}
                                </Button>
                            </>
                        )}

                        {!client && (
                            <Heading
                                as="h4"
                                fontSize="1.5rem"
                                color="wvwGreen"
                            >
                                {`${enquiryData.invoiceFName} ${enquiryData.invoiceLName}`}
                            </Heading>
                        )}
                    </VStack>

                    {client && (
                        <>
                            <Spacer />

                            <Box
                                paddingInline=".8rem"
                                alignSelf="flex-start"
                            >
                                <Badge variant="subtle" textColor="wvwGreen" borderRadius=".3rem" paddingInline="1rem">
                                    {client.entity.companyOrIndividual === 'COMPANY' ? `${t('integration.customerTypes.institution', { ns: 'account' })}` : `${t('integration.customerTypes.private', { ns: 'account' })}`}
                                </Badge>
                            </Box>
                        </>
                    )}
                </HStack>
            </VStack>

            <GeneralEnquiryOverview variant="white" overviewData={enquiryData} />

            {client && (
                <PublicUserSlider
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    userId={enquiryData.fromUserId}
                />
            )}
        </VStack>
    );
};

export default ValuerEnquiryOverview;
