import { Box, Text } from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { CountrySelect } from '../inputs';

type PropTypes = {
    name: string;
    selectDialCode?: boolean;
    w?: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void,
};

const FormikCountrySelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const { selectDialCode, w, onChange } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <Box w={w}>
            <CountrySelect
                bg="white"
                h="3rem"
                borderRadius="8"
                isInvalid={!!meta.touched && !!meta.error}
                w={w}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                selectDialCode={selectDialCode}
                onChange={e => {
                    field.onChange(e);

                    onChange?.(e.target.value, field);
                }}
            />

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </Box>
    );
};

FormikCountrySelect.defaultProps = {
    selectDialCode: false,
    w: undefined,
    onChange: () => {},
};

export default FormikCountrySelect;
