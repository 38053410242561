import { InfoCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { InspectionType } from '../../../../../types';
import InspectionLayout from './InspectionLayout';
import InspectionStatusBadge from './InspectionStatusBadge';
import { DropdownMenu } from '../../../../../common/components/inputs';
import { useInspectionReportProblem } from '../../../../../utils/api/hooks';

type PropTypes = {
    inspection: InspectionType;
};

const InspectionFinished = (props: PropTypes) => {
    const { inspection } = props;

    const [outcome] = inspection.outcomes;

    const { success } = outcome;

    const { t } = useTranslation('inspection');

    const { update: reportProblem } = useInspectionReportProblem({ caseId: inspection.caseId });

    if (!success) {
        return (
            <InspectionLayout
                color="wvwGrey05"
                inspection={inspection}
                greyText
                statusBadge={<InspectionStatusBadge status="rescheduled" />}
            />
        );
    }

    return (
        <InspectionLayout
            actionButton={(
                <DropdownMenu
                    options={[
                        {
                            icon: <InfoCircle />,
                            text: t('link.reportAProblem', { ns: 'common' }),
                            onClick: () => reportProblem({
                                comment: 'The inspection had a problem',
                                target: inspection.id,
                            }),
                        },
                    ]}
                />
            )}
            color="green"
            inspection={inspection}
            statusBadge={(
                <InspectionStatusBadge
                    status="completed"
                />
            )}
        />
    );
};

export default InspectionFinished;
