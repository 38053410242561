import {
    Container,
    Flex,
    HStack,
    IconButton,
    Button,
    Spacer,
    VStack,
    Box,
    Heading,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { RiArrowGoBackFill } from 'react-icons/ri';
import LanguageToggleButton from '../../common/components/LanguageToggleButton';
import Logo from '../../common/components/Logo';
import WaterMarkBox from '../../common/components/WaterMarkBox';
import { FormikForm, FormikInput } from '../../common/forms';
import { useFbSendPasswordReset } from '../../utils/api/hooks';
import Footer from '../../common/components/Footer';

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('errors.required'),
});

const ForgotPassword = () => {
    const { t } = useTranslation(['passwordReset', 'common', 'formik']);

    const toast = useToast();

    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { update } = useFbSendPasswordReset({
        onSuccess: () => {
            onOpen();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: (error?.message === 'Firebase: Error (auth/user-not-found).' ? t('error.authError.userNotFound', { ns: 'common' }) : error?.message),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.lg"
                >
                    <Box
                        bg="white"
                        borderRadius="lg"
                        p="2em"
                        w="100%"
                        marginTop="1.5rem"
                    >
                        <Flex>
                            <HStack>
                                <IconButton
                                    aria-label="Back"
                                    color="white"
                                    icon={<RiArrowGoBackFill />}
                                    size="xs"
                                    variant="primary"
                                    onClick={() => navigate('/login')}
                                />

                                <Button
                                    color="wvwGreen"
                                    variant="link"
                                    onClick={() => navigate('/login')}
                                >
                                    {t('button.back', { ns: 'common' })}
                                </Button>
                            </HStack>

                            <Spacer />

                            <LanguageToggleButton />
                        </Flex>

                        <FormikForm
                            validationSchema={validationSchema}
                            initialValues={{
                                email: '',
                            }}
                            onSubmit={values => {
                                update(values.email);
                            }}
                        >
                            <VStack
                                spacing="8"
                                align="center"
                                pb="9em"
                                pt="6em"
                                w="100%"
                            >
                                <Logo withColor />

                                <Heading
                                    as="h1"
                                    color="wvwGreen"
                                    size="xl"
                                >
                                    {t('forgotPassword')}
                                </Heading>

                                <Text
                                    align="center"
                                    w="25rem"
                                >
                                    {t('enterEmail')}
                                </Text>

                                <VStack
                                    spacing="4"
                                    align="center"
                                    w="20rem"
                                >
                                    <FormikInput
                                        name="email"
                                        type="email"
                                        placeholder={t('Email', { ns: 'common' })}
                                    />

                                    <Button
                                        type="submit"
                                        variant="primaryYellow"
                                    >
                                        {t('button.sendLink', { ns: 'common' })}
                                    </Button>
                                </VStack>
                            </VStack>
                        </FormikForm>
                    </Box>
                </Container>
                <Footer invertColor />
            </WaterMarkBox>

            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        {t('thankYou')}
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody>
                        <Text
                            align="center"
                        >
                            {t('linkMessage')}
                        </Text>
                    </ModalBody>

                    <ModalFooter justifyContent="center">
                        <Button
                            variant="primary"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            {t('button.done', { ns: 'common' })}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ForgotPassword;
