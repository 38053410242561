import { useQuery, useQueryClient } from 'react-query';
import { entityGetPanelValuers } from '../endpoints';

const ENTITY_PANEL_VALUERS = 'ENTITY_PANEL_VALUERS';

const useEntityPanelValuers = () => {
    const queryClient = useQueryClient();

    const {
        data: valuerList,
        error,
        isLoading,
    } = useQuery([ENTITY_PANEL_VALUERS], entityGetPanelValuers);

    const refresh = () => {
        queryClient.invalidateQueries(ENTITY_PANEL_VALUERS);
    };

    return {
        valuerList,
        error,
        isLoading,
        refresh,
    };
};

export default useEntityPanelValuers;
