import { useQuery, useQueryClient } from 'react-query';
import { clientInvitesGetReceived } from '../endpoints';

const CLIENT_INVITES_GET_RECEIVED = 'CLIENT_INVITES_GET_RECEIVED';

const useClientInvitesGetReceived = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([CLIENT_INVITES_GET_RECEIVED], () => clientInvitesGetReceived());

    const refresh = () => {
        queryClient.invalidateQueries(CLIENT_INVITES_GET_RECEIVED);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useClientInvitesGetReceived;
