import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import useToast from '../../../../common/hooks/useToast';
import { AdminUsersDto } from '../../dto';
import { adminUsers } from '../../endpoints';

const ADMIN_USERS = 'ADMIN_USERS';

const useAdminUsers = (dto: AdminUsersDto) => {
    const queryClient = useQueryClient();

    const { t } = useTranslation('common');

    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [ADMIN_USERS, JSON.stringify(dto)],
        () => adminUsers(dto),
        {
            onError: (err: Error) => {
                createErrorToast(t(err.message, { defaultValue: err.message }));
            },
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(ADMIN_USERS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useAdminUsers;
