import {
    Container,
    VStack,
    HStack,
    Heading,
    Text,
    Box,
    Circle,
    Img,
    Button,
    Flex,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../common/components/Logo';
import WaterMarkBox from '../../../common/components/WaterMarkBox';
import { useInviteValidateInvite } from '../../../utils/api/hooks';
import LanguageToggleButton from '../../../common/components/LanguageToggleButton';
import Footer from '../../../common/components/Footer';
import { FlowContext } from '../../../common/components/display/ProcessFlowStateful';
import SiteLoading from '../../../common/components/SiteLoading';

type ParamsType = {
    token: string;
    inviteType: 'invitedToClientPanel' | 'invitedToValuerPanel' | 'invitedToTeam';
};

const InviteLandingPage = () => {
    const { t } = useTranslation(['account', 'common']);
    const navigate = useNavigate();

    const { token = '', inviteType = 'invitedToTeam' } = useParams<ParamsType>();

    const { goNext } = useContext(FlowContext);

    const {
        isValid,
        isLoading,
    } = useInviteValidateInvite({ token, inviteType });

    if (isLoading) {
        return (
            <SiteLoading />
        );
    }

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    marginBlock="3"
                    minW="max"
                >
                    <VStack
                        align="left"
                        spacing="4"
                        w="100%"
                    >
                        <HStack spacing="2">
                            <Logo />

                            <Heading
                                as="h4"
                                color="white"
                                size="md"
                                textAlign="left"
                            >
                                {t('whoValuesWhat', { ns: 'common' })}
                            </Heading>
                        </HStack>

                        <Box
                            bg="white"
                            borderRadius="lg"
                            borderWidth="1px"
                            p="2em"
                            w="100%"
                        >
                            <Flex flexDirection="row-reverse">
                                <LanguageToggleButton />
                            </Flex>

                            {isValid === false && (
                                <VStack>
                                    <Text>
                                        {t('invalidInvite', { ns: 'account' })}
                                    </Text>

                                    <Button
                                        onClick={() => navigate('/login')}
                                        variant="primary"
                                    >
                                        {t('button.backToLogin', { ns: 'common' })}
                                    </Button>
                                </VStack>
                            )}

                            {isValid === true && (
                                <VStack
                                    align="center"
                                    spacing="4"
                                    w="100%"
                                >
                                    <Circle
                                        alignSelf="top"
                                        backgroundColor="#FFE478"
                                        border="3rem solid #FFECA1"
                                        size="xs"
                                    >
                                        <Circle
                                            bg="wvwYellow"
                                            size="9rem"
                                        >
                                            <Img
                                                alt="highfive"
                                                paddingTop="3rem"
                                                src="/images/highfive.svg"
                                                w="10rem"
                                            />
                                        </Circle>
                                    </Circle>

                                    <Heading
                                        as="h4"
                                        color="wvwGreen"
                                        size="lg"
                                    >
                                        {t('Welcome to WVW', { ns: 'common' })}
                                    </Heading>

                                    {inviteType === 'invitedToTeam' && (
                                        <Text>
                                            {t('teamInvite')}
                                        </Text>
                                    )}

                                    {inviteType === 'invitedToClientPanel' && (
                                        <Text>
                                            {t('clientInvite')}
                                        </Text>
                                    )}

                                    {inviteType === 'invitedToValuerPanel' && (
                                        <Text>
                                            {t('valuerInvite')}
                                        </Text>
                                    )}

                                    <Text
                                        textAlign="justify"
                                        overflowWrap="break-word"
                                        width="24rem"
                                    >
                                        {t('allInOneSolution')}
                                    </Text>

                                    <Button
                                        onClick={() => goNext()}
                                        variant="primary"
                                    >
                                        {inviteType === 'invitedToTeam' ? t('button.joinTeam', { ns: 'common' }) : t('button.joinPanel', { ns: 'common' })}
                                    </Button>
                                </VStack>
                            )}
                        </Box>
                    </VStack>

                    <Footer invertColor />
                </Container>
            </WaterMarkBox>
        </Box>
    );
};

export default InviteLandingPage;
