import { useQuery, useQueryClient } from 'react-query';
import { enquiryGetByStatus } from '../endpoints';

const MY_ENQUIRIES_BY_STATUS = 'MY_ENQUIRIES_BY_STATUS';

const useEnquiriesByStatus = (status: string) => {
    const queryClient = useQueryClient();

    const {
        data: enquiryList,
        error,
        isLoading,
    } = useQuery([MY_ENQUIRIES_BY_STATUS, status], () => enquiryGetByStatus(status));

    const refresh = () => {
        queryClient.invalidateQueries(MY_ENQUIRIES_BY_STATUS);
    };

    return {
        enquiryList,
        error,
        isLoading,
        refresh,
    };
};

export default useEnquiriesByStatus;
