import {
    Box,
    Button,
    Heading,
    HStack,
    Link as ChakraLink,
    Text,
    VStack,
} from '@chakra-ui/react';
import { People } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { useEntityPanelValuers, useOfferGetByEnquiryId } from '../../../../../utils/api/hooks';
import { OfferType } from '../../../../../types';
import DataTable from '../../../../../common/components/DataTable';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import delimitNumber from '../../../../../common/functions/delimitNumber';

const ClientOfferBanner = () => {
    const { t } = useTranslation(['enquiries', 'common', 'dashboard']);
    const { enquiryId } = useParams();

    const {
        data: clientOffers = [],
        isLoading: clientOffersLoading,
        error: clientOffersError,
    } = useOfferGetByEnquiryId(Number(enquiryId));

    const {
        valuerList = [],
        isLoading: valuerListLoading,
    } = useEntityPanelValuers();

    if (clientOffersLoading || valuerListLoading) {
        return (
            <LoadingSpinner />
        );
    }

    const headers = [
        {
            title: t('offerTable.header.company', { ns: 'dashboard' }),
            render: (i: OfferType) => (
                <HStack spacing="2">
                    <Text>
                        {i.fromEntity.name}
                    </Text>

                    { valuerList.some(v => v.id === i.fromEntityId) && <People size="20" /> }
                </HStack>
            ),
            sorter: (a: OfferType, b: OfferType) => (
                a.fromEntity.name.localeCompare(b.fromEntity.name)
            ),
        },
        {
            title: t('offerTable.header.valuer', { ns: 'dashboard' }),
            dataIndex: 'valuer',
            render: (i: OfferType) => `${i.fromValuer.firstName} ${i.fromValuer.lastName}`,
            sorter: (a: OfferType, b: OfferType) => {
                const aName = `${a.fromValuer.firstName} ${a.fromValuer.lastName}`;
                const bName = `${b.fromValuer.firstName} ${b.fromValuer.lastName}`;

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('offerTable.header.price', { ns: 'dashboard' }),
            dataIndex: 'price',
            sorter: (a: OfferType, b: OfferType) => a.price - b.price,
            render: (i: OfferType) => (
                <Text textAlign="left">
                    {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(i.price)}`}
                </Text>
            ),
        },
        {
            title: t('offerTable.header.availability', { ns: 'dashboard' }),
            dataIndex: 'availability',
            sorter: (a: OfferType, b: OfferType) => {
                if (b.availability.replace(/-/g, '') < a.availability.replace(/-/g, '')) {
                    return -1;
                }
                if (b.availability.replace(/-/g, '') > a.availability.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: t('offerTable.header.status', { ns: 'dashboard' }),
            dataIndex: 'status',
            render: (i: OfferType) => {
                let bgColor = 'wvwYellow10';
                let color = 'wvwYellow';

                if (i.status === 'ACCEPTED') {
                    bgColor = 'green10';
                    color = 'green';
                }

                if (i.status === 'REJECTED') {
                    bgColor = 'red10';
                    color = 'red';
                }

                if (i.status === 'WITHDRAWN') {
                    bgColor = 'purple10';
                    color = 'purple';
                }

                return (
                    <Text
                        bg={bgColor}
                        borderRadius="10"
                        color={color}
                        fontWeight="bold"
                        paddingBlock=".2rem"
                        paddingInline=".5rem"
                        fontSize=".8rem"
                        textAlign="center"
                        w="6rem"
                    >
                        {t(`status.${i.status}`, { ns: 'offer' })}
                    </Text>
                );
            },
            sorter: (a: OfferType, b: OfferType) => a.status.localeCompare(b.status),
        },
        {
            title: t('offerTable.header.action', { ns: 'dashboard' }),
            render: (i: OfferType) => (
                <ChakraLink
                    as={ReactLink}
                    to={`/dashboard/enquiry/${enquiryId}/offer/${i.id}`}
                >
                    <Button
                        variant="none"
                        color="wvwYellow"
                        width="none"
                        padding=".5rem"
                    >
                        {t('button.viewOffer', { ns: 'common' })}
                    </Button>
                </ChakraLink>
            ),
        },
    ];

    return (
        <VStack
            align="left"
            bg="wvwYellow10"
            borderRadius="10"
            outline="wvwYellow"
            pb=".5rem"
            pt="1rem"
        >
            <>
                { clientOffers.length === 0 && (
                    <VStack
                        align="left"
                        paddingInline="1rem"
                        pb=".5rem"
                        pt=".2rem"
                    >
                        <Heading
                            as="h4"
                            color="wvwYellow"
                            size="md"
                        >
                            {t('awaitingOffers.sectionTitle')}
                        </Heading>

                        <Text>
                            {t('awaitingOffers.blurb')}
                        </Text>
                    </VStack>
                )}

                { clientOffers.length !== 0 && (
                    <HStack
                        align="left"
                        paddingInline="1rem"
                    >
                        <Heading
                            as="h4"
                            color="wvwYellow"
                            size="md"
                        >
                            {t('awaitingOffers.sectionTitle')}
                        </Heading>
                    </HStack>
                )}

                { clientOffersError && (
                    <VStack>
                        <Text
                            color="wvwGrey80"
                            fontWeight="bold"
                        >
                            {t('error.weAreSorry', { ns: 'common' })}
                        </Text>

                        <Text color="wvwGrey80">
                            {t('error.offer.loadingFailed', { ns: 'common' })}
                        </Text>
                    </VStack>
                )}

                { !clientOffersError && clientOffers?.length > 0 && (
                    <Box paddingInline="1rem">
                        <DataTable
                            columnColor="white"
                            columns={headers}
                            data={clientOffers}
                            titleColor="black"
                        />
                    </Box>
                ) }
            </>
        </VStack>
    );
};

export default ClientOfferBanner;
