import {
    Container,
    Flex,
    HStack,
    IconButton,
    Button,
    Spacer,
    VStack,
    Box,
    Heading,
    Text,
    useToast,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { RiArrowGoBackFill } from 'react-icons/ri';
import LanguageToggleButton from '../../common/components/LanguageToggleButton';
import Logo from '../../common/components/Logo';
import WaterMarkBox from '../../common/components/WaterMarkBox';
import { FormikForm, FormikPassword } from '../../common/forms';
import { useFbConfirmPasswordReset } from '../../utils/api/hooks';

const ResetPassword = () => {
    const { t } = useTranslation(['common', 'passwordReset', 'account']);

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .required(t('required', { ns: 'account' })),
        lastName: Yup.string()
            .required(t('required', { ns: 'account' })),
        email: Yup.string()
            .email(t('invalidEmail', { ns: 'account' }))
            .required(t('required', { ns: 'account' })),
        password: Yup.string()
            .min(8, t('tooShort', { ns: 'account' }))
            .minLowercase(1, t('minLowercase', { ns: 'account' }))
            .minUppercase(1, t('minUppercase', { ns: 'account' }))
            .minNumbers(1, t('minNumbers', { ns: 'account' }))
            .minSymbols(1, t('minSymbols', { ns: 'account' }))
            .required(t('required', { ns: 'account' })),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], t('passwordMustMatch', { ns: 'account' }))
            .required(t('required', { ns: 'account' })),
    });

    const toast = useToast();

    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const urlData = window.location.href;

    const [oobCode, setOobCode] = useState('');

    useEffect(() => {
        if (urlData === '') return;

        const code = urlData.split('oobCode=')[1];

        const newOobCode = code.split('&')[0];

        setOobCode(newOobCode);
    }, []);

    const { update } = useFbConfirmPasswordReset({
        onSuccess: () => {
            onOpen();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.lg"
                >
                    <Box
                        bg="white"
                        borderRadius="lg"
                        p="2em"
                        w="100%"
                        marginTop="1.5rem"
                    >
                        <Flex>
                            <HStack>
                                <IconButton
                                    aria-label="Back"
                                    color="white"
                                    icon={<RiArrowGoBackFill />}
                                    size="xs"
                                    variant="primary"
                                    onClick={() => navigate('/login')}
                                />

                                <Button
                                    color="wvwGreen"
                                    variant="link"
                                    onClick={() => navigate('/login')}
                                >
                                    {t('button.back', { ns: 'common' })}
                                </Button>
                            </HStack>

                            <Spacer />

                            <LanguageToggleButton />
                        </Flex>

                        <FormikForm
                            validationSchema={validationSchema}
                            initialValues={{
                                password: '',
                                confirmPassword: '',
                            }}
                            onSubmit={values => {
                                update({
                                    oobCode,
                                    newPassword: values.password,
                                });
                            }}
                        >
                            <VStack
                                spacing="8"
                                align="center"
                                pb="9em"
                                pt="6em"
                                w="100%"
                            >
                                <Logo withColor />

                                <Heading
                                    as="h1"
                                    color="wvwGreen"
                                    size="xl"
                                >
                                    {t('resetPassword')}
                                </Heading>

                                <Text
                                    align="center"
                                    w="25rem"
                                >
                                    {t('enterNewPassword')}
                                </Text>

                                <VStack
                                    spacing="4"
                                    align="center"
                                    w="100%"
                                >
                                    <FormikPassword
                                        width="18rem"
                                        name="password"
                                        placeholder="Password"
                                    />

                                    <FormikPassword
                                        width="18rem"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                    />

                                    <Button
                                        type="submit"
                                        variant="primaryYellow"
                                    >
                                        {t('reset')}
                                    </Button>
                                </VStack>
                            </VStack>
                        </FormikForm>
                    </Box>
                </Container>
            </WaterMarkBox>

            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        {t('success.success', { ns: 'common' })}
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody>
                        <Text
                            align="center"
                        >
                            {t('successMessage')}
                        </Text>
                    </ModalBody>

                    <ModalFooter justifyContent="center">
                        <Button
                            variant="primary"
                            onClick={() => {
                                onClose();
                                navigate('/login');
                            }}
                        >
                            {t('takeMeThere')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ResetPassword;
