import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityType } from '../../../../../types';
import ProfilePictureCompany from '../../../../../common/components/display/ProfilePictureCompany';
import toUrl from '../../../../../common/functions/toUrl';
import { useRole, useStandards } from '../../../../../common/hooks';
import CompanyDetailsEdit from './CompanyDetailsEdit';
import CompanyDetailsEditClient from './CompanyDetailsEditClient';

type PropTypes = {
    entity: EntityType;
};

const CompanyDetailsMain = (props: PropTypes) => {
    const { entity } = props;

    const { t } = useTranslation('account');
    const { userIsCompanyAdmin: userIsAdmin, userIsClient, userIsValuer } = useRole();
    const { yearsOfExperience } = useStandards();

    const [editing, setEditing] = useState(false);

    if (editing) {
        return (
            <>
                {userIsValuer && (
                    <CompanyDetailsEdit onComplete={() => setEditing(false)} />
                )}

                {userIsClient && (
                    <CompanyDetailsEditClient
                        entity={entity}
                        onComplete={() => setEditing(false)}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <HStack
                align="left"
                paddingTop="1rem"
                alignItems="center"
                spacing={4}
            >
                <ProfilePictureCompany
                    size="8rem"
                />

                <Heading
                    color={entity.name ? 'wvwGreen' : 'wvwGrey40'}
                >
                    {!entity.name && t('companyDetails.noNameProvided')}

                    {entity.name && entity.name}
                </Heading>
            </HStack>

            <Box
                bg="wvwGrey05"
                borderRadius="10"
                marginTop="1.5rem"
                padding="1.5rem 2rem"
                w="100%"
            >
                <VStack
                    paddingTop="1rem"
                >
                    <Flex
                        alignItems="center"
                        h="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="100%"
                            maxW="17rem"
                        >
                            {t('companyDetails.website')}
                        </Text>

                        {entity?.websiteAddress ? (
                            <Link
                                color="wvwGreen"
                                href={toUrl(entity?.websiteAddress)}
                                isExternal
                                textOverflow="elipsis"
                                noOfLines={1}
                            >
                                {entity?.websiteAddress}
                            </Link>
                        ) : (

                            <Text
                                color="wvwGrey40"
                            >
                                {t('fieldNotProvided.website', { ns: 'common' })}
                            </Text>
                        )}
                    </Flex>

                    <Divider />

                    {userIsValuer && (
                        <>
                            <Flex
                                alignItems="flex-start"
                                w="100%"
                            >
                                <Text
                                    color="wvwGreen"
                                    w="17rem"
                                >
                                    {t('companyDetails.yearsOfExperience')}
                                </Text>

                                {entity?.valuerYearsOfExperience?.length === undefined ? (
                                    <Text
                                        color="wvwGrey40"
                                    >
                                        {t('fieldNotProvided.yearsOfExperience', { ns: 'common' })}
                                    </Text>
                                ) : (
                                    <Text>
                                        {yearsOfExperience.find(
                                            i => i.value === entity?.valuerYearsOfExperience,
                                        )?.label}
                                    </Text>
                                )}
                            </Flex>

                            <Divider />

                            <Flex
                                alignItems="flex-start"
                                w="100%"
                            >
                                <Text
                                    color="wvwGreen"
                                    w="17rem"
                                >
                                    {t('companyDetails.numberOfPartners')}
                                </Text>

                                {entity?.valuerNumberOfPartners?.length === undefined ? (
                                    <Text
                                        color="wvwGrey40"
                                    >
                                        {t('fieldNotProvided.numberOfPartners', { ns: 'common' })}
                                    </Text>
                                ) : (
                                    <Text>
                                        {entity?.valuerNumberOfPartners}
                                    </Text>
                                )}

                            </Flex>

                            <Divider />
                        </>
                    )}

                    <Flex
                        alignItems="center"
                        h="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('companyDetails.email')}
                        </Text>

                        {entity?.companyEmail ? (
                            <Text>
                                {entity?.companyEmail}
                            </Text>
                        ) : (

                            <Text
                                color="wvwGrey40"
                            >
                                {t('fieldNotProvided.email', { ns: 'common' })}
                            </Text>
                        )}
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="center"
                        h="2rem"
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                        >
                            {t('companyDetails.phoneNumber')}
                        </Text>

                        {entity?.contactNumber ? (
                            <Text>
                                {`${entity?.contactNumberDialCode} ${entity?.contactNumber}`}
                            </Text>
                        ) : (
                            <Text
                                color="wvwGrey40"
                            >
                                {t('fieldNotProvided.phoneNumber', { ns: 'common' })}
                            </Text>
                        )}
                    </Flex>

                    <Divider />

                    <Flex
                        w="100%"
                    >
                        <Text
                            color="wvwGreen"
                            w="17rem"
                            alignItems="flex-start"
                        >
                            {t('companyDetails.location')}
                        </Text>

                        {entity?.street ? (
                            <VStack
                                align="left"
                            >
                                <Text>
                                    {`${entity?.street}, ${entity?.postalCode}`}
                                </Text>

                                <Text>
                                    {`${entity?.city}, ${entity?.country}`}
                                </Text>
                            </VStack>
                        ) : (
                            <Text
                                color="wvwGrey40"
                            >
                                {t('fieldNotProvided.location', { ns: 'common' })}
                            </Text>
                        )}
                    </Flex>

                    {userIsAdmin && (
                        <>
                            <Divider />

                            <Flex
                                justifyContent="end"
                                w="100%"
                            >
                                <Button
                                    variant="primary"
                                    onClick={() => setEditing(true)}
                                >
                                    {t('button.edit', { ns: 'common' })}
                                </Button>
                            </Flex>
                        </>
                    )}
                </VStack>
            </Box>
        </>
    );
};

export default CompanyDetailsMain;
