export type ViewFiltersType = 'FOLDERS' | 'REQUESTS' | 'DOCUMENTS';

export const VIEW_FILTER: Record<string, ViewFiltersType> = {
    FOLDERS: 'FOLDERS',
    REQUESTS: 'REQUESTS',
    DOCUMENTS: 'DOCUMENTS',
};

export type RequestFiltersType = 'ALL' | 'APPROVED' | 'REJECTED' | 'SUBMITTED' | 'PENDING';

export const REQUEST_FILTER: Record<string, RequestFiltersType> = {
    ALL: 'ALL',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    SUBMITTED: 'SUBMITTED',
    PENDING: 'PENDING',
};

export type DocumentFiltersType = 'ALL' | 'ACCEPTED' | 'REJECTED' | 'SUBMITTED' | 'PENDING';

export const DOCUMENT_FILTER: Record<string, DocumentFiltersType> = {
    ALL: 'ALL',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    SUBMITTED: 'SUBMITTED',
    PENDING: 'PENDING',
};
