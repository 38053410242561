import { useMutation } from 'react-query';
import useToast from '../../../../common/hooks/useToast';
import { DocumentRequestCreateRequestsFromTemplateDto } from '../../dto';
import { documentRequestCreateRequestsFromTemplate } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestCreateRequestsFromTemplate = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createSuccessToast, createErrorToast } = useToast();

    const { refreshDocumentManagementArea } = useRefreshAllDMSDataStores(Number(caseId));

    const { mutate: update } = useMutation(
        (dto: DocumentRequestCreateRequestsFromTemplateDto) => (
            documentRequestCreateRequestsFromTemplate(dto)
        ),
        {
            onSuccess: () => {
                refreshDocumentManagementArea();

                onSuccess?.();

                createSuccessToast(t('toast.messages.requestsCreated'));
            },
            onError: (error: Error) => {
                onError?.(error);
                createErrorToast(t(error.message, { defaultValue: error.message }));
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestCreateRequestsFromTemplate;
