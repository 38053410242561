import {
    Heading,
    VStack,
    Text,
    Flex,
    Divider,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Link,
    List,
    ListItem,
    Spacer,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMe, usePublicUser, useSuspendAccount } from '../../../utils/api/hooks';
import LoadingSlider from '../LoadingSlider';
import { useRole } from '../../hooks';
import PublicProfileDetails from './PublicProfileDetails';
import ConfirmationModal from '../ConfirmationModal';
import toTitleCase from '../../functions/toTitleCase';

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
    userId: number;
};

const PublicUserSlider = (props: PropTypes) => {
    const { t } = useTranslation(['profile', 'account', 'common']);
    const {
        isOpen,
        onClose,
        userId,
    } = props;

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const inTeam = useLocation().pathname.includes('team');

    const {
        data: user,
        isLoading: userLoading,
        userCertificates,
        userCertificatesIsLoading,
    } = usePublicUser(userId);

    const {
        data: me,
    } = useMe();

    const {
        userIsCompanyAdmin,
    } = useRole();

    const {
        update: suspendAccount,
    } = useSuspendAccount({
        onSuccess: () => {
            setConfirmDeleteOpen(false);
            onClose();
        },
    });

    if (userLoading || userCertificatesIsLoading) {
        return (
            <LoadingSlider
                isOpen={isOpen}
                onClose={onClose}
                title={t('profileSectionLink.profile', { ns: 'profile' })}
            />
        );
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    color="wvwGreen"
                >
                    {t('details', { ns: 'common' })}
                </DrawerHeader>

                <DrawerBody>
                    <VStack
                        w="100%"
                        maxW="container.xl"
                        align="left"
                        spacing="5"
                    >
                        <PublicProfileDetails userId={userId} />

                        {user.entity.accountType === 'VALUER' && (
                            <Box
                                bg="wvwGrey05"
                                borderRadius="10"
                                padding="1.5rem 2rem"
                                w="100%"
                            >
                                <Heading
                                    as="h4"
                                    color="wvwGrey"
                                    size="md"
                                >
                                    {t('membershipsAndQualifications.sectionTitle')}
                                </Heading>

                                <VStack
                                    paddingTop="1rem"
                                >
                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('membershipsAndQualifications.memberships')}
                                        </Text>

                                        <List>
                                            {user?.standardMemberships?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { contect: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {user?.standardMemberships?.map(m => (
                                                <ListItem key={m}>
                                                    {t(m, { ns: 'formik', defaultValue: m })}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    {user.otherMemberships && (
                                        <>
                                            <Flex
                                                alignItems="flex-start"
                                                minH="2rem"
                                                w="100%"
                                            >
                                                <Text
                                                    color="wvwGreen"
                                                    w="17rem"
                                                >
                                                    {t('membershipsAndQualifications.otherMemberships')}
                                                </Text>

                                                <List>
                                                    {user?.otherMemberships?.length === 0 && (
                                                        <ListItem
                                                            color="wvwGrey40"
                                                        >
                                                            {t('none', { context: 'female', ns: 'common' })}
                                                        </ListItem>
                                                    )}

                                                    {user?.otherMemberships?.map(i => (
                                                        <ListItem key={i}>
                                                            {i}
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Flex>

                                            <Divider />
                                        </>
                                    )}

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('membershipsAndQualifications.otherMembershipCertificates')}
                                        </Text>

                                        <List>
                                            {userCertificates?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {userCertificates?.filter(
                                                certificate => certificate.type === 'otherMembershipCertificates',
                                            ).map(membership => (
                                                <ListItem key={membership.file.fullPath}>
                                                    <Link
                                                        href={membership.url}
                                                        isExternal
                                                        target="_blank"
                                                        color="wvwGreen"
                                                    >
                                                        {`${membership.type}: ${membership.label}`}
                                                    </Link>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('membershipsAndQualifications.qualifications')}
                                        </Text>

                                        <List>
                                            {user?.standardQualifications?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {user?.standardQualifications?.map(q => (
                                                <ListItem key={q}>
                                                    {t(q, { ns: 'formik', defaultValue: q })}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    {user.otherQualifications && (
                                        <>
                                            <Flex
                                                alignItems="flex-start"
                                                minH="2rem"
                                                w="100%"
                                            >
                                                <Text
                                                    color="wvwGreen"
                                                    w="17rem"
                                                >
                                                    {t('membershipsAndQualifications.otherQualifications')}
                                                </Text>

                                                <List>
                                                    {user?.otherQualifications?.length === 0 && (
                                                        <ListItem
                                                            color="wvwGrey40"
                                                        >
                                                            {t('none', { context: 'female', ns: 'common' })}
                                                        </ListItem>
                                                    )}

                                                    {user?.otherQualifications?.map(i => (
                                                        <ListItem key={i}>
                                                            {i}
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Flex>

                                            <Divider />
                                        </>
                                    )}

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('membershipsAndQualifications.otherQualificationCertificates')}
                                        </Text>

                                        <List>
                                            {userCertificates?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {userCertificates?.filter(
                                                certificate => certificate.type === 'otherQualificationCertificates',
                                            ).map(qualification => (
                                                <ListItem key={qualification.file.fullPath}>
                                                    <Link
                                                        href={qualification.url}
                                                        isExternal
                                                        target="_blank"
                                                        color="wvwGreen"
                                                    >
                                                        {`${qualification.type}: ${qualification.label}`}
                                                    </Link>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>
                                </VStack>
                            </Box>
                        )}

                        {user?.entity.accountType === 'VALUER' && (
                            <Box
                                bg="wvwGrey05"
                                borderRadius="10"
                                marginTop="1.5rem"
                                padding="1.5rem 2rem"
                                w="100%"
                            >
                                <Heading
                                    as="h4"
                                    color="wvwGrey"
                                    size="md"
                                >
                                    {t('valuationCompetence.sectionTitle')}
                                </Heading>

                                <VStack
                                    paddingTop="1rem"
                                >
                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('valuationCompetence.valuationTypes')}
                                        </Text>

                                        <List>
                                            {user?.valuationTypes?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {user?.valuationTypes?.map(valuation => (
                                                <ListItem key={valuation}>
                                                    {t(valuation, { ns: 'formik', defaultValue: valuation })}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('valuationCompetence.specialValuationTypes')}
                                        </Text>

                                        <List>
                                            {user?.specialValuationTypes?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {user?.specialValuationTypes?.map(valuation => (
                                                <ListItem key={valuation}>
                                                    {t(valuation, { ns: 'formik', defaultValue: valuation })}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('valuationCompetence.otherValuationTypes')}
                                        </Text>

                                        <List>
                                            {user?.otherValuationTypes?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {user?.otherValuationTypes?.map(valuation => (
                                                <ListItem key={valuation}>
                                                    {valuation}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('valuationCompetence.valuationOccasions')}
                                        </Text>

                                        <List>
                                            {user?.valuationOccasions?.length === 0
                                                && user?.otherValuationOccasions?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {user?.valuationOccasions?.map(occasion => (
                                                <ListItem key={occasion}>
                                                    {t(occasion, { ns: 'formik', defaultValue: occasion })}
                                                </ListItem>
                                            ))}

                                            {user?.otherValuationOccasions?.map(occasion => (
                                                <ListItem key={occasion}>
                                                    {occasion}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t('valuationCompetence.propertyTypes')}
                                        </Text>

                                        <List>
                                            {user?.valuationPropertyTypes?.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('none', { context: 'female', ns: 'common' })}
                                                </ListItem>
                                            )}

                                            {user?.valuationPropertyTypes?.map(propertyType => (
                                                <ListItem key={propertyType}>
                                                    {t(toTitleCase(propertyType), { ns: 'formik', defaultValue: toTitleCase(propertyType) })}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Flex>
                                </VStack>
                            </Box>
                        )}
                    </VStack>
                </DrawerBody>

                <DrawerFooter>
                    <Flex w="100%">
                        {(userIsCompanyAdmin && inTeam) && (
                            <Button
                                variant="danger"
                                onClick={() => setConfirmDeleteOpen(true)}
                            >
                                {t('teamDetails.button.revokeAccess', { ns: 'account' })}
                            </Button>
                        )}

                        <Spacer />

                        <Button variant="primary" mr={3} onClick={onClose}>
                            {t('button.close', { ns: 'common' })}
                        </Button>
                    </Flex>
                </DrawerFooter>
            </DrawerContent>

            <ConfirmationModal
                cancelButton={t('button.cancel', { ns: 'common' })}
                cancelButtonVariant="primary"
                continueButton={t('button.continue', { ns: 'common' })}
                continueButtonVariant="danger"
                isOpen={confirmDeleteOpen}
                content={t('teamDetails.revokeAccess.text', { ns: 'account' })}
                title={t('teamDetails.revokeAccess.title', { ns: 'account' })}
                onCancel={() => setConfirmDeleteOpen(false)}
                onContinue={() => {
                    suspendAccount({
                        entityId: me?.entityId,
                        targetUserId: user.id,
                    });
                }}
            />
        </Drawer>
    );
};

export default PublicUserSlider;
