import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetComments } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_COMMENTS = 'DOCUMENT_REQUEST_GET_COMMENTS';

const useDocumentRequestGetComments = (requestId: string | number) => {
    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_COMMENTS, requestId],
        () => documentRequestGetComments(requestId),
        {
            onError: (err: Error) => createErrorToast(
                t(err.message, { defaultValue: err.message }),
            ),
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_COMMENTS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetComments;
