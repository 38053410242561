import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { WvwText } from './typography';
import { WvwButton } from './inputs';
import { FormikForm, FormikPassword } from '../forms';
import { useReauthenticate } from '../../utils/api/hooks';

type PropTypes = {
    isOpen: boolean;
    onCancel: () => void;
    onContinue: () => void;
};

const PasswordConfirmModal = (props: PropTypes) => {
    const {
        isOpen,
        onCancel,
        onContinue,
    } = props;

    const { t } = useTranslation('common');

    const { update: signin } = useReauthenticate({
        onSuccess: onContinue,
    });

    return (
        <Modal
            isOpen={isOpen}
            onClose={onCancel}
            size="lg"
        >
            <ModalOverlay />

            <FormikForm
                initialValues={{
                    password: '',
                }}
                onSubmit={values => signin(values.password)}
            >
                <ModalContent alignItems="center">
                    <ModalHeader color="wvwGreen">
                        {t('modal.confirmPassword.title')}
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody
                        paddingBottom="1rem"
                        textAlign="center"
                    >
                        <VStack
                            spacing="3"
                            w="100%"
                        >
                            <WvwText>
                                {t('modal.confirmPassword.message')}
                            </WvwText>

                            <FormikPassword
                                name="password"
                                placeholder={t('instruction.pleaseEnter', { item: t('instruction.password', { ns: 'login' }), ns: 'login' })}
                            />
                        </VStack>
                    </ModalBody>

                    <ModalFooter w="60%">
                        <Flex
                            gap="2"
                            justifyContent="space-between"
                            w="100%"
                        >
                            <WvwButton
                                block
                                content={t('button.close')}
                                onClick={onCancel}
                            />

                            <WvwButton
                                block
                                content={t('button.continue')}
                                formSubmitButton
                                onClick={() => {}}
                                variant="primary"
                            />
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </FormikForm>
        </Modal>
    );
};

export default PasswordConfirmModal;
