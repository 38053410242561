import { useQuery, useQueryClient } from 'react-query';
import { offerGetByEnquiryId } from '../endpoints';

const OFFERS_BY_ENQUIRY_ID = 'OFFERS_BY_ENQUIRY_ID';

const useOfferGetByEnquiryId = (enquiryId: number | string) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([OFFERS_BY_ENQUIRY_ID, enquiryId], () => offerGetByEnquiryId(enquiryId));

    const refresh = () => {
        queryClient.invalidateQueries(OFFERS_BY_ENQUIRY_ID);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useOfferGetByEnquiryId;
