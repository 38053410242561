import { AddIcon, CheckCircleIcon } from '@chakra-ui/icons';
import {
    AccordionItem,
    AccordionPanel,
    Flex,
    VStack,
} from '@chakra-ui/react';
import { Location } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WvwButton, WvwLinkButton } from '../../../../../common/components/inputs';
import { CaseType, InspectionType, PropertyType } from '../../../../../types';
import InspectionDetailsItem from './InspectionDetailsItem';
import WvwAccordionButton from '../../../../../common/components/display/WvwAccordionButton';
import InspectionStatusBadge from './InspectionStatusBadge';
import { useInspectionCreate, useInspectionRejectAllTentativeDates } from '../../../../../utils/api/hooks';
import { useRole } from '../../../../../common/hooks';
import { WVWTitle } from '../../../../../common/components/typography';
import InspectionConfirmationModal from './InspectionConfirmationModal';

type PropTypes = {
    caseData: CaseType;
    inspectionList: InspectionType[];
    property: PropertyType;
    onClick: (property: PropertyType) => void;
};

const PropertyAccordionItem = (props: PropTypes) => {
    const {
        caseData,
        inspectionList,
        property,
        onClick,
    } = props;

    const { t } = useTranslation('inspection');
    const { caseId } = useParams();
    const { userIsValuer } = useRole();

    const [rejectAllOpen, setRejectAllOpen] = useState(false);

    const { update } = useInspectionCreate({ caseId: Number(caseId) });

    const { update: rejectAll } = useInspectionRejectAllTentativeDates({
        caseId: Number(caseId),
        onSuccess: () => setRejectAllOpen(false),
    });

    const inspections = useMemo(
        () => inspectionList
            .filter(inspection => inspection.propertyId === property.id)
            .sort((a, b) => b.id - a.id),
        [inspectionList],
    );

    const status = useMemo(() => {
        const iList = inspectionList.map(i => i.phase);

        if (iList.includes('PREPARATION')) return <InspectionStatusBadge status="awaitingDates" />;
        if (iList.includes('NEGOTIATION')) return <InspectionStatusBadge status="awaitingConfirmation" />;
        if (iList.includes('INSPECTION')) return <InspectionStatusBadge status="scheduled" />;
        if (iList.includes('FINISHED')) return <InspectionStatusBadge status="accepted" />;

        return null;
    }, [inspections]);

    const tickCircle = useMemo(() => {
        if (inspections.length === 0) return null;
        if (inspections.some(i => i.phase !== 'FINISHED')) return null;

        return (
            <CheckCircleIcon
                boxSize="1.2rem"
                color="green"
            />
        );
    }, [inspections]);

    const addInspectionButton = useMemo(() => {
        if (userIsValuer) return null;
        if (inspections.some(i => i.phase !== 'FINISHED')) return null;

        return (
            <WvwLinkButton
                content={t('button.addInspection')}
                icon={<AddIcon />}
                onClick={() => update({
                    propertyId: property.id,
                    target: Number(caseId),
                })}
            />
        );
    }, [inspections]);

    const rejectAllButton = useMemo(() => {
        if (userIsValuer) return null;
        if (inspections.every(i => i.phase !== 'NEGOTIATION')) return null;

        return (
            <WvwButton
                content={t('button.rejectAll')}
                onClick={() => setRejectAllOpen(true)}
                size="sm"
                variant="danger"
            />
        );
    }, [inspections]);

    return (
        <>
            <AccordionItem onClick={() => onClick(property)}>
                <WvwAccordionButton
                    actionButton={rejectAllButton}
                    icon={(
                        <Location
                            color="red"
                            size="24"
                            variant="Bold"
                        />
                    )}
                    statusBadge={status}
                    title={(
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            w="100%"
                        >
                            <WVWTitle
                                black
                                content={(
                                    t('addressOneLiner', {
                                        street: property?.street,
                                        city: property?.city,
                                        country: property?.country,
                                        postalCode: property?.postalCode,
                                        ns: 'common',
                                    })
                                )}
                                level="3"
                                normalFontWeight
                            />

                            {tickCircle}
                        </Flex>
                    )}
                />

                <AccordionPanel paddingInline="0">
                    <VStack align="start">
                        {addInspectionButton}

                        {inspections.map(inspection => (
                            <InspectionDetailsItem
                                caseData={caseData}
                                inspection={inspection}
                                key={inspection.id}
                            />
                        ))}
                    </VStack>
                </AccordionPanel>
            </AccordionItem>

            <InspectionConfirmationModal
                isOpen={rejectAllOpen}
                title={t('modal.rejectAppointments.title')}
                content={t('modal.rejectAppointments.content')}
                property={property}
                onConfirm={() => {
                    const inspection = inspections.find(i => i.phase === 'NEGOTIATION');

                    if (inspection) rejectAll(inspection.id);
                }}
                onClose={() => setRejectAllOpen(false)}
            />
        </>
    );
};

export default PropertyAccordionItem;
