import { useQuery, useQueryClient } from 'react-query';
import { AdminOffersDto } from '../../dto';
import { adminOffers } from '../../endpoints';

const ADMIN_OFFERS = 'ADMIN_OFFERS';

const useAdminOffers = (dto: AdminOffersDto) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([ADMIN_OFFERS, JSON.stringify(dto)], () => adminOffers(dto));

    const refresh = () => {
        queryClient.invalidateQueries(ADMIN_OFFERS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useAdminOffers;
