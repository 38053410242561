import { Flex, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { WVWTitle } from '../typography';
import { useRole } from '../../hooks';
import AwaitingAccess from '../AwaitingAccess';

type PropTypes = {
    allowUnapprovedUser?: boolean;
    children: ReactNode | ReactNode[];
    extra?: ReactNode | ReactNode[];
    title: string;
};

const PageWithTitleLayout = (props: PropTypes) => {
    const {
        allowUnapprovedUser,
        children,
        extra,
        title,
    } = props;

    const { userIsApproved, userIsSystemAdmin } = useRole();

    const showPage = userIsSystemAdmin || userIsApproved || allowUnapprovedUser;

    return (
        <VStack
            alignItems="space-between"
            spacing="2"
            w="100%"
        >
            <Flex justifyContent="space-between" w="100%">
                <WVWTitle
                    black
                    content={title}
                    level="1"
                />

                {showPage && extra}
            </Flex>

            {!showPage && <AwaitingAccess />}

            {showPage && children}
        </VStack>
    );
};

PageWithTitleLayout.defaultProps = {
    allowUnapprovedUser: false,
    extra: null,
};

export default PageWithTitleLayout;
