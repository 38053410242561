import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
} from 'firebase/auth';

const {
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_APP_ID,
    REACT_APP_FIREBASE_AUTH_DOMAIN,
    REACT_APP_FIREBASE_MEASUREMENT_ID,
    REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_SENDER_ID,
    REACT_APP_FIREBASE_STORAGE_BUCKET,
} = process.env;

const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const getToken = () => auth.currentUser?.getIdToken();

const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
        // eslint-disable-next-line no-alert
        alert(err.message);
    }
};

const registerWithEmailAndPassword = async ({
    email,
    password,
}: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    mobile: string;
}) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
        alert(err.message);
    }
};

// const sendPasswordReset = async (email: string) => {
//     try {
//         await sendPasswordResetEmail(auth, email);
//         // eslint-disable-next-line no-alert
//         alert('Password reset link sent!');
//     } catch (err: any) {
//         console.error(err);
//         // eslint-disable-next-line no-alert
//         alert(err.message);
//     }
// };

const logout = () => {
    signOut(auth);
};

export {
    app,
    auth,
    getToken,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    logout,
};
