import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const getNoAuth = async (path: string, params?: { [index: string]: unknown }) => {
    const result = await axios.get(
        `${REACT_APP_API_URL}${path}`,
        {
            params,
        },
    ).catch(error => {
        throw new Error(error?.response?.data?.message || error?.response?.statusText || 'Unknown error');
    });

    return result?.data;
};

export default getNoAuth;
