import { useQuery, useQueryClient } from 'react-query';
import { clientInvitesIsValid, userInvitesIsValid, valuerInvitesIsValid } from '../../endpoints';

const INVITE = 'INVITE';

type ParamsType = {
    token: string;
    inviteType: 'invitedToClientPanel' | 'invitedToValuerPanel' | 'invitedToTeam';
};

const useInviteValidateInvite = (params: ParamsType) => {
    const { token, inviteType } = params;

    const queryClient = useQueryClient();

    const {
        data: isValid,
        isLoading,
    } = useQuery([INVITE, inviteType], () => {
        if (inviteType === 'invitedToClientPanel') {
            return clientInvitesIsValid(token);
        }

        if (inviteType === 'invitedToValuerPanel') {
            return valuerInvitesIsValid(token);
        }

        return userInvitesIsValid(token);
    });

    const refresh = () => {
        queryClient.invalidateQueries(INVITE);
    };

    return {
        isValid,
        isLoading,
        refresh,
    };
};

export default useInviteValidateInvite;
