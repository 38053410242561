import {
    Select,
    Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string;
    width?: string;
    options: {
        disabled?: boolean;
        label: string;
        value: string | number;
    }[];
    placeholder?: string;
};

const FormikSelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        options,
        width,
    } = props;

    const [field, meta] = useField(props);

    return (
        <div
            style={{
                width,
            }}
        >
            <Select
                bg="white"
                h="3rem"
                borderRadius="8"
                fontSize="md"
                isInvalid={!!meta.touched && !!meta.error}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            >
                {options.map(option => (
                    <option
                        key={`${option.label} ${option.value}`}
                        disabled={option.disabled}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </Select>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </div>
    );
};

FormikSelect.defaultProps = {
    placeholder: '',
    width: undefined,
};

export default FormikSelect;
