import {
    Center, Spinner, Text, VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    color?: string;
    noText?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    thickness?: string;
};

const LoadingSpinner = (props: PropTypes) => {
    const { t } = useTranslation('common');
    const {
        color,
        noText,
        size,
        thickness,
    } = props;

    return (
        <Center
            w="100%"
            h="100%"
        >
            <VStack
                spacing="4"
                w="100%"
            >
                <Spinner
                    color={color}
                    size={size}
                    thickness={thickness}
                />

                { !noText && (
                    <Text>
                        {t('loading')}
                    </Text>
                ) }
            </VStack>
        </Center>
    );
};

LoadingSpinner.defaultProps = {
    color: 'wvwYellow',
    noText: false,
    size: 'md',
    thickness: undefined,
};

export default LoadingSpinner;
