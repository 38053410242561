import {
    AbsoluteCenter,
    As,
    Box,
    Divider,
    Icon,
} from '@chakra-ui/react';

type PropTypes = {
    icon?: As;
    onClick?: () => void;
    minHeight?: string;
};

const VerticalDivider = (props: PropTypes) => {
    const { icon, onClick, minHeight } = props;

    return (
        <Box
            paddingInline="1rem"
            position="relative"
            h="100%"
        >
            <Divider
                borderWidth="0.07rem"
                h="100%"
                marginLeft="0"
                minH={minHeight}
                orientation="vertical"
            />

            {icon && (
                <AbsoluteCenter
                    alignItems="right"
                    bg="white"
                    paddingBlock=".5rem"
                >
                    <Icon
                        as={icon}
                        onClick={() => onClick?.()}
                        color="wvwGrey40"
                        boxSize="8"
                    />
                </AbsoluteCenter>
            )}
        </Box>
    );
};

VerticalDivider.defaultProps = {
    icon: undefined,
    onClick: undefined,
    minHeight: '40rem',
};

export default VerticalDivider;
