import { Link as ChakraLink, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import { WvwText } from './typography';
import { WvwButton } from './inputs';
import { ContentBox } from './display';

const AwaitingAccess = () => {
    const { t } = useTranslation('account');

    return (
        <ContentBox>
            <VStack
                paddingInline="1rem"
                spacing="4"
                w="100%"
            >
                <WvwText textAlign="center">
                    {t('awaitingApproval')}
                </WvwText>

                <ChakraLink
                    as={ReactLink}
                    to="/dashboard/profile"
                >
                    <WvwButton content={t('backToProfile', { ns: 'common' })} />
                </ChakraLink>
            </VStack>
        </ContentBox>
    );
};

export default AwaitingAccess;
