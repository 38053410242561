import ClientEnquiryOverview from './common/ClientEnquiryOverview';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import ValuerEnquiryOverview from './common/ValuerEnquiryOverview';
import { useRole } from '../../../../common/hooks';

const EnquiryOverview = () => {
    const { userIsClient, roleLoading } = useRole();

    if (roleLoading) return <LoadingSpinner />;

    if (userIsClient) return <ClientEnquiryOverview />;

    return <ValuerEnquiryOverview />;
};

export default EnquiryOverview;
