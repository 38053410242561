import {
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
    useEntityPanelValuers,
    useMe,
    useMyEntity,
    useMyValuerPanels,
    useUsersByEntity,
} from '../../../utils/api/hooks';
import { useRole } from '../../hooks';
import WvwAlertBanner from './WvwAlertBanner';
import PiCoverExpiryAlert from './PiCoverExpiryAlert';

const WvwAlerts = () => {
    const { t } = useTranslation('updates');

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    const {
        usersList = [],
        isLoading: usersLoading,
    } = useUsersByEntity(entity?.id);

    // The the panels the Valuer is on
    const {
        data: valuerPanels,
    } = useMyValuerPanels();

    // The Client's valuer panel
    const {
        valuerList,
    } = useEntityPanelValuers();

    const {
        userIsClient,
        userIsCompany,
        userIsCompanyAdmin,
        userIsValuer,
        userIsApproved,
    } = useRole();

    if (entityLoading || userLoading || usersLoading) {
        return null;
    }

    const showAwaitingApproval = !userIsApproved;

    const showTeamAlert = (
        userIsCompany
        && userIsCompanyAdmin
        && usersList.length === 1
    );

    const showClientValuerPanelAlert = (
        userIsCompany
        && userIsClient
        && userIsCompanyAdmin
        && valuerList?.length === 0
    );

    const showValuerClientPanelAlert = (
        userIsCompany
        && userIsValuer
        && userIsCompanyAdmin
        && valuerPanels?.length === 0
    );

    const showAccountAlert = (
        !entity.city
        || !entity.country
        || !entity.postalCode
        || !entity.street
    );

    const showProfileAlert = (
        !user.city
        || !user.country
        || !user.postalCode
        || !user.street
    );

    return (
        <VStack>
            {showAwaitingApproval && (
                <WvwAlertBanner
                    isMandatory
                    message={t('missingInformation.awaitingApproval')}
                />
            )}

            {showAccountAlert && (
                <WvwAlertBanner
                    isMandatory
                    updateNeeded="/dashboard/account"
                    message={t('missingInformation.mandatoryAccountFields')}
                />
            )}

            {showProfileAlert && (
                <WvwAlertBanner
                    isMandatory
                    updateNeeded="/dashboard/profile"
                    message={t('missingInformation.mandatoryProfileFields')}
                />
            )}

            {showTeamAlert && (
                <WvwAlertBanner
                    updateNeeded="/dashboard/account/team"
                    message={t('missingInformation.teamMembers')}
                />
            )}

            {showClientValuerPanelAlert && (
                <WvwAlertBanner
                    updateNeeded="/dashboard/account/valuer-panel"
                    message={t('missingInformation.valuerPanel')}
                />
            )}

            {showValuerClientPanelAlert && (
                <WvwAlertBanner
                    updateNeeded="/dashboard/account/valuer-panel"
                    message={t('missingInformation.customerPanel')}
                />
            )}

            <PiCoverExpiryAlert />
        </VStack>
    );
};

export default WvwAlerts;
