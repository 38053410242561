import { useQuery, useQueryClient } from 'react-query';
import storage from '../../storage/storage';

const PUBLIC_ENTITY_PROFILE_PICTURE = 'PUBLIC_ENTITY_PROFILE_PICTURE';

const usePublicEntityProfilePicture = (entityId: string | number) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [PUBLIC_ENTITY_PROFILE_PICTURE, entityId],
        async () => {
            const profilePicture = await storage.getEntityProfilePictureUrl(Number(entityId));

            if (!profilePicture) {
                return '';
            }

            return profilePicture;
        },
        {
            enabled: !!entityId,
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(PUBLIC_ENTITY_PROFILE_PICTURE);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default usePublicEntityProfilePicture;
