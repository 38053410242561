import {
    VStack,
    Button,
    Box,
    Flex,
    IconButton,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { DocumentCloud, Edit } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FormikForm, FormikInput } from '../../../../../../../common/forms';
import { FileUploadInput } from '../../../../../../../common/inputs';
import { useDocumentRequestUpdateDocument, useDocumentRequestUploadDocument } from '../../../../../../../utils/api/hooks';
import WvwDeleteIconButton from '../../../../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    caseId: string | number;
    requestId: string | number;
    replacedDocumentId: string | number;
    onClose: () => void;
};

type DocumentType = {
    requestId: number;
    file: File;
    submissionComment: string;
};

const DocumentUploadWithNote = (props: PropTypes) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        caseId,
        requestId,
        replacedDocumentId,
        onClose,
    } = props;

    const [documents, setDocuments] = useState<DocumentType[]>([]);

    const { update: upload } = useDocumentRequestUploadDocument({ caseId });

    const { update: updateDocument } = useDocumentRequestUpdateDocument({
        caseId: Number(caseId),
        noSuccessToast: true,
        onSuccess: () => {
            documents.forEach(doc => {
                upload({
                    file: doc.file,
                    caseId,
                    requestId,
                    submissionComment: doc.submissionComment,
                });
            });

            onClose();
            navigate(`/dashboard/case/${caseId}/documents`, { replace: true });
        },
    });

    return (
        <FormikForm
            initialValues={{
                submissionComment: '',
            }}
            onSubmit={values => {
                setDocuments([
                    ...documents,
                    {
                        requestId: Number(requestId),
                        file: documents[0].file,
                        submissionComment: values.submissionComment,
                    },
                ]);

                updateDocument({
                    documentId: Number(replacedDocumentId),
                    deleted: true,
                });
            }}
        >
            <VStack w="100%">
                <FileUploadInput
                    noRename
                    onChange={files => files.forEach(file => {
                        setDocuments([
                            ...documents,
                            {
                                requestId: Number(requestId),
                                file,
                                submissionComment: '',
                            },
                        ]);
                    })}
                />

                {documents.map(doc => (
                    <VStack
                        key={doc.file.name}
                        w="100%"
                    >
                        <Flex
                            paddingBlock=".5rem"
                            paddingInline="1rem"
                            borderRadius="10"
                            w="100%"
                            bg="wvwGrey05"
                            align="center"
                        >
                            <Box marginInlineEnd="1rem">
                                <DocumentCloud />
                            </Box>

                            <Text variant="secondary">
                                {doc.file.name}
                            </Text>

                            <Spacer />

                            <IconButton
                                aria-label="Rename File"
                                icon={<Edit />}
                                onClick={() => {
                                    // Set Edit
                                }}
                                variant="ghost"
                            />

                            <WvwDeleteIconButton
                                onClick={() => {
                                    setDocuments(
                                        documents.filter(j => j.file.name !== doc.file.name),
                                    );
                                }}
                                color="black"
                            />
                        </Flex>
                    </VStack>
                ))}

                <FormikInput
                    name="submissionComment"
                    placeholder={t('submissionNote', { ns: 'documentmanagement' })}
                />

                <Button
                    variant="primaryYellow"
                    alignSelf="flex-end"
                    type="submit"
                >
                    {t('replace', { ns: 'documentmanagement' })}
                </Button>
            </VStack>
        </FormikForm>
    );
};

export default DocumentUploadWithNote;
