import {
    Divider,
    Flex,
    HStack,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Folder } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import delimitNumber from '../../../functions/delimitNumber';
import LoadingSpinner from '../../LoadingSpinner';
import PropertyOverview from '../../PropertyOverview';
import {
    useEntityPanelValuers,
    usePublicUser,
} from '../../../../utils/api/hooks';
import AccordionContainer from '../../AccordionContainer';
import { EnquiryType, EntityType, OfferType } from '../../../../types';
import toUrl from '../../../functions/toUrl';
import { useRole } from '../../../hooks';

type PropTypes = {
    variant?: string;
    overviewData: EnquiryType;
    acceptedOffer?: OfferType;
};

const GeneralEnquiryOverview = (props: PropTypes) => {
    const { variant, overviewData, acceptedOffer } = props;

    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);

    const {
        userIsCompany,
        userIsClient,
        userIsValuer,
    } = useRole();

    const {
        valuerList = [] as unknown as EntityType[],
        isLoading: valuerLoading,
    } = useEntityPanelValuers();

    const selectedValuers = valuerList?.filter(
        valuer => overviewData?.toEntityIds.includes(valuer.id),
    );

    const {
        data: user,
        isLoading,
    } = usePublicUser(overviewData?.fromUserId);

    if (valuerLoading || isLoading) return <LoadingSpinner />;

    return (
        <VStack
            w="100%"
            align="left"
            borderRadius="10"
        >
            <VStack
                w="100%"
                align="left"
                bg={variant === 'white' ? 'white' : 'wvwGrey05'}
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="2.5rem"
            >
                <Heading
                    as="h4"
                    color="wvwGreen"
                    paddingBlock=".8rem"
                    size="md"
                >
                    {t('newEnquiry.overview.valuation.requestedTitle')}
                </Heading>

                <Flex
                    alignItems="flex-start"
                    w="100%"
                >
                    <Text
                        maxW="18rem"
                        w="100%"
                    >
                        {t('newEnquiry.overview.valuation.type')}
                    </Text>

                    {overviewData?.valuationType === 'Other Valuation' && (
                        <VStack align="left">
                            <Text>
                                {t(overviewData?.valuationType, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                            </Text>

                            {overviewData?.valuationSpecial?.map(special => (
                                <Text
                                    key={special}
                                    pl="1rem"
                                >
                                    {t(special, { ns: 'formik', defaultValue: special })}
                                </Text>
                            ))}

                            <Text pl="1rem">
                                {overviewData?.valuationOther}
                            </Text>
                        </VStack>
                    )}

                    {overviewData?.valuationType === 'Retype Valuation' && (
                        <VStack align="left">
                            <Text>
                                {t(overviewData?.valuationType || '', { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                            </Text>

                            {overviewData?.retypeDocuments?.map(document => (
                                <Link
                                    href={document.url}
                                    isExternal
                                    key={document.id}
                                    color="wvwGreen"
                                    pl="1rem"
                                >
                                    <HStack>
                                        <Folder size="1rem" />

                                        <Text>
                                            {document.name}
                                        </Text>
                                    </HStack>
                                </Link>
                            ))}
                        </VStack>
                    )}

                    {overviewData?.valuationType !== ('Retype Valuation' && 'Special Valuation' && 'Other Valuation') && (
                        <Text>
                            {t(overviewData?.valuationType || '', { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                        </Text>
                    )}
                </Flex>

                <Divider />

                {(userIsCompany || userIsValuer) && (
                    <>
                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuation.standards')}
                            </Text>

                            <VStack align="left">
                                {overviewData?.valuationStandards === 'Other' ? (
                                    <Text color="wvwGrey40">
                                        {t(overviewData?.valuationStandardsOther || '', { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                    </Text>
                                ) : (
                                    <Text color="wvwGrey40">
                                        {t(overviewData?.valuationStandards || '', { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                    </Text>
                                )}
                            </VStack>
                        </Flex>

                        <Divider />
                    </>
                )}

                <Flex
                    alignItems="flex-start"
                    w="100%"
                >
                    <Text
                        maxW="18rem"
                        w="100%"
                    >
                        {t('newEnquiry.overview.valuation.purpose')}
                    </Text>

                    <VStack align="left">
                        {overviewData?.valuationPurposes?.map(purpose => (
                            <HStack key={purpose}>
                                <Text>
                                    {t(purpose, { ns: 'formik', defaultValue: purpose })}
                                </Text>
                            </HStack>
                        ))}

                        {overviewData.valuationPurposes.includes('Other') && (
                            <Text pl="1rem">
                                {`${overviewData?.valuationPurposesOther}`}
                            </Text>
                        )}
                    </VStack>
                </Flex>

                <Divider />

                <Flex
                    alignItems="flex-start"
                    w="100%"
                >
                    <Text
                        maxW="18rem"
                        w="100%"
                    >
                        {t('newEnquiry.overview.completionExpectation')}
                    </Text>

                    <VStack align="left">
                        <Text>
                            {overviewData?.completionDate}
                        </Text>
                    </VStack>
                </Flex>
            </VStack>

            {acceptedOffer && (
                <VStack
                    w="100%"
                    align="left"
                    bg={variant === 'white' ? 'white' : 'wvwGrey05'}
                    borderRadius="10"
                    paddingBlock="1rem"
                    paddingInline="2.5rem"
                >
                    <Heading
                        as="h4"
                        size="md"
                        color="wvwGreen"
                        paddingBlock=".8rem"
                    >
                        {t('acceptedOffer.valuer.sectionTitle', { ns: 'enquiryform' })}
                    </Heading>

                    <VStack
                        w="100%"
                        align="left"
                    >
                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('acceptedOffer.valuer.companyName', { ns: 'enquiryform' })}
                            </Text>

                            <Text>
                                {acceptedOffer?.fromValuer.workingForCompanyName}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('acceptedOffer.valuer.name', { ns: 'enquiryform' })}
                            </Text>

                            <Text>
                                {`${acceptedOffer?.fromValuer.firstName} ${acceptedOffer?.fromValuer.lastName}`}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('acceptedOffer.piCover', { ns: 'enquiryform' })}
                            </Text>

                            <Text>
                                {t('piCover_or_statement', {
                                    ns: 'common',
                                    coverValue: acceptedOffer?.piCoverValue === 0 || null ? '-' : delimitNumber(acceptedOffer?.piCoverValue?.toString()),
                                    coverPercentage: acceptedOffer?.piCoverPercentage === 0 || null ? '-' : acceptedOffer?.piCoverPercentage,
                                })}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('acceptedOffer.price', { ns: 'enquiryform' })}
                            </Text>

                            <Text>
                                {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(acceptedOffer?.price)}`}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('acceptedOffer.earliestInspection', { ns: 'enquiryform' })}
                            </Text>

                            <Text>
                                {acceptedOffer?.availability}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('acceptedOffer.timeline', { ns: 'enquiryform' })}
                            </Text>

                            <Text>
                                {`${acceptedOffer?.timeline} days`}
                            </Text>
                        </Flex>

                        <Divider />
                    </VStack>
                </VStack>
            )}

            {(userIsCompany || userIsValuer) && (
                <VStack
                    w="100%"
                    align="left"
                    bg={variant === 'white' ? 'white' : 'wvwGrey05'}
                    borderRadius="10"
                    paddingBlock="1rem"
                    paddingInline="2.5rem"
                >
                    <Heading
                        as="h4"
                        size="md"
                        color="wvwGreen"
                        paddingBlock=".8rem"
                    >
                        {t('newEnquiry.overview.valuer.requirements')}
                    </Heading>

                    <VStack
                        w="100%"
                        align="left"
                    >
                        {userIsClient && (
                            <>
                                <Flex
                                    alignItems="flex-start"
                                    w="100%"
                                >
                                    <Text
                                        maxW="18rem"
                                        w="100%"
                                    >
                                        {t('valuerRequirements.valuerPanel', { ns: 'enquiries' })}
                                    </Text>

                                    <VStack align="left">
                                        {selectedValuers?.map(valuer => (
                                            <Text key={valuer.id}>
                                                {valuer.name}
                                            </Text>
                                        ))}
                                        {selectedValuers?.length === 0 && (
                                            <Text color="wvwGrey40">
                                                {t('noRequirement', { ns: 'enquiryform' })}
                                            </Text>
                                        )}
                                    </VStack>
                                </Flex>

                                <Divider />
                            </>
                        )}

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuer.qualifications')}
                            </Text>

                            <VStack align="left">
                                {overviewData?.valuerQualifications?.length === 0 || null
                                    ? (
                                        <Text color="wvwGrey40">
                                            {t('noRequirement')}
                                        </Text>
                                    ) : (
                                        overviewData?.valuerQualifications?.map(qualification => (
                                            <Text key={qualification}>
                                                {qualification === 'Other' ? '' : t(qualification, { ns: 'formik', defaultValue: qualification })}
                                            </Text>
                                        ))
                                    )}
                                {overviewData?.valuerQualificationsOther && (
                                    <Text>
                                        {overviewData?.valuerQualificationsOther}
                                    </Text>
                                )}
                            </VStack>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuer.minExperience')}
                            </Text>

                            <Text color={overviewData?.valuerExperience === '0' ? 'wvwGrey40' : 'inherit'}>
                                {t(`newEnquiry.overview.valuerRequirements.minValuerExperience.${overviewData?.valuerExperience}`, { ns: 'enquiryform', defaultValue: `${t('noRequirement')}` })}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuer.minPICover')}
                            </Text>

                            <Text color={overviewData?.valuerMinimumPICoverPercentage <= 0 ? 'wvwGrey40' : 'inherit'}>
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {overviewData?.valuerMinimumPICover ? (
                                    `€ ${delimitNumber(overviewData?.valuerMinimumPICover?.toString())}`
                                ) : overviewData?.valuerMinimumPICoverPercentage === 0 || null
                                    ? (
                                        t('noRequirement')
                                    ) : (
                                        `${overviewData?.valuerMinimumPICoverPercentage} %`
                                    )}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuer.minPartners')}
                            </Text>

                            <Text color={overviewData?.valuerMinimumPartners === '0' ? 'wvwGrey40' : 'inherit'}>
                                {t(`newEnquiry.overview.valuerRequirements.minPartners.${overviewData?.valuerMinimumPartners}`, { ns: 'enquiryform', defaultValue: `${t('noRequirement')}` })}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuer.maxDistance')}
                            </Text>

                            <Text color={overviewData?.valuerMaxOfficeDistance === 'none' ? 'wvwGrey40' : 'inherit'}>
                                {t(`newEnquiry.overview.valuerRequirements.maxDistance.${overviewData?.valuerMaxOfficeDistance}`, { ns: 'enquiryform', defaultValue: `${t('noRequirement')}` })}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="18rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuer.additionalRequirements')}
                            </Text>

                            {overviewData?.additionalRequirements === '' || null ? (
                                <Text color="wvwGrey40">
                                    {t('noRequirement')}
                                </Text>
                            ) : (
                                <Text>
                                    {overviewData?.additionalRequirements}
                                </Text>
                            )}
                        </Flex>
                    </VStack>
                </VStack>
            )}

            <VStack
                w="100%"
                align="left"
                bg={variant === 'white' ? 'white' : 'wvwGrey05'}
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="2.5rem"
            >
                <Heading
                    as="h4"
                    size="md"
                    color="wvwGreen"
                    paddingBlock=".8rem"
                >
                    {t('newEnquiry.overview.contact.sectionTitle')}
                </Heading>

                <VStack
                    w="100%"
                    align="left"
                >
                    <Flex
                        alignItems="flex-start"
                        w="100%"
                    >
                        <Text
                            maxW="18rem"
                            w="100%"
                        >
                            {t('newEnquiry.overview.contact.fullName')}
                        </Text>

                        <Text>
                            {overviewData?.invoiceFName}
                            {' '}
                            {overviewData?.invoiceLName}
                        </Text>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="flex-start"
                        w="100%"
                    >
                        <Text
                            maxW="18rem"
                            w="100%"
                        >
                            {t('newEnquiry.overview.contact.email')}
                        </Text>

                        <Text>
                            {overviewData?.invoiceEmail}
                        </Text>
                    </Flex>

                    <Divider />

                    <Flex
                        alignItems="flex-start"
                        w="100%"
                    >
                        <Text
                            maxW="18rem"
                            w="100%"
                        >
                            {t('newEnquiry.overview.contact.mobileNumber')}
                        </Text>

                        <Text>
                            {overviewData?.invoiceMobileDialCode}
                            {' '}
                            {overviewData?.invoiceMobile}
                        </Text>
                    </Flex>
                </VStack>
            </VStack>

            {(userIsCompany && userIsClient) && (
                <VStack
                    w="100%"
                    align="left"
                    bg={variant === 'white' ? 'white' : 'wvwGrey05'}
                    borderRadius="10"
                    paddingBlock="1rem"
                    paddingInline="2.5rem"
                >
                    <Heading
                        as="h4"
                        color="wvwGreen"
                        size="md"
                    >
                        {t('companyDetails.sectionTitle', { ns: 'account' })}
                    </Heading>

                    <VStack paddingTop="1rem">
                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text
                                w="100%"
                                maxW="18rem"
                            >
                                {t('companyDetails.website', { ns: 'account' })}
                            </Text>

                            {user?.entity?.websiteAddress ? (
                                <Link
                                    href={toUrl(user?.entity?.websiteAddress)}
                                    isExternal
                                    textOverflow="ellipsis"
                                    noOfLines={1}
                                >
                                    {user?.entity?.websiteAddress}
                                </Link>
                            ) : (
                                <Text color="wvwGrey40">
                                    {t('fieldNotProvided.website', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text w="18rem">
                                {t('companyDetails.email', { ns: 'account' })}
                            </Text>

                            {user?.entity?.companyEmail ? (
                                <Text>
                                    {user?.entity?.companyEmail}
                                </Text>
                            ) : (
                                <Text color="wvwGrey40">
                                    {t('fieldNotProvided.email', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex
                            alignItems="center"
                            h="2rem"
                            w="100%"
                        >
                            <Text w="18rem">
                                {t('companyDetails.phoneNumber', { ns: 'account' })}
                            </Text>

                            {user?.entity?.contactNumber ? (
                                <Text>
                                    {`${user?.entity?.contactNumberDialCode} ${user?.entity?.contactNumber}`}
                                </Text>
                            ) : (
                                <Text color="wvwGrey40">
                                    {t('fieldNotProvided.phoneNumber', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        <Flex w="100%">
                            <Text
                                w="18rem"
                                alignItems="flex-start"
                            >
                                {t('companyDetails.location', { ns: 'account' })}
                            </Text>

                            {user?.entity?.street ? (
                                <VStack align="left">
                                    <Text>
                                        {`${user?.entity?.street}, ${user?.entity?.postalCode}`}
                                    </Text>

                                    <Text>
                                        {`${user?.entity?.city}, ${user?.entity?.country}`}
                                    </Text>
                                </VStack>
                            ) : (
                                <Text color="wvwGrey40">
                                    {t('fieldNotProvided.location', { ns: 'common' })}
                                </Text>
                            )}
                        </Flex>
                    </VStack>
                </VStack>
            )}

            <VStack
                w="100%"
                align="left"
                bg={variant === 'white' ? 'white' : 'wvwGrey05'}
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="2.5rem"
            >
                <Heading
                    as="h4"
                    size="md"
                    color="wvwGreen"
                    paddingBlock=".8rem"
                >
                    {t('newEnquiry.overview.property.detailsSectionTitle')}
                </Heading>

                <Flex
                    w="100%"
                    alignItems="flex-start"
                >
                    <VStack
                        w="100%"
                        alignSelf="end"
                        align="start"
                    >
                        {overviewData?.properties?.map(property => (
                            <AccordionContainer
                                title={`${property.street}, ${property.city}`}
                                key={`${property.street} ${property.city}`}
                            >
                                <PropertyOverview property={property} />
                            </AccordionContainer>
                        ))}
                    </VStack>
                </Flex>
            </VStack>
        </VStack>
    );
};

GeneralEnquiryOverview.defaultProps = {
    acceptedOffer: null,
    variant: 'white',
};

export default GeneralEnquiryOverview;
