import { useMutation } from 'react-query';
import { OfferCreateDto } from '../dto';
import { offerCreate } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useOfferCreate = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: OfferCreateDto) => offerCreate(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useOfferCreate;
