import { useMutation } from 'react-query';
import { DocumentsSendEmailDto } from '../dto';
import documentsSendEmail from '../endpoints/documents-send-email';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useDocumentsSendEmail = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: DocumentsSendEmailDto) => documentsSendEmail(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useDocumentsSendEmail;
