import { useMutation } from 'react-query';
import { offerCommentsMarkAsRead } from '../endpoints';
import { OfferCommentsMarkAsReadDto } from '../dto';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error?: Error) => void;
};

const useOfferCommentsMarkAsRead = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (commentIds: OfferCommentsMarkAsReadDto) => offerCommentsMarkAsRead(commentIds),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useOfferCommentsMarkAsRead;
