import {
    ReactNode,
    createContext,
    useMemo,
    useState,
} from 'react';

type PropTypes = {
    steps: ReactNode[];
    onProcessEnd?: () => void;
};

export const FlowContext = createContext({
    goNext: () => {},
    goBack: () => {},
});

const ProcessFlowStateful = (props: PropTypes) => {
    const { steps, onProcessEnd } = props;

    const [currentStep, setCurrentStep] = useState(0);

    const goNext = () => setCurrentStep(currentStep + 1);

    const goBack = () => setCurrentStep(Math.max(currentStep - 1, 0));

    if (currentStep === steps.length && onProcessEnd) {
        onProcessEnd();
    }

    const contextValue = useMemo(() => ({
        goNext,
        goBack,
    }), [goNext, goBack]);

    return (
        <FlowContext.Provider value={contextValue}>
            {steps[currentStep]}
        </FlowContext.Provider>
    );
};

ProcessFlowStateful.defaultProps = {
    onProcessEnd: () => {},
};

export default ProcessFlowStateful;
