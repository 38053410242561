import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
    Button,
    Input,
    InputGroup,
    InputRightElement,
    Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FormikPassword = (props: { name: string; placeholder: string; width?: string; }) => {
    const { t } = useTranslation('formik');

    const { width } = props;

    const [field, meta] = useField(props);

    const [show, setShow] = useState(false);

    return (
        <div style={{ width: '100%' }}>
            <InputGroup width={width}>
                <Input
                    bg="white"
                    h="3rem"
                    borderRadius="8"
                    isInvalid={!!meta.touched && !!meta.error}
                    type={show ? 'text' : 'password'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />

                <InputRightElement width="4.5rem">
                    <Button
                        variant="none"
                        color="wvwGreen"
                        marginTop=".5rem"
                        h="2rem"
                        size="sm"
                        tabIndex={-1}
                        onClick={() => setShow(!show)}
                    >
                        {show
                            ? <ViewOffIcon color="wvwGreen" boxSize="1.2rem" /> : <ViewIcon color="wvwGreen" boxSize="1.2rem" />}
                    </Button>
                </InputRightElement>
            </InputGroup>

            { meta.touched && meta.error && (
                <Text
                    color="red"
                >
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            ) }
        </div>
    );
};

FormikPassword.defaultProps = {
    width: '100%',
};

export default FormikPassword;
