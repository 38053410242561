import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const postNoAuth = async (path: string, data?: { [index: string]: unknown }) => axios.post(
    `${REACT_APP_API_URL}${path}`,
    data,
).catch(error => {
    throw new Error(error?.response?.data?.message || error?.response?.statusText || 'Unknown error');
});

export default postNoAuth;
