import { useFormikContext } from 'formik';
import { ReactElement } from 'react';

type PropTypes = {
    formDataTarget: string;
    condition: (value: string | string[]) => boolean;
    children: ReactElement;
};

const FormikConditionalSection = (props: PropTypes) => {
    const { formDataTarget, condition, children } = props;

    const { values } = useFormikContext<{ [index: string]: string | string[] }>();

    if (!condition(values[formDataTarget])) {
        return null;
    }

    return children;
};

export default FormikConditionalSection;
