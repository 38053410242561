import {
    Button,
    HStack,
    Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Terms from './Terms';
import Privacy from './Privacy';
import Imprint from './Imprint';
import useSupportModal from '../../utils/api/hooks/useSupportModal';
import ModalDynamic from './ModalDynamic';

type PropTypes = {
    invertColor?: boolean;
};

const Footer = (props: PropTypes) => {
    const { invertColor } = props;

    const { modal: supportModal, openModal } = useSupportModal();

    const { t } = useTranslation('common');

    const [modal, setModal] = useState<'terms' | 'privacy' | 'imprint' | undefined>();

    const textColor = invertColor ? 'white' : 'black';

    const modalHeader = {
        terms: t('termsHeader', { ns: 'common' }),
        imprint: t('imprintExtended', { ns: 'common' }),
        privacy: t('privacyHeader', { ns: 'common' }),
    };

    const modalBody = {
        terms: <Terms />,
        imprint: <Imprint />,
        privacy: <Privacy />,
    };

    const footerButton = (modalToOpen: any, text: string, divider: boolean) => (
        <HStack>
            <Button
                color={textColor}
                fontSize="0.8rem"
                fontWeight="normal"
                onClick={() => modalToOpen()}
                variant="link"
            >
                <Text color={textColor}>
                    {text}
                </Text>
            </Button>

            {divider && (
                <Text color={textColor}>
                    {' | '}
                </Text>
            )}
        </HStack>
    );

    return (
        <>
            <HStack
                fontSize="0.8rem"
                justifyContent="center"
                paddingBlock="0.5rem"
                w="100%"
            >
                <Text color={textColor}>
                    {t('copyright')}
                </Text>

                {footerButton(openModal, t('link.reportAProblem'), true)}
                {footerButton(() => setModal('privacy'), t('privacy'), true)}
                {footerButton(() => setModal('terms'), t('terms'), true)}
                {footerButton(() => setModal('imprint'), t('imprint'), false)}
            </HStack>

            {supportModal}

            <ModalDynamic
                variant="small"
                isOpen={!!modal}
                header={modalHeader[modal as | 'terms' | 'privacy' | 'imprint']}
                onClose={() => setModal(undefined)}
            >
                {modalBody[modal as 'terms' | 'privacy' | 'imprint']}
            </ModalDynamic>
        </>
    );
};

Footer.defaultProps = {
    invertColor: false,
};

export default Footer;
