import { useParams } from 'react-router-dom';
import GeneralEnquiryOverview from '../../../../common/components/display/enquiry-overview/GeneralEnquiryOverview';
import { useCaseGetCase } from '../../../../utils/api/hooks';
import { CaseType } from '../../../../types';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const CaseOverview = () => {
    const { caseId = '' } = useParams();

    const {
        data: caseData = {} as CaseType,
        isLoading: caseIsLoading,
    } = useCaseGetCase(caseId);

    if (caseIsLoading) return <LoadingSpinner />;

    return (
        <GeneralEnquiryOverview variant="white" overviewData={caseData.enquiry} acceptedOffer={caseData.acceptedOffer} />
    );
};

export default CaseOverview;
