import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LabelAndData } from '../../../../../common/components/display';
import { UserType } from '../../../../../types';

type PropTypes = {
    user: UserType;
};

const AdminUserDetails = (props: PropTypes) => {
    const { user } = props;

    const {
        firstName,
        lastName,
        city,
        country,
        email,
        entity,
        maxOperatingDistance,
        mobileDialCode,
        mobile,
        otherMemberships,
        otherQualifications,
        otherValuationOccasions,
        otherValuationTypes,
        postalCode,
        specialValuationTypes,
        standardMemberships,
        standardQualifications,
        street,
        systemAccess,
        systemAdmin,
        valuationOccasions,
        valuationPropertyTypes,
        valuationTypes,
    } = user;

    const { t } = useTranslation('profile');

    return (
        <VStack
            align="start"
            spacing="3"
            w="100%"
        >
            <LabelAndData
                label="User ID"
                data={user.id}
            />

            <LabelAndData
                label={t('profileDetails.name')}
                data={`${firstName} ${lastName}`}
            />

            <LabelAndData
                label={t('profileDetails.email')}
                data={email}
            />

            <LabelAndData
                label="Entity Type"
                data={entity.accountType}
            />

            <LabelAndData
                label="Role"
                data={user.role}
            />

            <LabelAndData
                label="System Access"
                data={systemAccess ? 'Yes' : 'No'}
            />

            <LabelAndData
                label="System Admin"
                data={systemAdmin ? 'Yes' : 'No'}
            />

            <LabelAndData
                label={t('profileDetails.phone')}
                data={`${mobileDialCode} ${mobile}`}
            />

            <LabelAndData
                label={t('profileDetails.location')}
                data={`${street}, ${postalCode}, ${city}, ${country}`}
            />

            <LabelAndData
                label="Qualifications"
                data={standardQualifications?.join(', ')}
            />

            <LabelAndData
                label="Other Qualifications"
                data={otherQualifications?.join(', ')}
            />

            <LabelAndData
                label="Valuation Standards"
                data={standardMemberships?.join(', ')}
            />

            <LabelAndData
                label="Other Valuation Standards"
                data={otherMemberships?.join(', ')}
            />

            <LabelAndData
                label="Valuation Types"
                data={valuationTypes?.join(', ')}
            />

            <LabelAndData
                label="Other Valuation Types"
                data={otherValuationTypes?.join(', ')}
            />

            <LabelAndData
                label="Special Valuation Types"
                data={specialValuationTypes?.join(', ')}
            />

            <LabelAndData
                label="Valuation Occasions"
                data={valuationOccasions?.join(', ')}
            />

            <LabelAndData
                label="Other Valuation Occasions"
                data={otherValuationOccasions?.join(', ')}
            />

            <LabelAndData
                label="Property Types"
                data={valuationPropertyTypes?.join(', ')}
            />

            <LabelAndData
                label="Max Operating Distance"
                data={maxOperatingDistance}
            />
        </VStack>
    );
};

export default AdminUserDetails;
