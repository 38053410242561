import { useMutation } from 'react-query';
import { EmailEmailPlatformDto } from '../dto';
import { emailEmailPlatform } from '../endpoints';

type ParamsType = {
    onSuccess: (data?: unknown) => void;
    onError: (error?: unknown) => void;
};

const useEmailEmailPlatform = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: send } = useMutation(
        (message: EmailEmailPlatformDto) => emailEmailPlatform(message),
        {
            onSuccess: (data: unknown) => {
                onSuccess?.(data);
            },
            onError: (error: unknown) => {
                onError?.(error);
            },
        },
    );

    return {
        send,
    };
};

export default useEmailEmailPlatform;
