export const calculateDaysDifference = (referenceDate: string, checkDate: string) => {
    // Reference and check date must be in ISO format
    const reference = new Date(referenceDate).getTime();
    const check = new Date(checkDate).getTime();

    const difference = check - reference;

    const daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));

    return daysDifference;
};

export const calculateDaysFromToday = (checkDate: string) => {
    const today = new Date().toISOString().split('T')[0];
    return calculateDaysDifference(today, checkDate);
};
