import { CheckCircleIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Grid,
    GridItem,
    HStack,
    Input,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    APIProvider,
    Map,
    Marker,
    useMarkerRef,
} from '@vis.gl/react-google-maps';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleGeocode } from '../../utils/api/hooks';
import { CountrySelect } from '../inputs';
import { WVWTitle } from '../components/typography';

const { REACT_APP_GOOGLEMAPS_API_KEY = '' } = process.env;

type PropTypes = {
    cityName: string;
    countryName: string;
    streetName: string;
    postalCodeName: string;
    latName: string;
    lngName: string;
    w?: string;
};

const FormikAddressInputWithMap = (props: PropTypes) => {
    const {
        cityName,
        countryName,
        streetName,
        postalCodeName,
        latName,
        lngName,
        w,
    } = props;

    const { t } = useTranslation('formik');

    const [markerRef] = useMarkerRef();

    const context = useFormikContext();

    const [zoom, setZoom] = useState<number>(12);

    const [streetField, streetMeta] = useField(streetName);
    const [postalCodeField, postalCodeMeta] = useField(postalCodeName);
    const [cityField, cityMeta] = useField(cityName);
    const [countryField, countryMeta] = useField(countryName);
    const [latField, latMeta] = useField(latName);
    const [lngField] = useField(lngName);

    const [location, setLocation] = useState<{
        confirmed: boolean;
        lat?: number;
        lng?: number;
    }>({
        confirmed: false,
        lat: Number(latField.value),
        lng: Number(lngField.value),
    });

    const [addressModified, setAddressModified] = useState(true);

    const {
        update: geocodeAddress,
    } = useGoogleGeocode({
        onSuccess: data => {
            if (data.results[0]) {
                const result = data.results[0];

                const { lat, lng } = result.geometry.location;

                setLocation({
                    lat,
                    lng,
                    confirmed: false,
                });

                setZoom(16);
            }
        },
    });

    useEffect(() => {
        if (location.confirmed) {
            latField.onChange({
                target: {
                    name: latName,
                    value: location.lat,
                },
            });

            lngField.onChange({
                target: {
                    name: lngName,
                    value: location.lng,
                },
            });

            context.setErrors({
                ...context.errors,
                [latName]: undefined,
                [lngName]: undefined,
            });
        } else {
            latField.onChange({
                target: {
                    name: latName,
                    value: undefined,
                },
            });

            lngField.onChange({
                target: {
                    name: lngName,
                    value: undefined,
                },
            });
        }
    }, [location]);

    return (
        <Box w={w}>
            <VStack
                alignItems="stretch"
                spacing="3"
            >
                <Box>
                    <Input
                        bg="white"
                        h="3rem"
                        onBlur={() => context.setFieldTouched(streetName, true)}
                        onChange={e => {
                            streetField.onChange({
                                target: {
                                    name: streetName,
                                    value: e.target.value,
                                },
                            });
                            setAddressModified(true);
                        }}
                        placeholder={t('fieldPlaceholder.street', { ns: 'common' })}
                        value={streetField.value}
                        w="100%"
                    />

                    { streetMeta.error && streetMeta.touched && (
                        <Text color="red">
                            {t(
                                streetMeta.error,
                                {
                                    defaultValue: streetMeta.error,
                                    ns: 'formik',
                                },
                            )}
                        </Text>
                    )}
                </Box>

                <Grid
                    gap="3"
                    templateColumns="repeat(4, 1fr)"
                    w="100%"
                >
                    <GridItem colSpan={1}>
                        <Box>
                            <Input
                                bg="white"
                                h="3rem"
                                onBlur={() => context.setFieldTouched(postalCodeName, true)}
                                onChange={e => {
                                    postalCodeField.onChange({
                                        target: {
                                            name: postalCodeName,
                                            value: e.target.value,
                                        },
                                    });
                                    setAddressModified(true);
                                }}
                                placeholder={t('fieldPlaceholder.postalCode', { ns: 'common' })}
                                value={postalCodeField.value}
                                w="100%"
                            />

                            { postalCodeMeta.error && postalCodeMeta.touched && (
                                <Text color="red">
                                    {t(
                                        postalCodeMeta.error,
                                        {
                                            defaultValue: postalCodeMeta.error,
                                            ns: 'formik',
                                        },
                                    )}
                                </Text>
                            ) }
                        </Box>
                    </GridItem>

                    <GridItem colSpan={1}>
                        <Box>
                            <Input
                                bg="white"
                                h="3rem"
                                onBlur={() => context.setFieldTouched(cityName, true)}
                                onChange={e => {
                                    cityField.onChange({
                                        target: {
                                            name: cityName,
                                            value: e.target.value,
                                        },
                                    });
                                    setAddressModified(true);
                                }}
                                placeholder={t('fieldPlaceholder.city', { ns: 'common' })}
                                value={cityField.value}
                                w="100%"
                            />

                            { cityMeta.error && cityMeta.touched && (
                                <Text color="red">
                                    {t(
                                        cityMeta.error,
                                        {
                                            defaultValue: cityMeta.error,
                                            ns: 'formik',
                                        },
                                    )}
                                </Text>
                            ) }
                        </Box>
                    </GridItem>

                    <GridItem colSpan={2}>
                        <Box>
                            <CountrySelect
                                name="country"
                                onBlur={() => context.setFieldTouched(countryName, true)}
                                onChange={e => {
                                    countryField.onChange({
                                        target: {
                                            name: countryName,
                                            value: e.target.value,
                                        },
                                    });
                                    setAddressModified(true);
                                }}
                                placeholder={t('fieldPlaceholder.country', { ns: 'common' })}
                                value={countryField.value}
                            />

                            { countryMeta.error && countryMeta.touched && (
                                <Text color="red">
                                    {t(
                                        countryMeta.error,
                                        {
                                            defaultValue: countryMeta.error,
                                            ns: 'formik',
                                        },
                                    )}
                                </Text>
                            ) }
                        </Box>
                    </GridItem>
                </Grid>

                <Button
                    isDisabled={
                        streetField.value === ''
                        || postalCodeField.value === ''
                        || cityField.value === ''
                        || countryField.value === ''
                        || !addressModified
                    }
                    onClick={() => {
                        geocodeAddress({
                            street: streetField.value,
                            postalCode: postalCodeField.value,
                            city: cityField.value,
                            country: countryField.value,
                        });
                        setAddressModified(false);
                        context.setFieldTouched(latName, false);
                        context.setFieldTouched(lngName, false);
                    }}
                    variant="primary"
                >
                    {t('button.locateAddress', { ns: 'common' })}
                </Button>

                <WVWTitle
                    level="2"
                    content={t('mapInputInstructions.propertyAccess')}
                />

                <Box h="40vh" w="100%">
                    <APIProvider apiKey={REACT_APP_GOOGLEMAPS_API_KEY}>
                        <Map
                            center={{
                                lat: location.lat || 53.54992,
                                lng: location.lng || 10.00678,
                            }}
                            onClick={event => {
                                const latLng = JSON.parse(JSON.stringify(event.detail.latLng));

                                setLocation({
                                    ...latLng,
                                    confirmed: false,
                                });

                                context.setFieldTouched(latName, true);
                                context.setFieldTouched(lngName, true);
                            }}
                            onZoomChanged={event => setZoom(event.detail.zoom)}
                            zoom={zoom}
                        >
                            { location.lat && location.lng && (
                                <Marker
                                    ref={markerRef}
                                    position={{
                                        lat: location.lat || 53.54992,
                                        lng: location.lng || 10.00678,
                                    }}
                                />
                            ) }
                        </Map>
                    </APIProvider>
                </Box>

                <Text>
                    {t('mapInputInstructions.confirmLocation', { ns: 'formik' })}
                </Text>

                <HStack>
                    <Button
                        isDisabled={
                            streetField.value === ''
                            || postalCodeField.value === ''
                            || cityField.value === ''
                            || countryField.value === ''
                            || !latMeta.touched
                        }
                        onClick={() => {
                            geocodeAddress({
                                street: streetField.value,
                                postalCode: postalCodeField.value,
                                city: cityField.value,
                                country: countryField.value,
                            });
                            context.setFieldTouched(latName, false);
                            context.setFieldTouched(lngName, false);
                        }}
                        variant="primary"
                    >
                        {t('mapInputInstructions.resetToAddress', { ns: 'formik' })}
                    </Button>

                    <Button
                        leftIcon={location.confirmed ? <CheckCircleIcon /> : undefined}
                        isDisabled={
                            !location.lat
                            || !location.lng
                            || streetField.value === ''
                            || postalCodeField.value === ''
                            || cityField.value === ''
                            || countryField.value === ''
                        }
                        onClick={() => {
                            setLocation({
                                ...location,
                                confirmed: true,
                            });
                        }}
                        opacity={location.confirmed ? 0.4 : 1}
                        variant="primary"
                    >
                        {location.confirmed ? t('mapInputInstructions.locationConfirmed', { ns: 'formik' }) : t('button.confirmLocation', { ns: 'common' })}
                    </Button>
                </HStack>

                { (latMeta.error && !location.confirmed) && (
                    <Text color="red">
                        {t(
                            'errors.mapRequired',
                            {
                                defaultValue: 'errors.mapRequired',
                                ns: 'formik',
                            },
                        )}
                    </Text>
                ) }
            </VStack>
        </Box>
    );
};

FormikAddressInputWithMap.defaultProps = {
    w: '100%',
};

export default FormikAddressInputWithMap;
