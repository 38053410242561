import { VStack } from '@chakra-ui/react';
import { CaseType, InspectionType } from '../../../../../types';
import TenatativeDateItem from './TentativeDateItem';

type PropTypes = {
    caseData: CaseType;
    inspection: InspectionType;
};

const InspectionNegotiation = (props: PropTypes) => {
    const { caseData, inspection } = props;

    const calendarEntries = inspection.cause.calendarEntries.filter(calendarEntry => {
        const { eventParticipations } = calendarEntry;

        return eventParticipations.every(eventParticipation => eventParticipation.status !== 'DECLINED');
    });

    return (
        <VStack spacing="1">
            {calendarEntries.map(calendarEntry => (
                <TenatativeDateItem
                    caseData={caseData}
                    calendarEntry={calendarEntry}
                    inspection={inspection}
                    key={calendarEntry.id}
                />
            ))}
        </VStack>
    );
};

export default InspectionNegotiation;
