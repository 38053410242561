import { Box, Divider, Flex } from '@chakra-ui/react';
import EventDate from './EventDate';
import EventTime from './EventTime';

type PropTypes = {
    date: Date; // The date of the event
    duration: number; // The duration of the event
    color?: 'black' | 'wvwGreen' | 'wvwGrey60' | 'white'; // The color of the text
    orientation?: 'horizontal' | 'vertical'; // The orientation of the component
};

const EventDateTime = (props: PropTypes) => {
    const {
        date,
        duration,
        color,
        orientation,
    } = props;

    if (orientation === 'horizontal') {
        return (
            <Flex
                justifyContent="space-between"
                maxW="20rem"
                w="100%"
            >
                <EventDate
                    color={color}
                    date={date}
                />

                <Divider
                    borderColor={color}
                    h="1.5rem"
                    opacity="1"
                    orientation="vertical"
                />

                <EventTime
                    color={color}
                    date={date}
                    duration={duration}
                />
            </Flex>
        );
    }

    return (
        <Flex
            direction="column"
            justifyContent="space-between"
            minH="5rem"
            w="100%"
        >
            <Box ml="1rem">
                <EventDate
                    color={color}
                    date={date}
                />
            </Box>

            <Divider
                borderColor={color}
                opacity="1"
            />

            <Box ml="1rem">
                <EventTime
                    alignText="left"
                    color={color}
                    date={date}
                    duration={duration}
                />
            </Box>
        </Flex>
    );
};

EventDateTime.defaultProps = {
    color: 'white',
    orientation: 'horizontal',
};

export default EventDateTime;
