import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { WVWLabel, WvwText } from '../typography';

type PropTypes = {
    label: string; // The label
    data: ReactNode; // The data
};

const LabelAndData = ({ label, data }: PropTypes) => (
    <Box w="100%">
        <WVWLabel content={label} />

        <WvwText>
            {data}
        </WvwText>
    </Box>
);

export default LabelAndData;
