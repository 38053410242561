import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
} from '@chakra-ui/react';
import LoadingSpinner from './LoadingSpinner';

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
};

const LoadingSlider = (props: PropTypes) => {
    const { isOpen, onClose, title } = props;

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    color="wvwGreen"
                >
                    {title}
                </DrawerHeader>

                <DrawerBody>
                    <Flex alignItems="center">
                        <LoadingSpinner
                            color="wvwYellow"
                            noText
                            size="xl"
                            thickness="4px"
                        />
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default LoadingSlider;
