import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { FormikForm } from '../../../../../common/forms';
import { useMyEntity } from '../../../../../utils/api/hooks';
import EditCompanyAwards from './EditCompanyAwards';
import EditCompanyMarketingDocs from './EditCompanyMarketingDocs';
import EditCompanyOtherCerts from './EditCompanyOtherCerts';
import EditCompanyPiDocs from './EditCompanyPiDocs';
import EditCompanySampleValuations from './EditCompanySampleValuations';

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
    type: string;
};

const validationSchema = Yup.object({
    valuationTypes: Yup.array()
        .min(1, 'Please select at least one property type'),
});

const EditCompanyDocsSlider = (props: PropTypes) => {
    const {
        isOpen,
        onClose,
        type,
    } = props;

    const {
        data: entity,
        updateEntity,
    } = useMyEntity();

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="md"
        >
            <DrawerOverlay />

            <FormikForm
                initialValues={{
                    valuationTypes: entity?.valuationTypes || [],
                }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    updateEntity({
                        valuationTypes: values.valuationTypes,
                    });

                    onClose?.();
                }}
            >
                <DrawerContent>
                    <DrawerCloseButton />

                    <DrawerHeader
                        color="wvwGreen"
                    >
                        Edit Documents
                    </DrawerHeader>

                    <DrawerBody>
                        {type === 'pi' && <EditCompanyPiDocs />}
                        {type === 'sampleValuations' && <EditCompanySampleValuations />}
                        {type === 'awards' && <EditCompanyAwards />}
                        {type === 'marketing' && <EditCompanyMarketingDocs />}
                        {type === 'other' && <EditCompanyOtherCerts />}
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            mr={3}
                            onClick={onClose}
                        >
                            Close
                        </Button>

                        <Button
                            variant="primaryYellow"
                            type={type === 'sampleValuations' ? 'submit' : 'button'}
                            onClick={onClose}
                        >
                            Save
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </FormikForm>
        </Drawer>
    );
};

export default EditCompanyDocsSlider;
