import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import WaterMarkBox from '../../../common/components/WaterMarkBox';
import Logo from '../../../common/components/Logo';
import LanguageToggleButton from '../../../common/components/LanguageToggleButton';
import Footer from '../../../common/components/Footer';
import { FlowContext } from '../../../common/components/display/ProcessFlowStateful';

type PropTypes = {
    onContinue: (values: { [index: string]: string }) => void;
};

const SelectOrganisationOrIndividual = (props: PropTypes) => {
    const { onContinue } = props;

    const { goBack, goNext } = useContext(FlowContext);

    const { t } = useTranslation(['account', 'common']);

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.lg"
                >
                    <Box
                        bg="white"
                        borderRadius="lg"
                        p="2em"
                        w="100%"
                        marginTop="1.5rem"
                    >
                        <Flex>
                            <HStack>
                                <IconButton
                                    aria-label="Back"
                                    color="white"
                                    icon={<RiArrowGoBackFill />}
                                    size="xs"
                                    variant="primary"
                                    onClick={() => goBack()}
                                />

                                <Button
                                    color="wvwGreen"
                                    variant="link"
                                    onClick={() => goBack()}
                                >
                                    {t('button.back', { ns: 'common' })}
                                </Button>
                            </HStack>

                            <Spacer />

                            <LanguageToggleButton />
                        </Flex>

                        <VStack
                            spacing="8"
                            pb="9em"
                            pt="6em"
                            w="100%"
                        >
                            <Logo withColor />

                            <Heading
                                as="h1"
                                color="wvwGreen"
                                size="xl"
                            >
                                {t('howCanWeHelp')}
                            </Heading>

                            <Text align="center">
                                {t('valuationChoice')}
                            </Text>

                            <Flex
                                maxW="container.md"
                                gap="1em"
                                w="100%"
                                align="flex-end"
                            >
                                <VStack
                                    p="1em"
                                    align="center"
                                >
                                    <Text
                                        paddingTop="1rem"
                                        paddingBottom="1rem"
                                        align="center"
                                    >
                                        {t('singleValuation')}
                                    </Text>

                                    <Button
                                        size="lg"
                                        variant="primary"
                                        w="100%"
                                        onClick={() => {
                                            onContinue({ companyOrIndividual: 'INDIVIDUAL' });
                                            goNext();
                                        }}
                                    >
                                        {t('oneOffValuation')}
                                    </Button>
                                </VStack>

                                <VStack
                                    p="1em"
                                >
                                    <Text
                                        paddingTop="1rem"
                                        paddingBottom="1rem"
                                        align="center"

                                    >
                                        {t('multipleValuations')}
                                    </Text>

                                    <Button
                                        size="lg"
                                        variant="primary"
                                        w="100%"
                                        onClick={() => {
                                            onContinue({ companyOrIndividual: 'COMPANY' });
                                            goNext();
                                        }}
                                    >
                                        {t('recurringValuation')}
                                    </Button>
                                </VStack>
                            </Flex>
                        </VStack>
                    </Box>
                </Container>

                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

export default SelectOrganisationOrIndividual;
