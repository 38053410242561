import { useQuery, useQueryClient } from 'react-query';
import { offerGetCompanyOffers } from '../endpoints';

const COMPANY_OFFERS = 'COMPANY_OFFERS';

const useOfferGetCompanyOffers = (enquiryId: string | number) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([COMPANY_OFFERS], () => offerGetCompanyOffers(enquiryId));

    const refresh = () => {
        queryClient.invalidateQueries(COMPANY_OFFERS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useOfferGetCompanyOffers;
