import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetRequest } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_REQUEST = 'DOCUMENT_REQUEST_GET_REQUEST';

const useDocumentRequestGetRequest = (requestId: string | number) => {
    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_REQUEST, requestId],
        () => documentRequestGetRequest(requestId),
        {
            enabled: !!requestId,
            onError: (err: Error) => createErrorToast(
                t(err.message, { defaultValue: err.message }),
            ),
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_REQUEST);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetRequest;
