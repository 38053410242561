import { useQueryClient, useQuery } from 'react-query';
import { UserType } from '../../../types';
import storage from '../../storage/storage';
import { userGetPublicUser } from '../endpoints';

const USER = 'user';
const USER_CERTIFICATES = 'USER_CERTIFICATES';
const USER_PROFILE_PICTURE = 'USER_PROFILE_PICTURE';

const usePublicUser = (userId: number | string) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [USER, userId],
        () => userGetPublicUser(userId),
        {
            enabled: !Number.isNaN(userId) && userId !== null && userId !== undefined,
        },
    );

    const {
        data: profilePictureUrl,
        error: profilePictureUrlError,
        isError: profilePictureUrlIsError,
        isLoading: profilePictureUrlIsLoading,
    } = useQuery(
        [USER_PROFILE_PICTURE, userId],
        async () => {
            const profilePicture = await storage.getUserProfilePictureUrl(userId);

            if (!profilePicture) {
                return '';
            }

            return profilePicture;
        },
        {
            enabled: !!userId,
        },
    );

    const profilePictureRefresh = () => {
        queryClient.invalidateQueries(USER_PROFILE_PICTURE);
    };

    const {
        data: userCertificates,
        error: userCertificatesError,
        isError: userCertificatesIsError,
        isLoading: userCertificatesIsLoading,
    } = useQuery(
        [USER_CERTIFICATES, userId],
        async () => {
            const certificateList = await storage.getUserCertificates(
                userId,
            );

            return certificateList;
        },
        { enabled: !!userId },
    );

    const userCertificatesRefresh = () => queryClient
        .invalidateQueries(USER_CERTIFICATES);

    const refresh = () => {
        queryClient.invalidateQueries(USER);
    };
    return {
        data: data as UserType,
        refresh,
        error,
        isLoading,

        profilePictureRefresh,
        profilePictureUrl,
        profilePictureUrlError,
        profilePictureUrlIsError,
        profilePictureUrlIsLoading,

        userCertificates,
        userCertificatesError,
        userCertificatesIsError,
        userCertificatesIsLoading,
        userCertificatesRefresh,
    };
};

export default usePublicUser;
