const standardMaxOfficeDistance = [
    { label: 'No Requirement', value: 'none' },
    { label: 'Less than 5 Kilometers', value: '5' },
    { label: 'Less than 10 Kilometers', value: '10' },
    { label: 'Less than 20 Kilometers', value: '20' },
    { label: 'Less than 50 Kilometers', value: '50' },
    { label: 'Less than 100 Kilometers', value: '100' },
];

export default standardMaxOfficeDistance;
