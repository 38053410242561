import { sendPasswordResetEmail } from 'firebase/auth';
import { useMutation } from 'react-query';
import { auth } from '../../firebase';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error?: any) => void;
};

const useFbSendPasswordReset = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (email: string) => sendPasswordResetEmail(auth, email),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useFbSendPasswordReset;
