import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { TickCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    width?: string;
    name: string;
    options?: {
        label: string;
        value: string | number;
    }[];
    onChange?: (field?: FieldInputProps<any>, value?: string[] | number[],) => void,
};

const FormikListSelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        options,
        width,
        onChange,
    } = props;

    const { values } = useFormikContext<{ [index: string]: any }>();

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const fieldValues = values[name] || [];

    return (
        <Box w="100%">
            <SimpleGrid
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                minChildWidth="100%"
                spacing={2}
            >
                {options?.map(option => (
                    <Button
                        width={width}
                        key={option.value}
                        justifyContent="left"
                        fontWeight="medium"
                        h="3rem"
                        name={`${field.name}[${option.value}]`}
                        variant={fieldValues.includes(option.value) ? 'primaryYellow' : 'primary'}
                        onClick={() => {
                            let newFieldValues = [...fieldValues, option.value];
                            if (fieldValues.includes(option.value)) {
                                newFieldValues = values[name].filter(
                                    (value: string | number) => value !== option.value,
                                );
                            }
                            field.onChange({ target: { name, value: newFieldValues } });

                            onChange?.(field, newFieldValues);
                        }}
                    >
                        <Flex
                            w="100%"
                            paddingInline=".5rem"
                            alignItems="center"
                        >
                            {option.label}

                            <Spacer />

                            {fieldValues.includes(option.value) && (
                                <TickCircle color="white" />
                            )}
                        </Flex>
                    </Button>
                ))}
            </SimpleGrid>

            {meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )}
        </Box>
    );
};

FormikListSelect.defaultProps = {
    width: '100%',
    options: [],
    onChange: () => { },
};

export default FormikListSelect;
