import {
    Center,
    Circle,
} from '@chakra-ui/react';
import { usePublicEntityProfilePicture } from '../../utils/api/hooks';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import ProfilePicture from '../../common/components/display/ProfilePicture';
import ProfilePictureDefault from '../../common/components/display/ProfilePictureDefault';

type PropTypes = {
    companyId: number;
    size: string;
};

const DirectEnquiryLogo = (props: PropTypes) => {
    const { companyId, size } = props;

    const {
        data: profilePictureUrl,
        isLoading: profilePictureUrlIsLoading,
    } = usePublicEntityProfilePicture(companyId);

    if (profilePictureUrlIsLoading) {
        return (
            <Circle
                size={size}
                margin="0"
            >
                <Center
                    w="100%"
                    h="100%"
                >
                    <LoadingSpinner
                        noText
                        size="md"
                        thickness="3px"
                    />
                </Center>
            </Circle>
        );
    }
    if (profilePictureUrl) {
        return (
            <ProfilePicture
                url={profilePictureUrl}
                size={size}
            />
        );
    }
    return (
        <ProfilePictureDefault
            accountType="VALUER"
            userType="ENTITY"
            size={size}
        />
    );
};

export default DirectEnquiryLogo;
