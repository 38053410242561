import { useMutation } from 'react-query';
import { clientInvitesDelete } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useClientInvitesDelete = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (inviteId: number | string) => clientInvitesDelete(inviteId),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useClientInvitesDelete;
