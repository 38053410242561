import {
    Box,
    Container,
    Link as ChakraLink,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import Logo from './common/components/Logo';
import WaterMarkBox from './common/components/WaterMarkBox';
import { WVWTitle, WvwText } from './common/components/typography';
import { WvwButton } from './common/components/inputs';

const FourZeroFour = () => {
    const { t } = useTranslation('common');

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.lg"
                >
                    <Box
                        bg="white"
                        borderWidth="1px"
                        borderRadius="lg"
                        p="2em"
                        w="100%"
                        marginTop="2rem"
                    >
                        <VStack
                            spacing="8"
                            pb="9em"
                            pt="6em"
                            w="100%"
                        >
                            <Logo withColor />

                            <WVWTitle
                                level="1"
                                content={t('fourZeroFour.header')}
                            />

                            <WvwText>
                                {t('fourZeroFour.message')}
                            </WvwText>

                            <ChakraLink
                                as={ReactLink}
                                to="/"
                            >
                                <WvwButton
                                    content={t('fourZeroFour.button')}
                                    onClick={() => {}}
                                    variant="primary"
                                />
                            </ChakraLink>
                        </VStack>
                    </Box>
                </Container>
            </WaterMarkBox>
        </Box>
    );
};

export default FourZeroFour;
