import {
    Box,
    Button,
    createStandaloneToast,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
    useClientInvitesCreate,
    useClientInvitesGetSent,
} from '../../../../../utils/api/hooks';
import { FormikForm, FormikInput } from '../../../../../common/forms';

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
};

const validationSchema = Yup.object({
    clientCompanyName: Yup.string()
        .required('errors.required'),
    clientName: Yup.string()
        .required('errors.required'),
    clientEmail: Yup.string()
        .required('errors.required'),
});

const CustomerInviteForm = (props: PropTypes) => {
    const { isOpen, onClose } = props;

    const { t } = useTranslation(['account', 'common', 'formik']);
    const { toast } = createStandaloneToast();

    const { refresh } = useClientInvitesGetSent();

    const { update } = useClientInvitesCreate({
        onSuccess: () => {
            onClose();
            refresh();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message || t('error.somethingWrong', { ns: 'common' }),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (isOpen === false) return null;

    return (
        <Modal
            isOpen
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent minWidth="4xl">
                <ModalHeader
                    bg="wvwGrey05"
                    color="wvwGreen"
                >
                    {t('panelMgmt')}
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody bg="wvwGrey05">
                    <Box
                        bg="wvwGrey05"
                        w="100%"
                        borderRadius="10"
                        paddingBlock="1.5rem"
                        paddingInline="1rem"
                    >
                        <FormikForm
                            initialValues={{
                                clientCompanyName: '',
                                clientEmail: '',
                                clientName: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                update({
                                    clientCompanyName: values.clientCompanyName,
                                    clientEmail: values.clientEmail,
                                    clientName: values.clientName,
                                });
                            }}
                        >
                            <VStack
                                align="left"
                                spacing="2rem"
                            >
                                <FormikInput
                                    placeholder={t('customerPanelDetails.customerCompanyName')}
                                    name="clientCompanyName"
                                    type="text"
                                />

                                <FormikInput
                                    placeholder={t('customerPanelDetails.name')}
                                    name="clientName"
                                    type="text"
                                />

                                <FormikInput
                                    placeholder={t('customerPanelDetails.email')}
                                    name="clientEmail"
                                    type="email"
                                />

                                <Flex>
                                    <Spacer />

                                    <Button
                                        width="13rem"
                                        variant="primaryYellow"
                                        alignSelf="flex-end"
                                        type="submit"
                                    >
                                        {t('button.add', { ns: 'common' })}
                                    </Button>
                                </Flex>
                            </VStack>
                        </FormikForm>
                    </Box>
                </ModalBody>

                <ModalFooter bg="wvwGrey05">
                    <Button
                        alignSelf="flex-end"
                        variant="primary"
                        onClick={onClose}
                    >
                        {t('button.close', { ns: 'common' })}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CustomerInviteForm;
