import {
    Textarea,
    Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string;
    placeholder?: string;
    onClick?: (e: any) => void;
};

const FormikTextarea = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        placeholder,
        onClick,
    } = props;

    const [field, meta] = useField({
        ...props,
    });

    return (
        <>
            <Textarea
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                name={name}
                placeholder={placeholder}
                bg="white"
                borderRadius="10"
                onClick={() => onClick}
            />

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </>
    );
};

FormikTextarea.defaultProps = {
    placeholder: '',
    onClick: () => {},
};

export default FormikTextarea;
