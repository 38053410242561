import { CheckIcon } from '@chakra-ui/icons';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Circle,
    Grid,
    GridItem,
    HStack,
    Heading,
    Icon,
    IconButton,
} from '@chakra-ui/react';
import {
    Folder2,
} from 'iconsax-react';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    DocumentRequestFolderType,
    DocumentRequestType,
    dragTargetTypes,
    FolderDropTargetType,
} from '../../../../../../types';
import { FormikForm, FormikInput } from '../../../../../../common/forms';
import { useRole } from '../../../../../../common/hooks';
import {
    useDocumentRequestDeleteFolder,
    useDocumentRequestGetRequests,
    useDocumentRequestUpdateFolder,
} from '../../../../../../utils/api/hooks';
import ConfirmationModal from '../../../../../../common/components/ConfirmationModal';
import Requests from '../../requests/Requests';
import { DocumentFiltersType, RequestFiltersType } from '../../common/documentManagementAreaTypes';
import WvwCancelIconButton from '../../../../../../common/components/WvwCancelIconButton';
import WvwDeleteIconButton from '../../../../../../common/components/WvwDeleteIconButton';

const { REQUEST_IMPORT, REQUEST_MOVE, TEMPLATE_IMPORT } = dragTargetTypes;

type PropTypes = {
    inEditMode: boolean;
    folder: DocumentRequestFolderType;
    requestFilter: RequestFiltersType;
    documentFilter: DocumentFiltersType;
};

const FolderNotificationMarker = ({ folder }: { folder: DocumentRequestFolderType }) => {
    const { userIsValuer } = useRole();

    const requests = folder?.requests?.filter(r => (r.status === `${userIsValuer ? 'SUBMITTED' : 'PENDING'}`));

    if (requests.length === 0) return null;

    return (
        <Circle
            size="10px"
            bg="wvwYellow"
        />
    );
};

const Folder = (props: PropTypes) => {
    const {
        inEditMode,
        folder,
        requestFilter,
        documentFilter,
    } = props;

    const { t } = useTranslation('documentmanagement');
    const { caseId = '' } = useParams();
    const { userIsValuer } = useRole();

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [editing, setEditing] = useState(false);

    const {
        data: requestList = [] as DocumentRequestType[],
    } = useDocumentRequestGetRequests({ caseId: Number(caseId), folderId: folder.id });

    const {
        update: updateFolder,
    } = useDocumentRequestUpdateFolder({
        caseId,
        onSuccess: () => setEditing(false),
    });

    const {
        update: deleteFolder,
    } = useDocumentRequestDeleteFolder({
        caseId,
        onSuccess: () => setDeleteConfirmOpen(false),
    });

    const [{ acceptedObjectIsBeingDragged, acceptedObjectIsOver }, drop] = useDrop(() => ({
        accept: [REQUEST_IMPORT, TEMPLATE_IMPORT, REQUEST_MOVE],
        drop: () => ({ folderId: folder.id } as FolderDropTargetType),
        collect: monitor => ({
            acceptedObjectIsBeingDragged: monitor.canDrop(),
            acceptedObjectIsOver: monitor.canDrop() && monitor.isOver(),
        }),
    }));

    let backgroundColor = 'wvwGrey10';

    if (acceptedObjectIsOver) {
        backgroundColor = 'wvwGreen80';
    } else if (acceptedObjectIsBeingDragged) {
        backgroundColor = 'wvwGreen20';
    }

    return (
        <>
            <AccordionItem>
                <AccordionButton
                    bgColor={backgroundColor}
                    data-testid="dustbinlkjsdnf" // TODO: Michael fix hehe
                    onClick={e => folder.requests.length === 0 && e.preventDefault()}
                    paddingBlock="0"
                    onKeyUp={e => e.preventDefault()}
                    ref={drop}
                    w="100%"
                >
                    <Grid
                        alignItems="center"
                        maxW="100%"
                        paddingBlock="0rem"
                        templateColumns="repeat(4, 1fr)"
                        w="100%"
                    >
                        <HStack
                            paddingBlock=".7rem"

                        >
                            <AccordionIcon
                                visibility={folder.requests.length === 0 ? 'hidden' : 'visible'}
                            />
                            <Icon
                                as={Folder2}
                                color="wvwGreen"
                                boxSize={6}
                            />

                            {(inEditMode && editing) ? (
                                <FormikForm
                                    initialValues={{
                                        folderName: folder.name,
                                    }}
                                    onSubmit={values => updateFolder({
                                        folderId: folder.id,
                                        name: values.folderName,
                                    })}
                                >
                                    <HStack paddingLeft="2rem">
                                        <FormikInput
                                            name="folderName"
                                            width="fit-content"
                                        />

                                        <IconButton
                                            aria-label="Save"
                                            borderRadius="30"
                                            icon={<CheckIcon />}
                                            onClick={e => e.stopPropagation()}
                                            size="xs"
                                            type="submit"
                                            variant="primary"
                                        />

                                        <WvwCancelIconButton
                                            onClick={e => {
                                                setEditing(false);
                                                e.stopPropagation();
                                            }}
                                        />

                                    </HStack>
                                </FormikForm>
                            ) : (
                                <>
                                    <Heading
                                        as="button"
                                        fontSize="md"
                                        fontWeight="500"
                                        maxW="14rem"
                                        mb="0"
                                        overflow="hidden"
                                        textAlign="left"
                                        textOverflow="ellipsis"
                                        whiteSpace="nowrap"
                                        _hover={inEditMode ? { textDecoration: 'underline' } : {}}
                                        onClick={e => {
                                            if (inEditMode) {
                                                e.stopPropagation();
                                                setEditing(!editing);
                                            }
                                        }}
                                    >
                                        {folder.name === 'General' ? t('general') : folder.name}
                                    </Heading>

                                    <FolderNotificationMarker
                                        folder={folder}
                                    />
                                </>
                            )}
                        </HStack>

                        {(userIsValuer && !inEditMode && requestList.length === 0) && (
                            <GridItem
                                colStart={4}
                                justifySelf="flex-end"
                                onClick={e => e.stopPropagation()}
                            >
                                <WvwDeleteIconButton
                                    onClick={() => setDeleteConfirmOpen(true)}
                                />
                            </GridItem>
                        )}
                    </Grid>
                </AccordionButton>

                <AccordionPanel
                    marginInline="0"
                    paddingInline="0"
                >
                    <Requests
                        inEditMode={inEditMode}
                        folderId={folder.id}
                        requestFilter={requestFilter}
                        documentFilter={documentFilter}
                    />
                </AccordionPanel>
            </AccordionItem>

            <ConfirmationModal
                title={t('modal.title.deleteFolder', { ns: 'documentmanagement' })}
                content={t('modal.message.deleteFolder', { ns: 'documentmanagement' })}
                cancelButtonVariant="primary"
                continueButtonVariant="danger"
                isOpen={deleteConfirmOpen}
                onContinue={() => deleteFolder(folder.id)}
                onCancel={() => setDeleteConfirmOpen(false)}
            />
        </>
    );
};

export default Folder;
