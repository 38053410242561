import { useQuery, useQueryClient } from 'react-query';
import { documentRequestGetDocument } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useToast from '../../../../common/hooks/useToast';

const DOCUMENT_REQUEST_GET_DOCUMENT = 'DOCUMENT_REQUEST_GET_DOCUMENT';

const useDocumentRequestGetDocument = (documentId: string | number) => {
    const queryClient = useQueryClient();

    const t = useSmartTranslation('documentmanagement');
    const { createErrorToast } = useToast();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [DOCUMENT_REQUEST_GET_DOCUMENT, documentId],
        () => documentRequestGetDocument(documentId),
        {
            enabled: !!documentId,
            onError: (err: Error) => createErrorToast(
                t(err.message, { defaultValue: err.message }),
            ),
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(DOCUMENT_REQUEST_GET_DOCUMENT);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useDocumentRequestGetDocument;
