const parkingTypes = [
    'Carport',
    'Single Garage - Normal',
    'Double Garage - Normal',
    'Single Garage - Deluxe',
    'Double Garage - Deluxe',
    'Parking - Deep',
    'Parking - High',
    'Parking - Outdoor',
];

export default parkingTypes;
