import {
    FormControl,
    Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { ColorPick } from '../inputs';

type PropTypes = {
    label: string;
    name: string;
};

const FormikColorPicker = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const { label, name } = props;

    const [field, meta] = useField(props);

    return (
        <>
            <FormControl
                isInvalid={!!meta.touched && !!meta.error}
            >
                <ColorPick
                    label={label}
                    onChange={color => {
                        field.onChange({
                            target: {
                                name,
                                value: color,
                            },
                        });
                    }}
                    value={field.value}
                />
            </FormControl>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </>
    );
};

export default FormikColorPicker;
