import { useQuery, useQueryClient } from 'react-query';
import { adminGetAllUsers } from '../endpoints';

const ALL_USERS = 'ALL_USERS';

const useAdminGetAllUsers = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([ALL_USERS], () => adminGetAllUsers());

    const refresh = () => {
        queryClient.invalidateQueries(ALL_USERS);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useAdminGetAllUsers;
