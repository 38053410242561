import {
    VStack,
    Text,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
    useDisclosure,
    createStandaloneToast,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useEnquirySteps from '../common/hooks/useEnquirySteps';
import useEnquirySubmit from '../../../utils/api/hooks/useEnquirySubmit';
import GeneralEnquiryOverview from '../../../common/components/display/enquiry-overview/GeneralEnquiryOverview';
import { useEnquiry, useEnquiryGetAll } from '../../../utils/api/hooks';
import { EnquiryType } from '../../../types';
import CancelEnquiryModal from '../common/CancelEnquiryModal';

const EnquiryOverviewInfo = () => {
    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { toast } = createStandaloneToast();
    const { onBack, onContinue } = useEnquirySteps();
    const { enquiryId = '' } = useParams();
    const navigate = useNavigate();

    const {
        refresh: enquiryRefresh,
    } = useEnquiryGetAll();

    const { update: enquirySubmit } = useEnquirySubmit({
        onSuccess: () => {
            onContinue();
            enquiryRefresh();
        },
        onError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,

        }),
    });

    const {
        enquiry: enquiryData = {} as unknown as EnquiryType,
    } = useEnquiry({ enquiryId });

    return (
        <VStack
            align="left"
            w="100%"
            padding="1rem"
        >
            <Text paddingInline=".5rem">
                {t('newEnquiry.overview.instruction')}
            </Text>

            <GeneralEnquiryOverview variant="grey" overviewData={enquiryData} />

            <Flex
                alignItems="left"
                gap="2"
                minWidth="max-content"
                paddingBlock="1rem"
                paddingInline="2rem"
                w="100%"
            >
                <ButtonGroup gap="2">
                    <Button
                        color="wvwGreen"
                        onClick={() => onBack()}
                        variant="outline"
                    >
                        {t('button.back', { ns: 'common' })}
                    </Button>
                </ButtonGroup>

                <Spacer />

                <ButtonGroup>
                    <Button
                        onClick={onOpen}
                        variant="danger"
                    >
                        {t('button.cancel', { ns: 'common' })}
                    </Button>

                    <Button
                        type="submit"
                        onClick={
                            () => {
                                enquirySubmit(enquiryId);
                                navigate('/dashboard');
                            }
                        }
                        variant="primary"
                    >
                        {t('button.submit', { ns: 'common' })}
                    </Button>
                </ButtonGroup>
            </Flex>

            <CancelEnquiryModal
                isOpen={isOpen}
                onCancel={onClose}
            />
        </VStack>
    );
};

export default EnquiryOverviewInfo;
