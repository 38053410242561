import { Accordion } from '@chakra-ui/react';
import { CaseType, InspectionType, PropertyType } from '../../../../../types';
import PropertyAccordionItem from './PropertyAccordionItem';

type PropTypes = {
    caseData: CaseType;
    inspectionList: InspectionType[];
    propertyList: PropertyType[];
    onClick: (property: PropertyType) => void;
};

const PropertyAccordion = (props: PropTypes) => {
    const {
        caseData,
        inspectionList,
        propertyList,
        onClick,
    } = props;

    return (
        <Accordion
            allowMultiple
            defaultIndex={[0]}
            variant="white"
        >
            {propertyList.map(property => (
                <PropertyAccordionItem
                    caseData={caseData}
                    inspectionList={inspectionList}
                    key={property.id}
                    property={property}
                    onClick={onClick}
                />
            ))}
        </Accordion>
    );
};

export default PropertyAccordion;
