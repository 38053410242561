import {
    AccordionButton,
    AccordionIcon,
    Box,
    Flex,
    HStack,
    Spacer,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import ListItemLayout from './ListItemLayout';
import { WVWTitle } from '../typography';

type PropTypes = {
    actionButton?: ReactNode;
    icon?: ReactNode;
    statusBadge: ReactNode;
    title: string | ReactNode;
};

const WvwAccordionButton = (props: PropTypes) => {
    const {
        actionButton,
        icon,
        statusBadge,
        title,
    } = props;

    return (
        <AccordionButton
            _hover={{ bgColor: 'wvwGrey10' }}
            bgColor="wvwGrey05"
        >
            <AccordionIcon visibility="visible" />

            <Box
                ml="0.8rem"
                w="100%"
            >
                <ListItemLayout
                    heading={(
                        <HStack spacing="3">
                            {icon}

                            { typeof title === 'string' && (
                                <WVWTitle
                                    black
                                    content={title}
                                    level="3"
                                    normalFontWeight
                                />
                            )}

                            { typeof title !== 'string' && title }
                        </HStack>
                    )}
                    extra={(
                        <Flex
                            ml="3rem"
                            w="100%"
                        >
                            {statusBadge}

                            <Spacer />

                            {actionButton}
                        </Flex>
                    )}
                />
            </Box>
        </AccordionButton>
    );
};

WvwAccordionButton.defaultProps = {
    actionButton: null,
    icon: null,
};

export default WvwAccordionButton;
