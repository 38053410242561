import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    extra: ReactNode | ReactNode[];
    heading: string | ReactNode;
};

const ListItemLayout = (props: PropTypes) => {
    const { extra, heading } = props;

    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            w="100%"
        >
            <Box w="50%">
                {heading}
            </Box>

            <Flex
                justifyContent="flex-end"
                w="50%"
            >
                {extra}
            </Flex>
        </Flex>
    );
};

export default ListItemLayout;
