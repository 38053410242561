import { useMutation } from 'react-query';
import { clientInvitesRespond } from '../endpoints';
import { ClientInvitesRespondDto } from '../dto';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useClientInvitesRespond = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: ClientInvitesRespondDto) => clientInvitesRespond(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useClientInvitesRespond;
