import { ChevronDownIcon, EmailIcon } from '@chakra-ui/icons';
import {
    Button,
    Link as ChakraLink,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    Tooltip,
    VStack,
    createStandaloneToast,
} from '@chakra-ui/react';
import {
    Activity,
    Clipboard,
    Link1,
    Moon,
    Trash,
} from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import DataTable from '../../../common/components/DataTable';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import delimitNumber from '../../../common/functions/delimitNumber';
import { useRole } from '../../../common/hooks';
import { EnquiryType } from '../../../types';
import {
    useEnquiryCancel,
    useEnquiryGetAll,
    useEnquiryReactivate,
    useEnquirySetDormant,
    useOfferGetAllMyOffers,
} from '../../../utils/api/hooks';
import { displayDateWithCurrentLocale } from '../../../common/functions/displayDateInLocale';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import StatusBadgeEnquiry from '../../../common/components/display/StatusBadgeEnquiry';

const Enquiries = () => {
    const navigate = useNavigate();

    const { t } = useTranslation(['dashboard', 'common', 'offer']);

    const { toast } = createStandaloneToast();

    const {
        userIsClient,
        userIsValuer,
        roleLoading,
    } = useRole();

    const {
        data: enquiryList = [],
        isLoading: enquiryLoading,
        refresh,
    } = useEnquiryGetAll();

    const { update: reactivate } = useEnquiryReactivate({
        onSuccess: data => {
            navigate(`/create-client-enquiry/enquiry-valuation-info/${data.data.id}`);
        },
    });

    const {
        data: offerList = [],
        isLoading: offerLoading,
    } = useOfferGetAllMyOffers();

    const { update: setDormant } = useEnquirySetDormant({
        onSuccess: () => {
            refresh();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const { cancelEnquiry, modal: cancelEnquiryModal } = useEnquiryCancel({
        onSuccess: () => refresh(),
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (roleLoading || enquiryLoading || offerLoading) return <LoadingSpinner />;

    const headers = [
        {
            title: t('enquiriesTable.header.client'),
            maxWidth: '15rem',
            render: (enquiry: EnquiryType) => `${enquiry.invoiceFName} ${enquiry.invoiceLName}`,
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aName = `${a.invoiceFName} ${a.invoiceLName}`;
                const bName = `${b.invoiceFName} ${b.invoiceLName}`;

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('enquiriesTable.header.property'),
            maxWidth: '15rem',
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aProperty = a?.properties?.[0]?.street || `${a?.properties?.length || 0} properties`;
                const bProperty = b?.properties?.[0]?.street || `${b?.properties?.length || 0} properties`;

                return aProperty.localeCompare(bProperty);
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i || {} as EnquiryType;

                if (properties.length === 1) {
                    return (
                        <Text>
                            {properties[0].street}
                        </Text>
                    );
                }

                return (
                    <Text>
                        {t('property_wc', { count: properties.length, ns: 'common' })}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.estimateValue'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const { properties: aProperties = [] } = a;
                const { properties: bProperties = [] } = b;

                const aEstimateValue = aProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                const bEstimateValue = bProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return aEstimateValue - bEstimateValue;
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i;

                const estimateValue = properties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return (
                    <Text
                        pr={userIsClient ? '3.8rem' : '2rem'}
                    >
                        {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(estimateValue)}`}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.currentPrice'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aOffers = offerList.filter(offer => offer.enquiryId === a.id);
                const bOffer = offerList.filter(offer => offer.enquiryId === b.id);

                const aLowestOffer = Math.min(...aOffers.map(offer => offer.price));
                const bLowestOffer = Math.min(...bOffer.map(offer => offer.price));

                return aLowestOffer - bLowestOffer;
            },
            render: (i: EnquiryType) => {
                const offers = offerList.filter(offer => offer.enquiryId === i.id);

                const lowestOffer = Math.min(...offers.map(offer => offer.price));

                return (
                    <Text
                        pr={userIsClient ? '2.4rem' : '1rem'}
                    >
                        {lowestOffer === Infinity ? t('enquiriesTable.cellValue.awaitingOffers') : `${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(lowestOffer)}`}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.status'),
            alignment: 'center',
            maxWidth: '10rem',
            render: (i: EnquiryType) => (
                <StatusBadgeEnquiry status={i.status} />
            ),
            sorter: (a: EnquiryType, b: EnquiryType) => a.status.localeCompare(b.status),
        },
        {
            title: t('enquiriesTable.header.dueDate'),
            dataIndex: 'completionDate',
            maxWidth: '10rem',
            render: (i: EnquiryType) => (
                <Text>
                    {displayDateWithCurrentLocale(i.completionDate)}
                </Text>
            ),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                if (b.completionDate.replace(/-/g, '') < a.completionDate.replace(/-/g, '')) {
                    return -1;
                }
                if (b.completionDate.replace(/-/g, '') > a.completionDate.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: t('enquiriesTable.header.creationDate'),
            maxWidth: '10rem',
            render: (i: EnquiryType) => (
                <Text>
                    {displayDateWithCurrentLocale(i.createdAt)}
                </Text>
            ),
            sorter: (a: EnquiryType, b: EnquiryType) => (
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            ),
        },
        {
            title: t('enquiriesTable.header.action'),
            maxWidth: '10rem',
            render: (i: EnquiryType) => (
                <VStack
                    w="100%"
                    align="center"
                >
                    <HStack spacing="1">
                        <Menu autoSelect={false}>
                            <MenuButton
                                as={IconButton}
                                borderRadius="30"
                                icon={<ChevronDownIcon />}
                                size="sm"
                                variant="primary"
                            />

                            <MenuList>
                                {userIsValuer || i.status === 'ACCEPTED' ? (
                                    <ChakraLink
                                        href={`mailto:${i.invoiceEmail}?subject=${i.properties[0].street}`}
                                        isExternal
                                        textUnderlineOffset={-2}
                                    >
                                        <MenuItem
                                            icon={<EmailIcon boxSize="1.2rem" />}
                                            color="wvwGreen"
                                        >
                                            {t('enquiriesTable.button.contact')}
                                        </MenuItem>
                                    </ChakraLink>
                                ) : (
                                    <Tooltip
                                        label={t('enquiriesTable.msg.contact')}
                                        textAlign="center"
                                    >
                                        <MenuItem
                                            icon={<EmailIcon boxSize="1.2rem" />}
                                            color="wvwGrey"
                                            isDisabled
                                            aria-label="Send email"
                                        >
                                            {t('enquiriesTable.button.contact')}
                                        </MenuItem>
                                    </Tooltip>
                                )}

                                {(userIsClient && i.status !== 'CANCELLED') && (
                                    <MenuItem
                                        icon={<Trash size="1.2rem" />}
                                        width="100%"
                                        color="wvwGreen"
                                        onClick={() => cancelEnquiry(i.id)}
                                    >
                                        {t('button.cancel', { ns: 'common' })}
                                    </MenuItem>
                                )}

                                {(userIsClient && i.status === 'CANCELLED') && (
                                    <MenuItem
                                        icon={<Activity size="1.2rem" />}
                                        width="100%"
                                        color="wvwGreen"
                                        onClick={() => reactivate(i.id)}
                                    >
                                        {t('button.reactivate', { ns: 'common' })}
                                    </MenuItem>
                                )}

                                <MenuItem
                                    icon={<Moon size="1.2rem" />}
                                    width="100%"
                                    color="wvwGreen"
                                    onClick={() => {
                                        setDormant({
                                            enquiryId: i.id,
                                            dormant: true,
                                        });
                                    }}
                                >
                                    {t('enquiriesTable.button.makeDormant')}
                                </MenuItem>
                            </MenuList>
                        </Menu>

                        <Spacer />

                        <ChakraLink
                            as={ReactLink}
                            to={`/dashboard/enquiry/${i.id}/overview`}
                        >
                            <Button
                                borderRadius="30"
                                size="sm"
                                variant="primaryYellow"
                            >
                                {t('enquiriesTable.button.view')}
                            </Button>
                        </ChakraLink>
                    </HStack>
                </VStack>
            ),
        },
    ];

    if (userIsClient) {
        headers.shift();
    }

    return (
        <>
            <PageWithTitleLayout
                title={t('section.enquiries')}
                extra={(
                    <>
                        {userIsClient && (
                            <ChakraLink
                                as={ReactLink}
                                to="/create-client-enquiry"
                            >
                                <Button
                                    variant="outline1"
                                    paddingBlock="1.2rem"
                                    borderRadius="10"
                                    width="12.8rem"
                                >
                                    {t('enquiriesTable.button.createNewEnquiry')}
                                </Button>
                            </ChakraLink>
                        )}

                        {userIsValuer && (
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    variant="outline1"
                                    paddingBlock="1.2rem"
                                    borderRadius="10"
                                    width="12.8rem"
                                    rightIcon={<ChevronDownIcon />}
                                >
                                    {t('enquiriesTable.button.createNewEnquiry')}
                                </MenuButton>

                                <MenuList>
                                    <MenuItem
                                        paddingBlock=".8rem"
                                        onClick={() => navigate('/create-client-enquiry')}
                                    >
                                        <Clipboard size="1.1rem" />
                                        <Text paddingInline=".5rem">{t('enquiriesTable.button.manualEnquiry')}</Text>
                                    </MenuItem>

                                    <MenuItem
                                        paddingBlock=".8rem"
                                        onClick={() => navigate('/dashboard/account/integration')}
                                    >
                                        <Link1 size="1.1rem" />
                                        <Text paddingInline=".5rem">{t('enquiriesTable.button.sendLink')}</Text>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        )}
                    </>
                )}
            >
                <DataTable
                    columns={headers}
                    data={enquiryList}
                    noDataText={t('noEnquiries', { ns: 'enquiries' })}
                />
            </PageWithTitleLayout>

            {cancelEnquiryModal}
        </>
    );
};

export default Enquiries;
