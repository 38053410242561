import {
    VStack,
    Heading,
    Text,
    Box,
    Flex,
    Spacer,
    Button,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    FormikForm,
    FormikRadioCloud,
    FormikConditionalSection,
    FormikFileUpload,
    FormikTextarea,
    FormikCheckboxGroup,
    FormikCloudSelect,
    FormikInput,
    FormikCloudSelectExpandable,
} from '../../common/forms';
import {
    standardSpecialValuationTypes,
    standardValuerMemberships,
    standardValuationOccasions,
    standardValuationTypes,
} from '../../common/vars/valuationsAndMembershipTypes';
import { DirectEnquiryDataType } from './types';

const validationSchema = Yup.object({
    valuationType: Yup.string().required('errors.selectValuationType'),
    valuationPurposes: Yup.array()
        .min(1, 'errors.selectValuationPurpose')
        .required('errors.selectValuationPurpose'),
    completionDate: Yup.string().required('errors.selectCompletionDate'),
});

type PropTypes = {
    onContinue: (values: any) => void;
    enquiryData: DirectEnquiryDataType;
};

const ValuationType = (props: PropTypes) => {
    const { onContinue, enquiryData } = props;
    const { clientType = 'individual' } = useParams();
    const { t } = useTranslation(['enquiryform', 'common']);

    const standardReducedPurposes = standardValuationOccasions.slice(0, 3);
    const standardExpandedPurposes = standardValuationOccasions.slice(
        3,
        standardValuationOccasions.length,
    );

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                valuationType: enquiryData.valuationType || '',
                valuationSpecial: enquiryData.valuationSpecial || [],
                retypeDocuments: enquiryData.retypeDocuments || [],
                valuationOther: enquiryData.valuationOther || '',
                completionDate: enquiryData.completionDate || '',
                valuationStandards: enquiryData.valuationStandards || '',
                valuationStandardsOther: enquiryData.valuationPurposesOther || '',
                valuationPurposes: enquiryData.valuationPurposes || [],
                valuationPurposesOther: enquiryData.valuationPurposesOther || '',
            }}
            onSubmit={values => {
                onContinue(values);
            }}
        >
            <VStack
                align="left"
                spacing={4}
                w="100%"
                paddingBlock="1.5rem"
                paddingInline="1rem"
            >
                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <VStack
                        w="100%"
                        align="left"
                    >
                        <Heading
                            size="md"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.valuationSection.valuationType.sectionTitle')}
                        </Heading>
                        <Text>
                            {t('newEnquiry.valuationSection.valuationType.leadQuestion')}
                        </Text>
                        <FormikRadioCloud
                            name="valuationType"
                            options={standardValuationTypes.map(type => ({
                                label: t(type, { ns: 'formik', defaultValue: type }),
                                value: type,
                            }))}
                        />
                    </VStack>

                    <FormikConditionalSection
                        formDataTarget="valuationType"
                        condition={value => value.includes('Retype Valuation')}
                    >
                        <VStack
                            align="left"
                            backgroundColor="wvwGrey05"
                            borderRadius="8"
                            padding="1rem"
                        >

                            <Text
                                as="b"
                                color="wvwGreen"
                            >
                                {t('newEnquiry.valuationSection.valuationType.retype.label')}
                            </Text>
                            <Text paddingBottom="1rem">
                                {t('newEnquiry.valuationSection.valuationType.retype.instruction')}
                            </Text>

                            <FormikFileUpload name="retypeDocuments" />
                        </VStack>
                    </FormikConditionalSection>

                    <FormikConditionalSection
                        formDataTarget="valuationType"
                        condition={value => value.includes('Other Valuation')}
                    >
                        <>
                            <VStack
                                align="left"
                                backgroundColor="wvwGrey05"
                                borderRadius="8"
                                padding="1rem"
                            >
                                <FormikCheckboxGroup
                                    name="valuationSpecial"
                                    options={standardSpecialValuationTypes.map(type => ({
                                        label: t(type, { ns: 'formik', defaultValue: type }),
                                        value: type,
                                    }))}
                                />
                            </VStack>

                            <VStack
                                align="left"
                                backgroundColor="wvwGrey05"
                                borderRadius="8"
                                padding="1rem"
                            >

                                <Text
                                    as="b"
                                    color="wvwGreen"
                                >
                                    {t('newEnquiry.valuationSection.valuationType.other.label')}
                                </Text>
                                <Text paddingBottom="1rem">
                                    {t('newEnquiry.valuationSection.valuationType.other.instruction')}
                                </Text>

                                <FormikTextarea
                                    name="valuationOther"
                                />
                            </VStack>
                        </>
                    </FormikConditionalSection>
                </VStack>

                {clientType === 'company' && (
                    <VStack
                        w="100%"
                        align="left"
                        bg="wvwGrey05"
                        borderRadius="10"
                        padding="1rem"
                    >
                        <Box>
                            <Heading
                                size="md"
                                paddingBottom=".5rem"
                                color="wvwGreen"
                            >
                                {t('newEnquiry.valuationSection.valuationStandards.sectionTitle')}
                            </Heading>
                            <Text>
                                {t('newEnquiry.valuationSection.valuationStandards.leadQuestion')}
                            </Text>
                        </Box>

                        <FormikRadioCloud
                            name="valuationStandards"
                            options={standardValuerMemberships.map(membership => ({
                                label: t(membership, { ns: 'formik', defaultValue: membership }),
                                value: membership,
                            }))}
                        />

                        <FormikConditionalSection
                            formDataTarget="valuationStandards"
                            condition={value => value.includes('Other')}
                        >
                            <VStack
                                align="start"
                                backgroundColor="wvwGrey05"
                                borderRadius="8"
                                padding="1rem"
                                spacing="4"
                                w="100%"
                            >
                                <Text
                                    color="wvwGreen"
                                >
                                    {t('newEnquiry.valuationSection.valuationStandards.other.instruction')}
                                </Text>

                                <FormikTextarea
                                    name="valuationStandardsOther"
                                />
                            </VStack>
                        </FormikConditionalSection>
                    </VStack>
                )}

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Box>
                        <Heading
                            size="md"
                            paddingBottom=".5rem"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.valuationSection.valuationPurpose.sectionTitle')}
                        </Heading>
                        <Text>
                            {t('newEnquiry.valuationSection.valuationPurpose.leadQuestion')}
                        </Text>
                    </Box>

                    {clientType === 'individual' ? (
                        <FormikCloudSelectExpandable
                            name="valuationPurposes"
                            options={standardReducedPurposes.map(purpose => ({
                                label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                                value: purpose,
                            }))}
                            optionsExpanded={standardExpandedPurposes.map(purpose => ({
                                label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                                value: purpose,
                            }))}
                        />
                    ) : (
                        <FormikCloudSelect
                            name="valuationPurposes"
                            options={standardValuationOccasions.map(purpose => ({
                                label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                                value: purpose,
                            }))}
                        />
                    )}

                    <FormikConditionalSection
                        formDataTarget="valuationPurposes"
                        condition={x => x.includes('Other')}
                    >
                        <VStack
                            align="left"
                            backgroundColor="wvwGrey05"
                            borderRadius="8"
                            padding="1rem"
                        >
                            <Text
                                color="wvwGreen"
                            >
                                {t('newEnquiry.valuationSection.valuationPurpose.other.instruction')}
                            </Text>

                            <FormikTextarea
                                name="valuationPurposesOther"
                            />
                        </VStack>
                    </FormikConditionalSection>
                </VStack>

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Box>
                        <Heading
                            size="md"
                            paddingBottom=".5rem"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.valuationSection.completionDate.sectionTitle')}
                        </Heading>

                        <Text>
                            {t('newEnquiry.valuationSection.completionDate.leadQuestion')}
                        </Text>
                    </Box>

                    <FormikInput
                        name="completionDate"
                        min={new Date().toISOString().split('T')[0]}
                        placeholder={t('fieldPlaceholder.date', { ns: 'common' })}
                        type="date"
                        width="53%"
                    />
                </VStack>

            </VStack>

            <Flex
                alignItems="right"
                gap="2"
                w="100%"
                paddingBlock="1rem"
                paddingInline="2rem"
            >
                <Spacer />

                <Button
                    type="submit"
                    variant="primary"
                >
                    {t('button.next', { ns: 'common' })}
                </Button>
            </Flex>
        </FormikForm>
    );
};

export default ValuationType;
