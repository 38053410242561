import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string;
    options: {
        icon?: ReactNode;
        label: string;
        value: string;
    }[];
    optionsExpanded: {
        label: string;
        value: string;
    }[];
    onChange?: (field?: FieldInputProps<any>, value?: string[],) => void,
};

const FormikCloudSelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        options,
        optionsExpanded,
        onChange,
    } = props;

    const { values } = useFormikContext<{ [index: string]: any }>();

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const fieldValues = values[name] || [];

    const initalExpansion = optionsExpanded.some(option => fieldValues.includes(option.value));

    const initialSelectedMainOptions = fieldValues.filter(
        (i: string) => !optionsExpanded.map(o => o.value).includes(i),
    );

    const initialSelectedExpandedOptions = fieldValues.filter(
        (i: string) => optionsExpanded.map(o => o.value).includes(i),
    );

    const [
        isExpanded,
        setIsExpanded,
    ] = useState(initalExpansion);

    const [
        selectedMainOptions,
        setSelectedMainOptions,
    ] = useState<string[]>(initialSelectedMainOptions);

    const [
        selectedExpandedOptions,
        setSelectedExpandedOptions,
    ] = useState<string[]>(initialSelectedExpandedOptions);

    return (
        <Box w="100%">
            <SimpleGrid
                minChildWidth="13rem"
                spacing="0.2rem"
            >
                {options.map(option => {
                    if (option.icon) {
                        return (
                            <Box
                                key={`${field.name}[${option.value}]`}
                                as="button"
                                maxWidth="20rem"
                                name={`${field.name}[${option.value}]`}
                                w="100%"
                            >
                                <VStack
                                    align="center"
                                    spacing="1"
                                    w="7em"
                                >
                                    {option.icon}

                                    <Text
                                        align="center"
                                    >
                                        {option.label}
                                    </Text>
                                </VStack>
                            </Box>
                        );
                    }

                    return (
                        <Button
                            key={option.value}
                            margin="0.5rem"
                            minW="12rem"
                            fontSize={option.label.length > 20 ? '0.8rem' : '1rem'}
                            name={`${field.name}[${option.value}]`}
                            variant={fieldValues.includes(option.value) ? 'primaryYellow' : 'primary'}
                            onClick={() => {
                                const wasSelected = selectedMainOptions.includes(option.value);

                                let newSelectedMainOptions = [];

                                if (wasSelected) {
                                    newSelectedMainOptions = selectedMainOptions.filter(
                                        (value: string) => value !== option.value,
                                    );
                                } else {
                                    newSelectedMainOptions = [...selectedMainOptions, option.value];
                                }

                                setSelectedMainOptions(newSelectedMainOptions);

                                const newFieldValue = [
                                    ...newSelectedMainOptions,
                                    ...selectedExpandedOptions,
                                ];

                                field.onChange({
                                    target: {
                                        name,
                                        value: newFieldValue,
                                    },
                                });

                                onChange?.(field, newFieldValue);
                            }}
                        >
                            {option.label}
                        </Button>
                    );
                })}

                <Button
                    margin="0.5rem"
                    minW="12rem"
                    fontSize="1rem"
                    variant={isExpanded ? 'primaryYellow' : 'primary'}
                    onClick={() => {
                        if (selectedExpandedOptions.length === 0) {
                            setIsExpanded(!isExpanded);
                        }
                    }}
                >
                    {t('more')}
                </Button>
            </SimpleGrid>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }

            {isExpanded && (
                <SimpleGrid
                    backgroundColor="wvwGrey05"
                    borderRadius="8"
                    minChildWidth="12rem"
                    padding="1rem"
                >
                    <CheckboxGroup
                        defaultValue={selectedExpandedOptions}
                        onChange={(value: string[]) => {
                            const newExpandedOptions = value;

                            setSelectedExpandedOptions(newExpandedOptions);

                            const newFieldValue = [
                                ...selectedMainOptions,
                                ...newExpandedOptions,
                            ];

                            field.onChange({ target: { name, value: newFieldValue } });

                            onChange?.(field, newFieldValue);
                        }}
                    >
                        {optionsExpanded.map(({ value, label }) => (
                            <Checkbox
                                key={value}
                                value={value}
                                paddingBlock=".3rem"
                            >
                                {label}
                            </Checkbox>
                        ))}
                    </CheckboxGroup>
                </SimpleGrid>
            ) }
        </Box>
    );
};

FormikCloudSelect.defaultProps = {
    onChange: () => {},
};

export default FormikCloudSelect;
