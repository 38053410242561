import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CompanySetAccessDto } from '../dto';
import { companySetAccess } from '../endpoints';
import useMyEntity from './useMyEntity';
import useUsersByEntity from './useUsersByEntity';
import useToast from '../../../common/hooks/useToast';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useCompanySetAccess = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { t } = useTranslation('common');

    const { createSuccessToast, createErrorToast } = useToast();

    const {
        data: myEntity,
    } = useMyEntity();

    const {
        refresh: refreshUsers,
    } = useUsersByEntity(myEntity.id);

    const { mutate: update } = useMutation(
        (dto: CompanySetAccessDto) => companySetAccess(dto),
        {
            onSuccess: () => {
                onSuccess?.();

                refreshUsers();

                createSuccessToast(t('success.accountSuspended', { ns: 'common' }));
            },
            onError: (error: Error) => {
                onError?.(error);

                createErrorToast(t(error.message, { defaultValue: error.message }));
            },
        },
    );

    return {
        update,
    };
};

export default useCompanySetAccess;
