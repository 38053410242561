import { IconButton } from '@chakra-ui/react';
import { JSXElementConstructor, MouseEvent, ReactElement } from 'react';

type PropTypes = {
    ariaLabel: string;
    color? : string;
    icon: ReactElement<any, string | JSXElementConstructor<any>>;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export type IconButtonPropType = Omit<PropTypes, 'icon' | 'ariaLabel'>;

const WvwIconButton = (props: PropTypes) => {
    const {
        ariaLabel,
        color,
        icon,
        onClick,
    } = props;

    return (
        <IconButton
            aria-label={ariaLabel}
            icon={icon}
            onClick={onClick}
            color={color}
            variant="none"
        />
    );
};

WvwIconButton.defaultProps = {
    onClick: () => {},
    color: 'black',
};

export default WvwIconButton;
