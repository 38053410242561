import { useMutation } from 'react-query';
import { DocumentRequestUploadDocumentDto } from '../../dto';
import { documentRequestUploadDocument } from '../../endpoints';
import useToast from '../../../../common/hooks/useToast';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    caseId: number | string;
};

const useDocumentRequestUploadDocument = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
        caseId,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { createSuccessToast, createErrorToast } = useToast();
    const { refreshAll } = useRefreshAllDMSDataStores(Number(caseId));

    const { mutate: update } = useMutation(
        (dto: DocumentRequestUploadDocumentDto) => documentRequestUploadDocument(dto),
        {
            onSuccess: () => {
                refreshAll();
                createSuccessToast(t('toast.messages.documentUploaded'));
                onSuccess?.();
            },
            onError: (error: Error) => {
                onError?.(error);
                createErrorToast(t(error.message, { defaultValue: error.message }));
            },
        },
    );

    return {
        update,
    };
};

export default useDocumentRequestUploadDocument;
