import WVWTitle from './WVWTitle';

type PropTypes = {
    content: string;
};

const WVWLabel = (props: PropTypes) => {
    const { content } = props;

    return (
        <WVWTitle
            content={content}
            level="3"
        />
    );
};

export default WVWLabel;
