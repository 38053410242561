import {
    Box,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePublicEntity } from '../../utils/api/hooks';
import DirectEnquiryLogo from './DirectEnquiryLogo';
import LanguageToggleButton from '../../common/components/LanguageToggleButton';
import ProgressTabs from '../../common/components/ProgressTabs';
import SiteLoading from '../../common/components/SiteLoading';

type PropTypes = {
    steps: string[];
    currentStep: string;
    children: ReactNode | ReactNode[];
};

const EnquiryContainer = (props: PropTypes) => {
    const {
        steps,
        currentStep,
        children,
    } = props;

    const { t } = useTranslation('enquiryform');

    const { token = '' } = useParams();

    const {
        data: valuer,
        isLoading,
    } = usePublicEntity(token);

    if (isLoading) {
        <SiteLoading
            noLogo
            text={t('loading', { ns: 'common' })}
        />;
    }

    return (
        <VStack
            align="left"
            bg="white"
            borderRadius="10px"
            paddingBottom=".5rem"
            w="100%"
        >
            <Flex
                paddingBottom=".5rem"
                paddingInline="2.5rem"
                paddingTop="2rem"
            >
                <HStack spacing="4">
                    <DirectEnquiryLogo
                        companyId={valuer?.id || -1}
                        size="6rem"
                    />

                    <Box>
                        <Heading>
                            {valuer?.name}
                        </Heading>

                        <HStack
                            paddingTop=".5rem"
                            spacing="4"
                        >
                            <Text>
                                {t('newEnquiry.sectionTitle')}
                            </Text>
                        </HStack>
                    </Box>
                </HStack>

                <Spacer />

                <LanguageToggleButton />
            </Flex>

            <Box
                alignSelf="center"
                w="95%"
            >
                <ProgressTabs
                    currentStep={currentStep}
                    steps={steps}
                />

                <Box>
                    {children}
                </Box>
            </Box>
        </VStack>
    );
};

export default EnquiryContainer;
