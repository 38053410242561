import {
    Button,
    ButtonGroup,
    Center,
    VStack,
    Text,
    HStack,
    IconButton,
    Flex,
    Tooltip,
    Spacer,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    AbsoluteCenter,
    Box,
} from '@chakra-ui/react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    DirectInbox,
    DocumentText,
    Edit,
    Message,
    Message2,
    Trash,
} from 'iconsax-react';
import {
    useDocumentRequestCreateDocumentComment,
    useDocumentRequestGetCaseDocuments,
    useDocumentRequestGetDocument,
    useDocumentRequestMarkDocumentCommentsAsRead,
    useDocumentRequestUpdateDocument,
    useDocumentRequestUpdateDocumentsStatus,
} from '../../../../../../utils/api/hooks';
import LoadingSpinner from '../../../../../../common/components/LoadingSpinner';
import { FormikForm, FormikInput, FormikTextarea } from '../../../../../../common/forms';
import { DocumentRequestDocumentType } from '../../../../../../types';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import CommentsThread from '../../../../../../common/components/CommentsThread';
import { useRole, useToast } from '../../../../../../common/hooks';
import Toggle from '../../../../../../common/components/Toggle';
import LoadingModal from '../../../../../../common/components/LoadingModal';
import ModalDymanic from '../../../../../../common/components/ModalDynamic';
import ConfirmationModal from '../../../../../../common/components/ConfirmationModal';
import VerticalDividerWithIcon from '../../../../../../common/components/VerticalDivider';
import WvwDeleteIconButton from '../../../../../../common/components/WvwDeleteIconButton';

type ParamsType = {
    caseId: string;
    docId: string;
    requestFilter: | 'PENDING'
    | 'SUBMITTED'
    | 'APPROVED'
    | 'REJECTED'
    | 'UNAVAILABLE'
    | 'DELETED'
    | 'ALL';
    documentFilter: | 'PENDING'
    | 'SUBMITTED'
    | 'ACCEPTED'
    | 'REJECTED'
    | 'UNAVAILABLE'
    | 'ALL';
};

const DocumentPreview = () => {
    const {
        docId = '',
        caseId,
        requestFilter,
        documentFilter,
    } = useParams<ParamsType>();

    const { t } = useTranslation(['common', 'documentmanagement']);

    const navigate = useNavigate();

    const { createErrorToast } = useToast();

    const { pathname } = useLocation();

    const documentView = pathname.split('/')[5] === 'documents';

    const { userIsValuer } = useRole();

    const onUpdateError = (error: string) => {
        createErrorToast(t(error, { defaultValue: error }));
    };

    const [reject, setReject] = useState(false);
    const [rename, setRename] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const {
        data: document = {} as DocumentRequestDocumentType,
        isLoading: documentIsLoading,
        refresh: refreshDocument,
    } = useDocumentRequestGetDocument(docId);

    const { documentRequestId: requestId } = document;

    const {
        data: documents = [] as DocumentRequestDocumentType[],
    } = useDocumentRequestGetCaseDocuments({
        caseId: Number(caseId),
        requestId: documentView ? undefined : requestId,
        documentStatus: documentFilter === 'ALL' ? undefined : documentFilter,
    });

    const {
        update: updateDocumentStatus,
    } = useDocumentRequestUpdateDocumentsStatus({
        caseId: Number(caseId),
        requestId: Number(requestId),
    });

    const {
        update: updateDocument,
    } = useDocumentRequestUpdateDocument({ caseId: Number(caseId) });

    const {
        update: deleteDocument,
    } = useDocumentRequestUpdateDocument({
        caseId: Number(caseId),
        onSuccess: () => {
            refreshDocument();
            navigate(-1);
        },
    });

    const {
        update: createDocumentComment,
    } = useDocumentRequestCreateDocumentComment({
        onSuccess: () => {
            refreshDocument();
        },
        onError: (error: { message: string; }) => onUpdateError(error.message),
    });

    const {
        update: markAsRead,
    } = useDocumentRequestMarkDocumentCommentsAsRead({
        documentId: docId,
        onSuccess: () => refreshDocument(),
    });

    if (documentIsLoading) {
        return <LoadingModal />;
    }

    const docIndex = documents.findIndex(
        (x: { id: number; }) => x.id === Number(docId),
    );

    const docFileType = document?.url?.split('.').pop()?.split('?')[0];

    const documentComments = document?.comments || [];

    return (
        <ModalDymanic
            header={(
                <HStack w="100%">
                    <DirectInbox />

                    <Text>
                        {document.documentRequest?.name || ''}
                    </Text>
                </HStack>
            )}
            isOpen
            onClose={() => navigate(-1)}
        >
            <VStack
                w="100%"
                spacing="0"
            >
                <Flex
                    w="100%"
                    align="center"
                >
                    <HStack
                        color="wvwGreen"
                        spacing="1"
                    >
                        {rename ? (
                            <FormikForm
                                initialValues={{
                                    name: document?.name,
                                }}
                                onSubmit={values => {
                                    updateDocument({
                                        documentRequestId: Number(requestId),
                                        documentId: document.id,
                                        name: values.name,
                                    });
                                    setRename(false);
                                }}
                            >
                                <HStack pb=".5rem">
                                    <FormikInput
                                        name="name"
                                    />
                                    <IconButton
                                        aria-label="Save"
                                        borderRadius="30"
                                        icon={<CheckIcon />}
                                        size="xs"
                                        type="submit"
                                        variant="primary"
                                    />

                                    <WvwDeleteIconButton
                                        onClick={() => setRename(false)}
                                    />
                                </HStack>
                            </FormikForm>
                        ) : (
                            <>
                                <DocumentText
                                    size="1.3rem"
                                />
                                <Text
                                    fontSize="xl"
                                    paddingRight=".5rem"
                                >
                                    {document?.name}
                                </Text>

                                {userIsValuer && (
                                    <Menu>
                                        <MenuButton
                                            as={IconButton}
                                            borderRadius="30"
                                            icon={<ChevronDownIcon />}
                                            size="xxs"
                                            variant="primary"
                                        />
                                        <MenuList>
                                            <MenuItem
                                                icon={<Edit size="1.2rem" />}
                                                width="100%"
                                                color="wvwGreen"
                                                onClick={() => setRename(true)}
                                            >
                                                {t('button.rename', { ns: 'common' })}
                                            </MenuItem>

                                            <MenuItem
                                                icon={<Trash size="1.2rem" />}
                                                width="100%"
                                                color="wvwGreen"
                                                onClick={() => {
                                                    setConfirmDeleteOpen(true);
                                                }}
                                            >
                                                {t('button.delete', { ns: 'common' })}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                )}
                            </>
                        )}
                    </HStack>

                    <Spacer />

                    <Toggle
                        index={docIndex}
                        itemId={docId}
                        toggleList={documents}
                        path={`dashboard/case/${caseId}/documents/documents/${requestFilter}/${documentFilter}/document-preview`}
                    />
                </Flex>

                <Flex w="100%">
                    {document?.responseComment && document.status === 'REJECTED' ? (
                        <Flex
                            marginBottom=".5rem"
                            paddingBlock=".5rem"
                            paddingInline="1rem"
                            borderRadius="10"
                            w="100%"
                            bg="red05"
                            align="center"
                        >
                            <VStack
                                align="left"
                                spacing="0"

                            >
                                <Text
                                    fontWeight="500"
                                    color="red"
                                >
                                    {t('rejectionNote', { ns: 'documentmanagement', defaultValue: 'Rejection note' })}
                                </Text>

                                <Text>
                                    {document?.responseComment}
                                </Text>
                            </VStack>

                            <Spacer />

                            <StatusBadge
                                status={document?.status || ''}
                                margin="0"
                            />
                        </Flex>
                    ) : (
                        <Flex
                            marginBottom=".5rem"
                            paddingBlock=".5rem"
                            paddingInline="1rem"
                            borderRadius="10"
                            w="100%"
                            bg="wvwGrey05"
                            align="center"
                        >
                            <VStack
                                align="left"
                                spacing="0"

                            >
                                <Text fontWeight="500">
                                    {t('submissionNote', { ns: 'documentmanagement', defaultValue: 'Submission note' })}
                                </Text>

                                <Text>
                                    {(document?.submissionComment === '' || document?.submissionComment === undefined) ? 'No submission note' : document?.submissionComment}
                                </Text>
                            </VStack>

                            <Spacer />

                            <StatusBadge
                                status={document?.status || ''}
                                margin="0"
                            />
                        </Flex>
                    )}

                    <HStack
                        paddingLeft="2rem"
                        justifyContent="flex-end"
                    >
                        {userIsValuer && !reject && (
                            <>
                                <Tooltip hasArrow label={document?.status === 'REJECTED' && t('alreadyRejected', { ns: 'documentmanagement' })} bg="grey">
                                    <Button
                                        minW="fit-content"
                                        variant={document?.status === 'REJECTED' ? 'none' : 'danger'}
                                        bg={document?.status === 'REJECTED' ? 'wvwGrey05' : 'red'}
                                        color={document?.status === 'REJECTED' ? 'grey' : 'white'}
                                        onClick={document?.status !== 'REJECTED' ? (() => {
                                            setReject(true);
                                            setChatOpen(true);
                                        }) : undefined}
                                    >
                                        {t('button.reject', { ns: 'common' })}
                                    </Button>
                                </Tooltip>

                                <Tooltip hasArrow label={document?.status === 'ACCEPTED' && t('alreadyAccepted', { ns: 'documentmanagement' })} bg="grey">
                                    <Button
                                        minW="fit-content"
                                        variant={document?.status === 'ACCEPTED' ? 'grey' : 'primaryGreen'}
                                        color={document?.status === 'ACCEPTED' ? 'grey' : 'white'}
                                        onClick={document?.status !== 'ACCEPTED' ? (
                                            () => updateDocumentStatus({
                                                requestId: Number(requestId),
                                                documents: [{
                                                    id: Number(docId),
                                                    status: 'ACCEPTED',
                                                }],
                                            })
                                        ) : undefined}

                                    >
                                        {t('button.approve', { ns: 'common' })}
                                    </Button>
                                </Tooltip>
                            </>
                        )}
                    </HStack>
                </Flex>
            </VStack>

            <Flex w="100%">
                <Box
                    pr=".5rem"
                    w="100%"
                >
                    {!document?.url && document.status !== 'UNAVAILABLE' && <LoadingSpinner />}

                    {document.status === 'UNAVAILABLE' && (
                        <AbsoluteCenter>
                            <Text color="wvwGrey60">
                                {t('documentUnavailable', { ns: 'documentmanagement' })}
                            </Text>
                        </AbsoluteCenter>
                    )}

                    {
                        (docFileType === 'pdf' || docFileType === 'PDF') && (
                            <iframe
                                src={document?.url}
                                width="100%"
                                height="100%"
                                title="PDF Preview"
                            />
                        )
                    }

                    {
                        (docFileType === 'img' || docFileType === 'png' || docFileType === 'jpg' || docFileType === 'jpeg') && (
                            <Center
                                w="100%"
                                h="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                overflow="scroll"
                            >
                                <Box
                                    h="100%"
                                >
                                    <img
                                        src={document?.url}
                                        style={{ objectFit: 'contain' }}
                                        alt="Preview"
                                    />
                                </Box>
                            </Center>
                        )
                    }
                </Box>

                <VerticalDividerWithIcon
                    icon={chatOpen ? Message2 : Message}
                    onClick={() => setChatOpen(!chatOpen)}
                />

                {chatOpen && (
                    <VStack w="30rem">
                        {reject ? (
                            <FormikForm
                                initialValues={{
                                    note: '',
                                }}
                                onSubmit={values => {
                                    updateDocumentStatus({
                                        requestId: Number(requestId),
                                        documents: [{
                                            id: document.id,
                                            status: 'REJECTED',
                                            responseComment: values.note,

                                        }],
                                    });
                                    setReject(false);
                                }}
                            >
                                <VStack
                                    align="left"
                                >
                                    <Text color="wvwGreen">
                                        {t('rejectionNote', { ns: 'documentmanagement', defaultValue: 'Rejection note' })}
                                    </Text>

                                    <FormikTextarea
                                        name="note"
                                    />

                                    <ButtonGroup
                                        alignSelf="flex-end"
                                    >
                                        <Button
                                            variant="primaryYellow"
                                            onClick={() => setReject(false)}
                                        >
                                            {t('button.cancel', { ns: 'common' })}
                                        </Button>

                                        <Button
                                            variant="danger"
                                            type="submit"
                                        >
                                            {t('button.reject', { ns: 'common' })}
                                        </Button>
                                    </ButtonGroup>
                                </VStack>
                            </FormikForm>
                        ) : (
                            <CommentsThread
                                title={t('modal.title.documentComments', { ns: 'documentmanagement' })}
                                commentsList={documentComments}
                                onSend={values => {
                                    createDocumentComment({
                                        caseId: Number(caseId),
                                        documentId: Number(docId),
                                        comment: values.comment,
                                    });
                                }}
                                markAsRead={() => markAsRead()}
                            />
                        )}
                    </VStack>
                )}
            </Flex>

            <ConfirmationModal
                cancelButton={t('button.cancel', { ns: 'common' })}
                cancelButtonVariant="primary"
                continueButton={t('button.delete', { ns: 'common' })}
                continueButtonVariant="danger"
                isOpen={confirmDeleteOpen}
                content={t('modal.message.deleteDocument', { ns: 'documentmanagement' })}
                title={t('modal.title.deleteDocument', { ns: 'documentmanagement' })}
                onCancel={() => setConfirmDeleteOpen(false)}
                onContinue={() => {
                    deleteDocument({
                        documentRequestId: Number(requestId),
                        documentId: document.id,
                        deleted: true,
                    });
                }}
            />
        </ModalDymanic>
    );
};

export default DocumentPreview;
