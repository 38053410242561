import { DeleteIcon } from '@chakra-ui/icons';
import {
    createStandaloneToast,
    Flex,
    VStack,
    Heading,
    Spacer,
    HStack,
    Button,
    Box,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
    useClientInvitesGetSent,
    useClientInvitesDelete,
} from '../../../../../utils/api/hooks';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import ProfilePictureDefault from '../../../../../common/components/display/ProfilePictureDefault';

const ValuerSentClientInvites = () => {
    const { t } = useTranslation(['account', 'common']);

    const [inviteForDeletion, setInviteForDeletion] = useState<number | undefined>(undefined);

    const { toast } = createStandaloneToast();

    const {
        data: clientInvitesList,
        error: clientInviteslistError,
        isLoading: clientInvitesListIsLoading,
        refresh: clientInvitesListRefresh,
    } = useClientInvitesGetSent();

    const { update: deleteInvite } = useClientInvitesDelete({
        onSuccess: () => {
            setInviteForDeletion(undefined);
            clientInvitesListRefresh();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return (
        <>
            <Flex>
                <VStack
                    align="left"
                    w="25%"
                    alignSelf="flex-start"
                    spacing="6"
                >
                    <Box>
                        <Heading
                            as="h4"
                            size="md"
                            fontWeight="light"
                        >
                            {t('sentInvites')}
                        </Heading>

                        <Text
                            color="wvwGrey"
                            paddingTop="1rem"
                        >
                            {t('sentStatusBlurb')}
                        </Text>
                    </Box>
                </VStack>

                <Spacer />

                <VStack
                    w="70%"
                    paddingTop="1.5rem"
                >
                    {clientInvitesListIsLoading && (
                        <LoadingSpinner />
                    )}

                    {clientInviteslistError !== null && (
                        <Text>
                            {t('sListError')}
                        </Text>
                    )}

                    {clientInvitesList?.length === 0 && (
                        <Text
                            w="22rem"
                            align="center"
                        >
                            {t('noSentInvites')}
                        </Text>
                    )}

                    {clientInvitesList?.map(invite => (
                        <Flex
                            w="100%"
                            height="3.5rem"
                            border="1px solid"
                            borderColor="wvwGrey40"
                            bg="wvwGrey05"
                            borderRadius="8"
                            paddingInline="1rem"
                            key={invite.id}
                        >
                            <HStack>
                                <ProfilePictureDefault
                                    accountType="CLIENT"
                                    userType="ENTITY"
                                    size="2.5rem"
                                />

                                <Box paddingBlock="1rem">
                                    <Text
                                        color="wvwGrey80"
                                        fontWeight="medium"
                                    >
                                        {invite.clientCompanyName}
                                    </Text>
                                </Box>

                                <Text color="wvwGrey40">
                                    {invite.clientEmail}
                                </Text>
                            </HStack>

                            <Spacer />

                            <Button
                                variant="none"
                                paddingTop="1rem"
                                onClick={() => setInviteForDeletion(invite.id)}
                            >
                                <DeleteIcon />
                            </Button>
                        </Flex>
                    ))}
                </VStack>
            </Flex>

            <ConfirmationModal
                cancelButton={t('button.close', { ns: 'common' })}
                continueButton={t('button.continue', { ns: 'common' })}
                isOpen={inviteForDeletion !== undefined}
                title={t('warning.cancelInvitation.title', { ns: 'common' })}
                content={t('warning.cancelInvitation.prompt', { ns: 'common' })}
                onCancel={() => setInviteForDeletion(undefined)}
                onContinue={() => deleteInvite(inviteForDeletion as number)}
            />
        </>
    );
};

export default ValuerSentClientInvites;
