import {
    Button,
    ButtonGroup,
    Divider,
    Flex,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { DocumentCloud, Edit } from 'iconsax-react';
import { useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { DocumentRequestDocumentType, RequestDropTargetType, dragTargetTypes } from '../../../../../types';
import { FormikForm, FormikInput } from '../../../../../common/forms';
import { useDocumentRequestUpdateDocument } from '../../../../../utils/api/hooks';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    document: DocumentRequestDocumentType;
    isDeleteable?: boolean;
    isEditable?: boolean;
};

const DocumentListItem = (props: PropTypes) => {
    const { document, isDeleteable, isEditable } = props;

    const {
        id: documentId,
        caseId = null as unknown as number,
        name,
    } = document;

    const { t } = useTranslation('common');

    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const { update: updateDocument } = useDocumentRequestUpdateDocument({
        caseId,
        documentId,
    });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragTargetTypes.DOCUMENT_IMPORT,
        item: { documentId: document.id },
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as RequestDropTargetType;

            if (item && dropResult) {
                updateDocument({
                    documentId: item.documentId,
                    documentRequestId: dropResult.requestId,
                    folderId: dropResult.folderId,
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    if (isDragging) {
        return null;
    }

    return (
        <>
            <VStack
                paddingInline=".5rem"
                paddingTop="1rem"
                ref={drag}
                w="100%"
            >
                <Flex w="100%">
                    {editing && (
                        <FormikForm
                            initialValues={{ name }}
                            onSubmit={values => {
                                updateDocument({ documentId, name: values.name });

                                setEditing(false);
                            }}
                        >
                            <VStack
                                align="left"
                                spacing={3}
                                w="100%"
                            >
                                <FormikInput
                                    name="name"
                                    placeholder={t('renameFile', { ns: 'documentmanagement' })}
                                />

                                <ButtonGroup
                                    alignSelf="flex-end"
                                    paddingBottom=".8rem"
                                >
                                    <Button
                                        onClick={() => setEditing(false)}
                                        variant="primary"
                                    >
                                        {t('button.cancel')}
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="primaryYellow"
                                        alignSelf="flex-end"
                                    >
                                        {t('button.save')}
                                    </Button>
                                </ButtonGroup>
                            </VStack>
                        </FormikForm>
                    )}

                    {!editing && (
                        <>
                            <HStack
                                spacing={3}
                                w="100%"
                            >
                                <DocumentCloud size="1.5rem" />

                                <Text fontWeight="500" maxW="10rem" noOfLines={3}>
                                    {name}
                                </Text>
                            </HStack>

                            <Spacer />

                            <HStack spacing="1rem">
                                {isEditable && (
                                    <IconButton
                                        aria-label="Edit"
                                        icon={<Edit />}
                                        onClick={() => setEditing(true)}
                                        variant="none"
                                        size="sm"
                                    />
                                )}

                                {isDeleteable && (
                                    <WvwDeleteIconButton
                                        onClick={() => setDeleting(true)}
                                        color="black"
                                    />
                                )}
                            </HStack>
                        </>
                    )}
                </Flex>

                <Divider paddingTop=".8rem" />
            </VStack>

            <ConfirmationModal
                cancelButtonVariant="primary"
                continueButton={t('button.delete')}
                continueButtonVariant="danger"
                content={t('modal.message.deleteDocument', { ns: 'documentmanagement' })}
                isOpen={deleting}
                onCancel={() => setDeleting(false)}
                onContinue={() => {
                    updateDocument({
                        documentId,
                        deleted: true,
                    });

                    setDeleting(false);
                }}
                title={t('modal.title.deleteDocument', { ns: 'documentmanagement' })}
            />
        </>
    );
};

DocumentListItem.defaultProps = {
    isDeleteable: false,
    isEditable: false,
};

export default DocumentListItem;
