import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
// eslint-disable-next-line import/no-named-as-default
import Router from './Router';
import theme from './theme';

const queryClient = new QueryClient();

const { ToastContainer } = createStandaloneToast();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
            <ChakraProvider
                theme={theme}
            >
                <Router />
            </ChakraProvider>
        </DndProvider>

        <ToastContainer />
    </QueryClientProvider>
);

export default (App);
