import { useMutation } from 'react-query';
import { CaseSetDormantDto } from '../dto';
import { caseSetDormant } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useCaseSetDormant = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: CaseSetDormantDto) => caseSetDormant(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useCaseSetDormant;
