import {
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';

const Imprint = () => {
    const { t } = useTranslation('common');

    return (
        <VStack
            align="left"
            spacing="1rem"
        >
            <Text>
                Thorsten Lamberty
                <br />
                Hohe Bleichen 22
                <br />
                20345 Hamburg
                <br />
                Germany
                <br />
                Tel.: +49 89 5147 0703
                <br />

                {'E-Mail: '}

                <Link
                    color="wvwYellow"
                    href="mailto:platform@wer-bewertet-was.de"
                    isExternal
                >
                    platform@wer-bewertet-was.de
                </Link>

                {' | Web: '}

                <Link
                    as={ReactLink}
                    color="wvwYellow"
                    to="/"
                >
                    wer-bewertet-was.de
                </Link>
            </Text>

            <Heading size="sm">
                Rechtsform:
            </Heading>

            <Text>
                Gesellschaft mit beschränkter Haftung (GmbH) mit Sitz in Hamburg
            </Text>

            <Heading size="sm">
                Vertreten durch:
            </Heading>

            <Text>
                Thorsten Lamberty
            </Text>

            <Heading size="sm">
                Registereintrag:
            </Heading>

            <Text>
                Eintragung im Handelsregister
                <br />
                Registergericht: Amtsgericht Hamburg
                <br />
                Register-Nr. HRB 162977
            </Text>

            <Heading size="sm">
                Register-Nr. HRB 162977
            </Heading>

            <Text>
                DE330771292
            </Text>

            <Heading size="sm">
                Hinweis zu Links:
            </Heading>

            <Text>
                {`Soweit auf andere Internet-Seiten verwiesen wird (Hyperlinks), stellen diese keine Meinung oder Inhalte der ${t('whoValuesWhat')} dar.`}
            </Text>
        </VStack>
    );
};

export default Imprint;
