import { Accordion } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { DocumentRequestType } from '../../../../../types';
import RequestOverviewItem from './common/RequestOverviewItem';
import { useDocumentRequestGetRequests } from '../../../../../utils/api/hooks';
import { DocumentFiltersType, RequestFiltersType } from '../common/documentManagementAreaTypes';

type PropTypes = {
    inEditMode: boolean;
    folderId?: number | string;
    requestFilter: RequestFiltersType;
    documentFilter: DocumentFiltersType;
};

const Requests = (props: PropTypes) => {
    const {
        inEditMode,
        folderId,
        requestFilter,
        documentFilter,
    } = props;

    const { caseId } = useParams<{ caseId: string }>();

    const {
        data: requests = [] as DocumentRequestType[],
    } = useDocumentRequestGetRequests({
        caseId: Number(caseId),
        folderId,
        status: requestFilter === 'ALL' ? undefined : requestFilter,
    });

    return (
        <Accordion
            allowMultiple
            variant="white"
        >
            {requests.map(request => (
                <RequestOverviewItem
                    inEditMode={inEditMode}
                    key={request.id}
                    request={request}
                    documentFilter={documentFilter}
                />
            ))}
        </Accordion>
    );
};

Requests.defaultProps = {
    folderId: undefined,
};

export default Requests;
