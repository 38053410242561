import axios from 'axios';
import { getToken } from '../../firebase';
import goToLogin from './goToLogin';

const { REACT_APP_API_URL } = process.env;

type DataType = {
    [index: string]: unknown;
};

const patch = async (path: string, data?: DataType) => {
    const token = await getToken();

    return axios.patch(
        `${REACT_APP_API_URL}${path}`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    ).catch(error => {
        if (error?.response?.status === 401) {
            goToLogin();
        } else {
            throw new Error(error?.response?.data?.message || error?.response?.statusText || 'Unknown error');
        }
    });
};

export default patch;
