import { useQuery, useQueryClient } from 'react-query';
import { clientInvitesGetSent } from '../endpoints';

const CLIENT_INVITES_GET_SENT = 'CLIENT_INVITES_GET_SENT';

const useClientInvitesGetSent = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([CLIENT_INVITES_GET_SENT], () => clientInvitesGetSent());

    const refresh = () => {
        queryClient.invalidateQueries(CLIENT_INVITES_GET_SENT);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useClientInvitesGetSent;
