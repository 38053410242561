const specialValuationTypes = [
    'Asbestos',
    'Drainage',
    'Electric',
    'Gas & Central Heating',
    'Roof',
    'Structural',
    'Trees',
    'Wood and Damp',
    'Wall Anchors',
    'Wall Cavities',
    'Invasive Plants',
    'Refurbishment',
    'Sanitary',
];

export default specialValuationTypes;
