import {
    VStack,
    Heading,
    Text,
    Divider,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
    HStack,
    Link,
} from '@chakra-ui/react';
import { Folder } from 'iconsax-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AccordionContainer from '../../common/components/AccordionContainer';
import { DirectEnquiryDataType } from './types';
import { FormikForm, FormikTextarea } from '../../common/forms';
import PropertyOverview from '../../common/components/PropertyOverview';

type PropTypes = {
    enquiryData: DirectEnquiryDataType;
    onBack: () => void;
    onDone: (data: DirectEnquiryDataType) => void;
    valuerName: string;
};

const DirectEnquiryOverview = (props: PropTypes) => {
    const {
        enquiryData,
        onBack,
        onDone,
        valuerName,
    } = props;

    const { clientType = '' } = useParams();

    const { properties } = enquiryData;

    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);

    return (
        <FormikForm
            initialValues={{
                additionalRequirements: '',
            }}
            onSubmit={values => {
                onDone({
                    ...enquiryData,
                    additionalRequirements: values.additionalRequirements,
                });
            }}
        >
            <VStack
                align="left"
                w="100%"
                spacing={4}
                paddingBlock="1rem"
            >
                <Text>
                    {t('newEnquiry.overview.instruction')}
                </Text>

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Heading
                        as="h4"
                        color="wvwGreen"
                        pb="0.5rem"
                        pl="0.5rem"
                        size="md"
                    >
                        {t('newEnquiry.overview.valuation.sectionTitle')}
                    </Heading>

                    <VStack
                        w="100%"
                        align="left"
                    >
                        <Flex w="100%">
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuer.sectionTitle')}
                            </Text>

                            <Text>
                                {valuerName}
                            </Text>
                        </Flex>

                        <Divider />

                        <Heading
                            as="h4"
                            color="wvwGreen"
                            paddingBlock=".8rem"
                            size="md"
                        >
                            {t('newEnquiry.overview.valuation.sectionTitle')}
                        </Heading>

                        <Flex
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {t('newEnquiry.overview.valuation.type')}
                            </Text>

                            {enquiryData?.valuationType === 'Other Valuation' && (
                                <VStack
                                    align="left"
                                >
                                    <Text fontWeight="500">
                                        {t(enquiryData?.valuationType || '', { ns: 'formik', defaultValue: `${t('Any')}` })}
                                    </Text>

                                    {enquiryData?.valuationSpecial?.map(special => (
                                        <Text key={special} pl="1rem">
                                            {t(special, { ns: 'formik', defaultValue: special })}
                                        </Text>
                                    ))}

                                    <Text pl="1rem">
                                        {enquiryData?.valuationOther}
                                    </Text>
                                </VStack>
                            )}

                            {enquiryData?.valuationType === 'Retype Valuation' && (
                                <VStack
                                    align="left"
                                >
                                    <Text fontWeight="500">
                                        {t(enquiryData?.valuationType || '', { ns: 'formik', defaultValue: `${t('Any')}` })}
                                    </Text>

                                    {enquiryData?.retypeDocuments?.map(document => (
                                        <Link
                                            href={document.url}
                                            isExternal
                                            key={document.id}
                                            color="wvwGreen"
                                            pl="1rem"
                                        >
                                            <HStack>
                                                <Folder size="1rem" />

                                                <Text>
                                                    {document.name}
                                                </Text>
                                            </HStack>
                                        </Link>
                                    ))}
                                </VStack>
                            )}

                            {enquiryData?.valuationType !== ('Retype Valuation' && 'Special Valuation' && 'Other Valuation') && (
                                <Text>
                                    {t(enquiryData?.valuationType || '', { ns: 'formik', defaultValue: `${t('Any')}` })}
                                </Text>
                            )}
                        </Flex>

                        <Divider />

                        {clientType === 'company' && (
                            <>
                                <Flex w="100%">
                                    <Text
                                        maxW="17rem"
                                        pl="1rem"
                                        w="100%"
                                    >
                                        {t('newEnquiry.valuationSection.valuationStandards.sectionTitle')}
                                    </Text>

                                    <VStack align="left">
                                        {enquiryData.valuationStandards === 'Other' ? (
                                            <Text>
                                                {t(enquiryData.valuationStandardsOther || '', { ns: 'formik', defaultValue: `${t('Any')}` })}
                                            </Text>
                                        ) : (
                                            <Text>
                                                {t(enquiryData.valuationStandards || '', { ns: 'formik', defaultValue: `${t('Any')}` })}
                                            </Text>
                                        )}
                                    </VStack>
                                </Flex>

                                <Divider />
                            </>
                        )}

                        <Flex w="100%">
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {t('newEnquiry.valuationSection.valuationPurpose.sectionTitle')}
                            </Text>

                            <VStack align="left">
                                {enquiryData.valuationPurposes?.map(purpose => (
                                    <Text key={purpose}>
                                        {purpose === 'Other' ? '' : t(purpose, { ns: 'formik', defaultValue: purpose })}
                                    </Text>
                                ))}

                                {enquiryData.valuationPurposesOther && (
                                    <Text>
                                        {t(enquiryData.valuationPurposesOther || '', { ns: 'formik', defaultValue: `${t('Any')}` })}
                                    </Text>
                                )}
                            </VStack>
                        </Flex>

                        <Divider />

                        <Flex w="100%">
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {t('newEnquiry.valuationSection.completionDate.sectionTitle')}
                            </Text>

                            <Text>
                                {enquiryData.completionDate}
                            </Text>
                        </Flex>

                        <Divider />
                    </VStack>
                </VStack>

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Heading
                        as="h4"
                        size="md"
                        color="wvwGreen"
                        pb="0.5rem"
                        pl="0.5rem"
                    >
                        {t('propertyDetails.sectionTitle', { ns: 'enquiries' })}
                    </Heading>

                    <Flex
                        w="100%"
                        alignItems="flex-start"
                    >
                        <VStack
                            w="100%"
                            alignSelf="end"
                            align="start"
                            pl="1rem"
                        >
                            {properties?.map(property => (
                                <AccordionContainer
                                    title={`${property.street}, ${property.city}`}
                                    key={property.street}
                                >
                                    <VStack
                                        align="left"
                                        spacing="2"
                                    >
                                        <PropertyOverview property={property} />
                                    </VStack>
                                </AccordionContainer>
                            ))}
                        </VStack>
                    </Flex>
                </VStack>

                <VStack
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                    w="100%"
                >
                    <Heading
                        as="h4"
                        size="md"
                        color="wvwGreen"
                        pb="0.5rem"
                        pl="0.5rem"
                    >
                        {t('newEnquiry.invoiceSection.contactDetails.sectionTitle')}
                    </Heading>

                    <VStack
                        align="left"
                        w="100%"
                    >
                        <Flex w="100%">
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {`${t('enquiryContact.fullName', { ns: 'enquiries' })}:`}
                            </Text>

                            <Text>
                                {`${enquiryData.invoiceFName} ${enquiryData.invoiceLName}`}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex w="100%">
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {`${t('enquiryContact.email', { ns: 'enquiries' })}:`}
                            </Text>

                            <Text>
                                {enquiryData.invoiceEmail}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex w="100%">
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {`${t('enquiryContact.phoneNumber', { ns: 'enquiries' })}:`}
                            </Text>

                            <Text>
                                {`${enquiryData.invoiceMobileDialCode} ${enquiryData.invoiceMobile}`}
                            </Text>
                        </Flex>

                        <Divider />

                        <Flex w="100%">
                            <Text
                                maxW="17rem"
                                pl="1rem"
                                w="100%"
                            >
                                {`${t('enquiryContact.address', { ns: 'enquiries' })}:`}
                            </Text>

                            <Text>
                                {t('addressOneLiner', {
                                    street: enquiryData.billingStreet,
                                    city: enquiryData.billingCity,
                                    country: enquiryData.billingCountry,
                                    postalCode: enquiryData.billingPostalCode,
                                    ns: 'common',
                                })}
                            </Text>
                        </Flex>

                        <Divider />
                    </VStack>
                </VStack>

                <VStack
                    align="left"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                    w="100%"
                >
                    <Heading
                        as="h4"
                        size="md"
                        color="wvwGreen"
                        pb="0.5rem"
                        pl="0.5rem"
                    >
                        {t('newEnquiry.additionalComments')}
                    </Heading>

                    <FormikTextarea
                        name="additionalRequirements"
                    />
                </VStack>

                <Flex
                    minWidth="max-content"
                    alignItems="left"
                    gap="2"
                    w="100%"
                    paddingBlock="1rem"
                    paddingInline="2rem"
                >
                    <ButtonGroup gap="2">
                        <Button
                            variant="outline"
                            color="wvwGreen"
                            onClick={() => onBack()}
                        >
                            {t('button.back', { ns: 'common' })}
                        </Button>
                    </ButtonGroup>

                    <Spacer />

                    <Button
                        type="submit"
                        variant="primary"
                    >
                        {t('button.submit', { ns: 'common' })}
                    </Button>
                </Flex>
            </VStack>
        </FormikForm>
    );
};

export default DirectEnquiryOverview;
