import { useTranslation } from 'react-i18next';
import { standardYearsOfExperience } from '../vars/valuationsAndMembershipTypes';

const useStandards = () => {
    const { t } = useTranslation('common');

    const yearsOfExperience = standardYearsOfExperience.map(i => ({
        ...i,
        label: t(`standards.yearsOfExperience.${i.label}`, { defaultValue: i.label }),
    }));

    return {
        yearsOfExperience,
    };
};

export default useStandards;
