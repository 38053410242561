import { useQuery, useQueryClient } from 'react-query';
import { valuerInvitesReceived } from '../endpoints';

const VALUER_INVITES_RECEIVED = 'VALUER_INVITES_RECEIVED';

const useValuerInvitesReceived = () => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery([VALUER_INVITES_RECEIVED], () => valuerInvitesReceived());

    const refresh = () => {
        queryClient.invalidateQueries(VALUER_INVITES_RECEIVED);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useValuerInvitesReceived;
