import { useQuery, useQueryClient } from 'react-query';
import { adminGetEnquiry } from '../../endpoints';

const ADMIN_GET_ENQUIRY = 'ADMIN_GET_ENQUIRY';

const useAdminGetEnquiry = (enquiryId: string | number) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [ADMIN_GET_ENQUIRY, enquiryId],
        () => adminGetEnquiry(enquiryId),
    );

    const refresh = () => {
        queryClient.invalidateQueries(ADMIN_GET_ENQUIRY);
    };

    return {
        data,
        error,
        isLoading,
        refresh,
    };
};

export default useAdminGetEnquiry;
