import {
    Box,
    Button,
    ButtonGroup,
    createStandaloneToast,
    Flex,
    Heading,
    HStack,
    SimpleGrid,
    Spacer,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import {
    FormikCloudSelect,
    FormikConditionalSection,
    FormikForm,
    FormikInput,
    FormikListSelect,
    FormikRadioGroup,
    FormikSelect,
    FormikTextarea,
} from '../../../common/forms';
import { standardValuerQualifications } from '../../../common/vars/valuationsAndMembershipTypes';
import { useEnquiry, useEntityPanelValuers } from '../../../utils/api/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import standardMaxOfficeDistance from '../../../common/vars/valuationsAndMembershipTypes/standardMaxOfficeDistance';
import standardMinimumPartners from '../../../common/vars/valuationsAndMembershipTypes/standardMinimumPartners';
import standardRequiredYearsOfExperience from '../../../common/vars/valuationsAndMembershipTypes/standardRequiredYearsOfExperienceRequired';
import useEnquirySteps from '../common/hooks/useEnquirySteps';
import { useRole } from '../../../common/hooks';

const VALUER_QUALIFICATIONS = [
    ...standardValuerQualifications,
    'Other',
];

const validationSchema = Yup.object({
    toEntityIds: Yup.array()
        .required('errors.selectOneValuer'),
    valuerExperience: Yup.string()
        .required('errors.selectOption'),
    valuerMinimumPartners: Yup.string()
        .required('errors.selectOption'),
    valuerMaxOfficeDistance: Yup.string()
        .required('errors.selectOption'),
    allowFullMatchmaking: Yup.string()
        .required('errors.selectOption'),
    valuerMinimumPICoverPercentage: Yup.number().required('errors.required').nullable(),
});

const EnquiryValuerInfo = () => {
    const { t } = useTranslation(['enquiryform', 'formik', 'common']);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onContinue, onBack } = useEnquirySteps();
    const { enquiryId = '' } = useParams();
    const { toast } = createStandaloneToast();

    const { userIsClient, userIsValuer } = useRole();

    const { enquiry, isLoading: isEnquiryLoading, update: updateEnquiry } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => onContinue(),
        onUpdateError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        }),
    });

    const {
        valuerList,
        isLoading,
        error,
    } = useEntityPanelValuers();

    if (isLoading || isEnquiryLoading) {
        return <LoadingSpinner />;
    }

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                toEntityIds: enquiry?.toEntityIds || [],
                valuerQualifications: enquiry?.valuerQualifications || [],
                valuerQualificationsOther: enquiry?.valuerQualificationsOther || '',
                valuerExperience: enquiry?.valuerExperience || '0',
                valuerMinimumPartners: enquiry?.valuerMinimumPartners || '0',
                valuerMaxOfficeDistance: enquiry?.valuerMaxOfficeDistance || 'none',
                valuerMinimumPICoverPercentage: enquiry?.valuerMinimumPICoverPercentage,
                additionalRequirements: enquiry?.additionalRequirements || '',
                allowFullMatchmaking: enquiry?.allowFullMatchmaking?.toString() || 'true',
            }}
            onSubmit={values => {
                const data = {
                    ...values,
                    allowFullMatchmaking: userIsValuer ? false : values.allowFullMatchmaking === 'true',
                    id: Number(enquiryId),
                };

                updateEnquiry(data);
            }}
        >
            <VStack
                align="left"
                spacing={4}
                w="100%"
                paddingBlock="1.5rem"
                paddingInline="1rem"
            >
                <Text>
                    {t('newEnquiry.valuerSection.instruction')}
                </Text>

                { userIsClient && (
                    <>
                        <VStack
                            w="100%"
                            align="left"
                            bg="wvwGrey05"
                            padding="1rem"
                            borderRadius="10"
                            spacing={4}
                        >
                            <Box>
                                <Heading
                                    size="md"
                                    color="wvwGreen"
                                >
                                    {t('newEnquiry.valuerSection.valuers')}
                                </Heading>

                                <Text>
                                    {t('newEnquiry.valuerSection.selectValuers')}
                                </Text>
                            </Box>

                            {isLoading && (
                                <LoadingSpinner />
                            )}

                            {error !== null && (
                                <VStack>
                                    <Heading
                                        size="sm"
                                    >
                                        {t('error.weAreSorry')}
                                    </Heading>

                                    <Text>
                                        {t('error.valuerList')}
                                    </Text>
                                </VStack>
                            )}

                            {valuerList?.length === 0 && (
                                <VStack>
                                    <Text>
                                        {t('newEnquiry.valuerSection.noValuers')}
                                    </Text>
                                </VStack>
                            )}

                            <FormikListSelect
                                width="100%"
                                name="toEntityIds"
                                options={valuerList?.map(valuer => ({
                                    label: valuer.name,
                                    value: valuer.id,
                                }))}
                            />

                            <Button
                                variant="none"
                                color="wvwGreen"
                                fontWeight="500"
                                alignSelf="flex-end"
                                onClick={() => navigate('/dashboard/account/valuer-panel')}
                            >
                                <HStack>
                                    <AddIcon width=".8rem" />

                                    <Text>
                                        {t('button.addValuers', { ns: 'common' })}
                                    </Text>
                                </HStack>
                            </Button>
                        </VStack>

                        <Flex
                            align="center"
                        >
                            <Box
                                w="100%"
                                bg="wvwGrey05"
                                borderRadius="10"
                                padding="1rem"
                            >
                                <Heading
                                    size="md"
                                    paddingBottom=".5rem"
                                    color="wvwGreen"
                                >
                                    {t('newEnquiry.valuerSection.allowedValuers.sectionTitle')}
                                </Heading>

                                <Text>
                                    {t('newEnquiry.valuerSection.allowedValuers.blurb')}
                                </Text>

                                <Box
                                    display="inline-block"
                                >
                                    <FormikRadioGroup
                                        name="allowFullMatchmaking"
                                        width="max"
                                        options={[
                                            {
                                                label: t('yes', { ns: 'common' }),
                                                value: 'true',
                                            },
                                            {
                                                label: t('no', { ns: 'common' }),
                                                value: 'false',
                                            },
                                        ]}
                                    />
                                </Box>
                            </Box>
                        </Flex>
                    </>
                )}

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    padding="1rem"
                    borderRadius="10"
                >
                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('newEnquiry.valuerSection.qualifications.sectionTitle')}
                    </Heading>

                    <FormikCloudSelect
                        name="valuerQualifications"
                        options={VALUER_QUALIFICATIONS.map(valuerQualification => ({
                            label: t(valuerQualification, { ns: 'formik', defaultValue: valuerQualification }),
                            value: valuerQualification,
                        }))}
                    />

                    <FormikConditionalSection
                        formDataTarget="valuerQualifications"
                        condition={value => value.includes('Other')}
                    >
                        <VStack
                            align="start"
                            backgroundColor="wvwGrey05"
                            borderRadius="8"
                            padding="1rem"
                            spacing="4"
                            w="100%"
                        >
                            <Text
                                color="wvwGreen"
                            >
                                {t('newEnquiry.valuerSection.qualifications.other.instruction')}
                            </Text>

                            <FormikTextarea
                                name="valuerQualificationsOther"
                            />
                        </VStack>
                    </FormikConditionalSection>
                </VStack>

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    padding="1rem"
                    borderRadius="10"
                >
                    <Heading
                        size="md"
                        color="wvwGreen"
                    >
                        {t('newEnquiry.valuerSection.requirements.sectionTitle')}
                    </Heading>

                    <SimpleGrid
                        columns={2}
                        spacing={4}
                    >
                        <Box>
                            <Text
                                color="wvwGrey80"
                                fontWeight="500"
                                paddingBottom=".5rem"
                            >
                                {t('newEnquiry.valuerSection.requirements.minPostQualiExperience')}
                            </Text>

                            <FormikSelect
                                name="valuerExperience"
                                placeholder={t('fieldPlaceholder.pleaseSelect', { ns: 'common' })}
                                options={standardRequiredYearsOfExperience.map(
                                    experience => ({
                                        label: t(experience.label, { ns: 'formik', defaultValue: experience.label }),
                                        value: experience.value,
                                    }),
                                )}
                            />
                        </Box>

                        <Box>
                            <Text
                                color="wvwGrey80"
                                fontWeight="500"
                                paddingBottom=".5rem"
                            >
                                {t('newEnquiry.valuerSection.requirements.minPartners')}
                            </Text>

                            <FormikSelect
                                name="valuerMinimumPartners"
                                placeholder={t('fieldPlaceholder.pleaseSelect', { ns: 'common' })}
                                options={standardMinimumPartners.map(
                                    partners => ({
                                        label: t(partners.label, { ns: 'formik', defaultValue: partners.label }),
                                        value: partners.value,
                                    }),
                                )}
                            />
                        </Box>

                        <Box
                            paddingTop="1rem"
                        >
                            <Text
                                color="wvwGrey80"
                                fontWeight="500"
                                paddingBottom=".5rem"
                            >
                                {t('newEnquiry.valuerSection.requirements.maxDistanceProperty')}
                            </Text>

                            <FormikSelect
                                name="valuerMaxOfficeDistance"
                                placeholder={t('fieldPlaceholder.pleaseSelect', { ns: 'common' })}
                                options={standardMaxOfficeDistance.map(
                                    distance => ({
                                        label: t(distance.label, { ns: 'formik', defaultValue: distance.label }),
                                        value: distance.value,
                                    }),
                                )}
                            />
                        </Box>

                        <Box paddingTop="1rem">
                            <Text
                                color="wvwGrey80"
                                fontWeight="500"
                                paddingBottom=".5rem"
                            >
                                {t('newEnquiry.valuerSection.requirements.minPICover')}
                            </Text>

                            <FormikInput
                                name="valuerMinimumPICoverPercentage"
                                placeholder="0"
                                prefix="%"
                                type="number"
                                min="1"
                            />
                        </Box>
                    </SimpleGrid>
                </VStack>

                <VStack
                    w="100%"
                    align="left"
                    bg="wvwGrey05"
                    padding="1rem"
                    borderRadius="10"
                >
                    <Box>
                        <Heading
                            size="md"
                            color="wvwGreen"
                        >
                            {t('newEnquiry.valuerSection.requirements.additional.sectionTitle')}
                        </Heading>

                        <Text>
                            {t('newEnquiry.valuerSection.requirements.additional.instruction')}
                        </Text>
                    </Box>

                    <FormikTextarea name="additionalRequirements" />
                </VStack>
            </VStack>

            <Flex
                minWidth="max-content"
                alignItems="left"
                gap="2"
                w="100%"
                paddingBlock="1rem"
                paddingInline="2rem"
            >
                <ButtonGroup gap="2">
                    <Button
                        variant="outline"
                        color="wvwGreen"
                        onClick={() => onBack()}
                    >
                        {t('button.back', { ns: 'common' })}
                    </Button>
                </ButtonGroup>

                <Spacer />

                <ButtonGroup>
                    <Button
                        onClick={onOpen}
                        variant="danger"
                    >
                        {t('button.cancel', { ns: 'common' })}
                    </Button>

                    <Button
                        type="submit"
                        variant="primary"
                    >
                        {t('button.next', { ns: 'common' })}
                    </Button>
                </ButtonGroup>
            </Flex>

            <ConfirmationModal
                cancelButton={t('button.continueEditing', { ns: 'common' })}
                cancelButtonVariant="primary"
                continueButton={t('button.exit', { ns: 'common' })}
                continueButtonVariant="danger"
                isOpen={isOpen}
                title={t('warning.exit.title', { ns: 'common' })}
                content={t('newEnquiry.exitModalText', { ns: 'enquiryform' })}
                onCancel={onClose}
                onContinue={() => {
                    navigate('/dashboard');
                }}
            />
        </FormikForm>
    );
};

export default EnquiryValuerInfo;
