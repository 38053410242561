import { useMutation } from 'react-query';
import { SignupDto } from '../dto';
import { signup } from '../endpoints';

type ParamsType = {
    onSuccess: (data?: unknown) => void;
    onError: (error: Error) => void;
};

const useSignup = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: SignupDto) => signup(dto),
        {
            onSuccess,
            onError,
        },
    );

    return {
        update,
    };
};

export default useSignup;
