import {
    Button,
    Box,
    VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
    FormikForm,
    FormikTextarea,
    FormikListInput,
    FormikConditionalSection,
    FormikRadioGroup,
} from '../../../../../../common/forms';
import { useDocumentRequestCreateUnavailableDocuments, useDocumentRequestMarkRequestUnavailable } from '../../../../../../utils/api/hooks';
import ModalDynamic from '../../../../../../common/components/ModalDynamic';

const UNAVAILABLE = 'unavailable';
const INCOMPLETE = 'incomplete';

const validationSchema = Yup.object({
    unavailableOrIncomplete: Yup.string()
        .required('errors.required'),
    reason: Yup.string()
        .test('reason', 'errors.required', function (value) {
            if (this.parent.unavailableOrIncomplete !== UNAVAILABLE) {
                return true;
            }

            return value ? value !== '' : false;
        }),
    unavailableDocuments: Yup.array()
        .test('unavailableDocuments', 'errors.required', function (value) {
            if (this.parent.unavailableOrIncomplete !== INCOMPLETE) {
                return true;
            }

            return value ? value.length > 0 : false;
        }),
});

const RequestUnavailableOrIncomplete = () => {
    const {
        caseId = null as any,
        requestId = null as any,
    } = useParams();
    const { t } = useTranslation(['documentmanagement', 'common']);

    const navigate = useNavigate();

    const {
        update: markRequestUnavailable,
    } = useDocumentRequestMarkRequestUnavailable({
        caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    const {
        update: uploadUnavailableDocuments,
    } = useDocumentRequestCreateUnavailableDocuments({
        caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    return (
        <ModalDynamic
            header={t('modal.title.markIncompleteOrUnavailable', { ns: 'documentmanagement' })}
            isOpen
            variant="small"
            onClose={() => navigate('../', { replace: true })}
        >
            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    unavailableOrIncomplete: '',
                    reason: '',
                    unavailableDocuments: [],
                }}
                onSubmit={values => {
                    if (values.unavailableOrIncomplete === UNAVAILABLE) {
                        markRequestUnavailable({
                            requestId: Number(requestId),
                            reason: values.reason,
                        });
                    }

                    if (values.unavailableOrIncomplete === INCOMPLETE) {
                        const missingDocuments = values.unavailableDocuments.map(
                            (missingDocument: string) => (
                                { name: missingDocument }
                            ),
                        );

                        uploadUnavailableDocuments({
                            requestId: Number(requestId),
                            documents: missingDocuments,
                        });
                    }
                }}
            >
                <VStack
                    alignItems="flex-start"
                    paddingTop="1rem"
                    w="100%"
                >
                    <FormikRadioGroup
                        direction="column"
                        fontWeight="normal"
                        name="unavailableOrIncomplete"
                        options={[
                            {
                                label: t('noDocumentsAvailable', { ns: 'documentmanagement' }),
                                value: UNAVAILABLE,
                            },
                            {
                                label: t('requestIncomplete', { ns: 'documentmanagement' }),
                                value: INCOMPLETE,
                            },
                        ]}
                        width="100%"
                    />

                    <FormikConditionalSection
                        condition={value => value === UNAVAILABLE}
                        formDataTarget="unavailableOrIncomplete"
                    >
                        <Box width="100%">
                            <FormikTextarea
                                name="reason"
                                placeholder={t('reason')}
                            />
                        </Box>
                    </FormikConditionalSection>

                    <FormikConditionalSection
                        condition={value => value === INCOMPLETE}
                        formDataTarget="unavailableOrIncomplete"
                    >
                        <FormikListInput
                            name="unavailableDocuments"
                            placeholder={t('missingDocument')}
                        />
                    </FormikConditionalSection>

                    <Button
                        size="sm"
                        type="submit"
                        variant="primaryYellow"
                        bg="wvwYellow"
                        alignSelf="flex-end"
                    >
                        {t('button.save', { ns: 'common' })}
                    </Button>
                </VStack>
            </FormikForm>
        </ModalDynamic>
    );
};

export default RequestUnavailableOrIncomplete;
