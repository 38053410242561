import {
    Text,
    VStack,
    Divider,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { DirectEnquiryDataType } from './types';
import AccordionContainer from '../../common/components/AccordionContainer';
import ConfirmationModal from '../../common/components/ConfirmationModal';
import { PropertyType } from '../../types';
import PropertyForm from '../../common/components/PropertyForm';
import PropertyOverview from '../../common/components/PropertyOverview';

type PropTypes = {
    onBack: () => void;
    onContinue: (values: any, goToNextStep?: boolean) => void;
    enquiryData: DirectEnquiryDataType;
};

type Property = Omit<PropertyType, 'id' | 'enquiryId'>;

const PropertyDescription = (props: PropTypes) => {
    const {
        onBack,
        onContinue,
        enquiryData,
    } = props;

    const { t } = useTranslation(['enquiryform', 'enquiries', 'common']);

    const [properties, setProperties] = useState<Property[]>(enquiryData.properties || []);
    const [editingProperty, setEditingProperty] = useState<Property | undefined>(undefined);

    const [deleteProp, setDeleteProp] = useState<Property | undefined>(undefined);

    useEffect(() => {
        if (properties.length === 0) {
            setEditingProperty({} as Property);
        }
    }, []);

    return (
        <>
            <VStack
                align="left"
                spacing="4"
                paddingBlock="1rem"
                paddingInline="2.5rem"
            >
                <Text>
                    {t('newEnquiry.propertyDetailsSection.instruction.fillOutForm')}
                </Text>

                {properties.map(property => (
                    <AccordionContainer
                        title={`${property.street}, ${property.city}`}
                        key={`${property.street} ${property.city}`}
                    >
                        <VStack
                            align="left"
                            spacing="2"
                        >
                            <PropertyOverview property={property} />

                            <Flex
                                alignSelf="flex-end"
                                gap="2"
                            >
                                <Button
                                    variant="danger"
                                    onClick={() => setDeleteProp(property)}
                                >
                                    {t('button.delete', { ns: 'common' })}
                                </Button>

                                <Spacer />

                                <Button
                                    variant="primary"
                                    onClick={() => setEditingProperty(property)}
                                >
                                    {t('button.edit', { ns: 'common' })}
                                </Button>
                            </Flex>
                        </VStack>

                        <ConfirmationModal
                            isOpen={deleteProp !== undefined}
                            title={t('warning.deleteProperty.title', { ns: 'common' })}
                            content={t('warning.deleteProperty.prompt', { ns: 'common' })}
                            onCancel={() => setDeleteProp(undefined)}
                            onContinue={() => {
                                const newProperties = properties
                                    .filter(
                                        i => JSON.stringify(i) !== JSON.stringify(deleteProp),
                                    );

                                setProperties(newProperties);

                                setDeleteProp(undefined);
                            }}
                        />
                    </AccordionContainer>
                ))}

                <Button
                    variant="none"
                    w="23%"
                    color="wvwGreen"
                    onClick={() => setEditingProperty({} as Property)}
                >
                    <AddIcon />

                    <Spacer width=".8rem" />

                    {t('button.addNewProperty', { ns: 'common' })}
                </Button>

                {properties.length === 0 && (
                    <Text color="red">
                        {t('newEnquiry.propertyDetailsSection.instruction.addProperty')}
                    </Text>
                )}
            </VStack>

            <Divider />

            <Flex
                minWidth="max-content"
                alignItems="left"
                gap="2"
                w="100%"
                paddingBlock="1rem"
                paddingInline="2rem"
            >
                <ButtonGroup gap="2">
                    <Button
                        variant="outline"
                        color="wvwGreen"
                        onClick={() => onBack()}
                    >
                        {t('button.back', { ns: 'common' })}
                    </Button>
                </ButtonGroup>

                <Spacer />

                <Button
                    type="submit"
                    variant="primary"
                    onClick={() => onContinue({})}
                >
                    {t('button.next', { ns: 'common' })}
                </Button>
            </Flex>

            <PropertyForm
                onCancel={() => setEditingProperty(undefined)}
                property={editingProperty}
                saveProperty={(initialProp, updatedProp) => {
                    if (updatedProp === undefined) {
                        setEditingProperty(undefined);
                        return;
                    }

                    const newProperties = [...properties];

                    const index = newProperties.findIndex(
                        p => JSON.stringify(p) === JSON.stringify(initialProp),
                    );

                    if (index !== -1) {
                        newProperties[index] = updatedProp;
                    } else {
                        newProperties.push(updatedProp);
                    }

                    setProperties(newProperties);

                    setEditingProperty(undefined);

                    onContinue(
                        {
                            properties: newProperties,
                        },
                        false,
                    );
                }}
            />
        </>
    );
};

export default PropertyDescription;
