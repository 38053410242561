import { useMutation } from 'react-query';
import { SetDormantDto } from '../dto/enquiry-set-dormant.dto';
import { enquirySetDormant } from '../endpoints';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useEnquirySetDormant = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: update } = useMutation(
        (dto: SetDormantDto) => enquirySetDormant(dto),
        {
            onSuccess: () => {
                onSuccess?.();
            },
            onError,
        },
    );

    return {
        update,
    };
};

export default useEnquirySetDormant;
