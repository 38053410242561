const residentialPropertyFlatTypes = [
    'Multi-Unit',
    'First Floor',
    'Apartment',
    'Top Floor',
    'Maisonette',
    'Penthouse',
];

export default residentialPropertyFlatTypes;
