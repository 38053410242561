import {
    Box,
    Text,
    VStack,
    Divider,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
    useDisclosure,
    createStandaloneToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { EnquiryType, PropertyType } from '../../../types';
import { useEnquiry } from '../../../utils/api/hooks';
import AccordionContainer from '../../../common/components/AccordionContainer';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import PropertyForm from '../../../common/components/PropertyForm';
import useEnquirySteps from '../common/hooks/useEnquirySteps';
import PropertyOverview from '../../../common/components/PropertyOverview';
import CancelEnquiryModal from '../common/CancelEnquiryModal';

type Property = Omit<PropertyType, 'id' | 'enquiryId'>;

const EnquiryPropertyInfo = () => {
    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);
    const { toast } = createStandaloneToast();
    const { onContinue, onBack } = useEnquirySteps();
    const { enquiryId = '' } = useParams();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        enquiry = {} as EnquiryType,
        isLoading: isEnquiryLoading,
        update: updateEnquiry,
    } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => onContinue(),
        onUpdateError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        }),
    });

    const [properties, setProperties] = useState<Property[]>(enquiry?.properties || []);
    const [editingProperty, setEditingProperty] = useState<Property | undefined>(undefined);
    const [deleteProp, setDeleteProp] = useState<Property | undefined>(undefined);

    useEffect(() => {
        if (properties.length === 0) {
            setEditingProperty({} as Property);
        }
    }, []);

    if (isEnquiryLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Box>
            <VStack
                align="left"
                spacing="4"
                paddingBlock="1rem"
                paddingInline="2.5rem"
            >
                <Text>
                    {t('newEnquiry.propertyDetailsSection.instruction.fillOutForm')}
                </Text>

                {properties?.map(property => (
                    <AccordionContainer
                        title={`${property.street}, ${property.city}`}
                        key={`${property.street} ${property.city}`}
                    >
                        <VStack
                            align="left"
                            spacing="2"
                        >
                            <PropertyOverview property={property} />

                            <Flex
                                alignSelf="flex-end"
                                gap="2"
                            >
                                <Button
                                    variant="danger"
                                    onClick={() => setDeleteProp(property)}
                                >
                                    {t('button.delete', { ns: 'common' })}
                                </Button>

                                <Button
                                    variant="primary"
                                    onClick={() => setEditingProperty(property)}
                                >
                                    {t('button.edit', { ns: 'common' })}
                                </Button>
                            </Flex>
                        </VStack>

                        <ConfirmationModal
                            cancelButtonVariant="primary"
                            continueButtonVariant="danger"
                            isOpen={deleteProp !== undefined}
                            title={t('warning.deleteProperty.title', { ns: 'common' })}
                            content={t('warning.deleteProperty.prompt', { ns: 'common' })}
                            onCancel={() => setDeleteProp(undefined)}
                            onContinue={() => {
                                const newProperties = properties
                                    .filter(
                                        i => JSON.stringify(i) !== JSON.stringify(deleteProp),
                                    );

                                setProperties(newProperties);

                                setDeleteProp(undefined);
                            }}
                        />
                    </AccordionContainer>
                ))}

                <Button
                    variant="none"
                    w="23%"
                    color="wvwGreen"
                    onClick={() => setEditingProperty({} as Property)}
                >
                    <AddIcon />

                    <Spacer width=".8rem" />

                    {t('button.addNewProperty', { ns: 'common' })}
                </Button>

                {properties.length === 0 && (
                    <Text color="red">
                        {t('newEnquiry.propertyDetailsSection.instruction.addProperty')}
                    </Text>
                )}
            </VStack>

            <Divider />
            <Flex
                minWidth="max-content"
                alignItems="left"
                gap="2"
                w="100%"
                paddingBlock="1rem"
                paddingInline="2rem"
            >
                <ButtonGroup gap="2">
                    <Button
                        variant="outline"
                        color="wvwGreen"
                        onClick={() => onBack()}
                    >
                        {t('button.back', { ns: 'common' })}
                    </Button>
                </ButtonGroup>

                <Spacer />

                <ButtonGroup>
                    <Button
                        onClick={onOpen}
                        variant="danger"
                    >
                        {t('button.cancel', { ns: 'common' })}
                    </Button>

                    <Spacer />

                    <Button
                        variant="primary"
                        onClick={() => {
                            if (properties.length === 0) {
                                toast({
                                    title: t('error.error', { ns: 'common' }),
                                    description: t('newEnquiry.propertyDetailsSection.instruction.addProperty', { ns: 'enquiryform' }),
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: true,
                                });
                                return;
                            }
                            const data = {
                                properties,
                                id: Number(enquiryId),
                            };

                            updateEnquiry(data);
                        }}
                    >
                        {t('button.next', { ns: 'common' })}
                    </Button>
                </ButtonGroup>
            </Flex>

            <PropertyForm
                allProperties={properties || []}
                onCancel={() => setEditingProperty(undefined)}
                property={editingProperty}
                saveProperty={(initialProp, updatedProp) => {
                    if (updatedProp === undefined) {
                        setEditingProperty(undefined);
                        return;
                    }

                    const newProperties = [...properties];

                    const index = newProperties.findIndex(
                        p => JSON.stringify(p) === JSON.stringify(initialProp),
                    );

                    if (index !== -1) {
                        newProperties[index] = updatedProp;
                    } else {
                        newProperties.push(updatedProp);
                    }

                    setProperties(newProperties);

                    setEditingProperty(undefined);
                }}
            />

            {/* <ConfirmationModal
                cancelButton={t('button.exit', { ns: 'common' })}
                continueButton={t('button.continueEditing', { ns: 'common' })}
                isOpen={isOpen}
                title={t('warning.exit.title', { ns: 'common' })}
                content={t('newEnquiry.exitModalText', { ns: 'enquiryform' })}
                onCancel={onClose}
                onContinue={() => {
                    navigate('/dashboard');
                }}
            /> */}
            <CancelEnquiryModal
                isOpen={isOpen}
                onCancel={onClose}
            />
        </Box>
    );
};

export default EnquiryPropertyInfo;
