import {
    Box,
    Container,
    Flex,
} from '@chakra-ui/react';
import Logo from './Logo';
import LanguageToggleButton from './LanguageToggleButton';
import AwaitingAccess from './AwaitingAccess';
import { ContentBox } from './display';
import Footer from './Footer';

const AwaitingAccessPage = () => (
    <Container
        maxW="container.md"
        paddingBlock="2rem"
    >
        <ContentBox color="white">
            <Flex justifyContent="space-between">
                <Logo withColor />

                <LanguageToggleButton />
            </Flex>

            <Box paddingBlock="2rem">
                <AwaitingAccess />
            </Box>
        </ContentBox>

        <Footer />
    </Container>
);

export default AwaitingAccessPage;
