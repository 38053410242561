import { useQuery, useQueryClient } from 'react-query';
import { entityPublicEntityGetById } from '../endpoints';
import storage from '../../storage/storage';
import { EntityType } from '../../../types';

const ENTITY_PUBLIC_ENTITY_BY_ENTITY_ID = 'ENTITY_PUBLIC_ENTITY_BY_ENTITY_ID';
const ENTITY_CERTIFICATES = 'ENTITY_CERTIFICATES';
const ENTITY_PROFILE_PICTURE = 'ENTITY_PROFILE_PICTURE';
const ENTITY_SAMPLE_VALUATIONS = 'ENTITY_SAMPLE_VALUATIONS';

const useEntityPublicEntityGetById = (entityId: number | string) => {
    const queryClient = useQueryClient();

    const {
        data,
        error,
        isLoading,
    } = useQuery(
        [ENTITY_PUBLIC_ENTITY_BY_ENTITY_ID, entityId],
        () => entityPublicEntityGetById(entityId),
        // {
        //     enabled: !entityId && !Number.isNaN(entityId),
        // },
    );

    const {
        data: entityCertificates,
        error: entityCertificatesError,
        isError: entityCertificatesIsError,
        isLoading: entityCertificatesIsLoading,
    } = useQuery(
        [ENTITY_CERTIFICATES, entityId],
        async () => {
            const certificateList = await storage.getEntityCertificates(
                entityId,
            );

            return certificateList;
        },
        {
            enabled: !!entityId,
        },
    );

    const entityCertificatesRefresh = () => {
        queryClient.invalidateQueries(ENTITY_CERTIFICATES);
    };

    const {
        data: entitySampleValuations,
        error: entitySampleValuationsError,
        isError: entitySampleValuationsIsError,
        isLoading: entitySampleValuationsIsLoading,
    } = useQuery(
        [ENTITY_SAMPLE_VALUATIONS, entityId],
        async () => {
            const documents = await storage.getEntitySampleValuations(
                entityId,
            );

            return documents;
        },
        {
            enabled: !!entityId,
        },
    );

    const entitySampleValuationsRefresh = () => {
        queryClient.invalidateQueries(ENTITY_SAMPLE_VALUATIONS);
    };

    const {
        data: entityProfilePictureUrl,
        error: entityProfilePictureUrlError,
        isError: entityProfilePictureUrlIsError,
        isLoading: entityProfilePictureUrlIsLoading,
    } = useQuery(
        [ENTITY_PROFILE_PICTURE, entityId],
        async () => {
            const profilePicture = await storage.getEntityProfilePictureUrl(entityId);

            if (!profilePicture) {
                return '';
            }

            return profilePicture;
        },
        {
            enabled: !!entityId,
        },
    );

    const refresh = () => {
        queryClient.invalidateQueries(ENTITY_PUBLIC_ENTITY_BY_ENTITY_ID);
    };

    return {
        data: data as EntityType,
        error,
        isLoading,
        refresh,

        entityCertificates,
        entityCertificatesError,
        entityCertificatesIsError,
        entityCertificatesIsLoading,
        entityCertificatesRefresh,

        entityProfilePictureUrl,
        entityProfilePictureUrlError,
        entityProfilePictureUrlIsError,
        entityProfilePictureUrlIsLoading,

        entitySampleValuations,
        entitySampleValuationsError,
        entitySampleValuationsIsError,
        entitySampleValuationsIsLoading,
        entitySampleValuationsRefresh,
    };
};

export default useEntityPublicEntityGetById;
